import { action, computed, makeObservable, observable } from 'mobx';

export default class GlobalErrorsService {
  public constructor() {
    makeObservable(this);
  }
  @observable
  private _userUnderEighteenError = '';

  @action
  public setUserUnderEighteenError(userUnderEighteenError: string) {
    this._userUnderEighteenError = userUnderEighteenError;
  }

  @computed
  public get userUnderEighteenError() {
    return this._userUnderEighteenError;
  }

  @observable
  private _unhandledError = '';

  @action
  public setUnhandledError(unhandledError: string) {
    this._unhandledError = unhandledError;
  }

  @computed
  public get unhandledError() {
    return this._unhandledError;
  }
}
