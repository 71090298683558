import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { ISubscriptionByUsableSubscription, SubscriptionsTrialPeriod } from 'types/subscriptions';

import { translateErrors } from 'utils/translateErrors';

import { CUSTOMER_SUPPORT_URL } from 'app/global/constants';
import { Routes } from 'app/global/routes';

import { CheckoutControllerContext, UpdateBillingControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Spinner from 'app/components/spinner/spinner';

import BillingInformation from '../billingInformation/billingInformation';
import SuccessAddCard from '../successAddCard/successAddCard';

import CheckoutOverview from './checkoutOverview/checkoutOverview';
import CheckoutSmallPrint from './checkoutSmallPrint/checkoutSmallPrint';

import styles from './checkoutSection.module.scss';

const CheckoutSection = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBillingInfo, setIsLoadingBillingInfo] = useState(true);
  const [errorInitPage, setErrorInitPage] = useState('');
  const [errorVerifyCheckout, setErrorVerifyCheckout] = useState('');
  const checkoutController = useContext(CheckoutControllerContext);
  const updateBillingController = useContext(UpdateBillingControllerContext);

  const navigate = useNavigate();
  const {
    cart,
    account,
    userCountryStored,
    stepCheckout,
    isErrorBuyProduct,
    errorCart,
    isNotCloutUser,
    errorBuyingProduct,
    isBuyingProduct,
    user,
    token3Ds,
    messageError3DSecure,
    setStepCheckout,
    updateStep,
    selectPaymentMechanism,
    buyProduct,
    onSuccessAfterBuyingProduct,
    onFailAfterBuyingProduct,
    setToken3Ds,
  } = checkoutController;

  const { paymentType } = updateBillingController;

  const [isBillingTypeIdeal, setIsBillingTypeIdeal] = useState(false);
  const product = cart?.product;
  const fetchCheckoutData = async () => {
    try {
      !isLoading && setIsLoading(true);
      setErrorVerifyCheckout('');

      await checkoutController.initCheckoutData();
    } catch (e: any) {
      setErrorInitPage(translateErrors(e));
    } finally {
      setIsLoading(false);
      setToken3Ds('');
    }
  };
  const checkHash = async () => {
    const queryStringHash = location.hash;
    const queryStringHashFail = queryStringHash.includes('fail');
    const queryStringHashSuccess = queryStringHash.includes('success');
    if (queryStringHashSuccess) {
      onSuccessAfterBuyingProduct();
    } else if (queryStringHashFail) {
      const path = queryStringHash.substring(2);
      const match = path.match(/error=([^&]+)/);
      const error = match ? match[1] : null;
      if (error) {
        await onFailAfterBuyingProduct(error);
      }
    }
  };

  const resetErrorSubmitCard = () => {
    if (errorCart || !product || !account) {
      user.account?.role === 'Member' ? navigate(Routes.Home) : navigate(Routes.Subscription);
    }
  };

  const changePlan = () => {
    navigate(Routes.SelectPlan);
  };

  useEffect(() => {
    fetchCheckoutData();
    checkHash();
  }, []);

  useEffect(() => {
    if (errorCart) {
      window.location.hash = '';
    }
  }, [errorCart]);
  if (isLoading) {
    return <Spinner>Fetching shopping cart...</Spinner>;
  }

  if (errorInitPage) {
    return (
      <>
        <Alert danger>{errorInitPage}</Alert>
        <Button xl onClick={resetErrorSubmitCard}>
          back
        </Button>
      </>
    );
  }

  if ((!product || !account) && stepCheckout !== 'processing_succeeded') {
    return (
      <>
        <Alert danger>Your shopping cart is empty.</Alert>
        <Button xl onClick={resetErrorSubmitCard}>
          back
        </Button>
      </>
    );
  }
  const shoppingCartEmpty = !product || !account;
  const isProductRecurringTrial =
    !shoppingCartEmpty && cart && product.recurring && product.trial_period_length && account?.can_start_trial;
  const isRenderSmallPrint =
    !shoppingCartEmpty && product.url && product.url.indexOf('subscriptionplan') > -1 && !cart.returnProduct;
  const isProductTrial =
    !isBuyingProduct && !shoppingCartEmpty && !!account.can_start_trial && !!product.trial_period_length;

  const isRenderBillingInfoSection =
    stepCheckout !== 'processing' && stepCheckout !== 'processing_succeeded' && stepCheckout !== 'processing_failed';

  const confirmPaymentMechanism = () => {
    paymentType && selectPaymentMechanism(paymentType);
    paymentType && updateBillingController.handleConfirmPaymentMechanism(paymentType);
  };

  return (
    <div>
      {isNotCloutUser && (
        <Alert className="alert-without-icon" danger>
          Utomik Cloud is not available in your region right now. By continuing with this plan, you may have a
          suboptimal experience.
        </Alert>
      )}
      {/* {!!isProductRecurringTrial && (
        <p className={styles['checkout-period']}>
          Payment starts after your free {product.trial_period_length}-
          {SubscriptionsTrialPeriod[product.trial_period || 'DY']} trial.
        </p>
      )} */}
      {errorVerifyCheckout && (
        <>
          <Alert danger>{errorVerifyCheckout}</Alert>
          <Button xl onClick={fetchCheckoutData}>
            back
          </Button>
        </>
      )}
      {errorCart && (
        <>
          <Alert danger>{errorCart}</Alert>
          <Button xl onClick={resetErrorSubmitCard}>
            back
          </Button>
        </>
      )}
      {!errorCart && !errorVerifyCheckout && (
        <div className={styles['checkout-section-includes']}>
          {product && product.trial_period_length && account?.can_start_trial && (
            <div className={styles['checkout-section-includes-item']}>
              <i className="fa-solid fa-hand-peace"></i>
              <span>Cancel at any time</span>
            </div>
          )}

          <div className={styles['checkout-section-includes-item']}>
            <i className="fa-solid fa-wallet"></i>
            <span>Enjoy a free trial!</span>
          </div>
          <div className={styles['checkout-section-includes-item']}>
            <i className="fa-solid fa-gamepad"></i>
            <span>Unlimited access to 1400+ games</span>
          </div>
        </div>
      )}

      {!errorCart && !errorVerifyCheckout && (
        <div className={styles['checkout-container']}>
          {!shoppingCartEmpty && (
            <>
              <div className={styles['checkout-container-overview-block']}>
                <div className={styles['checkout-container-overview-wrapper']}>
                  <div className={styles['checkout-container-overview']}>
                    {cart.returnProduct && (
                      <>
                        <h4 className={styles['checkout-container-overview-title']}>Your current subscription:</h4>
                        <CheckoutOverview
                          product={cart.returnProduct as unknown as ISubscriptionByUsableSubscription}
                          account={account}
                          productType={'returnproduct'}
                          userCountryStored={userCountryStored}
                          showOrderTotal={false}
                        />
                      </>
                    )}
                    {cart.returnProduct && (
                      <h4
                        className={clsx(
                          styles['checkout-container-overview-title'],
                          styles['checkout-container-overview-title-after-switching'],
                        )}
                      >
                        Your subscription after switching:
                      </h4>
                    )}
                    {!!isProductRecurringTrial && (
                      <h4 className={styles['checkout-container-overview-title']}>
                        Payment starts after your free {product.trial_period_length}-
                        {SubscriptionsTrialPeriod[product.trial_period || 'DY']} trial.
                      </h4>
                    )}
                    <CheckoutOverview
                      product={product}
                      account={account}
                      productType={'product'}
                      userCountryStored={userCountryStored}
                      showOrderTotal={true}
                    />
                  </div>
                </div>
                <div className={styles['checkout-btn-change-plan']}>
                  <Button
                    xl
                    block
                    onClick={changePlan}
                    disabled={stepCheckout === 'processing' || stepCheckout === 'processing_succeeded'}
                  >
                    Change plan
                  </Button>
                </div>
              </div>

              {stepCheckout !== 'confirm_checkout_without_billinginfo' && (
                <div className={styles['checkout-container-billing-block']}>
                  <div className={styles['checkout-container-billing']}>
                    {isProductTrial && isBillingTypeIdeal && (
                      <Alert info>You will be charged a small transaction fee to verify your bank account.</Alert>
                    )}
                    {(stepCheckout === 'view_billing_info' || stepCheckout === 'select_payment_mechanism') &&
                      !isLoadingBillingInfo &&
                      !isErrorBuyProduct && (
                        <h3 className={styles['checkout-container-billing-title']}>Please choose a payment option</h3>
                      )}
                    {stepCheckout === 'processing' && (
                      <SuccessAddCard
                        isFetching={true}
                        description={
                          <>
                            {cart &&
                              !cart.returnProduct &&
                              !(cart.product.trial_period_length && account.can_start_trial) && (
                                <p>Processing purchase</p>
                              )}
                            {cart &&
                              !cart.returnProduct &&
                              cart.product.trial_period_length &&
                              account.can_start_trial && <p>Verifying billing information</p>}
                            {cart && cart.returnProduct && <p>Changing your Utomik Plan</p>}
                            <span>(this may take a moment)</span>
                          </>
                        }
                      />
                    )}
                    {stepCheckout === 'processing_succeeded' && (
                      <SuccessAddCard
                        isFetching={false}
                        description={
                          <>
                            {!cart && <p>Request successful</p>}
                            {cart &&
                              !cart.returnProduct &&
                              !(cart.product.trial_period_length && account.can_start_trial) && (
                                <p>Purchase successful</p>
                              )}
                            {cart &&
                              !cart.returnProduct &&
                              cart.product.trial_period_length &&
                              account.can_start_trial && <p>Billing information verified</p>}
                            {cart && cart.returnProduct && <p>Your Utomik plan has been changed</p>}
                            <span>(you will be taken to the next screen in a few seconds)</span>
                          </>
                        }
                      />
                    )}
                    {stepCheckout === 'processing_failed' && errorBuyingProduct && (
                      <div className={styles['billing-info-empty']}>
                        <div>
                          <div className={styles['billing-info-error-buy']}>
                            <i className="fa-sharp fa-solid fa-xmark"></i>
                            {!cart && <p>Request unsuccessful</p>}
                            {cart &&
                              !cart?.returnProduct &&
                              !(cart.product.trial_period_length && account.can_start_trial) && (
                                <p>Purchase unsuccessful</p>
                              )}
                            {cart &&
                              !cart.returnProduct &&
                              cart.product.trial_period_length &&
                              account.can_start_trial && <p>Billing information verification failed</p>}
                            {cart && cart.returnProduct && <p>Changing Utomik plan failed</p>}

                            <Alert danger>{errorBuyingProduct}</Alert>
                          </div>
                          <div
                            className={clsx(
                              styles['billing-info-empty-buttons-checkout'],
                              styles['billing-info-empty-buttons-checkout-error'],
                            )}
                          >
                            <Button
                              block
                              onClick={() => setStepCheckout('select_payment_mechanism')}
                              disabled={isBuyingProduct}
                            >
                              Change payment method
                            </Button>
                            <Button
                              block
                              primary={false}
                              onClick={() => buyProduct()}
                              loading={isBuyingProduct}
                              disabled={isBuyingProduct}
                            >
                              Retry
                            </Button>
                          </div>
                          <p className={clsx(styles['small-text'], 'small')}>
                            If you keep experiencing problems, please{' '}
                            <a
                              href={CUSTOMER_SUPPORT_URL}
                              data-link="support"
                              className="text-danger"
                              title="Get in touch with our customer support!"
                            >
                              contact our support team
                            </a>
                          </p>
                        </div>
                      </div>
                    )}
                    {isRenderBillingInfoSection && (
                      <BillingInformation
                        isCheckoutPage={true}
                        selectedProductName={cart.product.name}
                        setStepCheckout={setStepCheckout}
                        updateStepCheckout={updateStep}
                        isProductTrial={!!isProductTrial}
                        selectPaymentMechanismCallback={selectPaymentMechanism}
                        setIsLoadingPage={setIsLoadingBillingInfo}
                        buyProduct={buyProduct}
                        callbackAfterUpdateCountry={fetchCheckoutData}
                        setIsBillingTypeIdeal={setIsBillingTypeIdeal}
                        checkoutInitPage={fetchCheckoutData}
                        setErrorVerifyCheckout={setErrorVerifyCheckout}
                        token3DsProduct={token3Ds}
                        messageError3DSecure={messageError3DSecure}
                      />
                    )}
                  </div>
                  {stepCheckout === 'select_payment_mechanism' && (
                    <div className={styles['checkout-btn-continue']}>
                      <Button primary block onClick={confirmPaymentMechanism} disabled={!paymentType}>
                        Continue
                      </Button>
                    </div>
                  )}
                </div>
              )}

              {stepCheckout === 'confirm_checkout_without_billinginfo' && (
                <div className={styles['checkout-container-billing']}>
                  {isBuyingProduct && <SuccessAddCard isFetching={isBuyingProduct} />}
                  {!isBuyingProduct && (
                    <>
                      <h3 className={styles['checkout-container-billing-title']}>Confirm</h3>
                      <p className={styles['checkout-period']}>Please confirm your selected product.</p>
                      <div className={styles['checkout-btn-without-billing']}>
                        <Button loading={isBuyingProduct} primary block onClick={() => buyProduct()}>
                          Confirm
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}

      {isRenderSmallPrint && !errorCart && !errorVerifyCheckout && (
        <CheckoutSmallPrint product={product} account={account} />
      )}
    </div>
  );
});
export default CheckoutSection;
