import { verifyEmailSchema } from 'app/validationSchemes/verifyEmailSchema';
import { FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import { useCallback, useContext, useState } from 'react';

import { isString } from 'types/guards';
import { emailError } from 'types/register';

import { isValidEmail } from 'utils/helpers';
import { translateErrors } from 'utils/translateErrors';

import { RegisterControllerContext, UserControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Input from 'app/components/input/input';

import './updateEmailForm.scss';

type UpdateEmailFormProps = {
  onCancel: () => void;
};

type FormValues = {
  password: string;
  email: string;
};

const UpdateEmailForm: React.FC<UpdateEmailFormProps> = observer(function UpdateEmailForm({ onCancel }) {
  const registerController = useContext(RegisterControllerContext);
  const userController = useContext(UserControllerContext);
  const [step, setStep] = useState<'password' | 'email'>('password');
  const [isLoading, setIsLoading] = useState(false);
  const [errorRequest, setErrorRequest] = useState('');
  const [fetchIsEmailAvailable, setFetchIsEmailAvailable] = useState(false);
  const initialValues: FormValues = { password: '', email: '' };

  const isDisabled = (errors: any, values: FormValues) => {
    if (step === 'password') return !!errors.password || !values.password;
    if (step === 'email') return !!errors.email || !values.email;

    return false;
  };

  const changeEmail = async (email: string) => {
    await registerController.changeEmail(email);
    await userController.fetchUser();
  };

  const checkPassword = async (password: string) => {
    await registerController.checkUserPassword(password);
  };

  const handleSubmit = async (values: FormValues) => {
    setIsLoading(true);

    try {
      if (step === 'password' && values.password) {
        await checkPassword(values.password);
        setErrorRequest('');
        setStep('email');
      }
      if (step === 'email' && values.email) {
        await changeEmail(values.email);
        onCancel();
      }
    } catch (error) {
      if (step === 'password') {
        setErrorRequest('Password incorrect.');
      } else {
        setErrorRequest(translateErrors(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: step === 'password' ? undefined : verifyEmailSchema,
    onSubmit: handleSubmit,
  });

  const debounceEmailValidation = async (email: string): Promise<void> => {
    if (!isValidEmail(email)) {
      return;
    }
    setFetchIsEmailAvailable(true);

    try {
      const isAvailableEmail = await registerController.checkIsEmailAvailable(email);
      if (!isAvailableEmail) {
        const errorMessage = 'This email address is already taken';
        formik.setFieldError('email', errorMessage);
      } else {
        formik.setFieldError('email', undefined);
      }
    } catch (error: any) {
      const message: string | undefined = error?.message;
      if (isString(message) && Object.keys(emailError).includes(message)) {
        const errorMessage = emailError[message as keyof typeof emailError];
        formik.setFieldError('email', errorMessage);
      }
    } finally {
      setFetchIsEmailAvailable(false);
    }
  };

  let debounceTimer: NodeJS.Timeout | null = null;

  const validateEmail = (emailValue: string) => {
    if (isValidEmail(emailValue)) {
      setFetchIsEmailAvailable(true);
    } else {
      setFetchIsEmailAvailable(false);
    }

    debounceTimer = setTimeout(async () => {
      await debounceEmailValidation(emailValue);
    }, 1000);
  };

  const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length && !formik.touched.email) {
      formik.setFieldTouched('email', true);
    }
    formik.handleChange(e);
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    validateEmail(e.target.value);
  }, []);

  const handleCancel = () => onCancel();

  const renderText = () =>
    step === 'password' ? (
      <p>Please fill in your password to continue.</p>
    ) : (
      <>
        <p>Please fill in your new email address.</p>
        <p>After changing your email address, we will send you an email with a new verification code.</p>
      </>
    );

  const renderInput = () =>
    step === 'password' ? (
      <Input
        className="update-email-form__input"
        type="password"
        name="password"
        placeholder="Password"
        disabled={isLoading}
        onChange={formik.handleChange}
        isValidateInputStroke={!!errorRequest}
      />
    ) : (
      <Input
        className="update-email-form__input"
        type="email"
        name="email"
        placeholder="Email address"
        disabled={isLoading}
        isLoading={fetchIsEmailAvailable}
        onChange={(e) => {
          handleEmailChange(e);
        }}
      />
    );

  return (
    <FormikProvider value={formik}>
      <form className="update-email-form" onSubmit={formik.handleSubmit}>
        {renderText()}
        {renderInput()}
        {errorRequest && (
          <Alert danger>
            <span dangerouslySetInnerHTML={{ __html: errorRequest }} />
          </Alert>
        )}
        <Button
          className="update-email-form__button"
          primary={step === 'password'}
          type="submit"
          disabled={isDisabled(formik.errors, formik.values) || fetchIsEmailAvailable || isLoading}
          loading={isLoading}
        >
          {step === 'password' ? 'Next step' : 'Update email'}
        </Button>
        <Button className="update-email-form__button" disabled={isLoading} onClick={handleCancel}>
          Cancel
        </Button>
      </form>
    </FormikProvider>
  );
});

export default UpdateEmailForm;
