import NavigateService from 'services/navigateService/navigateService';

export default class PageService {
  public constructor(private readonly navigateService: NavigateService) {}

  public reloadPage() {
    window.location.reload();
  }

  public getPath() {
    return window.location.pathname;
  }

  public getHostName() {
    return window.location.hostname;
  }

  public getHash() {
    return window.location.hash;
  }

  public checkPath(validPaths: string[]): boolean {
    const pathName = this.getPath();
    return validPaths.some((path) => pathName.includes(path));
  }

  public getQueryParams(locationStringOrLocation?: string | Location) {
    let queryParamsArray: string[] = [];

    if (!locationStringOrLocation) {
      queryParamsArray = window.location.search.length ? window.location.search.replace('?', '').split('&') : [];
    } else if (typeof locationStringOrLocation === 'string') {
      const search = locationStringOrLocation.includes('?')
        ? locationStringOrLocation.split('?')[1]
        : locationStringOrLocation;
      queryParamsArray = search.split('&');
    } else if (locationStringOrLocation instanceof Location) {
      queryParamsArray = locationStringOrLocation.search.replace('?', '').split('&');
    }

    if (!queryParamsArray?.length) {
      return {};
    }

    const parsedQueryParams: { [key: string]: string } = {};

    queryParamsArray?.forEach((param) => {
      const [key, value] = param.split('=');
      parsedQueryParams[key] = decodeURIComponent(value);
    });
    return parsedQueryParams;
  }

  public setLocation(newLocation: string, allowInsecureAbsoluteURLs?: boolean) {
    if (allowInsecureAbsoluteURLs) {
      window.location.href = newLocation;
      return;
    }
    this.navigateService.setNavigateLink(newLocation);
  }

  public checkIsFromRedeemPage = () => {
    const searchUrl = this.getHash();
    const decodedHash = decodeURIComponent(searchUrl);
    const params = new URLSearchParams(decodedHash.substring(1));
    const nextParam = params.get('next');
    const containsSubstring = nextParam && nextParam.includes('/log-in-and-redeem');
    return Boolean(containsSubstring);
  };
}
