import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavigationLink } from 'types/global';

import FooterLinkWrapper from './footerLinkWrapper';

import styles from './footerLink.module.scss';

const FooterLink: React.FC<NavigationLink> = ({ label, ...props }) => (
  <li className={styles.link}>
    <FontAwesomeIcon className={styles.link__arrow} icon="angle-right" />

    <FooterLinkWrapper className={styles.link__wrapper} {...props}>
      {label}
    </FooterLinkWrapper>
  </li>
);

export default FooterLink;
