import { action, computed, makeObservable, observable } from 'mobx';
import PaymentProviderService from 'services/paymentProviderService/paymentProviderService';

import { IdealBanks } from 'types/payment';

import { translateErrors } from 'utils/translateErrors';

export default class PaynlIdealController {
  public constructor(private readonly _paymentProviderService: PaymentProviderService) {
    makeObservable(this);
  }

  @observable
  private _idealBanks: IdealBanks[] = [];

  @action
  public setIdealBanks(idealBanks: IdealBanks[]) {
    this._idealBanks = idealBanks;
  }

  @computed
  public get idealBanks() {
    return this._idealBanks;
  }

  @observable
  private _isFetchingBanks = true;

  @action
  public setIsFetchingBanks(isFetchingBanks: boolean) {
    this._isFetchingBanks = isFetchingBanks;
  }

  @computed
  public get isFetchingBanks() {
    return this._isFetchingBanks;
  }

  @observable
  private _errorFetchBanks = '';

  @action
  public setErrorFetchingBanks(errorFetchBanks: string) {
    this._errorFetchBanks = errorFetchBanks;
  }

  @computed
  public get errorFetchBanks() {
    return this._errorFetchBanks;
  }

  public sortIdealBanks(idealBanks: IdealBanks[]): IdealBanks[] {
    return idealBanks.slice().sort((a, b) => {
      const nameA = a.visibleName.toUpperCase();
      const nameB = b.visibleName.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  public getIdealBanks = async () => {
    this.setIdealBanks([]);
    try {
      const idealBanks = await this._paymentProviderService.getPaynlBanks();
      const sortedBanks = this.sortIdealBanks(idealBanks);
      this.setIdealBanks(sortedBanks);
    } catch (e) {
      this.setErrorFetchingBanks(translateErrors(e));
    } finally {
      this.setIsFetchingBanks(false);
    }
  };
}
