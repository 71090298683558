import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { NavigationLink } from 'types/global';

import FooterIcon from './footerIcon';
import FooterLinkWrapper from './footerLinkWrapper';

import styles from './footerIconLink.module.scss';

const Content: React.FC<Omit<FooterIconLinkProps, 'to'>> = ({ label, description, icon }) => (
  <>
    <FooterIcon icon={icon} />

    <div>
      <p className={styles.link__label}>
        {'>'} {label}
      </p>

      <p className={styles.link__description}>{description}</p>
    </div>
  </>
);

export type FooterIconLinkProps = NavigationLink & {
  icon: IconProp;
  description: string;
};

const FooterIconLink: React.FC<FooterIconLinkProps> = ({ to, title, ...props }) => (
  <li className={styles.link}>
    <FooterLinkWrapper className={styles.link__wrapper} to={to} title={title}>
      <Content {...props} />
    </FooterLinkWrapper>
  </li>
);

export default FooterIconLink;
