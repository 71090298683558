import { action, computed, makeObservable, observable } from 'mobx';

export default class NavigateService {
  public constructor() {
    makeObservable(this);
  }
  @observable
  private _navigateLink = '';

  @action
  public setNavigateLink(link: string) {
    this._navigateLink = link;
  }

  @computed
  public get navigateLink() {
    return this._navigateLink;
  }
}
