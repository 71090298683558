import moment from 'moment';
import ServiceSettingService from 'services/serviceSettingService/serviceSettingService';
import UserService from 'services/userService/userService';

import { FreePeriodData, IDoRedeemFreeSubscriptionResponse } from 'types/shared';

import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import { FREE_PERIOD_SERVICE_SETTING } from 'app/global/constants';

export default class CampaignService {
  public constructor(
    private readonly _userService: UserService,
    private readonly _serviceSettingService: ServiceSettingService,
    private readonly _httpTransport: AxiosTransport,
  ) {}

  public async getActiveFreeSubscriptionCampaign(): Promise<FreePeriodData | null> {
    try {
      const freePeriodData = await this._serviceSettingService.getServiceSetting(FREE_PERIOD_SERVICE_SETTING);
      const now = moment();
      if (!freePeriodData || !(moment(freePeriodData.starts_at) <= now && moment(freePeriodData.ends_at) >= now)) {
        return null;
      }
      return freePeriodData as FreePeriodData;
    } catch (e) {
      return null;
    }
  }

  public async tryActivateFreeSubscription(): Promise<FreePeriodData | null> {
    await this._userService.fetchUser();
    const activeCampaign = await this.getActiveFreeSubscriptionCampaign();
    const user = this._userService.user;
    if (activeCampaign && user.promo_code && activeCampaign.promo_codes.includes(user.promo_code)) {
      const response = await this._httpTransport.post<IDoRedeemFreeSubscriptionResponse, undefined>(
        '/v2/promos/do_redeem_free_subscription',
        undefined,
      );
      if (!response?.data) return null;
      return activeCampaign;
    } else {
      return null;
    }
  }
}
