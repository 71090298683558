import { IAllCountries, ICountriesAlpha } from 'types/localizations';

interface IOptions {
  label: string;
  value: string;
}

export const countriesRange = (countries: IAllCountries[]): IOptions[] => {
  return countries.map((country) => ({
    label: country.countryName,
    value: `${country.countryId}`,
  }));
};

export const statesRange = (states: ICountriesAlpha[]): IOptions[] => {
  return states.map((state) => ({
    label: state.name,
    value: state.alpha2,
  }));
};
