import Button from 'app/components/button/button';

import styles from './newsLetterModal.module.scss';

interface INewsLetterModal {
  confirm: () => void;
  reject: () => void;
  isLoading: boolean;
}

const NewsLetterModal: React.FC<INewsLetterModal> = ({ confirm, reject, isLoading }) => {
  return (
    <div className={styles['newsLetter-body']}>
      <p className={styles['newsLetter-text']}>
        We always add new games, new features, and share exciting news about the company! Sign up for our newsletter, so
        that you're always the first to know!
      </p>
      <div className={styles['newsLetter-buttons']}>
        <Button primary disabled={isLoading} onClick={confirm}>
          Yes please!
        </Button>
        <Button className="btn btn-block" disabled={isLoading} onClick={reject}>
          No thanks
        </Button>
      </div>
    </div>
  );
};
export default NewsLetterModal;
