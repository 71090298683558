import clsx from 'clsx';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { NavigationLink } from 'types/global';

import { Routes } from 'app/global/routes';

import { useMediaQuery } from 'app/hooks/useMediaQuery';

import LogInForm from 'app/pages/login/loginForm';

import breakpoints from 'app/theme/breakpoints.module.scss';

import Button from '../../button/button';
import Modal from '../../modal/modal';
import MainNavigationSection from '../mainNavigationSection/mainNavigationSection';

import styles from '../header.module.scss';

export type NavigationProps = {
  navigateItems: NavigationLink[];
  register?: boolean;
  loginWithTvPlatform: boolean;
  isRegisterPage?: boolean;
};

const DefaultUserHeader: React.FC<NavigationProps> = ({
  navigateItems,
  register,
  loginWithTvPlatform = false,
  isRegisterPage = false,
}) => {
  const isShowLogInButtonHelperTitle = useMediaQuery(`(min-width: ${breakpoints.tablet})`);
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const startTrial = () => {
    navigate(Routes.Register);
  };
  return (
    <div className={clsx(styles['header-default'], isRegisterPage && styles['header-default-register'])}>
      {!register && <MainNavigationSection navigateItems={navigateItems} />}

      <div className={clsx(styles.buttons, register && styles['buttons--register'])}>
        {register ? (
          <>
            {isShowLogInButtonHelperTitle && <span>Already have an account?</span>}
            <Button onClick={openModal} className={styles['buttons--register-log-in']}>
              <span className="fa-solid fa-user" />
              Log in{' '}
            </Button>
          </>
        ) : (
          <>
            {!loginWithTvPlatform && (
              <Button className={styles.buttons__trial} onClick={startTrial} primary>
                Start your trial
              </Button>
            )}

            <button className={styles.buttons__user} onClick={openModal}>
              <img src="/images/ui/user.svg" alt="User" />
            </button>
          </>
        )}
      </div>

      <Modal show={modalIsOpen} onHide={closeModal} title="Log in">
        <LogInForm popup closeModal={closeModal} />
      </Modal>
    </div>
  );
};

export default DefaultUserHeader;
