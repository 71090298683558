import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { NavigationLink } from 'types/global';

import { PROMO_CODE_COOKIE, ROOT_DOMAIN } from 'app/global/constants';

import { useMediaQuery } from 'app/hooks/useMediaQuery';

import {
  HeaderControllerContext,
  PageControllerContext,
  PairingControllerContext,
  UserControllerContext,
} from 'app/context/storesContext';

import breakpoints from 'app/theme/breakpoints.module.scss';

import Dropdown from '../dropdown/dropdown';
import Logo from '../logo/logo';

import DefaultUserHeader from './defaultUserHeader/defaultUserHeader';
import LoggedInUserHeader from './loggedInUserHeader/loggedInUserHeader';
import VerifyUserHeader from './verifyUserHeader/verifyUserHeader';

import styles from './header.module.scss';

const navigationLinks: NavigationLink[] = [
  {
    label: 'Games',
    title: 'Games',
    to: 'https://www.utomik.com/games/',
  },
  {
    label: 'News',
    title: 'The latest news',
    to: 'https://www.utomik.com/news/',
  },
];

export type HeaderProps = {
  className?: string;
  register?: boolean;
  onlyLogo?: boolean;
};

const pathsWithoutNavigateLinks = ['/checkout', '/checkout/', '/register/checkout'];

const Header: React.FC<HeaderProps> = observer(({ className, register = false, onlyLogo = false }) => {
  const isShowDropdown = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
  const userController = useContext(UserControllerContext);
  const pageController = useContext(PageControllerContext);
  const pairingController = useContext(PairingControllerContext);
  const headerController = useContext(HeaderControllerContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [isShowRegisterFlow, setIsShowRegisterFlow] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const { pathname } = useLocation();
  const { email, checkIsLoggedIn, user } = userController;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refParam = urlParams.get('ref');
    if (refParam) {
      const encodedRefParam = btoa(encodeURIComponent(refParam));
      headerController.setCookie(PROMO_CODE_COOKIE, encodedRefParam);
    }
  }, []);
  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    setIsShowRegisterFlow(pairingController.getIsTvPlatform() || pageController.checkIsFromRedeemPage());
  }, [pathname]);

  const checkUserLoggedIn = async () => {
    setIsLoading(true);
    try {
      const isLoggedIn = await checkIsLoggedIn();
      if (isLoggedIn && !email) {
        await userController.fetchUser();
      }
      setIsLoggedInUser(isLoggedIn);
    } catch (e: any) {
      console.error(e, 'checkUserLoggedIn Header error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkUserLoggedIn();
  }, [user?.email]);
  const isRegisterPage = register && !isLoggedInUser;
  const handleToggle = () => setIsOpenDropdown((prev) => !prev);
  const renderWithDropdown = (children: ReactNode) => (
    <Dropdown
      id="header-navigation"
      menuClassName={clsx(styles['header__dropdown-menu'])}
      toggleClassName={clsx(
        styles['header__dropdown-button'],
        isOpenDropdown && styles['header__dropdown-button-open'],
      )}
      toggleContent={<FontAwesomeIcon icon="bars" />}
      onToggle={handleToggle}
    >
      {children}
    </Dropdown>
  );

  useEffect(() => {
    if (isOpenDropdown) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpenDropdown]);

  const verifyNavigatePanel = () => <VerifyUserHeader userEmail={email || ''} isDropdownMenu={isShowDropdown} />;
  const loggedInUserNavigationPanel = () => {
    const istRenderNavigateLinks = !pathsWithoutNavigateLinks.includes(pathname);

    return (
      <LoggedInUserHeader
        email={(user.username && `Hello ${user.username}`) || user.email}
        isShowDropdown={isShowDropdown}
        navigationLinks={istRenderNavigateLinks ? navigationLinks : []}
      />
    );
  };

  const defaultNavigationPanel = () => (
    <DefaultUserHeader
      navigateItems={navigationLinks}
      register={register}
      loginWithTvPlatform={isShowRegisterFlow}
      isRegisterPage={isRegisterPage}
    />
  );

  const renderNavigatePanel = () => {
    if (isLoading) return;
    if (pageController.checkPath(['/verify/']) || (isLoggedInUser && pageController.checkPath(['/verify-email/']))) {
      return verifyNavigatePanel();
    } else if (isLoggedInUser) {
      return loggedInUserNavigationPanel();
    } else {
      return defaultNavigationPanel();
    }
  };

  return (
    <header className={clsx(styles.header, className)}>
      <Link to={isLoggedInUser ? '/' : ROOT_DOMAIN} title="Home">
        <Logo />
      </Link>
      {isRegisterPage && isClient
        ? renderNavigatePanel()
        : !onlyLogo && isClient && isShowDropdown
        ? renderWithDropdown(renderNavigatePanel())
        : onlyLogo
        ? null
        : renderNavigatePanel()}
    </header>
  );
});

export default Header;
