import ChangePasswordModal from 'app/modals/changePasswordModal/changePasswordModal';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Routes } from 'app/global/routes';

import { DeleteAccountControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Modal from 'app/components/modal/modal';
import Spinner from 'app/components/spinner/spinner';

import styles from './deleteAccount.module.scss';

const DeleteAccount: React.FC = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const deleteAccountController = useContext(DeleteAccountControllerContext);
  const navigate = useNavigate();
  const {
    limitations,
    errorRequest,
    isFetching,
    modalIsOpen,
    reauthModalMessage,
    subscription,
    user,
    reseller,
    toggleModal,
    deleteAccount,
    requestDeletion,
  } = deleteAccountController;

  const iniPage = async () => {
    try {
      await deleteAccountController.initPage();
    } catch (e) {
      console.error('DeleteAccount iniPage');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    iniPage();
  }, []);

  const revokeDeletion = async () => {
    await deleteAccountController.revokeDeletion();
  };

  const redirectToSubscription = () => {
    navigate(Routes.Subscription);
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      {limitations.disallowedReason && (
        <Alert danger isShowIcon={false}>
          <div className={styles['icon-inline-middle']}>
            <span className="fas fa-2x fa-exclamation-triangle"></span>

            {limitations.disallowedReason === 'ACTIVE_SUBSCRIPTION' && (
              <div>
                <div>
                  You have an active subscription which is valid until{' '}
                  <strong> {moment(subscription.renews || subscription.paid_until).format('MMMM Do, YYYY')}</strong>.
                </div>
                <div>
                  To delete your account, please{' '}
                  <Button link onClick={redirectToSubscription}>
                    cancel your subscription
                  </Button>{' '}
                  first.
                </div>
              </div>
            )}
            {limitations.disallowedReason === 'RESELLER' && (
              <div>
                <div>You still have an active subscription.</div>
                <div>
                  Your subscription is managed via {reseller ? reseller : 'a reseller partner of Utomik'}. Please cancel
                  your subscription {reseller ? 'at ' + reseller + ' ' : ''}first.
                </div>
              </div>
            )}
          </div>
        </Alert>
      )}
      {!limitations.disallowedReason && limitations.warningReason && (
        <Alert warning isShowIcon={false}>
          <div className={styles['icon-inline-middle']}>
            <div>
              <span className="fas fa-2x fa-exclamation-triangle"></span>
            </div>
            {limitations.warningReason === 'FAMILY_PLAN_OWNER' && (
              <div>
                <div>You are the owner of a family plan.</div>
                <div>
                  Any members in your current family plan will lose access to Utomik when you delete your account.
                </div>
              </div>
            )}
            {limitations.warningReason === 'FAMILY_PLAN_MEMBER' && (
              <div data-name="message-family-plan-member">
                You are a member of a family subscription. You will be removed from the family plan when you delete your
                account.
              </div>
            )}
          </div>
        </Alert>
      )}
      {user.id && (
        <>
          {user.deletion_date && !errorRequest && (
            <div className={styles['alert-with-uther-deletion-date']}>
              <Alert warning>
                <div>
                  <p>Your account is pending deletion.</p>
                  <p>
                    Your account is set to be deleted on{' '}
                    <strong>{moment(user.deletion_date).format('MMMM Do, YYYY')}</strong>. If you change your mind, you
                    can revoke your deletion request before {moment(user.deletion_date).format('MMMM Do, YYYY')}.
                  </p>
                </div>
              </Alert>
            </div>
          )}
          {!user.deletion_date && !errorRequest && (
            <>
              {limitations.disallowedReason && (
                <p className={styles['subtitle-text']}>
                  When scheduling your account to be deleted, the following will be removed:
                </p>
              )}
              {!limitations.disallowedReason && (
                <p className={styles['subtitle-text']}>
                  You are about to schedule your account to be deleted. This will remove:
                </p>
              )}
              <ul className={styles['list']}>
                <li>your billing information;</li>
                <li>your personal information;</li>
                <li>all your game progress;</li>
                <li>your achievements;</li>
                <li>your save games;</li>
                <li>your subscription to our newsletter.</li>
              </ul>
            </>
          )}

          <div>
            {errorRequest && <Alert danger>{errorRequest}</Alert>}
            {user.deletion_date && (
              <Button primary onClick={revokeDeletion} loading={isFetching} disabled={isFetching}>
                Revoke request
              </Button>
            )}
            {!user.deletion_date && !limitations.disallowedReason && (
              <Button danger onClick={requestDeletion} loading={isFetching} disabled={isFetching}>
                Delete account
              </Button>
            )}
          </div>
          <Modal show={modalIsOpen} onHide={toggleModal} title="Are you sure?">
            <ChangePasswordModal
              closeModal={toggleModal}
              subtitle={reauthModalMessage}
              placeholder="Password"
              danger
              textButtonNext="Yes, delete my account"
              closeModalAfterConfirm={true}
              completeConfirm={deleteAccount}
            />
          </Modal>
        </>
      )}
    </div>
  );
});
export default DeleteAccount;
