import PreconditionService from 'services/preconditionService/preconditionService';

import { PreconditionPathNames } from 'types/shared';

export default class PreconditionController {
  public constructor(private readonly _preconditionService: PreconditionService) {}

  public getPreconditions() {
    return this._preconditionService.preconditions;
  }

  public async fetchPreconditions(path: string) {
    try {
      await this._preconditionService.fetchPreconditions(path as PreconditionPathNames);
    } catch (e) {
      console.error(e, 'fetchPreconditions');
    }
  }

  public clearPreconditions() {
    this._preconditionService.setPreconditions(null);
  }
}
