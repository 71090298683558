import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './footerIcon.module.scss';

export type FooterIconProps = {
  icon: IconProp;
};

const FooterIcon: React.FC<FooterIconProps> = ({ icon }) => (
  <div className={styles.icon}>
    <FontAwesomeIcon icon={icon} />
  </div>
);

export default FooterIcon;
