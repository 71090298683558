import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import styles from './alert.module.scss';

type AlertProps = React.PropsWithChildren<{
  icon?: IconProp;
  success?: boolean;
  info?: boolean;
  warning?: boolean;
  danger?: boolean;
  cloud?: boolean;
  inline?: boolean;
  className?: string;
  isShowIcon?: boolean;
}>;

const Alert: React.FC<AlertProps> = ({
  icon = 'triangle-exclamation',
  children,
  success,
  info,
  warning,
  danger,
  cloud,
  inline,
  className = '',
  isShowIcon = true,
}) => (
  <div
    className={clsx(styles.alert, 'alert-block', {
      [styles['alert--type-success']]: success,
      [styles['alert--type-info']]: info,
      [styles['alert--type-warning']]: warning,
      [styles['alert--type-danger']]: danger,
      [styles['alert--type-cloud']]: cloud,
      [styles['alert--inline']]: inline,
      [className]: className,
    })}
  >
    <div>
      {isShowIcon && icon && <FontAwesomeIcon icon={icon} />}
      {children}
    </div>
  </div>
);

export default Alert;
