import { Elements } from '@recurly/react-recurly';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { BillingInfoStep, IExtraData, IPaymentType, IUpdateIdealBank, IUpdateRecurly3DVerify } from 'types/billing';
import { IBuyProduct, StepsCheckout } from 'types/checkout';
import { Messages } from 'types/messages';
import { PaymentData3Ds } from 'types/payment';

import { translateErrors } from 'utils/translateErrors';

import { REACT_APP_RECAPTCHA_KEY } from 'app/global/constants';

import {
  LocalizationServiceContext,
  UpdateBillingControllerContext,
  UserControllerContext,
  UtomikSupportedInCountryControllerContext,
} from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Spinner from 'app/components/spinner/spinner';

import BillingInfoCard from '../billingInfoCard/billingInfoCard';
import PaynlIdeal from '../paynlIdeal/paynlIdeal';
import Recurly3DVerify from '../recurly3DVerify/Recurly3DVerify';
import RecurlyCreditCard from '../recurlyCreditCard/recurlyCreditCard';
import RecurlyPayPal from '../recurlyPayPal/recurlyPayPal';
import SelectCountryForm from '../selectCountryForm/selectCountryForm';
import SelectPaymentMechanism from '../selectPaymentMechanism/selectPaymentMechanism';
import SuccessAddCard from '../successAddCard/successAddCard';

import styles from './billingInformation.module.scss';

interface IBillingInformation {
  isCheckoutPage?: boolean;
  isProductTrial?: boolean;
  selectedProductName?: string;
  token3DsProduct?: string;
  messageError3DSecure?: string;
  setIsLoadingPage?: (isLoading: boolean) => void;
  setStepCheckout?: (step: StepsCheckout) => void;
  updateStepCheckout?: () => void;
  selectPaymentMechanismCallback?: (paymentMechanism: IPaymentType) => void;
  buyProduct?: ({ data, paymentType }: IBuyProduct) => void;
  callbackAfterUpdateCountry?: () => void;
  setIsBillingTypeIdeal?: (isIdeal: boolean) => void;
  checkoutInitPage?: () => Promise<void>;
  setErrorVerifyCheckout?: (error: string) => void;
}

const BillingInformation: React.FC<IBillingInformation> = observer(
  ({
    isCheckoutPage = false,
    isProductTrial,
    selectedProductName,
    token3DsProduct,
    messageError3DSecure,
    setIsLoadingPage,
    setStepCheckout,
    updateStepCheckout,
    selectPaymentMechanismCallback,
    buyProduct,
    callbackAfterUpdateCountry,
    setIsBillingTypeIdeal,
    checkoutInitPage,
    setErrorVerifyCheckout,
  }) => {
    const updateBillingInfoController = useContext(UpdateBillingControllerContext);
    const [errorRequest, setErrorRequest] = useState('');
    const [successRequest, setSuccessRequest] = useState(false);
    const userController = useContext(UserControllerContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isFetchingBillingInfo, setIsFetchingBillingInfo] = useState(false);
    const [errorInitPage, setErrorInitPage] = useState('');
    const [isFetchingCheckoutInitPage, setIsFetchingCheckoutInitPage] = useState(false);
    const { step, billingInfo, paymentType, sendAnalyticVerify } = updateBillingInfoController;
    const utomikSupportedInCountryController = useContext(UtomikSupportedInCountryControllerContext);
    const localizationService = useContext(LocalizationServiceContext);
    const [token3Ds, setToken3Ds] = useState('');
    const [recurlyData, setRecurlyData] = useState<IUpdateRecurly3DVerify | null>(null);
    const [error3DVerify, setError3DVerify] = useState('');
    const [error3DByProduct, setError3DByProduct] = useState(false);
    const initPage = async () => {
      try {
        await utomikSupportedInCountryController.checkIsUtomikSupportedInCountry();
        await updateBillingInfoController.initBillingPage();
        await localizationService.getCountries();
        updateStepCheckout && updateStepCheckout();
      } catch (e) {
        setErrorInitPage(translateErrors(e));
      } finally {
        setIsLoading(false);
        setIsLoadingPage && setIsLoadingPage(false);
      }
    };
    const scrollPageTop = () => {
      window.scrollTo(0, 0);
    };
    useEffect(() => {
      scrollPageTop();
      initPage();
    }, []);

    useEffect(() => {
      if (userController.user.country && step === 'select_country' && !token3DsProduct) {
        updateBillingInfoController.setBillingInfoStep();
      }
    }, [userController.user.country]);

    useEffect(() => {
      if (billingInfo?.type === 'ideal' && isCheckoutPage && setIsBillingTypeIdeal) {
        setIsBillingTypeIdeal(true);
      }
    }, [billingInfo?.type, isCheckoutPage, setIsBillingTypeIdeal]);

    useEffect(() => {
      return () => updateBillingInfoController.setStep('select_country');
    }, []);

    useEffect(() => {
      if (token3DsProduct) {
        setError3DByProduct(true);
        setError3DVerify(messageError3DSecure || '');
        updateBillingInfoController.setStep('verify_creditcard');
      } else {
        setError3DByProduct(false);
        setError3DVerify('');
        setIsFetchingBillingInfo(false);
      }
    }, [token3DsProduct]);
    const handleBuyProduct = async (data: PaymentData3Ds | null = null) => {
      if (buyProduct) {
        if (data?.three_ds_token) {
          setIsFetchingBillingInfo(true);
        }
        try {
          await buyProduct({
            data: data,
            paymentType: billingInfo?.type || paymentType,
          });
        } catch (e: any) {
          console.error('ERROR handleBuyProduct');
        }
      }
    };

    const handleSetNextStep = (step: BillingInfoStep) => {
      updateStepCheckout && updateStepCheckout();
      updateBillingInfoController.setStep(step);
      scrollPageTop();
    };

    const handleBack = () => {
      setStepCheckout && setStepCheckout('view_billing_info');
      updateBillingInfoController.setStep('view_billing_info');
      scrollPageTop();
      setErrorRequest('');
      setRecurlyData(null);
      setToken3Ds('');
    };
    const handleBackWithError = async () => {
      setIsFetchingCheckoutInitPage(true);
      await updateBillingInfoController.initBillingPage();
      checkoutInitPage && (await checkoutInitPage());
      handleBack();
      setErrorRequest('');
    };

    const updateStepAfterCreateCard = async () => {
      await updateBillingInfoController.initBillingPage();

      setTimeout(() => {
        setSuccessRequest(false);
        setStepCheckout && setStepCheckout('view_billing_info');
        updateBillingInfoController.setStep('view_billing_info');
      }, 4000);
    };

    const handleSubmitCreditCard = async (
      extraData: IExtraData,
      recurlyToken: string,
      paymentType: IPaymentType,
      three_ds_token?: string,
    ) => {
      setIsFetchingBillingInfo(true);

      try {
        await updateBillingInfoController.updateBillingInfo({
          extraData,
          token: recurlyToken,
          paymentType: paymentType || 'creditcard',
          ...(three_ds_token && { three_ds_token: three_ds_token }),
        });
        setRecurlyData(null);
        setToken3Ds('');
        setError3DVerify('');
        if (isCheckoutPage) {
          await handleBuyProduct();
          return;
        }
        setSuccessRequest(true);
        updateStepAfterCreateCard();
      } catch (e: any) {
        const errorField = e?.errorDetails?.errors;
        const error3ds = e.errorDetails?.transaction?.three_d_secure_action_token_id;
        const errorMessageVerify = e.errorDetails?.transaction?.customer_message;
        if (error3ds) {
          setRecurlyData({ paymentType: paymentType, extraData: extraData, token: recurlyToken });
          setToken3Ds(error3ds);
          setError3DVerify(errorMessageVerify || '');
          updateBillingInfoController.setStep('verify_creditcard');
          return;
        }
        if (errorField && Array.isArray(errorField) && errorField.length && 'field' in errorField[0]) {
          const errorMessage = `${errorField[0].message}`;

          // const errorMessage = errorField[0].field.split('.')[1] + ` ${errorField[0].message}`;
          setErrorRequest(errorMessage);
        } else {
          setErrorRequest(translateErrors(e, false, Messages.BILLING_INFORMATION_INCOMPLETE));
        }
      } finally {
        setIsFetchingBillingInfo(false);
      }
    };

    const handleSubmitIdealBank = async (data: IUpdateIdealBank) => {
      setIsFetchingBillingInfo(true);
      try {
        await updateBillingInfoController.updateIdealSelectedBank(data);
        if (isCheckoutPage) {
          await handleBuyProduct();
          return;
        }
        setSuccessRequest(true);
        updateStepAfterCreateCard();
      } catch (e: any) {
        setErrorRequest(translateErrors(e, false, Messages.BILLING_INFORMATION_INCOMPLETE));
      } finally {
        setIsFetchingBillingInfo(false);
      }
    };

    const renderBillingInfoForCheckoutPage = () => {
      const handleConfirmPayment: React.MouseEventHandler<HTMLButtonElement> = async () => {
        await handleBuyProduct();
      };
      return (
        <div>
          <div
            className={clsx(
              styles['billing-info-empty-buttons-checkout'],
              billingInfo?.type === 'ideal' && styles['billing-info-empty-buttons-checkout-single'],
            )}
          >
            {billingInfo?.type !== 'ideal' && (
              <Button
                block
                onClick={() => {
                  handleSetNextStep('select_payment_mechanism');
                  setStepCheckout && setStepCheckout('select_payment_mechanism');
                }}
                disabled={isFetchingBillingInfo}
              >
                Change payment method
              </Button>
            )}

            <Button
              block
              primary
              onClick={handleConfirmPayment}
              loading={isFetchingBillingInfo}
              disabled={isFetchingBillingInfo}
            >
              Confirm payment
            </Button>
          </div>
        </div>
      );
    };

    const renderBillingInfo = () => {
      return (
        <div className={styles['billing-info-empty']}>
          <div>
            <p className={styles['billing-info-empty-text']}>
              {!billingInfo
                ? 'No payment method is currently associated with your account.'
                : 'The following payment method is currently associated with your account:'}
            </p>

            {billingInfo && (
              <BillingInfoCard
                billingProvider={billingInfo.provider}
                cardType={billingInfo.card_type}
                lastFour={billingInfo.last_four}
                type={billingInfo.type}
              />
            )}
            {isCheckoutPage && userController.utomikIsLiveInUsersCountry && renderBillingInfoForCheckoutPage()}
          </div>

          {!isCheckoutPage && userController.utomikIsLiveInUsersCountry && billingInfo?.type !== 'ideal' && (
            <Button primary onClick={() => handleSetNextStep('select_payment_mechanism')}>
              {!billingInfo ? 'Add' : 'Edit'}
            </Button>
          )}
        </div>
      );
    };

    const renderRecurlyCreditCard = () =>
      !window?.recurly ? (
        <Alert danger>{Messages.COULDNT_FETCH_BILLINGINFO}</Alert>
      ) : (
        <Elements>
          <RecurlyCreditCard
            handleSubmitCard={handleSubmitCreditCard}
            handleBack={handleBack}
            isCheckoutPage={isCheckoutPage}
          />
        </Elements>
      );

    const renderRecurlyPayPal = () =>
      !window?.recurly ? (
        <Alert danger>{Messages.COULDNT_FETCH_BILLINGINFO}</Alert>
      ) : (
        <Elements>
          <RecurlyPayPal
            handleSubmitCard={handleSubmitCreditCard}
            handleBack={handleBack}
            selectedProductName={selectedProductName}
            isCheckoutPage={isCheckoutPage}
          />
        </Elements>
      );

    const verifyCreditCard = () => (
      <Elements>
        <Recurly3DVerify
          threeDSecureToken={token3DsProduct || token3Ds}
          handleSubmitCard={handleSubmitCreditCard}
          isError3DByProduct={error3DByProduct}
          handleBuyProduct={handleBuyProduct}
          handleBack={handleBack}
          isCheckoutPage={isCheckoutPage}
          recurlyData={recurlyData}
          sendAnalyticVerify={sendAnalyticVerify}
          errorMessage={error3DVerify}
          setErrorVerifyCheckout={setErrorVerifyCheckout}
        />
      </Elements>
    );

    const paynlIdeal = () => {
      return (
        <PaynlIdeal handleSubmit={handleSubmitIdealBank} isProductTrial={isProductTrial} handleBack={handleBack} />
      );
    };

    const renderSteps = (renderStep: BillingInfoStep) => {
      if (isCheckoutPage && !billingInfo && renderStep === 'view_billing_info') {
        handleSetNextStep('select_payment_mechanism');
      }

      switch (renderStep) {
        case 'select_country':
          return <SelectCountryForm callbackAfterUpdate={callbackAfterUpdateCountry} />;
        case 'view_billing_info':
          return renderBillingInfo();
        case 'select_payment_mechanism':
          return (
            <SelectPaymentMechanism
              isProductTrial={isProductTrial}
              selectPaymentMechanismCallback={selectPaymentMechanismCallback}
              handleBack={handleBack}
              isHideBack={isCheckoutPage && !billingInfo}
              isCheckoutPage={isCheckoutPage}
            />
          );
        case 'update_with_creditcard':
          return renderRecurlyCreditCard();
        case 'update_with_ideal':
          return paynlIdeal();
        case 'update_with_paypal':
          return renderRecurlyPayPal();
        case 'verify_creditcard':
          return verifyCreditCard();
      }
    };

    if (errorRequest) {
      {
        return (
          <div className={styles['error-billing-checkout']}>
            <Alert danger>{errorRequest}</Alert>
            <Button
              onClick={isCheckoutPage ? handleBackWithError : handleBack}
              disabled={isFetchingCheckoutInitPage}
              loading={isFetchingCheckoutInitPage}
            >
              {isCheckoutPage ? 'Continue' : 'Back'}
            </Button>
          </div>
        );
      }
    }

    if (errorInitPage) {
      return <Alert danger>{errorInitPage}</Alert>;
    }

    if (isLoading) {
      return <Spinner />;
    }
    if (isFetchingBillingInfo || successRequest) {
      return <SuccessAddCard isFetching={isFetchingBillingInfo} />;
    }
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={REACT_APP_RECAPTCHA_KEY}
        scriptProps={{
          async: false,
          defer: false,
          appendTo: 'head',
          nonce: undefined,
        }}
      >
        {renderSteps(step)}
      </GoogleReCaptchaProvider>
    );
  },
);
export default BillingInformation;
