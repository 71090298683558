import _ from 'lodash';

import { MAXIMUM_MINOR_AGE, MINIMUM_USER_AGE } from 'app/global/constants';

export const emptyOptions = {
  label: '',
  value: '',
};

export const dateOfBirthM = [
  {
    label: 'JAN',
    value: '01',
  },
  {
    label: 'FEB',
    value: '02',
  },
  {
    label: 'MAR',
    value: '03',
  },
  {
    label: 'APR',
    value: '04',
  },
  {
    label: 'MAY',
    value: '05',
  },
  {
    label: 'JUN',
    value: '06',
  },
  {
    label: 'JUL',
    value: '07',
  },
  {
    label: 'AUG',
    value: '08',
  },
  {
    label: 'SEP',
    value: '09',
  },
  {
    label: 'OCT',
    value: '10',
  },
  {
    label: 'NOV',
    value: '11',
  },
  {
    label: 'DEC',
    value: '12',
  },
];

const dayOfMonth = 31;

export const dateOfBirthD = () => {
  const days = [];

  for (let i = 1; i <= dayOfMonth; i++) {
    days.push({
      label: `${i < 10 ? '0' : ''}${i}`,
      value: `${i < 10 ? '0' : ''}${i}`,
    });
  }
  return days;
};

export const yearRange = _.map(_.range(1900, new Date().getFullYear() - (18 - 1)).reverse(), function (year) {
  return {
    label: year.toString(),
    value: year.toString(),
  };
});

export const memberEditYearRange = _.map(_.range(1900, new Date().getFullYear()).reverse(), function (year) {
  return {
    label: year.toString(),
    value: year.toString(),
  };
});

export const memberYearRange = _.map(
  _.range(new Date().getFullYear() - MAXIMUM_MINOR_AGE, new Date().getFullYear() - (MINIMUM_USER_AGE - 1)).reverse(),
  function (year) {
    return {
      label: year.toString(),
      value: year.toString(),
    };
  },
);

export const genderOptions = [
  { label: 'Male', value: 'm' },
  { label: 'Female', value: 'f' },
  { label: "Other/don't specify", value: 'u' },
];

export const memberOverEighteenOptions = [{ label: 'This person is over eighteen', value: '1' }];
export const memberUnderEighteenOptions = [{ label: 'This person is under eighteen ', value: '2' }];
