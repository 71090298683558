import { action, computed, makeObservable, observable } from 'mobx';

import { IServicesResponse } from 'types/localizations';

export default class ServiceSettingStore {
  public constructor() {
    makeObservable(this);
  }
  @observable
  private _serviceSetting: IServicesResponse[] = [];

  @action
  public setServiceSetting(serviceSetting: IServicesResponse[]) {
    this._serviceSetting = serviceSetting;
  }

  @computed
  public get serviceSetting(): IServicesResponse[] {
    return this._serviceSetting;
  }
}
