import React from 'react';

import Button from 'app/components/button/button';

import PromoCodeForm from './promocodeForm/promoCodeForm';

import styles from './setPromoCode.module.scss';

interface SetPromoModal {
  skipAndContinue: () => void;
}

const SetPromoCodeModal: React.FC<SetPromoModal> = ({ skipAndContinue }) => {
  return (
    <div>
      <div className={`modal-body ${styles['modal-set-promo']}`}>
        <p className={`${styles['modal-set-promo-subtitle']}`}>If you have a promo code, fill it in here.</p>
        <PromoCodeForm />

        {/* ng-click="continueToNext()" */}
        <Button block data-role="cancel" type="button" onClick={skipAndContinue}>
          Skip and continue
        </Button>
      </div>
    </div>
  );
};
export default SetPromoCodeModal;
