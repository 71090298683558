import { IOrdersResponse } from 'types/orders';
import {
  ISubscriptionByUsableSubscription,
  ISubscriptionsResponseWithOrders,
  SubscriptionPlan,
} from 'types/subscriptions';

export const toUsableAccountSubscription = (
  subscriptionPlan: ISubscriptionsResponseWithOrders,
): ISubscriptionsResponseWithOrders => {
  subscriptionPlan._isCloudSubscription = Boolean(subscriptionPlan.add_ons && subscriptionPlan.add_ons.length > 0);

  const order = subscriptionPlan.order;
  if (order && 'currency' in order) {
    subscriptionPlan._totalPricing = {
      currency: order.currency,
      amount_in_cents: order.amount_in_cents,
      includes_tax: order.includes_tax,
    };
  }

  return subscriptionPlan;
};

export const toUsableSubscription = (subscriptionPlan: SubscriptionPlan): ISubscriptionByUsableSubscription => {
  const isCloudPlan = subscriptionPlan.add_ons.some((addon) => {
    return addon.add_on_type === 'CLOUD';
  });
  const planAddOn = subscriptionPlan.add_ons.map((addon) => {
    return { ...addon, pricing: addon.pricing[0] };
  });

  const defaultPricing = subscriptionPlan.pricing?.length
    ? { ...subscriptionPlan.pricing[0] }
    : {
        currency: 'USD',
        amount_in_cents: 0,
        includes_tax: false,
      };
  const planTotalPricing = planAddOn.reduce((total, addon) => {
    total.amount_in_cents += addon.pricing.amount_in_cents;
    return total;
  }, defaultPricing);

  const copyPlan: ISubscriptionByUsableSubscription = {
    ...subscriptionPlan,
    _isCloudSubscription: isCloudPlan,
    add_ons: planAddOn,
    _totalPricing: planTotalPricing,
  };
  return copyPlan;
};

export const activeUtomikSubscriptions = ({
  subscriptions,
  filterType,
}: {
  subscriptions: ISubscriptionsResponseWithOrders[];
  filterType: 'canceledResellerSubscriptions' | 'activeUtomikSubscriptions';
}): ISubscriptionsResponseWithOrders[] => {
  const activeSubs = filterActiveSubscriptions(subscriptions);
  if (filterType === 'activeUtomikSubscriptions') {
    return activeSubs;
  }

  return utomikSubscriptions(activeSubs);
};

export const filterActiveSubscriptions = (
  subscriptions: ISubscriptionsResponseWithOrders[],
): ISubscriptionsResponseWithOrders[] => {
  return subscriptions.filter((subscription) => {
    return (
      subscription.status === 'ACTIVE' || subscription.status === 'CANCELED' || subscription.status === 'DEACTIVATED'
    );
  });
};

export const filterFamilySubscriptions = (
  subscriptions: ISubscriptionsResponseWithOrders[],
): ISubscriptionsResponseWithOrders[] => {
  return subscriptions.filter((subscription) => subscription.type === 'SF');
};

export const filterPersonalSubscriptions = (
  subscriptions: ISubscriptionsResponseWithOrders[],
): ISubscriptionsResponseWithOrders[] => {
  return subscriptions.filter((subscription) => subscription.type === 'SP');
};

export const utomikSubscriptions = (subscriptions: ISubscriptionsResponseWithOrders[]) => {
  return subscriptions.filter((subscription) => {
    return subscription.order && (subscription.order as IOrdersResponse).reseller === null;
  });
};

export const bestSubscriptionsForCloud = (
  subscriptions: ISubscriptionByUsableSubscription[],
  onlyCloudAllowed: boolean,
) => {
  const cloudValue = (item: ISubscriptionByUsableSubscription) => {
    return item._isCloudSubscription ? 1 : 0;
  };

  const personalPlanValue = (item: ISubscriptionByUsableSubscription) => {
    return item.type === 'SP' ? 1 : 0;
  };

  const priceValue = (item: ISubscriptionByUsableSubscription) => {
    return item._totalPricing ? item._totalPricing.amount_in_cents : 0;
  };

  let subs = subscriptions.sort((a, b) => {
    return (
      cloudValue(b) - cloudValue(a) || personalPlanValue(b) - personalPlanValue(a) || priceValue(b) - priceValue(a)
    );
  });

  if (onlyCloudAllowed) {
    subs = subs.filter((sub) => {
      return sub._isCloudSubscription;
    });
  }

  subs = subs.map(function (sub, idx) {
    sub._isRecommended = idx < 3;
    return sub;
  });

  return subs;
};

export const bestSubscriptionsWithoutCloud = ({
  subscriptions,
  isInTVFlow,
  isSwitching,
}: {
  subscriptions: ISubscriptionByUsableSubscription[];
  isInTVFlow: boolean;
  isSwitching: boolean;
}) => {
  let onlyNonCloudPlans = subscriptions.filter(function (subscription) {
    return !subscription._isCloudSubscription;
  });

  const shouldBeRecommended = !isInTVFlow || isSwitching;
  onlyNonCloudPlans = onlyNonCloudPlans.map(function (sub, idx) {
    sub._isRecommended = shouldBeRecommended && idx < 3;
    return sub;
  });

  return onlyNonCloudPlans;
};

export const getCanPurchase = (subscription: ISubscriptionByUsableSubscription) => {
  return subscription && subscription.can_purchase && subscription.can_purchase.allow;
};

const isRefMatchIfAny = (
  subscription: ISubscriptionByUsableSubscription,
  ref: string | number | null,
  forceRefMatch?: boolean,
) => {
  if (!forceRefMatch || !(subscription && subscription.ref)) {
    return !ref;
  }
  return subscription && subscription.ref && subscription.ref.code === ref;
};

export const findMatchingSubscription = (
  subscriptionPlans: ISubscriptionByUsableSubscription[],
  ref: string | number | null,
) => {
  return subscriptionPlans.find((subscription) => {
    return getCanPurchase(subscription) && isRefMatchIfAny(subscription, ref, true);
  });
};

export const filterFirstSubscriptionByType = (
  subscriptionPlans: ISubscriptionByUsableSubscription[],
  type: 'SP' | 'SF',
): ISubscriptionByUsableSubscription | null => {
  if (!subscriptionPlans || !type) {
    return null;
  }
  return subscriptionPlans.find((subscription) => subscription.type === type && getCanPurchase(subscription)) || null;
};
