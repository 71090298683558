import CountryUnSupported from 'app/widgets/countryUnSupported/countryUnSupported';
import { devices } from 'lists/devices';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { Messages } from 'types/messages';
import { BreadcrumbProps } from 'types/shared';

import {
  LoginControllerContext,
  PageControllerContext,
  RegisterControllerContext,
  UserControllerContext,
  UtomikSupportedInCountryControllerContext,
} from 'app/context/storesContext';

import Spinner from 'app/components/spinner/spinner';

import Breadcrumbs from './breadcrumbs';
import RegisterForm from './registerForm';

import './registerPage.scss';

const breadcrumbs: BreadcrumbProps[] = [
  {
    order: 1,
    title: 'Create account',
    subtitle: 'Create an account and verify your email address.',
    active: true,
  },
  {
    order: 2,
    title: 'Select plan',
    subtitle: 'Choose a plan that fits you.',
  },
  {
    order: 3,
    title: 'Start your trial',
    subtitle: 'Play our complete game library.',
  },
];

type ListItemProps = {
  title: string;
  subtitle: string;
  listIcon: React.ReactNode;
};

const ListItem: React.FC<ListItemProps> = ({ listIcon, title, subtitle }) => (
  <li>
    {listIcon}
    <div className="list-item-wrapper">
      <strong>{title}</strong>
      <span className="list-item-subtitle">{subtitle}</span>
    </div>
  </li>
);

const listItems: ListItemProps[] = [
  {
    title: 'Discover Utomik for free with a trial',
    subtitle: 'No strings attached. If you cancel, there is no charge.',
    listIcon: <img src="/images/icons/utomik-logo-min.png" className="checkmark" alt="Utomik logo" />,
  },
  {
    title: 'Play anywhere, anytime',
    subtitle:
      'Seamlessly enjoy 1400+ PC games with instant downloads and 300+ cloud-streamed games on Android and Smart TVs!',
    listIcon: <img src="/images/icons/icon-gamepad.png" className="checkmark" alt="Utomik logo" />,
  },
  {
    title: 'New games added every month',
    subtitle: 'We bring new titles to PC and Cloud each month, from classics to new releases!',
    listIcon: <img src="/images/icons/icon-cloud.png" className="checkmark" alt="Utomik logo" />,
  },
];

const List: React.FC = () => (
  <ul className="register-page__list">
    {listItems.map((props) => (
      <ListItem key={props.title} {...props} />
    ))}
  </ul>
);

const RegisterPage: React.FC = observer(() => {
  const loginController = useContext(LoginControllerContext);
  const pageController = useContext(PageControllerContext);
  const userController = useContext(UserControllerContext);
  const utomikSupportedInCountryController = useContext(UtomikSupportedInCountryControllerContext);
  const registerController = useContext(RegisterControllerContext);

  const [autoLogoutWarning, setAutoLogoutWarning] = useState('');
  const [errorWithUri, setErrorWithUri] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const setPromoCodeFromCookie = async () => {
    await registerController.setPromoCodeFromCookie();
  };

  useEffect(() => {
    setPromoCodeFromCookie();
  }, []);

  const handleLogout = async () => {
    try {
      await loginController.logout();
    } catch (e) {
      console.error(e, 'REGISTER PAGE handleLogout');
    }
  };

  const setErrorUri = (error: string) => {
    const errorMessage: keyof typeof Messages = error as any;
    if (errorMessage in Messages) {
      return setErrorWithUri(Messages[errorMessage]);
    }
    setErrorWithUri(Messages.GENERAL_FALLBACK);
  };

  const checkIsAutoLogOut = async () => {
    if (await loginController.checkIsLoggedIn()) {
      const pathName = location.pathname;
      const pathWithWarning = pathName + '#?warning=USER_AUTO_LOGOUT';
      await handleLogout();
      pageController.setLocation(pathWithWarning, false);
    }
  };

  const initPage = async () => {
    try {
      await utomikSupportedInCountryController.checkIsUtomikSupportedInCountry();
      await registerController.initPage();
    } catch (e) {
      console.error(e, 'REGISTER PAGE init');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await checkIsAutoLogOut();
      await initPage();
    };

    fetchData();
  }, []);

  useEffect(() => {
    return () => registerController.clearStates();
  }, []);

  useEffect(() => {
    const queryStringHash = location.hash;
    const warningOrError = queryStringHash.split('=')[0];
    const queryStringHashError = warningOrError.includes('error');
    const queryStringHashWarning = warningOrError.includes('warning');
    const hashToSearch = location.hash.substring(1);
    const searchParams = new URLSearchParams(hashToSearch);
    const inviteToken = searchParams.get('invitetoken');
    const email = searchParams.get('email');

    if (email) {
      registerController.setInviteEmail(email);
    }

    if (inviteToken) {
      registerController.setInviteToken(inviteToken);
    }

    if (queryStringHashWarning) {
      const warning = queryStringHash.split('=')[1];
      return setAutoLogoutWarning(warning);
    } else if (queryStringHashError) {
      const error = queryStringHash.split('=')[1];
      return setErrorUri(decodeURIComponent(error));
    }
  }, [location.hash]);

  useEffect(() => {
    const errorUri = pageController.getQueryParams();
    if (errorUri.error) {
      return setErrorUri(decodeURIComponent(errorUri.error));
    }
  }, [location.pathname]);

  const renderMainSection = () => {
    if (!userController.utomikIsLiveInUsersCountry) {
      const { name, isSetAsUserCountry = false } = userController.userCountry;
      return (
        <div className="register-page">
          <CountryUnSupported countryName={name} isSetAsUserCountry={isSetAsUserCountry} />
        </div>
      );
    } else {
      return (
        <div className="register-page">
          <div className="register-page__wrapper container">
            <Breadcrumbs breadcrumbs={breadcrumbs} />

            <div className="register-page__content">
              <RegisterForm autoLogoutWarning={autoLogoutWarning} errorWithUri={errorWithUri} />

              <div className="register-page__info">
                <div className="register-page__info-advantages">
                  <h2 className="register-page__title muted">Why sign up now?</h2>

                  <List />
                </div>
                <img
                  className="register-page__image"
                  src="/images/screenshots/utomik-multiple-devices.png"
                  alt="Utomik registration cross-platform support"
                />
                <div className="register-page__devices">
                  <div className="register-page__devices-logo">
                    {devices.map((device) => (
                      <div className="register-page__devices-logo-item" key={device.id}>
                        <div className="register-page__devices-logo-item-item">
                          {<img src={device.img} alt={device.id} />}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return <div>{renderMainSection()}</div>;
});

export default RegisterPage;
