import { Messages } from 'types/messages';

import { throttleRetryDuration } from './durationFilters';

export const parseErrorMessage = (error: any) => {
  if (typeof error === 'string') {
    return error;
  }
  const errorMessage = error.message;
  const parts = errorMessage.split(': ');
  if (parts.length === 2) {
    return parts[1];
  } else {
    return parts[0];
  }
};

const parseTimeInMessage = (errorMessage: string, shortTimeMessage?: boolean): string => {
  const messageTime = throttleRetryDuration(errorMessage);
  const messagePrefix = shortTimeMessage
    ? 'Too many requests. Please try again in'
    : 'We received too many requests all at once, so we have limited your access for a bit. Please try again in';
  return `${messagePrefix} ${messageTime}`;
};

export const translateErrors = (error: any, shortTimeMessage?: boolean, customMessage?: string): string => {
  const errorMessage: keyof typeof Messages = parseErrorMessage(error);
  const errorWithTime = errorMessage.indexOf('Request was throttled') >= 0;
  if (errorWithTime) {
    const message = parseTimeInMessage(errorMessage, shortTimeMessage);
    return message;
  }

  if (errorMessage in Messages) {
    return Messages[errorMessage];
  }
  if (customMessage) {
    return customMessage;
  }
  return errorMessage || Messages.DEFAULT_FALLBACK;
};
