import { action, computed, makeObservable, observable } from 'mobx';
import CookieService from 'services/cookieService/cookieService';
import LoginService from 'services/loginService/loginServise';
import PreconditionService from 'services/preconditionService/preconditionService';

import { IPreconditions, PreconditionPathNames } from 'types/shared';

import { urlsWithoutPreconditions } from '../constants';

export default class HeaderController {
  public constructor(
    private readonly _preconditionService: PreconditionService,
    private readonly _loginService: LoginService,
    private readonly _cookieService: CookieService,
  ) {
    makeObservable(this);
  }

  @observable
  private _preconditions: IPreconditions | null = null;

  @action
  public setPreconditions(precondition: IPreconditions | null) {
    this._preconditions = precondition;
  }

  @computed
  public get preconditions() {
    return this._preconditions;
  }

  public clearPreconditions() {
    this._preconditionService.clearPreconditions();
  }

  public async getPreconditions(pathname: string) {
    if (!urlsWithoutPreconditions.includes(pathname)) {
      await this._preconditionService.fetchPreconditions(pathname as PreconditionPathNames);
    } else {
      this.clearPreconditions();
    }
    this.setPreconditions(this._preconditionService.preconditions);
  }

  public async logout() {
    await this._loginService.logout();
  }

  public setCookie = (cookieName: string, cookieValue: string) => {
    this._cookieService.setCookie(cookieName, cookieValue, { path: '/' });
  };
}
