import { changePasswordModalSchema } from 'app/validationSchemes/changePasswordModalSchema';
import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useState } from 'react';

import { translateErrors } from 'utils/translateErrors';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Input from 'app/components/input/input';

import styles from './changePasswordModalForm.module.scss';

type FormValues = {
  password: string;
  confirmPassword: string;
};

const initialValues: FormValues = {
  password: '',
  confirmPassword: '',
};

interface IChangePasswordModalForm {
  handleConfirmChangePassword: (newPassword: string) => void;
  closeModal: () => void;
  setIsLoading: (isLoading: boolean) => void;
}

const ChangePasswordModalForm: React.FC<IChangePasswordModalForm> = ({
  handleConfirmChangePassword,
  closeModal,
  setIsLoading,
}) => {
  const [isFetchingChangePassword, setIsFetchingChangePassword] = useState(false);
  const [errorRequest, setErrorRequest] = useState('');
  const onSubmit = async (values: FormValues) => {
    setIsLoading(true);
    setIsFetchingChangePassword(true);
    try {
      await handleConfirmChangePassword(values.password);
      closeModal();
    } catch (e: any) {
      setErrorRequest(translateErrors(e.message));
    } finally {
      setIsLoading(false);
      setIsFetchingChangePassword(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordModalSchema,
    onSubmit: onSubmit,
    validateOnBlur: false,
  });

  const handlePasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value.length && !formik.touched['password'] && formik.setFieldTouched('password', true);

    formik.handleChange(e);
  }, []);

  const handleConfirmPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value.length && !formik.touched[`confirmPassword`] && formik.setFieldTouched('confirmPassword', true);

    formik.handleChange(e);
  }, []);
  return (
    <div>
      <p className={styles['subtitle']}>Change your password on Utomik by filling in your new password below.</p>
      <FormikProvider value={formik}>
        <div className={styles['form-change-password']}>
          <form onSubmit={formik.handleSubmit}>
            <div
              className={clsx(
                styles['form-field'],
                formik.errors.password && styles['form-field-error'],
                formik.touched.password && !formik.values.password && styles['form-field-error'],
              )}
            >
              <Input
                name="password"
                onChange={(e) => {
                  handlePasswordChange(e);
                }}
                onBlur={() => {
                  return;
                }}
                type="password"
                placeholder="Your new password"
                showCheck={false}
                isLoading={false}
                disabled={isFetchingChangePassword}
                isValidate={false}
              />
            </div>
            <div
              className={clsx(
                styles['form-field'],
                formik.errors.confirmPassword && formik.touched.confirmPassword && styles['form-field-error'],
              )}
            >
              <Input
                label=""
                name="confirmPassword"
                type="password"
                onChange={(e) => {
                  handleConfirmPasswordChange(e);
                }}
                onBlur={() => {
                  return;
                }}
                placeholder="Your new password (again)"
                showCheck={false}
                isLoading={false}
                disabled={isFetchingChangePassword}
                isValidate={false}
              />
            </div>

            {(formik.errors.password || (formik.errors.confirmPassword && formik.touched.confirmPassword)) && (
              <div className={styles['form-field-error']}>
                <Alert danger>{formik.errors.password || formik.errors.confirmPassword}</Alert>
              </div>
            )}
            {errorRequest && <Alert danger>{errorRequest}</Alert>}
            <Button
              type="submit"
              disabled={
                !formik.values.password ||
                !formik.values.confirmPassword ||
                !!formik.errors.confirmPassword ||
                !!formik.errors.password ||
                isFetchingChangePassword
              }
              xl
              loading={isFetchingChangePassword}
              block
              name="Change password"
              className={styles['form-button']}
            >
              Change password
            </Button>
          </form>
        </div>
      </FormikProvider>
    </div>
  );
};
export default ChangePasswordModalForm;
