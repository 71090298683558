export enum Messages {
  'ACCOUNT_ALREADY_ON_SAME_PLAN_TYPE' = 'You already have this type of plan.',
  'ACCOUNT_CANNOT_SWITCH_AGAIN_THIS_TERM' = 'You have already switched your plan the maximum amount of times this term. Please wait until the next term.',
  'ACCOUNT_DELETION_PENDING' = 'Your account is pending deletion.',
  'ACCOUNT_ERROR' = 'Could not find this account.',
  'ACCOUNT_HAS_NON_RECURRING_SUBSCRIPTION' = 'You already have an active plan. Please wait until your subscription expires.',
  'ACCOUNT_PAYS_VIA_RESELLER' = 'Your account is linked to a third party. You can only get a subscription there.',
  'AGE_UNDER_13' = 'Accounts for children under the age of 13 are currently not supported.<br/><br/> While Utomik does offer games that are rated for young gamers, we want to ensure that any such children who use the Utomik Service (along with their personal information) will be adequately protected. Therefore, we are planning to expand the Utomik Service to younger gamers once we can ensure compliance with all applicable privacy regulations.<br/><br/> We appreciate your patience, and, in the meantime, we encourage parents/guardians of young gamers to create their own account.',
  'INVALID_VERIFICATION_CODE' = 'This verification code is not valid.',
  'MISSING_VERIFICATION_CODE' = 'Please fill in the verification code that you received by email.',
  'EXPIRED_VERIFICATION_CODE' = 'Sorry, this verification code has expired. Please request a new one.',
  'USER_ALREADY_VERIFIED' = 'Your email has already successfully been verified.',
  'USER_AUTO_LOGOUT' = 'You have been logged out because this page is not available for users who are logged in. You can log in again, or register for a new account below.',
  'INCORRECT_LOGIN_CREDENTIALS' = 'Email and/or password incorrect.',
  'VERIFYING_EMAIL' = 'Verifying email address..',
  'NO_CODE_SPECIFIED' = 'Please enter a valid code.',
  'PAIR-1-LOGIN' = 'Other pairing code pending. <br/>Please <a href="/login/tv/">go back</a> and try again?',
  'PAIR-1-REGISTER' = 'Other pairing code pending. <br/>Please <a href="/register/tv/">go back</a> and try again?',
  'PAIR-2-LOGIN' = 'Pairing code not found. <br/>Please <a href="/login/tv/">go back</a> and try again?',
  'PAIR-2-REGISTER' = 'Pairing code not found. <br/>Please <a href="/register/tv/">go back</a> and try again?',
  'PAIR-3-REGISTER' = 'Pairing code is expired',
  'PAIR-INVALID' = "We couldn't find any device with this code. Please check your TV to make sure the code is correct.",
  'EXPIRED_TOKEN' = 'Could not change your password, because your reset link has expired.<br/><a href="/reset-password/">Request a new reset link.</a>',
  'Missing needed parameter `email address`' = 'We require your email address to register with a social media account. Please give us permission to retrieve your email address, or add an email address to your social media account. Click <a href="https://support.utomik.com/hc/en-us/articles/360004376073-Help-I-don-t-have-an-e-mail-address-on-my-Facebook-" target="_blank">here</a> for more information.',
  'Authentication failed: This social account is not associated with any Utomik account.' = "Your social media account isn't currently linked to a Utomik account.<br/>If you'd like to register a new account, click the button below!",
  'Authentication+failed:+This+social+account+is+not+associated+with+this+Utomik+account.' = 'This social media account is not connected to this account, please log in with the correct social media account.',
  'FAILED_WINDOW_COMMUNICATION' = 'Oops, something went wrong and we lost communication with the main window! Please close this window and try again, or contact support if the problem continues to exist.',
  'This+facebook+account+is+already+in+use.' = 'This social media account is not connected to this account, please log in on social with the correct account.',
  'This+crunchyroll+account+is+already+in+use.' = 'This social media account is not connected to this account, please log in on social with the correct account.',
  'BLACKLISTED_DOMAIN' = 'This email address is invalid.',
  'SOCIAL_ACCOUNT_ALREADY_IN_USE' = 'This social media account is not connected to this account, please log in on social with the correct account.',
  'GENERAL_FALLBACK' = 'Something went wrong trying to log you in. Please refresh and try again.',
  'USER_IS_OWNER' = 'You are already in this family plan. It is not possible to join your own family plan.',
  'CLOUD_REGISTRATION_NOT_ALLOWED' = 'Due to overwhelming demand, new cloud subscriptions are temporarily unavailable. Continue now to get a desktop-only subscription, or please try again later.',
  'COULD_NOT_FIND_COUNTRY' = 'Could not find country.',
  'COULD_NOT_READ_CART' = 'Could not read your shopping cart. Please check your internet connection and try again.',
  'INVALID_TOKEN' = 'Invalid token. Are you using the correct URL?',
  'INVALID_VERIFICATION_LINK' = 'This verification link is not valid. Please check your email and try again.',
  'LOGIN_FAILED' = 'Could not log you in. Please try again.',
  'AGE_UNDER_18' = 'You need to be at least 18 years old.',
  'USER_COUNTRY_NOT_SUPPORTED' = 'Utomik does not yet support this country.',
  'USERNAME_ALREADY_TAKEN' = 'This username is already taken.',
  'BILLING_INFORMATION_INCOMPLETE' = 'Could not update billing information; some details are missing. Please fill in all required information and try again.',
  'MISSING_PAYMENT_TOKEN' = 'Cannot finalize payment; missing payment token.',
  'RECAPTCHA_FAILED' = 'reCAPTCHA verification failed. Unfortunately, we are not sure that you are a human being. We could be wrong! Please try again, or <a href="https://support.utomik.com/hc/en-us/requests/new"> contact support</a> if the problem persists.',
  'EXPIRED_PROMO_CODE' = 'Sorry, this offer has expired.',
  'INVALID_PROMO_CODE' = 'Sorry, this code is invalid.',
  'PROMO-003' = 'Subscription has not expired yet.',
  'PROMO_CODE_USED' = 'This code has already been used.',
  'UNHANDLED_ERROR_CODE_undefined' = 'Something went wrong trying to log you in. Please refresh and try again.',
  'USER_HAS_AWAITING_PAYMENT_ORDER' = 'You still have a pending order. Please wait for the payment on the pending order to finish.',
  'CANNOT_CHANGE_FROM_NONRECURRING_PLAN_TO_CLOUD' = 'To continue in the app, you need a cloud subscription. Unfortunately, we cannot upgrade your subscription to one with cloud access. Please wait for the subscription to expire, or <a href="https://support.utomik.com/hc/en-us/requests/new" target="_blank">contact Customer Support</a>.',
  'CANNOT_CHANGE_FROM_NONRECURRING_PLAN' = `You have a subscription that automatically expires on this account. To switch to a different subscription, you will need to wait for your current subscription to end. <a href="https://support.utomik.com/hc/en-us/articles/360016864439-How-do-I-redeem-a-key-or-coupon-code-" target="_blank">Read more about it in our FAQ</a>.`,
  'CANNOT_CHANGE_TO_OR_FROM_FREE_PLAN' = 'You have an active subscription on this account. In order to get a new subscription, you first need to cancel your current subscription and wait for it to be terminated. Your save games and settings will be kept available on your account. <a href="https://support.utomik.com/hc/en-us/articles/360016864439-How-do-I-redeem-a-key-or-coupon-code-" target="_blank">Read more about it in our FAQ</a>.',
  'COULD_NOT_FETCH_AVAILABLE_SUBSCRIPTIONS' = 'We could not find any available subscriptions. Please contact us if the problem continues to exist.',
  'USER_IS_MEMBER' = 'Please contact the owner of your family account to do this.',
  'ORDER_NOT_CANCELED' = "Cannot reactivate this order; this order hasn't been canceled",
  'SUBSCRIPTION_MANAGED_VIA_PARTNER' = 'Your subscription is managed via a reseller partner of Utomik. Please go to the website of the party managing your Utomik subscription to manage your subscription.',
  'EMAIL_TAKEN' = 'This email address is already taken',
  'NO_DELETION_PENDING' = 'No deletion pending.',
  'INVALID_INVITE_TOKEN' = 'We could not find your invitation. Are you using the correct URL?',
  'BADLY_FORMED_INVITE_TOKEN' = 'Incorrect invite token.',
  'INVITE_TOKEN_ALREADY_USED' = 'This invitation has already been used.',
  'NO_INVITE_TOKEN_SPECIFIED' = 'Invite token is missing.',
  'NO_PRODUCT_IN_CART' = 'Your shopping cart is empty',
  'ORDER_ALREADY_DELIVERED' = 'Order was already delivered.',
  'ORDER_CANCELED' = 'You canceled the transaction.',
  'ORDER_NOT_PENDING' = "This order isn't pending",
  'PAYMENT_DENIED' = 'Your payment was denied. Please try again or contact us if the problem continues to exist.',
  'PAYMENT_EXPIRED' = 'The payment failed, try again later.',
  'PAYMENT_PENDING' = 'The payment failed, try again later.',
  'PRODUCT_NOT_ADDED_TO_CART' = 'Something went wrong adding this product to your shopping cart. Please check your internet connection and try again.',
  'INVITE_IS_USED' = 'Invite has already been used.',
  'MEMBER_IS_SUSPENDED' = 'Cannot log in: your access has been suspended by the owner of your family plan.',
  'COULDNT_FETCH_BILLINGINFO' = 'Could not retrieve your stored billing information. Please try again later. If this problem persists, contact Support.',
  'DEFAULT_FALLBACK' = 'Something went wrong. Please refresh and try again.',
  'SUBSCRIPTION_IN_GRACE' = 'Your subscription payment is still being processed.',
  'INVITING_AGE_UNDER_13_NOT_ALLOWED_IN_USA' = 'Accounts for children under the age of 13 are currently not supported.<br/><br/> While Utomik does offer games that are rated for young gamers, we want to ensure that any such children who use the Utomik Service (along with their personal information) will be adequately protected. Therefore, we are planning to expand the Utomik Service to younger gamers once we can ensure compliance with all applicable privacy regulations.<br/><br/> We appreciate your patience, and, in the meantime, we encourage parents/guardians of young gamers to create their own account.',
  'NO_EMAIL_SPECIFIED' = 'Please specify your email address.',
  'VERIFY_3D_ERROR' = 'Something went wrong. Please try again, or contact us if the problem persists.',
}
