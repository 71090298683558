import clsx from 'clsx';
import React from 'react';

import { IAccountMineResponse } from 'types/account';
import { ICartDataCheckout } from 'types/cart';

import { formatPriceToAmountInCents } from 'utils/textFormat';

import CheckoutProduct from '../checkoutProduct/checkoutProduct';

import styles from './checkoutOverview.module.scss';

export const zeroPrice = {
  currency: null,
  amount_in_cents: 0,
  includes_tax: false,
};

interface ICheckoutOverview {
  product: ICartDataCheckout['product'];
  account: IAccountMineResponse;
  productType: 'returnproduct' | 'product';
  userCountryStored: boolean;
  showOrderTotal: boolean;
}
const CheckoutOverview: React.FC<ICheckoutOverview> = ({
  productType,
  account,
  product,
  userCountryStored,
  showOrderTotal,
}) => {
  const isProductHasTrialPeriod = product?.trial_period_length && account.can_start_trial;
  const isProductHasRequiresPaymentInfo = product.requires_payment_info;

  return (
    <>
      <CheckoutProduct productType={productType} account={account} product={product} />
      {showOrderTotal && (
        <div
          className={clsx(
            styles['checkout-total'],
            product.name.toLowerCase().includes('cloud') && styles['checkout-total-cloud'],
          )}
        >
          <div className={styles['checkout-total-overview']}>
            <div>Order total</div>
            <div className={clsx(styles['checkout-total-overview-subtext'])}>
              {product.pricing
                ? product.pricing[0].includes_tax
                  ? 'Includes VAT where applicable.'
                  : 'Excludes VAT where applicable.'
                : 'VAT where applicable.'}
            </div>
          </div>
          <div className={styles['checkout-total-price']}>
            {!isProductHasTrialPeriod && isProductHasRequiresPaymentInfo && (
              <strong className="currency checkout-total-price">
                {formatPriceToAmountInCents(product._totalPricing, 'amount_in_cents')}
              </strong>
            )}
            {(isProductHasTrialPeriod || !isProductHasRequiresPaymentInfo) && (
              <strong className="currency checkout-total-price" data-name="price-trial">
                {product.pricing &&
                  formatPriceToAmountInCents(
                    { ...zeroPrice, currency: product.pricing[0].currency },
                    'amount_in_cents',
                  )}
              </strong>
            )}
          </div>
        </div>
      )}

      {/* {isProductHasTrialPeriod && (
        <div className={styles['icon-group']}>
          <span className={clsx('far fa-hand-peace', styles['icon-group-icon'])} />
          Cancel at any time
        </div>
      )} */}
      {!userCountryStored && (
        <div>
          <p className={clsx('small', styles['small-indent'])}>
            The price for your subscription has been estimated based on your location.
          </p>
        </div>
      )}
    </>
  );
};
export default CheckoutOverview;
