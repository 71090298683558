import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { useEffect } from 'react';

import { REACT_APP_SENTRY_ENV, SENTRY_DSN } from 'app/global/constants';

export const SentryInit = () => {
  useEffect(() => {
    if (!process.env.NODE_ENV || REACT_APP_SENTRY_ENV === 'localhost') {
      // to do
      // Maybe we should add some stage env and track errors from there
    } else {
      // production code
      Sentry.init({
        environment: REACT_APP_SENTRY_ENV,
        dsn: SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
      });
    }
  }, []);
};
