import React from 'react';

import { IInviteMember, StateInviteMember } from 'types/familyMembers';
import { IDate } from 'types/users';

import Button from 'app/components/button/button';

import SuccessAddCard from '../successAddCard/successAddCard';

import InviteFamilyForm from './inviteFamilyForm';

import styles from './inviteFamilyMember.module.scss';

interface IInviteFamilyMember {
  invitesRemaining: number;
  maxMembers: number;
  forbiddenEmails: string[];
  state: StateInviteMember;
  inviteMemberError: string;
  setStateInvite: (state: StateInviteMember) => void;
  isValidDate: (date: IDate) => boolean;
  isOfAge: (date: string, minAge?: number) => boolean;
  doInvite: (inviteMember: IInviteMember) => void;
}

const InviteFamilyMember: React.FC<IInviteFamilyMember> = ({
  invitesRemaining,
  maxMembers,
  forbiddenEmails,
  state,
  inviteMemberError,
  setStateInvite,
  isValidDate,
  isOfAge,
  doInvite,
}) => {
  const doOpen = () => setStateInvite('open');
  const doCancel = () => setStateInvite('closed');
  return (
    <div className={styles['add-family']}>
      {!!invitesRemaining && state === 'closed' && (
        <Button primary lg onClick={doOpen} className={styles['button-invite']}>
          <span className="fas fa-user-plus"></span>Add a new family member ({maxMembers - invitesRemaining}/
          {maxMembers})
        </Button>
      )}
      {!invitesRemaining && <div>Maximum number of family members added.</div>}

      {!!invitesRemaining && state !== 'closed' && (
        <React.Fragment>
          <h2 className={styles['add-family-title']}>
            <span className="fas fa-user-plus"></span>
            Add a new family member ({maxMembers - invitesRemaining}/{maxMembers})
          </h2>
          {(state == 'open' || state == 'saving') && (
            <InviteFamilyForm
              forbiddenEmails={forbiddenEmails}
              state={state}
              inviteMemberError={inviteMemberError}
              isValidDate={isValidDate}
              isOfAge={isOfAge}
              doCancel={doCancel}
              doInvite={doInvite}
            />
          )}
          {state === 'saved' && (
            <SuccessAddCard
              isFetching={false}
              description={
                <>
                  <p>Successfully added family member.</p>
                  <p>We have sent an email to this person with an activation link.</p>
                </>
              }
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default InviteFamilyMember;
