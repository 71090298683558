import { observer } from 'mobx-react';
import React, { useState, useEffect, ComponentType, useContext } from 'react';

import { GlobalErrorsServiceContext } from 'app/context/storesContext';

import UnhandledErrorPage from 'app/pages/500/500';
import UnderAge from 'app/pages/underAge/underAge';

const withErrorBoundary = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const ErrorBoundary: React.FC<P> = observer((props) => {
    const [isClient, setIsClient] = useState(false);

    const globalErrorsService = useContext(GlobalErrorsServiceContext);

    useEffect(() => {
      setIsClient(true);
    }, []);
    if (isClient && globalErrorsService.userUnderEighteenError) {
      return <UnderAge />;
    }

    if (isClient && globalErrorsService.unhandledError) {
      return <UnhandledErrorPage />;
    }

    return isClient ? <WrappedComponent {...(props as P)} /> : <></>;
  });

  return ErrorBoundary;
};

export default withErrorBoundary;
