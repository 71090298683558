import React from 'react';

import styles from './alreadyVerifySection.module.scss';

const AlreadyVerifySection = () => {
  return (
    <div className="container">
      <section className="page-section">
        <div className={styles['success-screen']}>
          <i className={`${styles['success-screen-icon']} fa fa-check `}></i>
          You have already verified your email address.
        </div>
      </section>
    </div>
  );
};
export default AlreadyVerifySection;
