import clsx from 'clsx';
import React from 'react';

import Button from '../../components/button/button';

import styles from './purchaseSubscriptionButton.module.scss';

interface IPurchaseSubscriptionButton {
  handleClick: () => void;
  canStartTrial: boolean;
  trialLabel?: string;
  renewLabel?: string;
  renewIcon?: boolean;
}

const PurchaseSubscriptionButton: React.FC<IPurchaseSubscriptionButton> = ({
  handleClick,
  canStartTrial,
  trialLabel = 'Try for free now',
  renewLabel = 'Buy New',
  renewIcon = false,
}) => {
  const renewContent = (
    <span>
      {renewIcon && <span className={clsx(styles['renew-icon'], 'fas fa-sync-alt')}></span>}
      {renewLabel}
    </span>
  );
  return (
    <Button primary lg onClick={handleClick} data-role={canStartTrial ? 'trial' : 'renew'}>
      {canStartTrial && <span>{trialLabel}</span>}
      {!canStartTrial && renewContent}
    </Button>
  );
};
export default PurchaseSubscriptionButton;
