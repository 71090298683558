import { computed, makeObservable, observable, action } from 'mobx';
import moment from 'moment';

import { IAllGames, IGameResponse, IGames } from 'types/pages';

import { HOME_TOP_APPLICATION_EXCERPT_LENGTH } from 'app/global/constants';

export default class PageStore {
  public constructor() {
    makeObservable(this);
  }

  @observable
  private _games: IGameResponse[] = [];

  @observable
  private _allGames: IGameResponse[] = [];

  @action
  public setDisplayedGames(newGames: IGameResponse[]): void {
    this._games = newGames;
  }
  @action
  public setAllGames(allGames: IGameResponse[]): void {
    this._allGames = allGames;
  }

  @computed
  public get allGames(): IAllGames[] {
    if (!this._allGames.length) {
      return [];
    }
    return this._allGames.map((game) => ({
      id: game.id,
      name: game.name.en,
      rating: game.userrating.avg,
      publishedDate: game.published_date,
      slug: game.slug,
    }));
  }

  @action
  public filterBackGroundImage(images: IGameResponse['images']): string {
    const image = images.filter((img) => img.tag === 'BI')[0];
    if (!image) {
      return '';
    }
    return image.download_url;
  }

  @computed
  public get displayedGames(): IGames[] {
    if (!this._games.length) {
      return [];
    }
    return this._games.map((game) => ({
      id: game.id,
      name: game.name.en,
      excerpt: game.excerpt.en.slice(0, HOME_TOP_APPLICATION_EXCERPT_LENGTH),
      rating: game.userrating.avg,
      ratingCount: game.userrating.count,
      backgroundImage: game.images.length ? this.filterBackGroundImage(game.images) : '',
      publishedDate: game.published_date,
      slug: game.slug,
    }));
  }

  @computed
  public get gamesPublishedLastMonth(): IAllGames[] {
    const today = moment();
    const publishedLastMonth = this.allGames.filter((app) => {
      if (app.publishedDate) {
        const publishedOn = moment(app.publishedDate, 'YYYY-MM-DD');
        const daysSincePublication = today.diff(publishedOn, 'days');
        return daysSincePublication < 31;
      }
      return false;
    });

    publishedLastMonth.sort((a, b) => {
      const avgA = a.rating ? a.rating : 0;
      const avgB = b.rating ? b.rating : 0;
      return avgB - avgA;
    });

    return publishedLastMonth.slice(0, 9) || [];
  }
}
