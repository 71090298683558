import withAuth from 'app/hoc/withAuth';
import NewsLetterModal from 'app/modals/newsLetterModal/newsLetterModal';
import DownloadOptions from 'app/widgets/downloadOptions/downloadOptions';
import SuccessFunSection from 'app/widgets/successFunSection/successFunSection';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';

import { CheckoutSuccessControllerContext, NewsLetterControllerContext } from 'app/context/storesContext';

import Modal from 'app/components/modal/modal';
import Spinner from 'app/components/spinner/spinner';

import styles from './checkoutSuccessMember.module.scss';

const CheckoutSuccessMember = observer(() => {
  const checkoutSuccessController = useContext(CheckoutSuccessControllerContext);
  const { isLoading, isCloudUser, platformList, canUpgradeToCloud, handleDownloadUtomik, handleDownloadGooglePlay } =
    checkoutSuccessController;
  const newsLetterController = useContext(NewsLetterControllerContext);
  const { showNewsLetter, checkIsShowNewsLetter, closeNewsLetterModal } = newsLetterController;

  useEffect(() => {
    checkoutSuccessController.initPage();
  }, []);

  useEffect(() => {
    checkIsShowNewsLetter();
  }, [checkoutSuccessController.user.newsletter_signup]);
  return (
    <>
      <section className={styles['checkout-success-member']}>
        <div className={clsx('container', styles['checkout-success-member-container'])}>
          <h1 className={styles['checkout-success-member-title']}>Welcome to Utomik!</h1>
          <div className={styles['checkout-success-member-content']}>
            <p>You've successfully created your account and joined a family plan. Play away!</p>
            {isLoading && <Spinner />}
            {!isLoading && (
              <DownloadOptions
                initPlatformList={checkoutSuccessController.initPlatformList}
                isCloudUser={isCloudUser}
                platformList={platformList}
                canUpgradeToCloud={canUpgradeToCloud}
                handleDownloadUtomik={handleDownloadUtomik}
                handleDownloadGooglePlay={handleDownloadGooglePlay}
              />
            )}
          </div>
        </div>
        <Modal onHide={closeNewsLetterModal} show={showNewsLetter} title="Don't miss a thing!">
          <NewsLetterModal
            confirm={newsLetterController.confirmAndCallback}
            reject={newsLetterController.rejectAndCallback}
            isLoading={newsLetterController.isFetchingNewsLetter}
          />
        </Modal>
      </section>
      {/* <SuccessFunSection /> */}
    </>
  );
});
export default withAuth(CheckoutSuccessMember);
