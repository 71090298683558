import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';

import { LoginControllerContext } from 'app/context/storesContext';

import './logoutPage.scss';

const AutoLogoutPage = observer(() => {
  const loginController = useContext(LoginControllerContext);

  const handleLogout = async () => {
    try {
      await loginController.checkIsLoggedIn();
    } catch (e) {
      console.error(e, 'AutoLogoutScreen');
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <div className="autoLogout-wrapper">
      <div className="container">
        <h1 className="autoLogout-title">You have been logged out automatically</h1>
        <p className="autoLogout-subtitle">Your session has expired and you have been logged out automatically.</p>
      </div>
    </div>
  );
});
export default AutoLogoutPage;
