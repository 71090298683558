export enum Errors {
  USER_HAS_AWAITING_PAYMENT_ORDER = 'USER_HAS_AWAITING_PAYMENT_ORDER',
  USER_ALREADY_VERIFIED = 'USER_ALREADY_VERIFIED',
  INCORRECT_LOGIN_CREDENTIALS = 'INCORRECT_LOGIN_CREDENTIALS',
  ACCOUNT_DELETION_PENDING = 'ACCOUNT_DELETION_PENDING',
  JWT_OBSOLETE = 'JWT_OBSOLETE',
  USER_NAME_TAKEN = 'USER_NAME_TAKEN',
  EMAIL_TAKEN = 'EMAIL_TAKEN',
  DECODING_SIGNATURE = 'Error decoding signature.',
  INVALID_JWT = 'Invalid JWT token.',
  SIGNATURE_HAS_EXPIRED = 'Signature has expired.',
  NETWORK_ERROR = 'Network Error',
  ACCOUNT_CANNOT_SWITCH_AGAIN_THIS_TERM = 'ACCOUNT_CANNOT_SWITCH_AGAIN_THIS_TERM',
  SSO_AUTHENTICATION_CANCELED = 'Authentication+process+canceled:+Permissions+error',
  ACCOUNT_ALREADY_ON_SAME_PLAN_TYPE = 'ACCOUNT_ALREADY_ON_SAME_PLAN_TYPE',
  INVALID_INVITE_TOKEN = 'INVALID_INVITE_TOKEN',
  NO_PRODUCT_IN_CART = 'NO_PRODUCT_IN_CART',
  USER_IS_BANNED = 'USER_IS_BANNED',
  EMAIL_ADDRESS_TAKEN = 'This email address is already taken',
  MEMBER_FAMILY_MANAGE = 'You do not have permission to view this page as you are not the owner of the Family Plan. Please log in as the owner of the Family Plan to view this page.',
  USER_IS_UNDER_EIGHTEEN_AND_NO_MEMBER = 'USER_IS_UNDER_EIGHTEEN_AND_NO_MEMBER',
  SUBSCRIPTION_IN_GRACE = 'SUBSCRIPTION_IN_GRACE',
  IS_INVALID_PURCHASING = 'is invalid',
}

export interface IErrorOverrides {
  CANNOT_CHANGE_TO_OR_FROM_FREE_PLAN: string;
  ACCOUNT_HAS_NON_RECURRING_SUBSCRIPTION: string;
}

const promoInvalid =
  'Whoops! Seems like this code is invalid. You can still try us out for free for 14 days by signing up below!';
const promoExpired =
  'Whoops! Seems like this code has expired. You can still try us out for free for 14 days by signing up below!';

export const errorsRegisterPageGetPromo = {
  INVALID_PROMO_CODE: promoInvalid,
  MALFORMED_PROMO_CODE: promoInvalid,
  EXPIRED_PROMO_CODE: promoExpired,
  PROMO_CODE_USED: promoExpired,
};
