import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { ErrorMessage as FormikErrorMessage, ErrorMessageProps as FormikErrorMessageProps } from 'formik';
import { ReactNode } from 'react';

import styles from './errorMessage.module.scss';

export type ErrorMessageProps = FormikErrorMessageProps & {
  className?: string;
  icon?: IconProp | null;
  customMessage?: string | React.ReactNode;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  className,
  icon = 'triangle-exclamation',
  customMessage,
  ...props
}) => (
  <FormikErrorMessage {...props}>
    {(message: string | ReactNode) => (
      <div className={clsx(styles['error-message'], className)}>
        {icon && <FontAwesomeIcon data-testid="error-message-icon" icon={icon} />}

        <div>{customMessage ?? message}</div>
      </div>
    )}
  </FormikErrorMessage>
);

export default ErrorMessage;
