import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';

import { IPaymentType } from 'types/billing';
import { Messages } from 'types/messages';

import { translateErrors } from 'utils/translateErrors';

import { FAQ_URL, ROOT_DOMAIN } from 'app/global/constants';

import { UpdateBillingControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import { RadioSelect } from 'app/components/radioSelect/radioSelect';
import Spinner from 'app/components/spinner/spinner';

import styles from './selectPaymentMechanism.module.scss';

interface ISelectPaymentMechanism {
  selectPaymentMechanismCallback?: (paymentMechanism: IPaymentType) => void;
  handleBack: () => void;
  isCheckoutPage?: boolean;
  isProductTrial?: boolean;
  isHideBack?: boolean;
}

const SelectPaymentMechanism: React.FC<ISelectPaymentMechanism> = observer(
  ({
    selectPaymentMechanismCallback,
    isProductTrial = false,
    handleBack,
    isHideBack = false,
    isCheckoutPage = false,
  }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [errorRequest, setErrorRequest] = useState('');
    const updateBillingController = useContext(UpdateBillingControllerContext);
    const { paymentMechanisms, paymentType } = updateBillingController;

    const getPaymentOptions = async () => {
      try {
        await updateBillingController.setPaymentMechanisms();
      } catch (e: any) {
        setErrorRequest(translateErrors(e.message));
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      getPaymentOptions();
    }, []);

    if (isLoading) {
      return <Spinner />;
    }

    const cancelBack = () => {
      handleBack();
    };
    const selectPaymentMechanism = (paymentType: IPaymentType) => {
      updateBillingController.selectPaymentType(paymentType);
    };
    const confirmPaymentMechanism = () => {
      paymentType && selectPaymentMechanismCallback && selectPaymentMechanismCallback(paymentType);
      paymentType && updateBillingController.handleConfirmPaymentMechanism(paymentType);
    };

    if (errorRequest) {
      return <Alert danger>{errorRequest}</Alert>;
    }
    if (!window?.recurly && paymentMechanisms.find((item) => item.provider === 'recurly')) {
      return (
        <div
          className={clsx(
            isCheckoutPage && styles['selectPaymentMechanism-checkout'],
            styles['selectPaymentMechanism'],
          )}
        >
          {!isCheckoutPage && <span>Please choose a payment option</span>}

          <Alert danger>{Messages.COULDNT_FETCH_BILLINGINFO}</Alert>
        </div>
      );
    }

    return (
      <div
        className={clsx(isCheckoutPage && styles['selectPaymentMechanism-checkout'], styles['selectPaymentMechanism'])}
      >
        {paymentMechanisms.length ? (
          <>
            {!isCheckoutPage && <span>Please choose a payment option</span>}
            <div className={styles['selectPaymentMechanism-cards-block']}>
              {paymentMechanisms.map((payment) => (
                <div key={payment.name} className={styles['selectPaymentMechanism-card-wrapper']}>
                  <div
                    className={styles['selectPaymentMechanism-card']}
                    onClick={() => selectPaymentMechanism(payment.type)}
                  >
                    <div className={styles['selectPaymentMechanism-card-item']}>
                      <RadioSelect isChecked={!!paymentType && paymentType === payment.type} />
                      <span>{payment.name}</span>
                    </div>
                    <div className={styles['selectPaymentMechanism-card-item-image-block']}>
                      <div
                        className={clsx(
                          styles['selectPaymentMechanism-card-item-image'],
                          styles[`selectPaymentMechanism-card-item-image-${payment.type}`],
                        )}
                      >
                        <img src={payment.imgSrc} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {isProductTrial && (
              <div className={styles['payment-mechanism-info']}>
                <h4>Why do we need your payment info?</h4>
                <p>
                  We require your payment information for verification purposes and fraud prevention. Don't worry, we
                  won't start charging you during your trial.
                </p>
              </div>
            )}
            <div className={clsx(styles['payment-mechanism-info'], styles['payment-mechanism-info-small'])}>
              <h4>Extra payment options</h4>
              <p>
                Is your preferred payment not listed here? We support 700+ payment methods worldwide in our prepaid
                shop. Pay upfront and save money!{' '}
                <a
                  href={`${ROOT_DOMAIN}/prepaid`}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Visit the Utomik prepaid shop"
                >
                  Go to the shop.
                </a>
              </p>
            </div>
            <div className={styles['payment-mechanism-buttons']}>
              {!isHideBack && (
                <div className={styles['payment-mechanism-button-back']}>
                  <Button block onClick={cancelBack}>
                    Go back
                  </Button>
                </div>
              )}
              {!isCheckoutPage && (
                <div className={styles['payment-mechanism-button-confirm']}>
                  <Button primary block onClick={confirmPaymentMechanism} disabled={!paymentType}>
                    Continue
                  </Button>
                </div>
              )}
            </div>
          </>
        ) : (
          <div>
            Your country is not supported by Utomik.{' '}
            <a href={`${FAQ_URL}/articles/115001034649/`} title="Read about the countries we support">
              Learn more
            </a>
            .
          </div>
        )}
      </div>
    );
  },
);
export default SelectPaymentMechanism;
