import React from 'react';

import Alert from 'app/components/alert/alert';

const SubAccountMessages = () => {
  return (
    <Alert info icon="info-circle">
      You do not have permission to view this page as you are not the owner of the Family Plan. Please log in as the
      owner of the Family Plan to view this page.
    </Alert>
  );
};
export default SubAccountMessages;
