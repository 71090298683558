import withAuth from 'app/hoc/withAuth';
import NewsLetterModal from 'app/modals/newsLetterModal/newsLetterModal';
import DownloadOptions from 'app/widgets/downloadOptions/downloadOptions';
import SubscriptionOverview from 'app/widgets/subscriptionOverview/subscriptionOverview';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';

import { translateErrors } from 'utils/translateErrors';

import {
  CheckoutSuccessControllerContext,
  NewsLetterControllerContext,
  PairingControllerContext,
} from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Modal from 'app/components/modal/modal';
import Spinner from 'app/components/spinner/spinner';

import styles from './checkoutSuccessTv.module.scss';

const CheckoutSuccessTv = observer(() => {
  const pairingController = useContext(PairingControllerContext);
  const checkoutSuccessController = useContext(CheckoutSuccessControllerContext);
  const newsLetterController = useContext(NewsLetterControllerContext);

  const {
    isLoading,
    accountRole,
    subscription,
    isReactivating,
    isCloudUser,
    platformList,
    canUpgradeToCloud,
    handleDownloadUtomik,
    handleDownloadGooglePlay,
  } = checkoutSuccessController;
  const { showNewsLetter, checkIsShowNewsLetter, closeNewsLetterModal } = newsLetterController;

  useEffect(() => {
    checkIsShowNewsLetter();
  }, [checkoutSuccessController.user.newsletter_signup]);

  const errorRegistrationTv = pairingController.error;
  const isLoadingRegistrationTv = pairingController.busy;
  const completeRegistrationFromTv = async () => {
    await pairingController.completeRegistrationWithPairing();
  };

  useEffect(() => {
    checkoutSuccessController.initPage();
    completeRegistrationFromTv();
  }, []);

  return (
    <section className={styles['checkout-success-tv']}>
      <div className={clsx('container', styles['checkout-success-tv-container'])}>
        <h1 className={styles['checkout-success-tv-title']}>Welcome to Utomik!</h1>
        <div className={styles['checkout-success-tv-content']}>
          {isLoadingRegistrationTv && <Spinner> We are pairing with your device, please wait...</Spinner>}
          {!isLoadingRegistrationTv && errorRegistrationTv && (
            <Alert danger>{translateErrors(errorRegistrationTv)}</Alert>
          )}
          {!isLoadingRegistrationTv && !errorRegistrationTv && (
            <>
              <p>
                You have successfully paired your device. The paired device will automatically log in with your Utomik
                account.
              </p>
              <p>You may close this page.</p>
            </>
          )}
          <SubscriptionOverview
            accountRole={accountRole}
            subscription={subscription}
            isReactivating={isReactivating}
            handleReactivate={checkoutSuccessController.reactivateSubscription}
            isLoading={isLoading}
          />
          {!isLoading && (
            <DownloadOptions
              initPlatformList={checkoutSuccessController.initPlatformList}
              isCloudUser={isCloudUser}
              platformList={platformList}
              canUpgradeToCloud={canUpgradeToCloud}
              platform="TV"
              handleDownloadUtomik={handleDownloadUtomik}
              handleDownloadGooglePlay={handleDownloadGooglePlay}
            />
          )}
        </div>
      </div>
      <Modal onHide={closeNewsLetterModal} show={showNewsLetter} title="Don't miss a thing!">
        <NewsLetterModal
          confirm={newsLetterController.confirmAndCallback}
          reject={newsLetterController.rejectAndCallback}
          isLoading={newsLetterController.isFetchingNewsLetter}
        />
      </Modal>
    </section>
  );
});
export default withAuth(CheckoutSuccessTv);
