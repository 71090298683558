import clsx from 'clsx';
import React from 'react';

import { IAccountMineResponse } from 'types/account';
import { ICartDataCheckout } from 'types/cart';
import { SubscriptionsTrialPeriod } from 'types/subscriptions';

import { formatPriceToAmountInCents } from 'utils/textFormat';

import { zeroPrice } from '../checkoutOverview/checkoutOverview';

import styles from './checkoutProduct.module.scss';

interface ICheckoutProduct {
  product: ICartDataCheckout['product'];
  account: IAccountMineResponse;
  productType: 'returnproduct' | 'product';
}

const CheckoutProduct: React.FC<ICheckoutProduct> = ({ product, account, productType }) => {
  const isSubscriptions = product.url && product.url.indexOf('subscriptions') > -1;
  const isSubscriptionPlans = product.url && product.url.indexOf('subscriptionplans') > -1;
  const isHaveTrialPeriod = product.trial_period_length && account.can_start_trial;
  const isProduct = productType === 'product';
  return (
    <div
      className={clsx(
        styles['checkout-product'],
        isProduct && styles['checkout-product-subscription'],
        product.name.toLowerCase().includes('cloud') && styles['checkout-product-cloud'],
      )}
    >
      <div className={styles['checkout-product-overview']}>
        {/* for subscription */}
        {isSubscriptions && !isProduct && (
          <div>
            <div className={styles['checkout-product-title']}>{product.name}</div>
            {/* {!!product.add_ons.length &&
              product.add_ons.map((addOn) => (
                <div key={addOn.id} className="checkout-product-title">
                  {addOn.name}
                </div>
              ))} */}
          </div>
        )}

        {/* for subscriptionPlans */}
        {isSubscriptionPlans && isProduct && (
          <div>
            <div className={styles['checkout-product-switching-title']}>{product.name}</div>
            {/* {!!product.add_ons.length &&
              product.add_ons.map((addOn) => (
                <div key={addOn.id} className="checkout-product-title">
                  {addOn.name}
                </div>
              ))} */}
            {!product.period_length && isHaveTrialPeriod && (
              <span className="checkout-product-duration">
                {product.trial_period_length} - {SubscriptionsTrialPeriod[product.trial_period || 'DY']}{' '}
                {product.period_length > 1 ? 's' : ''} trial
              </span>
            )}
            {!!product.period_length && isHaveTrialPeriod && (
              <div className={clsx(styles['checkout-product-duration-with-trial'])}>
                First {product.trial_period_length} {SubscriptionsTrialPeriod[product.trial_period || 'DY']}
                {product.trial_period_length > 1 ? 's' : ''} for free
              </div>
            )}
            {!!product.period_length && !isHaveTrialPeriod && (
              <span
                className={clsx(styles['checkout-product-duration-without-trial'], 'nowrap checkout-product-duration')}
              >
                per {SubscriptionsTrialPeriod[product.period || 'DY']}
                {product.period_length > 1 ? 's' : ''}
              </span>
            )}
          </div>
        )}
      </div>
      <div className={styles['checkout-product-price']}>
        <div>
          {isProduct && product.pricing && (
            <div className="checkout-product-title">
              {/* if you're signing up for a trial, show the post-trial price with a strikethrough effect, followed by the actual price of whatever is in your cart (which should be 0.00) */}
              {isHaveTrialPeriod && (
                <span>
                  {!!product.period_length && (
                    <strong className={clsx(styles['checkout-product-old-price'], 'currency')}>
                      <del>
                        {formatPriceToAmountInCents(product._totalPricing || product.pricing[0], 'amount_in_cents')}
                      </del>
                    </strong>
                  )}
                  <br />
                </span>
              )}
              {/* if not signing up for a trial, just show the full price of the item in the cart. */}
              {!isHaveTrialPeriod && product.requires_payment_info && (
                <strong className={clsx('currency', styles['checkout-new-price'])}>
                  {formatPriceToAmountInCents(product._totalPricing || product.pricing[0], 'amount_in_cents')}
                </strong>
              )}
              {!isHaveTrialPeriod && !product.requires_payment_info && (
                <strong className={clsx('currency', styles['checkout-new-price'])}>
                  {formatPriceToAmountInCents(
                    { ...zeroPrice, currency: product.pricing[0].currency },
                    'amount_in_cents',
                  )}
                </strong>
              )}
              {/* if signing up for a trial alone, show that it's free. */}
              {!!product.trial_period_length && !product.period_length && (
                <strong className="currency checkout-new-price">
                  {formatPriceToAmountInCents(
                    { ...zeroPrice, currency: product.pricing[0].currency },
                    'amount_in_cents',
                  )}
                </strong>
              )}
            </div>
          )}
        </div>
        {/* <div>
          {isProduct &&
            !!product.add_ons.length &&
            product.add_ons.map((addon) => {
              if (isHaveTrialPeriod) {
                // if you're signing up for a trial, show the post-trial price with a strikethrough effect, followed by the actual price of whatever is in your cart (which should be 0.00)
                return (
                  <span key={addon.id}>
                    <del className={clsx(styles['checkout-old-price'], 'currency')}>
                      {formatPriceToAmountInCents(addon.pricing, 'amount_in_cents')}
                    </del>
                    <br />
                  </span>
                );
              }
              if (!isHaveTrialPeriod && product.requires_payment_info) {
                return (
                  <strong key={addon.id} className="currency checkout-new-price">
                    {formatPriceToAmountInCents(addon.pricing, 'amount_in_cents')}
                  </strong>
                );
              }
              if (!isHaveTrialPeriod && !product.requires_payment_info) {
                return (
                  <strong className="currency checkout-new-price" key={addon.id}>
                    {formatPriceToAmountInCents({ ...zeroPrice, currency: addon.pricing.currency }, 'amount_in_cents')}
                  </strong>
                );
              }
            })}
        </div> */}
        {isHaveTrialPeriod && !!product.period_length && isProduct && (
          <span>
            <strong className="currency checkout-new-price">
              {formatPriceToAmountInCents({ ...zeroPrice, currency: product.pricing[0].currency }, 'amount_in_cents')}
            </strong>
            <br />
          </span>
        )}
      </div>
    </div>
  );
};
export default CheckoutProduct;
