import { action, computed, makeObservable, observable } from 'mobx';
import AnalyticService from 'services/analyticsService/analyticsService';
import BoxOfficeService from 'services/boxOficceService/boxOficceService';
import CartService from 'services/cartService/cartService';
import CookieService from 'services/cookieService/cookieService';
import MiscService from 'services/miscService/miskService';
import PageService from 'services/pageService/pageService';
import PaymentService from 'services/paymentService/paymentService';
import PromoService from 'services/promoService/promoService';
import UserService from 'services/userService/userService';

import { IAccountMineResponse } from 'types/account';
import { IPaymentType, IUpdateRecurlyToken } from 'types/billing';
import { ICartDataCheckout } from 'types/cart';
import { IBuyProduct, StepsCheckout } from 'types/checkout';
import { Errors } from 'types/errors';
import { Messages } from 'types/messages';
import { IPromoResponse } from 'types/promo';
import { ISubscriptionsResponseWithOrders } from 'types/subscriptions';

import { translateErrors } from 'utils/translateErrors';

import { PROMO_CODE_COOKIE } from '../constants';
import { Routes } from '../routes';

export default class CheckoutController {
  public constructor(
    private readonly _userService: UserService,
    private readonly _cartService: CartService,
    private readonly _promoService: PromoService,
    private readonly _miscService: MiscService,
    private readonly _paymentService: PaymentService,
    private readonly _boxOfficeService: BoxOfficeService,
    private readonly _cookieService: CookieService,
    private readonly _pageService: PageService,
    private readonly _analyticService: AnalyticService,
  ) {
    makeObservable(this);
  }

  @observable
  private _accountSubscriptions: ISubscriptionsResponseWithOrders[] | null = null;

  @action
  public setAccountSubscriptions(subscription: ISubscriptionsResponseWithOrders[] | null) {
    this._accountSubscriptions = subscription;
  }

  @computed
  public get accountSubscriptions(): ISubscriptionsResponseWithOrders[] | null {
    return this._accountSubscriptions;
  }

  @observable
  private _account: IAccountMineResponse | null = null;

  @observable
  private _userCountryStored = false;

  @observable
  private _cart: ICartDataCheckout | null = null;

  @observable
  private _stepCheckout: StepsCheckout = 'select_country';

  @observable
  private _isErrorBuyProduct = false;

  @observable
  private _errorCart = '';

  @action
  public setErrorCart(error: string) {
    this._errorCart = error;
  }
  @computed
  public get errorCart() {
    return this._errorCart;
  }

  @action
  public setIsErrorBuyProduct(isErrorBuyProduct: boolean) {
    this._isErrorBuyProduct = isErrorBuyProduct;
  }

  @computed
  public get isErrorBuyProduct() {
    return this._isErrorBuyProduct;
  }

  @action setCart(cart: ICartDataCheckout | null) {
    this._cart = cart;
  }

  @computed get cart() {
    return this._cart;
  }

  @action
  public setStepCheckout = (step: StepsCheckout) => {
    this._stepCheckout = step;
  };

  @computed
  public get stepCheckout(): StepsCheckout {
    return this._stepCheckout;
  }

  @action
  public setUserCountryStored(userCountryStored: boolean) {
    this._userCountryStored = userCountryStored;
  }

  @computed
  public get userCountryStored() {
    return this._userCountryStored;
  }

  @action
  public setAccount(account: IAccountMineResponse | null) {
    this._account = account;
  }

  @computed
  public get account() {
    return this._account;
  }

  public async initPage() {
    await this._userService.fetchUser();
  }

  @observable
  private _isNotCloudUser = false;

  @action
  public setIsNotCloudUser(isNotCloutUser: boolean) {
    this._isNotCloudUser = isNotCloutUser;
  }

  @computed
  public get isNotCloutUser() {
    return this._isNotCloudUser;
  }

  @observable
  private _errorBuyingProduct = '';

  @action
  public setErrorBuyingProduct(errorBuyingProduct: string) {
    this._errorBuyingProduct = errorBuyingProduct;
  }

  @computed
  public get errorBuyingProduct() {
    return this._errorBuyingProduct;
  }

  @observable
  private _isBuyingProduct = false;

  @action
  public setIsBuyingProduct(isBuyingProduct: boolean) {
    this._isBuyingProduct = isBuyingProduct;
  }

  @computed
  public get isBuyingProduct() {
    return this._isBuyingProduct;
  }

  @observable
  private _token3Ds = '';

  @action
  public setToken3Ds = (token3Ds: string) => {
    this._token3Ds = token3Ds;
  };

  @computed
  public get token3Ds() {
    return this._token3Ds;
  }

  @observable
  private _orderUrl = '';

  @action
  public setOrderUrl(orderUrl: string) {
    this._orderUrl = orderUrl;
  }

  @computed
  public get orderUrl() {
    return this._orderUrl;
  }

  @observable
  private _messageError3DSecure = '';

  @action
  public setMessageError3DSecure(messageError3DSecure: string) {
    this._messageError3DSecure = messageError3DSecure;
  }

  @computed
  public get messageError3DSecure() {
    return this._messageError3DSecure;
  }

  public async initCheckoutData() {
    this.setErrorCart('');
    this.setIsNotCloudUser(false);
    this.setStepCheckout('select_country');
    this.setErrorBuyingProduct('');
    await this._miscService.setUserIsInSupportedCloudCountry();
    await this._userService.fetchAccountSubscriptions();
    await this._userService.getBillingInfo();
    await this._userService.fetchLoggedInAccount();
    await this.updateAccountSubscriptions();
    const account = this._userService.getLoggedInAccount();
    await this._miscService.getUserCountry();
    account && this.setAccount(account);
    this.setUserCountryStored(this.user.country !== null);
    await this.updateCart();
    this.updateStep();
  }

  public async updateCart() {
    try {
      const cart = await this._cartService.getCart();
      this.setCart(cart);
      if (cart.product?._isCloudSubscription && !this._userService.isInSupportedCloudCountry) {
        this.setIsNotCloudUser(true);
      }
    } catch (e) {
      console.error(e, 'updateCart');
    }
  }

  public handlerCartErrors(error: string) {
    switch (error) {
      case Errors.USER_HAS_AWAITING_PAYMENT_ORDER:
        this.setErrorCart(Messages.USER_HAS_AWAITING_PAYMENT_ORDER);
        break;
      case Errors.ACCOUNT_ALREADY_ON_SAME_PLAN_TYPE:
        this.setErrorCart(Messages.ACCOUNT_ALREADY_ON_SAME_PLAN_TYPE);
        break;
      case Errors.NO_PRODUCT_IN_CART:
        this.setErrorCart(Messages.NO_PRODUCT_IN_CART);
        break;
      case Errors.SUBSCRIPTION_IN_GRACE:
        this.setErrorCart(Messages.SUBSCRIPTION_IN_GRACE);
        break;
      default:
        this.setErrorCart('Something went wrong. Please try again later.');
    }
  }

  public updateStep = () => {
    const isCartProductHasMessage =
      this.cart?.product && !(this.cart.product.can_purchase && this.cart.product.can_purchase.allow);
    if (this.stepCheckout === 'processing_succeeded' || this.stepCheckout === 'processing_failed') return;
    if (isCartProductHasMessage) {
      const cartError = this.cart?.product.can_purchase.message as string;
      this.handlerCartErrors(cartError);
    }
    if (!this.user.country) {
      this.setStepCheckout('select_country');
    } else if (this.cart?.product?.requires_payment_info === false) {
      this.setStepCheckout('confirm_checkout_without_billinginfo');
    } else if (this._userService.billingInfo) {
      this.setStepCheckout('view_billing_info');
    } else {
      this.setStepCheckout('select_payment_mechanism');
    }
  };

  public async updateAccountSubscriptions() {
    const subscription = await this._userService.getAccountSubscriptions();
    this.setAccountSubscriptions(subscription);
    const cartPromo = this._cartService.getPromo();
    if (typeof cartPromo === 'string') {
      const promoData = await this._promoService.getPromo({ promoCode: cartPromo });
      if (promoData) {
        this._cartService.setPromo(promoData);
      }
    }
  }

  public selectPaymentMechanism = (paymentMechanism: IPaymentType) => {
    switch (paymentMechanism) {
      case 'creditcard':
        return this.setStepCheckout('pay_using_creditCard');
      case 'paypal':
        return this.setStepCheckout('pay_using_paypal');
      case 'ideal':
        return this.setStepCheckout('pay_using_ideal');
    }
  };

  public get today(): Date {
    return new Date();
  }

  public get userCountry() {
    return this._userService.userCountry;
  }

  public get utomikIsLiveInUsersCountry() {
    return this._userService.utomikIsLiveInUsersCountry;
  }

  public get user() {
    return this._userService.user;
  }

  @observable
  private _gaSent = false;

  @action
  public setGaSent(gaSent: boolean) {
    this._gaSent = gaSent;
  }

  @computed
  public get gaSent() {
    return this._gaSent;
  }

  public onSuccessAfterBuyingProduct = async (paymentType?: IPaymentType | null) => {
    try {
      this.setStepCheckout('processing_succeeded');
      const cart = await this._cartService.getCart();

      this._analyticService.handleAddPaymentInfo({
        paymentType: paymentType,
        product: cart.product,
        promo: cart.promo,
      });

      const activeSubscriptions = this.accountSubscriptions && this.accountSubscriptions[0];

      if (cart.returnProduct) {
        this._analyticService.handleSwitchPlan({
          order: activeSubscriptions?.order,
          promo: this._cartService.getPromo() as IPromoResponse,
          newProduct: cart.product,
        });
      } else {
        this._analyticService.handlePurchase({
          product: cart.product,
          order: activeSubscriptions?.order,
          promo: this._cartService.getPromo() as IPromoResponse,
        });
        this.setGaSent(true);
      }
    } finally {
      const next = this._cartService.getNext() as string;

      setTimeout(() => {
        this._cartService.emptyCart();
        this._pageService.setLocation(next || Routes.Success);
      }, 4000);
    }
  };

  public validateError = (error: string) => {
    switch (error) {
      case Messages.USER_HAS_AWAITING_PAYMENT_ORDER:
      case Errors.USER_HAS_AWAITING_PAYMENT_ORDER:
        this.setErrorCart(Messages.USER_HAS_AWAITING_PAYMENT_ORDER);
        break;
      case Errors.NO_PRODUCT_IN_CART:
      case Messages.NO_PRODUCT_IN_CART:
        this.setErrorCart(Messages.NO_PRODUCT_IN_CART);
        break;
      case Messages.ACCOUNT_ALREADY_ON_SAME_PLAN_TYPE:
      case Errors.ACCOUNT_ALREADY_ON_SAME_PLAN_TYPE:
        this.setErrorCart(Messages.ACCOUNT_ALREADY_ON_SAME_PLAN_TYPE);
        break;
      default:
        if (error === Errors.IS_INVALID_PURCHASING) {
          this.setErrorBuyingProduct('Checkout failed.');
        } else {
          this.setErrorBuyingProduct(error);
        }
        this.setStepCheckout('processing_failed');
        break;
    }
  };

  public onFailAfterBuyingProduct = async (error: any) => {
    this.setStepCheckout('processing_failed');
    this.validateError(translateErrors(error));
    const cart = await this._cartService.getCart();
    if (!this.gaSent) {
      if (cart.returnProduct) {
        this._analyticService.handleSwitchPlanError(error);
      } else {
        this._analyticService.handlePurchaseError(error);
      }
      this.setGaSent(true);
    }
  };

  public buyProduct = async ({ data = null, paymentType = null }: IBuyProduct = {}) => {
    this.setIsBuyingProduct(true);
    this.setStepCheckout('processing');

    try {
      if (this.orderUrl && data?.three_ds_token) {
        await this._paymentService.payForOrder(this.orderUrl, data);
      } else {
        const urlRedirect = await this._boxOfficeService.fulfillCart();
        if (urlRedirect) {
          this._cookieService.removeCookie(PROMO_CODE_COOKIE, { path: '/' });
          return;
        }
      }
      this.setStepCheckout('processing_succeeded');
      this.setToken3Ds('');
      this.setOrderUrl('');
      this.setMessageError3DSecure('');
      await this.onSuccessAfterBuyingProduct(paymentType);

      this._cookieService.removeCookie(PROMO_CODE_COOKIE, { path: '/' });
      this.clearAccountSubscriptionsWithOrder();
    } catch (e: any) {
      if (e?.errorDetails?.transaction?.three_d_secure_action_token_id) {
        this.setToken3Ds(e.errorDetails.transaction.three_d_secure_action_token_id);
        this.setOrderUrl(e?.url || '');
        this.setMessageError3DSecure(e.errorDetails?.transaction?.customer_message || '');
        this.setStepCheckout('3ds_verify');
        return;
      }
      this.setIsErrorBuyProduct(true);
      await this.onFailAfterBuyingProduct(e);
      this.setIsBuyingProduct(false);
    }
  };

  public clearAccountSubscriptionsWithOrder() {
    this._userService.setOrders([]);
    this._userService.setAccountSubscriptionDefault([]);
  }
}
