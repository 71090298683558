export const errorToStringTranslator = (error: any) => {
  if (error.errors && Array.isArray(error.errors)) {
    const errorMessages: string[] = error.errors.map(function (error: Error) {
      return error.message;
    });
    return errorMessages.length ? errorMessages[0] : '';
  } else if (error.error && error.error.details && error.error.details.error) {
    return error.error.details.error || '';
  }
  return '';
};
