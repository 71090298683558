import React from 'react';
import { Link } from 'react-router-dom';

import { IAllGames, IGames } from 'types/pages';

import { gamesDomain } from 'app/global/constants';

import './gameCount.scss';
import './gameSection.scss';
import './gameTile.scss';

interface IGameSection {
  games: IGames[];
  gamesPublishedLastMonth: IAllGames[];
  isLoading: boolean;
}

const GamesSection: React.FC<IGameSection> = ({ games, gamesPublishedLastMonth, isLoading }) => {
  return (
    <section className="full-page-section full-page-section-style-game-count-tron" id="game-count">
      <div className="container">
        <div className="row flex-container">
          <div className="col-md-4 block-weekly">
            <h2>Weekly new games for you</h2>
            <div className="caption-header">
              <span className="fas fa-heart"></span> <strong>{gamesPublishedLastMonth?.length} Games</strong> added last
              month
            </div>
            <div className="row">
              <div className="col-sm-7">
                <div className="list-arrow-title">Recently added:</div>
                <ul className="list-arrows list-arrows-spacious">
                  {!isLoading &&
                    !!gamesPublishedLastMonth?.length &&
                    gamesPublishedLastMonth.map((gameLastMonth) => (
                      <li
                        data-testid="games-last-month"
                        data-appid={gameLastMonth.id}
                        key={gameLastMonth.id + 'last-month'}
                      >
                        <Link
                          to={`${gamesDomain}/${gameLastMonth.slug || gameLastMonth.id}`}
                          itemProp="url"
                          title="Read more about {{games.name}}"
                        >
                          {gameLastMonth.name}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-xs-12 block-games">
            <div id="top-games">
              <h2>
                Top games on Utomik
                <Link to={gamesDomain} title="See all games on Utomik" className="see-all">
                  See all
                </Link>
              </h2>

              <div className="game-tile-row">
                {!isLoading &&
                  !!games.length &&
                  games.map((game) => (
                    <div data-appid={`${game.id}-wrapper`} key={game.id}>
                      <div itemScope itemType="http://schema.org/VideoGame" data-appid={`${game.id}-wrapper`}>
                        <Link
                          data-testid={!game.backgroundImage ? 'no-boximage' : 'has-boxImage'}
                          className={`game-tile ${!game.backgroundImage && 'no-boximage'}`}
                          to={`${gamesDomain}/${game.slug || game.id}`}
                          title={`Read more about ${game.name}`}
                          itemProp="url"
                        >
                          <span className="game-tile-boximage">
                            <img src={game.backgroundImage || ''} />
                            {/* {% if app.admin_flags.is_coming_soon %} */}
                            <span className="game-tile-boximage-label game-tile-boximage-label-coming-soon"></span>
                            {/* {% endif %} */}
                          </span>
                          <span className="game-tile-name">{game.name}</span>
                          <span className="game-tile-excerpt">
                            {game.excerpt}
                            &hellip;&nbsp;
                            <span className="game-tile-link">Read more</span>
                          </span>
                        </Link>
                      </div>
                    </div>
                  ))}
                {/* {% for app in applications|slice:home_top_application_count%} */}

                {/* {% endfor %} */}
              </div>
            </div>
          </div>

          {/* <div className="col-md-8 col-xs-12">
              {% if twitch_stream_is_online %}
              <!-- Twitch stream is online! Show Twitch stream... -->
              <div id="twitch-stream">
                  <h1>We are now LIVE playing:</h1>
                  <div className="caption-header text-success">{{twitch_stream.stream.game}}</div>
                  <div className="row">
                      <div className="col-xs-12">
                          <div id="twitch_player"></div>
                          <script type="text/javascript">
                              var twitchLibLoaded = function () {
                                  var options = {
                                      channel: "{{TWITCH_CHANNEL}}"
                                  };
                                  var player = new Twitch.Player("twitch_player", options);
                                  player.setVolume(1);
                                  player.setMuted(true);
                              }
                              </script>
                          <script src="https://player.twitch.tv/js/embed/v1.js" onload="twitchLibLoaded()"></script>
                      </div>
                  </div>
              </div>
              
              {% else %}
              <!-- Twitch stream is offline! Show top games on Utomik -->
              
              {% endif %}
          </div> */}
        </div>
      </div>
    </section>
  );
};
export default GamesSection;
