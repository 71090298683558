import clsx from 'clsx';
import { Field, useField } from 'formik';
import { forwardRef } from 'react';

import styles from './checkbox.module.scss';

export type CheckboxProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'type'
> & {
  label: string;
  name: string;
};

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox({ className, label, ...props }, ref) {
  const [field] = useField(props);

  const inputClassName = clsx(styles.checkbox__input, className && `${className}__input`);
  const labelClassName = clsx(styles.checkbox__label, className && `${className}__label`);

  return (
    <label className={clsx(styles.checkbox, className)}>
      <Field {...field} {...props} type="checkbox" className={inputClassName} checked={field.value} innerRef={ref} />

      <span className={labelClassName}>{label}</span>
    </label>
  );
});

export default Checkbox;
