import { observer } from 'mobx-react';
import React, { useCallback, useContext, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';

import { ImageSize } from 'types/images';

import { getCroppedImg } from 'utils/canvas';

import { AvatarControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';

import styles from './changeAvatarModal.module.scss';

interface IChangeAvatarModal {
  closeModal: () => void;
}

const ChangeAvatarModal: React.FC<IChangeAvatarModal> = observer(({ closeModal }) => {
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [areaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [imageSize, setImageSize] = useState<ImageSize | null>(null);
  const [isFetchingAvatar, setIsFetchingAvatar] = useState(false);
  const [fileName, setFileName] = useState('');
  const [error, setError] = useState('');
  const avatarController = useContext(AvatarControllerContext);

  const onCropComplete = useCallback((_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const readFile = (file: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;
    e.persist();
    const file = e.target.files[0];
    if (!avatarController.isAcceptableImageType(file)) return;
    setFileName(`avatar_${file.name}`);

    const imageDataUrl = await readFile(file);
    const img = new Image();

    img.src = imageDataUrl as string;
    img.onload = () => {
      setImageSize({
        height: img.height,
        width: img.width,
      });
      setImage(imageDataUrl as string);
    };
  };

  const uploadCroppedImage = async (croppedImage: Blob): Promise<void> => {
    const newImage = {
      filename: fileName,
      file: croppedImage,
      tag: 'AV',
    };

    await avatarController.uploadImage(newImage);
  };

  const showCroppedImage = useCallback(async (): Promise<void> => {
    if (!image || !areaPixels) return;
    setIsFetchingAvatar(true);

    try {
      const croppedImage = await getCroppedImg(image, areaPixels);
      if (croppedImage) {
        await uploadCroppedImage(croppedImage.file);
        closeModal();
      }
    } catch (error) {
      setError('Something went wrong. Please try again later.');
    } finally {
      setIsFetchingAvatar(false);
    }
  }, [image, areaPixels]);

  return (
    <div className={styles['avatar-form-container']}>
      <div className={styles['input-file-container']}>
        <input
          className={styles['input-file']}
          type="file"
          name="cover"
          accept="image/bmp, image/gif, image/jpeg, image/png"
          onChange={handleFileChange}
          id="fileInput"
          disabled={isFetchingAvatar}
        />
      </div>

      {image && imageSize && (
        <div className={styles['crop-container']} style={{ width: '100%', height: imageSize.height }}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            maxZoom={5}
            cropShape="round"
            showGrid={false}
            objectFit="contain"
          />
        </div>
      )}
      {error && <Alert danger>{error}</Alert>}
      <div className={styles['modal-buttons']}>
        <Button onClick={showCroppedImage} disabled={!image || isFetchingAvatar} loading={isFetchingAvatar}>
          Save changes
        </Button>
        <Button onClick={closeModal} disabled={isFetchingAvatar}>
          Cancel
        </Button>
      </div>
    </div>
  );
});
export default ChangeAvatarModal;
