import withAuth from 'app/hoc/withAuth';
import ChangePasswordModal from 'app/modals/changePasswordModal/changePasswordModal';
import DeleteAccount from 'app/widgets/deleteAccount/deleteAccount';
import PurchaseSubscriptionButton from 'app/widgets/purchaseSubscriptionButton/purchaseSubscriptionButton';
import SelectPlanForm from 'app/widgets/selectPlanForm/selectPlanForm';
import PromoCodeForm from 'app/widgets/setPromoCodeModal/promocodeForm/promoCodeForm';
import SubAccountMessages from 'app/widgets/subAccountMessages/subAccountMessages';
import Subscription from 'app/widgets/subscription/subscription';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { IOrdersResponse } from 'types/orders';

import { translateErrors } from 'utils/translateErrors';

import { Routes } from 'app/global/routes';

import {
  ManageSubscriptionsControllerContext,
  UtomikSupportedInCountryControllerContext,
} from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Modal from 'app/components/modal/modal';
import Spinner from 'app/components/spinner/spinner';

import styles from './subscriptons.module.scss';

const SubscriptionPage = observer(() => {
  const utomikSupportedInCountryController = useContext(UtomikSupportedInCountryControllerContext);
  const manageSubscriptionsController = useContext(ManageSubscriptionsControllerContext);
  const [modalPasswordIsOpen, setModalPasswordIsOpen] = useState(false);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const toggleModalPassword = () => {
    setModalPasswordIsOpen((prev) => !prev);
  };
  const {
    canStartUtomikSubscription,
    userCanStartTrial,
    utomikIsLiveInUsersCountry,
    subscriptions,
    userHasPendingSubscriptionOrders,
    isFetchingReactivate,
    canSwitchToUtomikSubscription,
    hasSwitchedMaxNumberOfTimes,
    cannotSwitchWarning,
    reseller,
    preconditions,
    setSubscriptionUrl,
    cancelSubscription,
    reactivateSubscription,
    user,
  } = manageSubscriptionsController;

  const initPage = async () => {
    setIsLoading(true);

    try {
      await utomikSupportedInCountryController.checkIsUtomikSupportedInCountry();
      await manageSubscriptionsController.initialPage();
    } catch (e) {
      console.error('SUBSCRIPTION PAGE initPage', e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initPage();
  }, [user.deletion_date]);

  const handleClickPurchaseButton = () => {
    navigate(Routes.SelectPlan);
  };

  const handleCancelSubscription = (url: string) => {
    setSubscriptionUrl(url);
    toggleModalPassword();
  };

  const handleReactivateSubscription = async (url: string) => {
    setSubscriptionUrl(url);
    await reactivateSubscription();
  };

  const isShowDeletedAccountSection = user.deletion_date && !isLoading;
  const isCanStartUtomikSubscription = canStartUtomikSubscription && !user.deletion_date && !isLoading;
  const isCanNotStartUtomikSubscription = !canStartUtomikSubscription && !user.deletion_date && !isLoading;
  const isShowPromoForm =
    !user.deletion_date && (canSwitchToUtomikSubscription || canStartUtomikSubscription) && !isLoading;

  const hasPermissions = preconditions && preconditions.canManageSubscriptionAndBillingInfo;
  const notPermissions = preconditions && !preconditions.canManageSubscriptionAndBillingInfo;

  return (
    <>
      <div className={styles['page-subscriptions']}>
        <section className="container">
          {!isLoading && !utomikIsLiveInUsersCountry && (
            <Alert info icon={'circle-exclamation'}>
              Utomik is not officially supported in your country. You may not be able to buy, reactivate or cancel a
              subscription.
            </Alert>
          )}

          <h1 className={styles['page-subscriptions-title']}>
            <span className="fas fa-id-card"></span> Manage subscription
          </h1>
          {isLoading && <Spinner />}
          {!isLoading && notPermissions && <SubAccountMessages />}
          {isShowDeletedAccountSection && <DeleteAccount />}
          {isCanStartUtomikSubscription && hasPermissions && (
            <div className={styles['page-subscriptions-subtitle']}>
              {userHasPendingSubscriptionOrders && (
                <Alert info icon="info-circle">
                  An order for a subscription is currently pending. As soon as the payment transaction has completed,
                  your Utomik subscription will be activated.
                </Alert>
              )}
              <p>You have no active Utomik subscription.</p>
              <p>Start using a Personal Plan or Family Plan now.</p>
              {!userHasPendingSubscriptionOrders && utomikIsLiveInUsersCountry && (
                <PurchaseSubscriptionButton
                  canStartTrial={userCanStartTrial}
                  renewLabel="Renew Subscription"
                  handleClick={handleClickPurchaseButton}
                ></PurchaseSubscriptionButton>
              )}
            </div>
          )}

          {!user.deletion_date && !isLoading && hasPermissions && (
            <div>
              {subscriptions.map((subscription) => (
                <React.Fragment key={subscription.id}>
                  <Subscription
                    name={subscription.name}
                    status={subscription.status}
                    inTrial={subscription.in_trial}
                    inGrace={subscription.in_grace}
                    isActiveSubscription={subscription.status === 'ACTIVE'}
                    recurring={subscription.recurring}
                    renews={subscription.renews}
                    expireDue={subscription.expire_due}
                    paidUntil={subscription.paid_until}
                    orderSubTrialPeriodLength={Number((subscription.order as IOrdersResponse).sub_trial_period_length)}
                    orderSubTrialPeriod={(subscription.order as IOrdersResponse).sub_trial_period}
                    orderReseller={!!(subscription.order as IOrdersResponse).reseller}
                    url={subscription.url}
                    cancelSubscription={handleCancelSubscription}
                    reactivateSubscription={handleReactivateSubscription}
                    isFetchingReactivate={isFetchingReactivate}
                    reseller={reseller}
                  />
                </React.Fragment>
              ))}
            </div>
          )}
        </section>

        <Modal
          show={modalPasswordIsOpen}
          onHide={toggleModalPassword}
          title="Are you sure you want to cancel your subscription?"
        >
          <ChangePasswordModal
            closeModal={toggleModalPassword}
            subtitle={[
              'When you cancel this subscription, your subscription will automatically terminate at the end date.',
            ]}
            placeholder="Password"
            danger
            textButtonNext="Yes, I am sure"
            closeModalAfterConfirm={true}
            completeConfirm={cancelSubscription}
          />
        </Modal>
      </div>

      {isCanNotStartUtomikSubscription && hasPermissions && (
        <section className="full-page-section">
          <div className="container">
            <h1 className={styles['title-subscriptions-form']}>
              <span className="fas fa-id-card" />
              Change your subscription
            </h1>
            {!canSwitchToUtomikSubscription && hasSwitchedMaxNumberOfTimes && (
              <Alert info className={styles['alert-info']}>
                <div className={styles['alert-info-body']}>
                  <p>Thanks for upgrading your subscription!</p>
                  <p className="small">
                    You have reached the limit of subscription plan switches
                    {subscriptions[0].recurring && <span>&nbsp;this term</span>}.
                  </p>
                </div>
              </Alert>
            )}
            {!canSwitchToUtomikSubscription && cannotSwitchWarning && (
              <Alert warning>
                {<span dangerouslySetInnerHTML={{ __html: translateErrors(cannotSwitchWarning) }} />}
              </Alert>
            )}
            {canSwitchToUtomikSubscription && <SelectPlanForm isSubscriptionPage={true} />}
          </div>
        </section>
      )}
      {isShowPromoForm && hasPermissions && (
        <div className={clsx(styles['page-subscriptions'], styles['page-subscription-promo'])}>
          <section className="container">
            <h1 className={styles['page-subscriptions-title']}>
              <span className="fas fa-gift"></span> Coupon code
            </h1>
            {canStartUtomikSubscription && <p>If you have a coupon code for a subscription, claim it here.</p>}
            {!canStartUtomikSubscription && <p>If you have a coupon code for a subscription, you can check it here.</p>}

            <PromoCodeForm isButtonPrimary={false} isShowButton={true} placeholder="coupon code" />
          </section>
        </div>
      )}
    </>
  );
});
export default withAuth(SubscriptionPage);
