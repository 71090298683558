import withNavigate from 'app/hoc/withNavigate';
import { Outlet } from 'react-router-dom';

import Copyrights from 'app/components/copyrights/copyrights';
import Header from 'app/components/header/header';

import './onlyLogo.scss';

const OnlyLogoLayout = () => (
  <div className="only-logo-layout">
    <Header className="only-logo-layout__header" register onlyLogo />

    <main className="only-logo-layout__main">
      <Outlet />
    </main>

    <Copyrights className="only-logo-layout__copyrights" />
  </div>
);

export default withNavigate(OnlyLogoLayout);
