import AnalyticService from 'services/analyticsService/analyticsService';

import { DOWNLOAD_UTOMIK_INSTALLER_LINK, GOOGLE_PLAY_DOWNLOAD_LINK } from 'app/global/constants';

export default class DownloadService {
  public constructor(private readonly _analyticService: AnalyticService) {}
  public handleDownloadUtomik = () => {
    window.location.href = DOWNLOAD_UTOMIK_INSTALLER_LINK;
    this._analyticService.handleDownloadInstaller(DOWNLOAD_UTOMIK_INSTALLER_LINK);
  };

  public handleDownloadGooglePlay = () => {
    window.open(GOOGLE_PLAY_DOWNLOAD_LINK);
    this._analyticService.handleDownloadInstaller(GOOGLE_PLAY_DOWNLOAD_LINK);
  };
}
