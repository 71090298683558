import { validateRegistrationSchema } from 'app/validationSchemes/registerSchema';
import SsoButtons from 'app/widgets/ssoButtons/ssoButtons';
import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Errors } from 'types/errors';
import { isString } from 'types/guards';
import { Messages } from 'types/messages';
import { emailError } from 'types/register';

import { isValidEmail } from 'utils/helpers';
import { translateErrors } from 'utils/translateErrors';

import { Routes } from 'app/global/routes';

import { useMediaQuery } from 'app/hooks/useMediaQuery';

import { RegisterControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Input from 'app/components/input/input';
import Modal from 'app/components/modal/modal';

import breakpoints from 'app/theme/breakpoints.module.scss';

import LogInForm from '../login/loginForm';

import './registerForm.scss';

const Links: React.FC = () => (
  <div className="register-form__links">
    <p className="small">
      Got a discount/promo code?{' '}
      <Link className="register-form__link" to={Routes.Redeem}>
        Redeem code here
      </Link>
      .
    </p>

    <p className="small">
      By creating a Utomik account, you agree with our{' '}
      <a
        className="register-form__link"
        href="https://static.utomik.com/termsofservice.pdf"
        rel="noopener noreferrer"
        target="_blank"
        title="Read the terms of service"
      >
        terms of service
      </a>{' '}
      and{' '}
      <a
        className="register-form__link"
        href="https://static.utomik.com/privacypolicy.pdf"
        rel="noopener noreferrer"
        target="_blank"
        title="Read the privacy policy"
      >
        privacy policy
      </a>
      .
    </p>
  </div>
);

type FormValues = {
  email: string;
  password: string;
};

const initialValues: FormValues = {
  email: '',
  password: '',
};

interface IRegisterForm {
  autoLogoutWarning: string;
  errorWithUri: string;
}

const RegisterForm: React.FC<IRegisterForm> = observer(function RegisterForm({ autoLogoutWarning, errorWithUri }) {
  const registerController = useContext(RegisterControllerContext);
  const [errorRequest, setErrorRequest] = useState('');
  const [isEmailAvailable, setIsEmailAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorFormEmail, setErrorFormEmail] = useState<string | undefined>('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const isShowLabelText = useMediaQuery(`(max-width: ${breakpoints.mobile})`);

  const {
    promoValidationError,
    promotion,
    activeFreeCampaign,
    inviteEmail,
    inviteToken,
    isValidInviteToken,
    assumedCountry,
    errorInviteToken,
    warning,
    userCountry,
    checkIsValidInviteToken,
  } = registerController;

  useEffect(() => {
    if (inviteEmail) {
      formik.setFieldTouched('email', true);
      formik.setFieldValue('email', inviteEmail);
      validateEmail(inviteEmail);
    }
  }, [inviteEmail]);

  const toggleLogin = (e: React.MouseEvent) => {
    e.preventDefault();
    setModalIsOpen((prev) => !prev);
  };

  const closeModalLogin = () => {
    setModalIsOpen(false);
  };
  useEffect(() => {
    const checkIsValidToken = async () => {
      await checkIsValidInviteToken(inviteToken);
    };
    if (inviteToken) {
      checkIsValidToken();
    }
  }, [inviteToken]);

  const onSubmit = async (values: FormValues) => {
    try {
      const promoCode = promotion?.code;
      await registerController.registerWithEmail({
        email: values.email,
        password: values.password,
        promoCode: promoCode || null,
        inviteToken: inviteToken,
        country: userCountry,
      });
    } catch (e: any) {
      setErrorRequest(translateErrors(e));
    }
  };

  const debounceEmailValidation = async (email: string): Promise<void> => {
    if (!isValidEmail(email)) {
      return;
    }
    setIsLoading(true);
    try {
      const isAvailableEmail = await registerController.checkIsEmailAvailable(email);
      if (!isAvailableEmail) {
        const errorMessage = `This email address is already taken`;
        formik.setFieldError('email', errorMessage);
        setErrorFormEmail(errorMessage);
      } else {
        formik.setFieldError('email', undefined);
        setErrorFormEmail(undefined);
      }
      setIsEmailAvailable(!!isAvailableEmail);
    } catch (error: any) {
      const message: string | undefined = error?.message;
      if (isString(message) && Object.keys(emailError).includes(message)) {
        const errorMessage = emailError[message as keyof typeof emailError];
        formik.setFieldError('email', errorMessage);
        setErrorFormEmail(errorMessage);
      }
      setIsEmailAvailable(false);
    } finally {
      setIsLoading(false);
    }
  };

  let debounceTimer: NodeJS.Timeout | null = null;
  let validationTimeout: NodeJS.Timeout | null = null;

  const validateEmail = (emailValue: string) => {
    setIsEmailAvailable(false);
    if (isValidEmail(emailValue)) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setErrorFormEmail(undefined);
    }

    debounceTimer = setTimeout(async () => {
      await debounceEmailValidation(emailValue);
    }, 1000);
  };

  const setEmailError = (value: FormValues) => {
    if (errorFormEmail && isValidEmail(value.email)) {
      return { email: errorFormEmail };
    }
    if (isValidEmail(value.email)) {
      return {};
    }
    return { email: null };
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validateRegistrationSchema,
    onSubmit: onSubmit,
    validate: setEmailError,
  });

  const validateEmailDebounce = (): string | void => {
    validationTimeout && clearTimeout(validationTimeout);
    validationTimeout = setTimeout(() => {
      formik.validateField('email');
    }, 500);
  };

  const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length && !formik.touched.email) {
      formik.setFieldTouched('email', true);
    }
    setIsEmailAvailable(false);
    setErrorFormEmail(undefined);
    formik.handleChange(e);

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    validationTimeout && clearTimeout(validationTimeout);
    validateEmailDebounce();
    validateEmail(e.target.value);
  }, []);
  const handlePasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length && !formik.touched.password) {
      formik.setFieldTouched('password', true);
    }
    formik.handleChange(e);
  }, []);

  const isDisabled = (errors: any, values: FormValues) =>
    !!(errors.email || errors.password || !values.email || !values.password) ||
    !!errorRequest ||
    isLoading ||
    !isEmailAvailable ||
    registerController.busy;
  return (
    <FormikProvider value={formik}>
      <div className="register-form">
        <form className="register-form__form" onSubmit={formik.handleSubmit}>
          {isValidInviteToken && (
            <>
              <h1 className="title-invite-token">Accept your invite</h1>
              <p>Unlimited play in the Utomik family plan.</p>
            </>
          )}
          {!isValidInviteToken && (
            <>
              <h1 className="register-form__title">Create your Utomik account</h1>
              {activeFreeCampaign && (
                <div>
                  {assumedCountry?.isCloudSupported && (
                    <Alert cloud isShowIcon={false}>
                      {activeFreeCampaign.supported_user_message}
                    </Alert>
                  )}
                  {!assumedCountry?.isCloudSupported && (
                    <Alert cloud isShowIcon={false}>
                      {activeFreeCampaign.unsupported_user_message}
                    </Alert>
                  )}
                </div>
              )}
            </>
          )}

          {!activeFreeCampaign && promotion && promotion.type !== 'AF' && (
            <Alert info isShowIcon={false}>
              <p>
                Woohoo! You are joining Utomik with <strong>{promotion.name}</strong>.
              </p>
              <p>Sign up or log in now and start playing.</p>
            </Alert>
          )}

          {promoValidationError && <Alert danger>{promoValidationError}</Alert>}

          {errorInviteToken && <Alert danger>{errorInviteToken}</Alert>}

          {warning && <Alert warning>{translateErrors(warning)}</Alert>}

          {autoLogoutWarning && <Alert warning>{translateErrors(autoLogoutWarning)}</Alert>}

          {errorRequest && (
            <Alert danger>
              {errorRequest == Messages['PAIR-3-REGISTER'] ? (
                <span>
                  Account created, but something went wrong logging you in.{' '}
                  <a href="" onClick={toggleLogin}>
                    Log in now.
                  </a>
                </span>
              ) : (
                <span dangerouslySetInnerHTML={{ __html: errorRequest }} />
              )}
            </Alert>
          )}

          {errorWithUri && (
            <Alert danger>
              <span dangerouslySetInnerHTML={{ __html: errorWithUri }} />
            </Alert>
          )}

          <div>
            <SsoButtons
              action="Register"
              isDisabledButton={!!errorRequest}
              fromFilter="onlyActive"
              inviteToken={inviteToken}
            />
          </div>

          <hr className="register-form__divider register-form__divider-top" />

          <div className="register-form__credentials-form">
            <Input
              name="email"
              title="Please fill in this field."
              onChange={handleEmailChange}
              placeholder="Email"
              label={isShowLabelText ? 'Email address:' : ''}
              showCheck={!isLoading && isEmailAvailable}
              isLoading={isLoading}
              disabled={registerController.busy || !!errorRequest}
              customMessage={
                errorFormEmail === Errors.EMAIL_ADDRESS_TAKEN ? (
                  <>
                    This email address is already taken <br />{' '}
                    <Button link className="btn-link-danger" onClick={toggleLogin}>
                      Log in with this account?
                    </Button>
                  </>
                ) : null
              }
            />

            <Input
              name="password"
              type="password"
              label={isShowLabelText ? 'Password:' : ''}
              title="Please fill in this field."
              placeholder="Password"
              showCheck={!!formik.values.password && !formik.errors.password}
              onChange={handlePasswordChange}
              disabled={registerController.busy || !!errorRequest}
            />

            <Button
              type="submit"
              className={clsx('register-form__submit-button', 'register-form__form-button')}
              disabled={isDisabled(formik.errors, formik.values)}
              lg
              primary
              loading={registerController.busy}
            >
              {isValidInviteToken ? 'Finish' : 'Create account'}
            </Button>
          </div>
        </form>

        <hr className="register-form__divider register-form__divider--no-text" />

        <Links />
      </div>
      <Modal show={modalIsOpen} onHide={closeModalLogin} title="Log in">
        <LogInForm popup closeModal={closeModalLogin} />
      </Modal>
    </FormikProvider>
  );
});

export default RegisterForm;
