import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import { SentryInit } from 'utils/sentryInit';

import router from 'app/global/router';

import { LoginControllerContext } from './context/storesContext';
import { REACT_APP_GTM_KEY } from './global/constants';
import CookieConsent from './widgets/cookieConsent/cookieConsent';

import './App.scss';

const tagManagerArgs = {
  gtmId: REACT_APP_GTM_KEY,
};
TagManager.initialize(tagManagerArgs);

const App: React.FC = observer(function App() {
  const [cookies] = useCookies(['cookiescriptaccept']);
  const loginController = useContext(LoginControllerContext);

  SentryInit();
  const initialApp = () => {
    loginController.checkIsLoggedIn();
  };

  useEffect(() => {
    let antiClickjack = document.getElementById('awaitingClickjackCheck');

    if (!antiClickjack) {
      antiClickjack = document.createElement('style');
      antiClickjack.id = 'awaitingClickjackCheck';

      antiClickjack.innerHTML = 'body { display:none !important; }';

      document.body.appendChild(antiClickjack);
    }

    if (window.self === window.top) {
      if (antiClickjack.parentNode) {
        antiClickjack.parentNode.removeChild(antiClickjack);
      }
    } else if (window.top && window.top.location) {
      window.top.location = window.self.location;
    }
  }, []);

  useEffect(() => {
    initialApp();
  }, []);

  return (
    <>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={`
            default-src 'self' blob:;
            script-src 'self' 'unsafe-inline' 'unsafe-eval' *.hubspot.com feedback.hubapi.com *.hs-analytics.net *.hsadspixel.net *.hs-banner.com https://api.localhost.utomik.com/ https://api.utomik.com/ https://api.beta.utomik.com/ https://api.staging.utomik.com/ https://api.dev.utomik.com/ http://*.cloudfront.net https://static.utomik.com/ https://code.jquery.com/ https://js.recurly.com/ https://js.stripe.com https://tagmanager.google.com https://www.google-analytics.com https://ssl.google-analytics.com https://*.googletagmanager.com https://ajax.googleapis.com https://squeezely.tech/ https://*.visualwebsiteoptimizer.com http://*.visualwebsiteoptimizer.com/ https://cdn.pushcrew.com https://*.vwo.com https://*.hotjar.com https://*.hotjar.io https://js.hs-scripts.com/ https://ssl.kaptcha.com https://connect.facebook.net https://platform.twitter.com https://www.googleadservices.com https://googleads.g.doubleclick.net https://www.google.com https://www.gstatic.com https://platform.twitter.com/;
            style-src 'self' 'unsafe-inline' http://*.cloudfront.net https://*.googletagmanager.com https://tagmanager.google.com https://fonts.googleapis.com https://*.vwo.com https://*.visualwebsiteoptimizer.com https://s3.amazonaws.com https://cdn.pushcrew.com https://*.stripe.com/ https://cdnjs.cloudflare.com/ajax/libs/font-awesome/ https://js.recurly.com;
            img-src 'self' data: http://127.0.0.1.xip.io *.hubspot.com http://*.cloudfront.net https://*.cdn77.org https://ssl.gstatic.com https://www.gstatic.com https://fonts.gstatic.com https://*.google-analytics.com https://*.analytics.google.com https://*.googletagmanager.com https://*.g.doubleclick.net https://*.google.com https://www.google.com.ua/ https://www.google.nl https://www.google.de https://*.vwo.com https://*.visualwebsiteoptimizer.com https://cdn.pushcrew.com https://chart.googleapis.com https://wingify-assets.s3.amazonaws.com  https://ssl.kaptcha.com https://connect.facebook.net https://www.facebook.com https://*.twitter.com https://*.squeezely.tech https://api.recurly.com https://admin.pay.nl https://static.pay.nl https://i.ytimg.com/;
            font-src 'self' data: http://*.cloudfront.net https://fonts.gstatic.com https://cdnjs.cloudflare.com/ajax/libs/font-awesome/ https://*.vwo.com;
            connect-src 'self' *.hubspot.com *.hubapi.com *.hs-banner.com https://api.localhost.utomik.com/ https://api.beta.utomik.com/ https://api.staging.utomik.com/ https://api.utomik.com/ http://*.cloudfront.net https://api.recurly.com/ https://*.google-analytics.com https://*.analytics.google.com https://*.googletagmanager.com https://*.g.doubleclick.net https://*.google.com https://google.com https://ssl.kaptcha.com https://squeezely.tech https://*.hotjar.com wss://*.hotjar.com https://*.hotjar.io https://www.facebook.com https://connect.facebook.net https://utomik-nsu.akamaihd.net https://*.vwo.com https://*.visualwebsiteoptimizer.com https://sentry.light-it.tools;
            frame-src  https://platform.twitter.com/ *.hubspot.com https://connect.facebook.net https://www.facebook.com https://web.facebook.com/ https://www.google.com https://www.google.de https://www.facebook.com https://api.recurly.com https://*.twitter.com https://*.hotjar.com/ https://*.stripe.com/ https://*.vwo.com https://*.visualwebsiteoptimizer.com;
            child-src https://ssl.kaptcha.com;
            worker-src 'self' blob: https://ssl.kaptcha.com;
          `}
        ></meta>
        <meta httpEquiv="cache-control" content="no-cache, no-store, must-revalidate" />
        <meta httpEquiv="pragma" content="no-cache" />
        <meta httpEquiv="expires" content="0" />
      </Helmet>
      {!cookies.cookiescriptaccept && <CookieConsent />}
      <RouterProvider router={router} />
    </>
  );
});

export default App;
