import { action, computed, makeObservable, observable } from 'mobx';
import AnalyticService from 'services/analyticsService/analyticsService';
import CartService from 'services/cartService/cartService';
import CookieService from 'services/cookieService/cookieService';
import LoginService from 'services/loginService/loginServise';
import PageService from 'services/pageService/pageService';
import PromoService from 'services/promoService/promoService';
import SubscriptionService from 'services/subscriptionService/subscriptionService';
import UserService from 'services/userService/userService';

import { Messages } from 'types/messages';
import { IPromoResponse } from 'types/promo';

import { filterActiveSubscriptions } from 'utils/subscriptionFilters';

import { PROMO_CODE_COOKIE } from '../constants';
import { Routes } from '../routes';

export default class PromoPageController {
  public constructor(
    private readonly _promoService: PromoService,
    private readonly _subscriptionService: SubscriptionService,
    private readonly _loginService: LoginService,
    private readonly _userService: UserService,
    private readonly _cartService: CartService,
    private readonly _pageService: PageService,
    private readonly _cookieService: CookieService,
    private readonly _analyticService: AnalyticService,
  ) {
    makeObservable(this);
  }

  @observable
  private _promoFromCookie = '';

  @action
  public setPromoFromCookie(promoCode: string) {
    this._promoFromCookie = promoCode;
  }

  @computed
  public get promoFromCookie() {
    return this._promoFromCookie;
  }

  public putInCartAndProceedToCheckout = async (promo: IPromoResponse) => {
    let userHadSubscriptionsBefore = false;
    const accountSubscriptions = await this._userService.getAccountSubscriptions();
    const userCanStartTrial = await this._userService.getCanStartTrial();
    const activeAccountSubscriptions = filterActiveSubscriptions(accountSubscriptions);

    if (accountSubscriptions.length && !activeAccountSubscriptions.length) {
      userHadSubscriptionsBefore = true;
    }

    const subscriptionPlan = await this._subscriptionService.getSubscriptionPlan(promo.subscriptionplan.id);
    this._cartService.emptyCart();
    this._cartService.setProduct(promo.subscriptionplan);
    this._cartService.setPromo(promo);
    const metadata = {
      firstTimeSubscriber: userHadSubscriptionsBefore,
      canStartTrial: userCanStartTrial,
    };
    this._cartService.setMeta(metadata);
    this._analyticService.handleAddToCart({
      product: subscriptionPlan,
      canStartTrial: userCanStartTrial,
      promo: promo,
    });
    this._pageService.setLocation(Routes.Checkout);
  };

  public storePromoAndContinue = async (promoCode: string) => {
    const promoSubscription = await this._subscriptionService.getSubscriptionPlanForPromo(promoCode);
    if (promoSubscription) {
      const promo = promoSubscription.ref;
      if (!promo) {
        throw new Error('INVALID_PROMO_CODE');
      }
      const encodedRefParam = btoa(encodeURIComponent(promo.code));
      this._cookieService.setCookie(PROMO_CODE_COOKIE, encodedRefParam, { path: '/' });
      const isLoggedIn = await this._loginService.checkIsLoggedIn();

      if (isLoggedIn) {
        await this.putInCartAndProceedToCheckout(promo);
      } else {
        this._pageService.setLocation(Routes.Register + `/?ref=${promo.code}`);
      }
    } else {
      throw new Error(Messages.INVALID_PROMO_CODE);
    }
  };

  public setPromoCodeFromCookie = () => {
    const promoCodeFromCookie = this._promoService.getPromoCodeFromCookie();
    this.setPromoFromCookie(promoCodeFromCookie || '');
  };
}
