import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import CartService from 'services/cartService/cartService';
import DownloadService from 'services/downloadService/downloadService';
import FamilyMembersService from 'services/familyMembersService/familyMembersService';
import InitPagesService from 'services/initPagesService/initPagesService';
import PageService from 'services/pageService/pageService';
import SubscriptionManagementService from 'services/subscriptionManagementService/subscriptionManagementService';
import SubscriptionService from 'services/subscriptionService/subscriptionService';
import UserService from 'services/userService/userService';

import { IReauthModalContent } from 'types/shared';
import {
  ISubscriptionByUsableSubscription,
  ISubscriptionsResponseWithOrders,
  SubscriptionPlansTrialPeriod,
  SubscriptionsTrialPeriod,
} from 'types/subscriptions';

import { filterFirstSubscriptionByType } from 'utils/subscriptionFilters';
import { translateErrors } from 'utils/translateErrors';

export default class HomePageController extends InitPagesService {
  public constructor(
    private readonly _userService: UserService,
    private readonly _subscriptionService: SubscriptionService,
    private readonly _cartService: CartService,
    private readonly _subscriptionManagementService: SubscriptionManagementService,
    private readonly _familyMembersService: FamilyMembersService,
    private readonly _pageService: PageService,
    private readonly _downloadService: DownloadService,
  ) {
    super();
    makeObservable(this);
  }

  @observable
  private _accountSubscriptions: ISubscriptionsResponseWithOrders[] = [];

  @action
  public setAccountSubscriptions(subscription: ISubscriptionsResponseWithOrders[]) {
    this._accountSubscriptions = subscription;
  }

  @computed
  public get accountSubscriptions(): ISubscriptionsResponseWithOrders[] {
    return this._accountSubscriptions;
  }

  @observable
  private _accountCanceledSubscriptions: ISubscriptionsResponseWithOrders[] = [];

  @action
  public setAccountCanceledSubscriptions(subscription: ISubscriptionsResponseWithOrders[]) {
    this._accountCanceledSubscriptions = subscription;
  }

  @computed
  public get accountCanceledSubscriptions(): ISubscriptionsResponseWithOrders[] {
    return this._accountCanceledSubscriptions;
  }

  @observable
  private _accountActiveSubscriptions: ISubscriptionsResponseWithOrders[] = [];

  @action
  public setAccountActiveSubscriptions(subscription: ISubscriptionsResponseWithOrders[]) {
    this._accountActiveSubscriptions = subscription;
  }

  @computed
  public get accountActiveSubscriptions(): ISubscriptionsResponseWithOrders[] {
    return this._accountActiveSubscriptions;
  }

  @observable
  private _userHasDeletionDate = false;

  @action
  public setUserHasDeletionDate(isHasDeletionDate: boolean) {
    this._userHasDeletionDate = isHasDeletionDate;
  }

  @computed
  public get userHasDeletionDate() {
    return this._userHasDeletionDate;
  }

  @observable
  private _hasActiveSubscriptions = false;

  @action
  public setHasActiveSubscriptions(hasActiveSubscriptions: boolean) {
    this._hasActiveSubscriptions = hasActiveSubscriptions;
  }

  @computed
  public get hasActiveSubscriptions() {
    return this._hasActiveSubscriptions;
  }

  @observable
  private _hasCanceledSubscriptions = false;

  @action
  public setHasCanceledSubscriptions(hasCanceledSubscriptions: boolean) {
    this._hasCanceledSubscriptions = hasCanceledSubscriptions;
  }

  @computed
  public get hasCanceledSubscriptions() {
    return this._hasCanceledSubscriptions;
  }

  @observable
  private _basicSubscription: ISubscriptionByUsableSubscription | null = null;

  @action
  public setBasicSubscription(basicSubscription: ISubscriptionByUsableSubscription | null) {
    this._basicSubscription = basicSubscription;
  }

  @computed
  public get basicSubscription() {
    return this._basicSubscription;
  }

  @observable
  private _basicFamilySubscription: ISubscriptionByUsableSubscription | null = null;

  @action
  public setBasicFamilySubscription(basicFamilySubscription: ISubscriptionByUsableSubscription | null) {
    this._basicFamilySubscription = basicFamilySubscription;
  }

  @computed
  public get basicFamilySubscription() {
    return this._basicFamilySubscription;
  }

  @observable
  private _trialPeriod: SubscriptionPlansTrialPeriod | null = null;

  @action
  public setTrialPeriod(trialPeriod: SubscriptionPlansTrialPeriod | null) {
    this._trialPeriod = trialPeriod;
  }

  @computed
  public get trialPeriod() {
    return this._trialPeriod;
  }

  @observable
  private _isFetchingIsReactivate = false;

  @action
  public setIsFetchingIsReactivate(isFetchingIsReactivate: boolean) {
    this._isFetchingIsReactivate = isFetchingIsReactivate;
  }

  @computed
  public get isFetchingIsReactivate() {
    return this._isFetchingIsReactivate;
  }

  @observable
  private _hadPastSubscription = false;

  @action
  public setHasPastSubscription(isHadPastSubscription: boolean) {
    this._hadPastSubscription = isHadPastSubscription;
  }

  @computed
  public get hadPastSubscription() {
    return this._hadPastSubscription;
  }

  @observable
  private _errorReactivate = '';

  @action
  public setErrorReactivate(errorReactivate: string) {
    this._errorReactivate = errorReactivate;
  }

  @computed
  public get errorReactivate() {
    return this._errorReactivate;
  }

  @observable
  private _reauthModalIsOpen = false;

  @action
  public setIsOpenReauyhModal(isOpenReauth: boolean) {
    this._reauthModalIsOpen = isOpenReauth;
  }

  @computed
  public get reauthModalIsOpen() {
    return this._reauthModalIsOpen;
  }

  @observable
  private _reauthModaContent: IReauthModalContent | null = null;

  @action
  public setReauthModaContent(modalContent: IReauthModalContent | null) {
    this._reauthModaContent = modalContent;
  }

  @computed
  public get reauthModaContent() {
    return this._reauthModaContent;
  }

  @observable
  private _isOpenReactivateModal = false;

  @action
  public setIsOpenReactivateModal(isOpenReactivateModal: boolean) {
    this._isOpenReactivateModal = isOpenReactivateModal;
  }

  @computed
  public get isOpenReactivateModal() {
    return this._isOpenReactivateModal;
  }

  @observable
  private _expirationDateOldSubscription: Date | null = null;

  @action
  public setExpirationDateOldSubscription(expirationDateOldSubscription: Date | null) {
    this._expirationDateOldSubscription = expirationDateOldSubscription;
  }

  @computed
  public get expirationDateOldSubscription() {
    return this._expirationDateOldSubscription;
  }

  @observable
  private _oldSubscriptionUrl = '';

  @action
  public setOldSubscriptionUrl(oldSubscriptionUrl: string) {
    this._oldSubscriptionUrl = oldSubscriptionUrl;
  }

  @computed
  public get oldSubscriptionUrl() {
    return this._oldSubscriptionUrl;
  }

  public initPage = async () => {
    this.setIsLoading(true);
    if (!this._userService.user.id) {
      await this._userService.fetchUser();
    }
    const accountSubscriptions = await this._userService.getAccountSubscriptions();
    const subscriptions = await this._subscriptionService.getSubscriptionPlans();

    const basicSubscription = filterFirstSubscriptionByType(subscriptions, 'SP');
    const basicFamilySubscription = filterFirstSubscriptionByType(subscriptions, 'SF');

    this.setBasicSubscription(basicSubscription);
    this.setBasicFamilySubscription(basicFamilySubscription);
    this.updateTrialPeriod();
    this.setAccountSubscriptions(accountSubscriptions);
    const accountActiveSubscriptions = accountSubscriptions.filter((subscription) => subscription.status === 'ACTIVE');
    const accountCanceledSubscriptions = accountSubscriptions.filter(
      (subscription) => subscription.status === 'CANCELED',
    );

    this.setAccountActiveSubscriptions(accountActiveSubscriptions);
    this.setHasActiveSubscriptions(!!accountActiveSubscriptions.length);
    this.setAccountCanceledSubscriptions(accountCanceledSubscriptions);
    this.setHasCanceledSubscriptions(!!accountCanceledSubscriptions.length);

    this.setIsLoading(false);
    this.checkHasUserDeletionDate();
    this.checkUserHadPastSubscription();
  };

  public getAccount = () => {
    return this._userService.getLoggedInAccount();
  };

  public checkHasUserDeletionDate = () => {
    this.setUserHasDeletionDate(!!this._userService.user.deletion_date);
  };

  public updateTrialPeriod = () => {
    if (!this.basicSubscription) {
      this.setTrialPeriod(null);
      return;
    }
    const trialPeriod = {
      trialPeriod:
        this.basicSubscription.trial_period &&
        `${SubscriptionsTrialPeriod[this.basicSubscription.trial_period]}${
          this.basicSubscription.trial_period_length > 1 ? 's' : ''
        }`,
      trialPeriodLength: this.basicSubscription.trial_period_length,
    };
    this.setTrialPeriod(trialPeriod);
  };

  public getUser = () => {
    return this._userService.user;
  };

  public reactivateSubscription = async () => {
    if (!this.accountCanceledSubscriptions.length && !this.oldSubscriptionUrl) return;

    this.setIsFetchingIsReactivate(true);
    try {
      const subscriptionUrl = this.oldSubscriptionUrl || this.accountCanceledSubscriptions[0].url;
      const oldSubscriptions = await this._userService.getAccountSubscriptions();

      const subscription = this.oldSubscriptionUrl ? oldSubscriptions[0] : this.accountCanceledSubscriptions[0];
      await this._subscriptionManagementService.reactivateSubscription(subscriptionUrl, subscription);
      this.setAccountCanceledSubscriptions([]);
      this.setHasCanceledSubscriptions(false);
      this.setHasActiveSubscriptions(true);
    } catch (e) {
      this.setErrorReactivate(translateErrors(e));
    } finally {
      this.setIsFetchingIsReactivate(false);
    }
  };

  public checkUserHadPastSubscription = () => {
    if (!this.accountSubscriptions.length) {
      this.setHasPastSubscription(false);
      return;
    }
    const accountSubscriptionsLength = this.accountSubscriptions.length;
    const accountActiveSubscriptionsLength = this.accountActiveSubscriptions.length;
    const accountSubscriptionCanceledLength = this.accountCanceledSubscriptions.length;
    const isHasAccountPastSubscription =
      accountSubscriptionsLength > accountActiveSubscriptionsLength + accountSubscriptionCanceledLength;
    this.setHasPastSubscription(isHasAccountPastSubscription);
  };

  public toggleModalReauth = () => {
    this.setIsOpenReauyhModal(!this.reauthModalIsOpen);
  };

  public reactivateOldSubscriptions = async () => {
    await this._userService.fetchAccountSubscriptions();
    const oldSubscriptions = await this._userService.getAccountSubscriptions();
    if (oldSubscriptions[0]) {
      const oldSubscription = oldSubscriptions[0];
      const expirationDate = oldSubscription.expire_due;
      this.setOldSubscriptionUrl(oldSubscription.url);
      this.setExpirationDateOldSubscription(expirationDate);
      const callback = async () => {
        await this.reactivateSubscription();
        this.reloadPage();
      };
      this.setReauthModaContent({
        title: 'Old Subscription Found!',
        message: [
          'You successfully left the family plan. We noticed that you still have an old plan' +
            (this.expirationDateOldSubscription
              ? ' which will expire on ' + moment(this.expirationDateOldSubscription).format('LL')
              : '') +
            '.',
          'Would you like to reactivate this plan now?',
        ],
        buttonLabel: 'Reactivate',
        buttonIsDanger: false,
        cancelButtonLabel: "No, don't reactivate",
        callback: async () => await callback(),
      });
      this.toggleModalReauth();
    } else {
      this.reloadPage();
    }
  };

  public reloadPage = () => {
    this._pageService.reloadPage();
  };

  public uncoupleSubAccount = async () => {
    await this._familyMembersService.uncoupleMember();
    this.setIsOpenReactivateModal(true);
  };

  public leaveFamilyPlan = () => {
    this.setReauthModaContent({
      title: 'Are you sure?',
      message: ['If you leave this family plan, you will have to get a new subscription to play games on Utomik.'],
      buttonLabel: 'Yes, I am sure',
      buttonIsDanger: true,
      callback: async () => await this.uncoupleSubAccount(),
    });
    this.toggleModalReauth();
  };

  public handleDownloadUtomik = () => {
    this._downloadService.handleDownloadUtomik();
  };

  public handleDownloadGooglePlay = () => {
    this._downloadService.handleDownloadGooglePlay();
  };
}
