import { RecurlyProvider } from '@recurly/react-recurly';
import App from 'app/App';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from 'reportWebVitals';

import { REACT_APP_RECURLY_KEY } from 'app/global/constants';

import { initGlobals } from 'app/context/storesContext';

import 'app/theme/global.scss';

import './utils/initFontAwesome';

// Init contexts
initGlobals();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <CookiesProvider defaultSetOptions={{ path: '/' }}>
    <HelmetProvider>
      {window.recurly ? (
        <RecurlyProvider publicKey={REACT_APP_RECURLY_KEY}>
          <App />
        </RecurlyProvider>
      ) : (
        <App />
      )}
    </HelmetProvider>
  </CookiesProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
