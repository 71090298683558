import AnalyticService from 'services/analyticsService/analyticsService';
import BoxOfficeService from 'services/boxOficceService/boxOficceService';
import CampaignService from 'services/campaignService/campaignService';
import CartService from 'services/cartService/cartService';
import DownloadService from 'services/downloadService/downloadService';
import FamilyMembersService from 'services/familyMembersService/familyMembersService';
import GlobalErrorsService from 'services/globalErrorsService/globalErrorsService';
import InitPagesService from 'services/initPagesService/initPagesService';
import LocalizationService from 'services/localizationService/localizationService';
import LoginService from 'services/loginService/loginServise';
import MiscService from 'services/miscService/miskService';
import NavigateService from 'services/navigateService/navigateService';
import PageService from 'services/pageService/pageService';
import PairingService from 'services/pairingService/pairingService';
import PaymentProviderService from 'services/paymentProviderService/paymentProviderService';
import PaymentService from 'services/paymentService/paymentService';
import PreconditionService from 'services/preconditionService/preconditionService';
import ProfileService from 'services/profileService/profileService';
import PromoService from 'services/promoService/promoService';
import SelectPaymentMechanismService from 'services/selectPaymentMechanismService/selectPaymentMechanismService';
import ServiceSettingService from 'services/serviceSettingService/serviceSettingService';
import SsoService from 'services/ssoService/ssoService';
import SubscriptionManagementService from 'services/subscriptionManagementService/subscriptionManagementService';
import SubscriptionService from 'services/subscriptionService/subscriptionService';
import UploadService from 'services/uploadService/uploadService';
import UserService from 'services/userService/userService';

import SecurityToken from 'dataStore/securityToken/securityToken';
import AuthorizationStore from 'dataStore/stores/authorizationStore/authorizationStore';
import LocalizationStore from 'dataStore/stores/localizationStore/localizationStore';
import PageStore from 'dataStore/stores/pageStore/pageStore';
import ServiceSettingStore from 'dataStore/stores/serviceSettingStore/serviceSettingStore';
import UserStore from 'dataStore/stores/userStore/userStore';
import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import CookieService from '../../services/cookieService/cookieService';

import AvatarController from './avatarController/avatarController';
import CheckoutController from './checkoutController/checkoutController';
import CheckoutSuccessController from './checkoutSuccessController/checkoutSuccessController';
import { platformURL } from './constants';
import DeleteAccountController from './deleteAccountController/deleteAccountController';
import FamilyController from './familyController/familyController';
import HeaderController from './headerController/headerController';
import HomePageController from './homePageController/homePageController';
import InvoicesController from './invoicesController/invoicesController';
import JoinFamilyController from './joinFamilyController/joinFamilyController';
import LoginController from './loginController/loginController';
import ManageSubscriptionsController from './manageSubscriptionsController/manageSubscriptionsController';
import NewsLetterController from './newsLetterController/newsLetterController';
import PageController from './pageController/pageController';
import PairingController from './pairingController/pairingController';
import PaynlIdealController from './paynlIdealController/paynlIdealController';
import PreconditionController from './preconditionController/preconditionController';
import PromoPageController from './promoPageController/promoPageController';
import RegisterController from './registerController/registerController';
import SelectPlanController from './selectPlanController/selectPlanController';
import SsoController from './ssoController/ssoController';
import UpdateBillingController from './updateBillingController/updateBillingController';
import UserController from './userController/userController';
import UtomikSupportedInCountryController from './utomikSupportedInCountryController/utomikSupportedInCountryController';

export default class RootStore {
  private readonly httpTransport: AxiosTransport;
  private readonly authorizationStore: AuthorizationStore;
  private readonly pageStore: PageStore;
  public readonly securityToken: SecurityToken;
  public readonly cookieService: CookieService;
  public readonly userStore: UserStore;
  public readonly userService: UserService;
  public readonly ssoService: SsoService;
  public readonly pairingService: PairingService;
  public readonly serviceSettingService: ServiceSettingService;
  public readonly serviceSettingStore: ServiceSettingStore;
  public readonly localizationService: LocalizationService;
  public readonly localizationStore: LocalizationStore;
  public readonly miscService: MiscService;
  public readonly utomikSupportedInCountryController: UtomikSupportedInCountryController;
  public readonly profileService: ProfileService;
  public readonly uploadService: UploadService;
  public readonly selectPaymentMechanismService: SelectPaymentMechanismService;
  public readonly boxOfficeService: BoxOfficeService;
  public readonly subscriptionService: SubscriptionService;
  public readonly navigateService: NavigateService;
  public readonly cartService: CartService;
  public readonly promoService: PromoService;
  public readonly paymentService: PaymentService;
  public readonly subscriptionManagementService: SubscriptionManagementService;
  public readonly loginService: LoginService;
  public readonly campaignService: CampaignService;
  public readonly preconditionService: PreconditionService;
  public readonly initPagesService: InitPagesService;
  public readonly pageService: PageService;
  public readonly paymentProviderService: PaymentProviderService;
  public readonly familyMembersService: FamilyMembersService;
  public readonly globalErrorsService: GlobalErrorsService;
  public readonly analyticService: AnalyticService;
  public readonly downloadService: DownloadService;

  // Application Controllers
  public readonly registerController: RegisterController;
  public readonly loginController: LoginController;
  public readonly userController: UserController;
  public readonly pageController: PageController;
  public readonly ssoController: SsoController;
  public readonly pairingController: PairingController;
  public readonly avatarController: AvatarController;
  public readonly updateBillingController: UpdateBillingController;
  public readonly selectPlanController: SelectPlanController;
  public readonly checkoutController: CheckoutController;
  public readonly manageSubscriptionsController: ManageSubscriptionsController;
  public readonly invoicesController: InvoicesController;
  public readonly checkoutSuccessController: CheckoutSuccessController;
  public readonly headerController: HeaderController;
  public readonly preconditionController: PreconditionController;
  public readonly newsLetterController: NewsLetterController;
  public readonly deleteAccountController: DeleteAccountController;
  public readonly homePageController: HomePageController;
  public readonly promoPageController: PromoPageController;
  public readonly paynlIdealController: PaynlIdealController;
  public readonly familyController: FamilyController;
  public readonly joinFamilyController: JoinFamilyController;

  public constructor() {
    this.cookieService = new CookieService();
    this.authorizationStore = new AuthorizationStore(this.cookieService);
    this.pageStore = new PageStore();
    this.navigateService = new NavigateService();
    this.initPagesService = new InitPagesService();
    this.pageService = new PageService(this.navigateService);
    this.globalErrorsService = new GlobalErrorsService();
    // Security token
    this.securityToken = new SecurityToken(this.cookieService);
    // Transports & Authorization
    this.httpTransport = new AxiosTransport(
      platformURL,
      this.securityToken,
      this.cookieService,
      this.authorizationStore,
      this.navigateService,
      this.globalErrorsService,
    );
    this.httpTransport.resetDefaultConfig?.('en');
    this.userStore = new UserStore();
    this.localizationStore = new LocalizationStore();
    this.paymentProviderService = new PaymentProviderService(this.httpTransport);
    this.paynlIdealController = new PaynlIdealController(this.paymentProviderService);
    this.pageController = new PageController(this.httpTransport, this.pageStore, this.pageService);
    this.loginService = new LoginService(
      this.httpTransport,
      this.authorizationStore,
      this.cookieService,
      this.userStore,
    );
    this.analyticService = new AnalyticService(this.loginService, this.cookieService, this.httpTransport);
    this.downloadService = new DownloadService(this.analyticService);
    this.paymentService = new PaymentService(this.httpTransport, this.loginService, this.pageService);
    this.promoService = new PromoService(this.httpTransport, this.cookieService, this.analyticService);
    this.userService = new UserService(
      this.httpTransport,
      this.cookieService,
      this.authorizationStore,
      this.userStore,
      this.loginService,
    );
    this.familyMembersService = new FamilyMembersService(this.httpTransport, this.userService);
    this.familyController = new FamilyController(this.userService, this.familyMembersService);
    this.newsLetterController = new NewsLetterController(this.userService);
    this.serviceSettingStore = new ServiceSettingStore();
    this.serviceSettingService = new ServiceSettingService(this.httpTransport, this.serviceSettingStore);
    this.campaignService = new CampaignService(this.userService, this.serviceSettingService, this.httpTransport);
    this.localizationService = new LocalizationService(
      this.httpTransport,
      this.serviceSettingService,
      this.localizationStore,
    );
    this.cartService = new CartService(
      this.cookieService,
      this.httpTransport,
      this.userService,
      this.localizationService,
    );
    this.subscriptionService = new SubscriptionService(
      this.httpTransport,
      this.campaignService,
      this.userService,
      this.promoService,
      this.cartService,
      this.pageService,
      this.analyticService,
    );
    this.joinFamilyController = new JoinFamilyController(
      this.userService,
      this.subscriptionService,
      this.pageService,
      this.analyticService,
    );

    this.deleteAccountController = new DeleteAccountController(this.userService, this.subscriptionService);

    this.subscriptionManagementService = new SubscriptionManagementService(
      this.paymentService,
      this.cartService,
      this.loginService,
      this.httpTransport,
      this.analyticService,
    );

    this.boxOfficeService = new BoxOfficeService(
      this.userService,
      this.subscriptionService,
      this.cartService,
      this.subscriptionManagementService,
      this.paymentService,
    );
    this.checkoutSuccessController = new CheckoutSuccessController(
      this.userService,
      this.subscriptionManagementService,
      this.boxOfficeService,
      this.downloadService,
    );
    this.preconditionService = new PreconditionService(this.boxOfficeService, this.userService);
    this.invoicesController = new InvoicesController(this.userService, this.preconditionService);

    this.headerController = new HeaderController(this.preconditionService, this.loginService, this.cookieService);
    this.selectPaymentMechanismService = new SelectPaymentMechanismService(this.userService);
    this.preconditionController = new PreconditionController(this.preconditionService);
    this.updateBillingController = new UpdateBillingController(
      this.userService,
      this.selectPaymentMechanismService,
      this.analyticService,
      this.cartService,
    );

    this.uploadService = new UploadService(this.httpTransport);

    this.avatarController = new AvatarController(this.uploadService, this.userService);

    this.pairingService = new PairingService(
      this.cookieService,
      this.httpTransport,
      this.pageController,
      this.userService,
      this.subscriptionService,
      this.analyticService,
    );

    this.homePageController = new HomePageController(
      this.userService,
      this.subscriptionService,
      this.cartService,
      this.subscriptionManagementService,
      this.familyMembersService,
      this.pageService,
      this.downloadService,
    );

    this.loginController = new LoginController(
      this.httpTransport,
      this.authorizationStore,
      this.cookieService,
      this.userService,
      this.pageController,
      this.pairingService,
      this.loginService,
      this.preconditionService,
      this.subscriptionService,
      this.globalErrorsService,
      this.analyticService,
    );
    this.miscService = new MiscService(
      this.serviceSettingService,
      this.loginController,
      this.userService,
      this.localizationService,
    );

    this.manageSubscriptionsController = new ManageSubscriptionsController(
      this.boxOfficeService,
      this.userService,
      this.subscriptionManagementService,
      this.subscriptionService,
      this.preconditionService,
      this.analyticService,
    );

    this.checkoutController = new CheckoutController(
      this.userService,
      this.cartService,
      this.promoService,
      this.miscService,
      this.paymentService,
      this.boxOfficeService,
      this.cookieService,
      this.pageService,
      this.analyticService,
    );
    this.selectPlanController = new SelectPlanController(
      this.miscService,
      this.userService,
      this.pairingService,
      this.boxOfficeService,
      this.subscriptionService,
      this.cartService,
      this.pageService,
      this.analyticService,
    );
    this.promoPageController = new PromoPageController(
      this.promoService,
      this.subscriptionService,
      this.loginService,
      this.userService,
      this.cartService,
      this.pageService,
      this.cookieService,
      this.analyticService,
    );
    this.profileService = new ProfileService(this.userService, this.miscService);
    this.userController = new UserController(
      this.authorizationStore,
      this.localizationStore,
      this.profileService,
      this.userService,
      this.loginService,
      this.preconditionService,
      this.pageService,
    );

    this.utomikSupportedInCountryController = new UtomikSupportedInCountryController(
      this.cookieService,
      this.miscService,
      this.userService,
    );

    this.registerController = new RegisterController(
      this.loginController,
      this.httpTransport,
      this.pageController,
      this.userService,
      this.promoService,
      this.subscriptionService,
      this.campaignService,
      this.pairingService,
      this.miscService,
      this.familyMembersService,
      this.analyticService,
    );
    this.ssoService = new SsoService(
      this.pageController,
      this.userService,
      this.loginController,
      this.cookieService,
      this.pairingService,
      this.familyMembersService,
      this.analyticService,
    );
    this.pairingController = new PairingController(
      this.httpTransport,
      this.pairingService,
      this.loginController,
      this.pageController,
    );

    this.ssoController = new SsoController(
      this.httpTransport,
      this.pageController,
      this.cookieService,
      this.ssoService,
    );
    this.securityToken.setLoginController(this.loginController);
    this.securityToken.setAuthorizationStore(this.authorizationStore);
  }
}
