import { action, computed, makeObservable, observable } from 'mobx';

import { IAllCountries, ICountriesResponse } from 'types/localizations';

export default class LocalizationStore {
  public constructor() {
    makeObservable(this);
  }
  @observable
  private _allCountries: ICountriesResponse[] = [];

  @action
  public setAllCountries(allCountries: ICountriesResponse[]) {
    this._allCountries = allCountries;
  }

  @computed
  public get allCountries(): IAllCountries[] {
    return this._allCountries.map((country) => ({
      countryName: country.name,
      countryId: country.id,
      code: country.code,
    }));
  }

  @computed
  public get countriesNotSupported(): IAllCountries[] {
    const countryNotSupported = this._allCountries.filter((country) => !country.is_supported);
    return countryNotSupported.map((country) => ({
      countryName: country.name,
      countryId: country.id,
      code: country.code,
    }));
  }
}
