import withAuth from 'app/hoc/withAuth';
import DeleteAccount from 'app/widgets/deleteAccount/deleteAccount';
import clsx from 'clsx';
import React from 'react';

import styles from './deleteAccountPage.module.scss';

const DeleteAccountPage = () => {
  return (
    <div className={clsx('container', styles['delete-account-container'])}>
      <section className="page-section">
        <h1 className={styles['title']}>
          <span className="far fa-user-circle"></span> Delete account
        </h1>
        <DeleteAccount />
      </section>
    </div>
  );
};
export default withAuth(DeleteAccountPage);
