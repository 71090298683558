import moment from 'moment';
import AnalyticService from 'services/analyticsService/analyticsService';
import CookieService from 'services/cookieService/cookieService';

import { IPromoResponse } from 'types/promo';
import { SubscriptionPlan } from 'types/subscriptions';

import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import { removePlatformURLFromURL } from 'utils/utils';

import { PROMO_CODE_COOKIE, platformURL } from 'app/global/constants';

export default class PromoService {
  public constructor(
    private readonly _httpTransport: AxiosTransport,
    private readonly _cookieService: CookieService,
    private readonly _analyticService: AnalyticService,
  ) {}

  public async getPromo({
    promoCode,
    allowExpired = false,
  }: {
    promoCode: string;
    allowExpired?: boolean;
  }): Promise<IPromoResponse | void> {
    if (!promoCode) throw new Error('NO_CODE_SPECIFIED');
    try {
      const response = await this._httpTransport.get<IPromoResponse>('v2/promos/' + promoCode);
      if (!response?.data) return;

      if (!allowExpired && response.data.ends_at && moment(response.data.ends_at).isBefore()) {
        throw new Error('EXPIRED_PROMO_CODE');
      }
      return response.data;
    } catch (e: any) {
      let error = '';
      switch (e.statusCode) {
        case 404:
          error = 'INVALID_PROMO_CODE';
          break;
        case 409:
          error = 'PROMO-003';
          break;
        case 410:
          error = 'PROMO_CODE_USED';
          break;
        default:
          error = e;
          break;
      }
      throw new Error(error);
    }
  }

  public getPromoCodeFromCookie = (): string | null => {
    const storedPromo = this._cookieService.getCookie(PROMO_CODE_COOKIE);
    return storedPromo ? decodeURIComponent(atob(`${storedPromo}`)) : null;
  };

  public setCookiePromoCode = (promoCode: string) => {
    const encodedRefParam = btoa(encodeURIComponent(promoCode));
    this._cookieService.setCookie(PROMO_CODE_COOKIE, encodedRefParam, { path: '/' });
  };

  public validatePromoCode = async (promoCode: string) => {
    try {
      const promo = await this.getPromo({ promoCode: promoCode });
      if (!promo) return null;
      promo.code = promoCode;
      if (promo.subscriptionplan && promo.subscriptionplan.url) {
        const subscriptionUrl = promo.subscriptionplan.url;
        const normalizedSubscriptionUrl = removePlatformURLFromURL(subscriptionUrl, platformURL);
        const promoSubscription = await this._httpTransport.get<SubscriptionPlan>(normalizedSubscriptionUrl);
        this._analyticService.handleValidatePromo({ promo: promo, product: promoSubscription?.data });
      }

      return promo;
    } catch (e) {
      this._analyticService.handleValidatePromoError();
      throw e;
    }
  };
}
