import withAuth from 'app/hoc/withAuth';
import NewsLetterModal from 'app/modals/newsLetterModal/newsLetterModal';
import DownloadOptions from 'app/widgets/downloadOptions/downloadOptions';
import SubscriptionOverview from 'app/widgets/subscriptionOverview/subscriptionOverview';
import SuccessFunSection from 'app/widgets/successFunSection/successFunSection';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { Routes } from 'app/global/routes';

import { CheckoutSuccessControllerContext, NewsLetterControllerContext } from 'app/context/storesContext';

import Modal from 'app/components/modal/modal';

import styles from './checkoutSuccessOwner.module.scss';

const CheckoutSuccessOwner = observer(() => {
  const checkoutSuccessController = useContext(CheckoutSuccessControllerContext);
  const newsLetterController = useContext(NewsLetterControllerContext);

  const {
    isLoading,
    accountRole,
    subscription,
    isReactivating,
    isCloudUser,
    platformList,
    canUpgradeToCloud,
    handleDownloadUtomik,
    handleDownloadGooglePlay,
  } = checkoutSuccessController;
  const { showNewsLetter, checkIsShowNewsLetter, closeNewsLetterModal } = newsLetterController;
  const navigate = useNavigate();

  const goToFamilyManage = () => {
    navigate(Routes.Family);
  };

  useEffect(() => {
    checkIsShowNewsLetter();
  }, [checkoutSuccessController.user.newsletter_signup]);

  useEffect(() => {
    checkoutSuccessController.initPage();
  }, []);

  return (
    <>
      <section className={styles['checkout-success']}>
        <div className={clsx('container', styles['checkout-success-container'])}>
          <h1 className={styles['checkout-success-title']}>Welcome to Utomik!</h1>

          <div className={styles['checkout-success-content']}>
            <p>You just unlocked our full gaming library. Start playing right now!</p>
            <SubscriptionOverview
              accountRole={accountRole}
              subscription={subscription}
              isReactivating={isReactivating}
              handleReactivate={checkoutSuccessController.reactivateSubscription}
              isLoading={isLoading}
              goToFamilyManage={goToFamilyManage}
            />
            {!isLoading && (
              <DownloadOptions
                initPlatformList={checkoutSuccessController.initPlatformList}
                isCloudUser={isCloudUser}
                platformList={platformList}
                canUpgradeToCloud={canUpgradeToCloud}
                handleDownloadUtomik={handleDownloadUtomik}
                handleDownloadGooglePlay={handleDownloadGooglePlay}
              />
            )}
          </div>
        </div>
      </section>
      {/* <SuccessFunSection /> */}
      <Modal onHide={closeNewsLetterModal} show={showNewsLetter} title="Don't miss a thing!">
        <NewsLetterModal
          confirm={newsLetterController.confirmAndCallback}
          reject={newsLetterController.rejectAndCallback}
          isLoading={newsLetterController.isFetchingNewsLetter}
        />
      </Modal>
    </>
  );
});
export default withAuth(CheckoutSuccessOwner);
