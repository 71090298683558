import withAuth from 'app/hoc/withAuth';
import ChangePasswordModal from 'app/modals/changePasswordModal/changePasswordModal';
import NewsLetterModal from 'app/modals/newsLetterModal/newsLetterModal';
import GamesSection from 'app/widgets/gamesSection/gamesSection';
import Jumbotron from 'app/widgets/jumbotronSection/jumbotron';
import TileSection from 'app/widgets/tilesSection/tileSection';
import UnleashGamingStarts from 'app/widgets/unleashGamingStarts/unleashGamingStarts';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Routes } from 'app/global/routes';

import {
  HomePageControllerContext,
  NewsLetterControllerContext,
  PageControllerContext,
  PreconditionControllerContext,
  UserControllerContext,
} from 'app/context/storesContext';

import Modal from 'app/components/modal/modal';
import Spinner from 'app/components/spinner/spinner';

import './homePage.scss';

const HomePage: React.FC = observer(() => {
  const [isLoadingGames, setIsLoadingGames] = useState(true);
  const navigate = useNavigate();
  const userController = useContext(UserControllerContext);
  const newsLetterController = useContext(NewsLetterControllerContext);

  const preconditionController = useContext(PreconditionControllerContext);
  const homePageController = useContext(HomePageControllerContext);
  const pageController = useContext(PageControllerContext);
  const preconditions = preconditionController.getPreconditions();
  const { showNewsLetter, checkIsShowNewsLetter, closeNewsLetterModal } = newsLetterController;
  const [isLoading, setIsLoading] = useState(true);
  const {
    initPage,
    getUser,
    userHasDeletionDate,
    hasActiveSubscriptions,
    hasCanceledSubscriptions,
    accountSubscriptions,
    trialPeriod,
    isFetchingIsReactivate,
    hadPastSubscription,
    errorReactivate,
    reauthModalIsOpen,
    reauthModaContent,
    isOpenReactivateModal,
    reloadPage,
    toggleModalReauth,
    reactivateSubscription,
    leaveFamilyPlan,
    reactivateOldSubscriptions,
    handleDownloadUtomik,
    handleDownloadGooglePlay,
  } = homePageController;

  const user = getUser();
  const account = userController.getAccount();

  const fetchData = async () => {
    try {
      await initPage();
    } catch (e) {
      console.error(e, 'HOME PAGE INIT');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpenReactivateModal) {
      setTimeout(() => {
        reactivateOldSubscriptions();
      }, 50);
    }
  }, [isOpenReactivateModal]);

  useEffect(() => {
    checkIsShowNewsLetter();
  }, [userController.user?.newsletter_signup]);

  useEffect(() => {
    fetchData();
  }, [user.deletion_date]);

  const inVisibleTiles = preconditions
    ? Object.keys(preconditions).filter((key) => preconditions[key] === false)
    : ['logOut'];

  const getTopGames = async () => {
    setIsLoadingGames(true);
    try {
      await pageController.fetchGames();
    } catch (e) {
      console.error(e, 'HOME PAGE GET GAMES');
    } finally {
      setIsLoadingGames(false);
    }
  };

  useEffect(() => {
    getTopGames();
  }, []);

  const redirectToSelectPlan = () => {
    navigate(Routes.SelectPlan);
  };

  const homePageGames = pageController.games;
  const gamesPublishedLastMonth = pageController.gamesPublishedLastMonth;
  const accountMembers = `${(account?.cur_members || 0) + (account?.cur_invites || 0)}/${account?.max_members}`;

  if (isLoading) {
    return (
      <div style={{ height: '100vh', maxWidth: '100vw', background: '#121429', overflow: 'hidden' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="home-page container-main relative">
      <Jumbotron
        trialPeriodInfo={trialPeriod}
        redirectToSelectPlan={redirectToSelectPlan}
        hasActiveSubscriptions={hasActiveSubscriptions}
        hasCanceledSubscriptions={hasCanceledSubscriptions}
        userHasDeletionDate={userHasDeletionDate}
        user={user}
        accountSubscription={accountSubscriptions ? accountSubscriptions[0] : null}
        isFetchingReactivate={isFetchingIsReactivate}
        reactivateSubscription={reactivateSubscription}
        hadPastSubscription={hadPastSubscription}
        errorReactivate={errorReactivate}
        handleDownloadUtomik={handleDownloadUtomik}
        handleDownloadGooglePlay={handleDownloadGooglePlay}
      />
      {!preconditions && <Spinner />}
      {preconditions && (
        <TileSection
          inVisibleTiles={[...inVisibleTiles, 'logOut']}
          accountMembers={accountMembers || ''}
          leaveFamilyPlan={leaveFamilyPlan}
        />
      )}

      <GamesSection
        games={homePageGames}
        gamesPublishedLastMonth={gamesPublishedLastMonth}
        isLoading={isLoadingGames}
      />
      {/*<UnleashGamingStarts />*/}
      <Modal
        show={reauthModalIsOpen}
        onBackdropClick={isOpenReactivateModal ? reloadPage : undefined}
        onHide={isOpenReactivateModal ? reloadPage : toggleModalReauth}
        title={reauthModaContent?.title}
      >
        <ChangePasswordModal
          closeModal={isOpenReactivateModal ? reloadPage : toggleModalReauth}
          subtitle={reauthModaContent?.message}
          placeholder="Password"
          danger={reauthModaContent?.buttonIsDanger}
          textButtonNext={reauthModaContent?.buttonLabel}
          closeModalAfterConfirm={true}
          completeConfirm={reauthModaContent?.callback}
          cancelButtonLabel={reauthModaContent?.cancelButtonLabel}
        />
      </Modal>
      <Modal onHide={closeNewsLetterModal} show={showNewsLetter} title="Don't miss a thing!">
        <NewsLetterModal
          confirm={newsLetterController.confirmAndCallback}
          reject={newsLetterController.rejectAndCallback}
          isLoading={newsLetterController.isFetchingNewsLetter}
        />
      </Modal>
    </div>
  );
});

export default withAuth(HomePage);
