import React from 'react';

import { IAccountMineResponse } from 'types/account';
import { ICartDataCheckout } from 'types/cart';
import { SubscriptionsTrialPeriod } from 'types/subscriptions';

import { TERMS_OF_SERVICE_URL } from 'app/global/constants';

interface ICheckoutSmallPrint {
  product: ICartDataCheckout['product'];
  account: IAccountMineResponse;
}

const CheckoutSmallPrint: React.FC<ICheckoutSmallPrint> = ({ product, account }) => {
  const isHaveTrialPeriod = product.trial_period_length && account.can_start_trial;

  const renderContent = () => {
    if (!product.requires_payment_info) {
      return <span className="small">Once you click 'Confirm' your Utomik subscription will start.</span>;
    } else if (!product.period_length) {
      return (
        <span className="small">
          Once your billing information is verified, your Utomik subscription will start. You will not be charged.
        </span>
      );
    }
    if (product.recurring) {
      if (isHaveTrialPeriod) {
        return (
          <span className="small">
            Once your billing information is verified, your free {product.trial_period_length}-
            {SubscriptionsTrialPeriod[product.trial_period || 'DY']} trial period will start. You will not be charged
            during this period and you can cancel at any time. At the end of the free period, your Utomik subscription
            will start and the subscription fee will be automatically charged on a monthly basis to the payment method
            of your choice.
          </span>
        );
      } else {
        return (
          <span className="small">
            Once your billing information is verified, your Utomik subscription will start and the subscription fee will
            be automatically charged on a monthly basis to the payment method of your choice.
          </span>
        );
      }
    } else {
      if (isHaveTrialPeriod) {
        return (
          <span className="small">
            Once your billing information is verified, your free {product.trial_period_length}-
            {SubscriptionsTrialPeriod[product.trial_period || 'DY']} trial period will start. You will not be charged
            during this period and you can cancel at any time. At the end of the free period, your Utomik subscription
            will start and the subscription fee will be automatically charged to the payment method of your choice.
          </span>
        );
      } else {
        return (
          <span className="small">
            Once your billing information is verified, your Utomik subscription will start and the subscription fee will
            be automatically charged to the payment method of your choice.
          </span>
        );
      }
    }
  };
  return (
    <div>
      {renderContent()}{' '}
      <span className="small">
        The Utomik service and this website are guided by our{' '}
        <a href={TERMS_OF_SERVICE_URL} title="Read the terms of service" target="_blank" rel="noopener noreferrer">
          terms of service
        </a>
        .
      </span>
    </div>
  );
};
export default CheckoutSmallPrint;
