import { RouteObject } from 'react-router-dom';

import AppLayout from 'app/layout/app/app';
import EmptyLayout from 'app/layout/empty/empty';
import OnlyLogoLayout from 'app/layout/onlyLogo/onlyLogo';
import RegisterLayout from 'app/layout/register/register';

import NotFoundPage from 'app/pages/404/404';
import AutoLogoutPage from 'app/pages/autoLogoutPage/autoLogoutPage';
import Billing from 'app/pages/billing/billing';
import ChangePassword from 'app/pages/changePassword/changePassword';
import Checkout from 'app/pages/checkout/checkout';
import DeleteAccountPage from 'app/pages/deleteAccountPage/deleteAccountPage';
import Family from 'app/pages/family/family';
import HomePage from 'app/pages/homePage/homePage';
import ImportSession from 'app/pages/importSession/importSession';
import Invoices from 'app/pages/invoices/invoices';
import JoinFamilyPlan from 'app/pages/joinFamilyPlan/joinFamilyPlan';
import LogInAndRedeem from 'app/pages/logInAndRedeem/logInAndRedeem';
import LogInPage from 'app/pages/login/loginPage';
import AccountNewsLetter from 'app/pages/newsletter/accountNewsLetter';
import PairingPage from 'app/pages/pairing/pairingPage/pairingPage';
import Profile from 'app/pages/profile/profile';
import RedeemPage from 'app/pages/redeem/redeem';
import RegisterPage from 'app/pages/register/registerPage';
import ResetPassword from 'app/pages/resetPassword/resetPassword';
import SelectPlanPage from 'app/pages/selectPlan/selectPlan';
import Sso from 'app/pages/sso/sso';
import SubscriptionPage from 'app/pages/subscription/SubscriptionPage';
import CheckoutSuccessOwner from 'app/pages/success/checkoutSuccessOwner';
import CheckoutSuccessMember from 'app/pages/successMember/checkoutSuccessMember';
import CheckoutSuccessTv from 'app/pages/successTv/checkoutSuccessTv';
import UnderAge from 'app/pages/underAge/underAge';
import VerifyPage from 'app/pages/verify/verifyPage';
import VerifyEmailPage from 'app/pages/verifyEmail/verifyEmailPage';

export enum Routes {
  Home = '/',
  LogIn = '/login',
  ResetPassword = '/reset-password',
  Redeem = '/redeem',
  Verify = '/verify/',
  VerifyEmail = '/verify-email',
  Family = '/family',
  Subscription = '/subscription',
  Billing = '/billing',
  Invoices = '/invoices',
  Profile = '/profile',
  Newsletter = '/newsletter',
  Register = '/register',
  RegisterSelectPlan = '/register/selectplan',
  SelectPlan = '/selectplan',
  ImportSession = '/import-session',
  Sso = '/sso',
  RegisterTv = '/register/tv',
  LoginTv = '/login/tv',
  SuccessTv = '/success/tv/',
  Success = '/success/',
  SuccessMember = '/successfully-joined/',
  AutoLogoutScreen = '/you-have-been-logged-out',
  ForgotPassword = '/reset-password/',
  ChangePassword = '/change-password/',
  Checkout = '/checkout',
  RegisterCheckout = '/register/checkout',
  DeleteAccount = '/delete-account',
  LoginAndRedeem = '/log-in-and-redeem',
  JoinFamilyPlan = '/join-family-plan',
  UnderAge = '/underage',
}

const routes: RouteObject[] = [
  {
    element: <AppLayout />,
    children: [
      {
        path: Routes.Home,
        element: <HomePage />,
      },
      {
        path: Routes.LogIn,
        element: <LogInPage />,
      },
      {
        path: Routes.ResetPassword,
        element: <ResetPassword />,
      },
      {
        path: Routes.ChangePassword,
        element: <ChangePassword />,
      },
      {
        path: Routes.Family,
        element: <Family />,
      },
      {
        path: Routes.Subscription,
        element: <SubscriptionPage />,
      },
      {
        path: Routes.SelectPlan,
        element: <SelectPlanPage />,
      },
      {
        path: Routes.Billing,
        element: <Billing />,
      },
      {
        path: Routes.Checkout,
        element: <Checkout />,
      },
      {
        path: Routes.Invoices,
        element: <Invoices />,
      },
      {
        path: Routes.Profile,
        element: <Profile />,
      },
      {
        path: Routes.Newsletter,
        element: <AccountNewsLetter />,
      },
      {
        path: Routes.SuccessTv,
        element: <CheckoutSuccessTv />,
      },
      {
        path: Routes.Success,
        element: <CheckoutSuccessOwner />,
      },
      {
        path: Routes.SuccessMember,
        element: <CheckoutSuccessMember />,
      },
      {
        path: Routes.DeleteAccount,
        element: <DeleteAccountPage />,
      },
      {
        path: Routes.AutoLogoutScreen,
        element: <AutoLogoutPage />,
      },
      {
        path: Routes.Redeem,
        element: <RedeemPage />,
      },
      {
        path: Routes.LoginAndRedeem,
        element: <LogInAndRedeem />,
      },
      {
        path: Routes.JoinFamilyPlan,
        element: <JoinFamilyPlan />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
  {
    element: <RegisterLayout />,
    children: [
      {
        path: Routes.Register,
        element: <RegisterPage />,
      },
      {
        path: Routes.Verify,
        element: <VerifyPage />,
      },

      {
        path: Routes.VerifyEmail,
        element: <VerifyEmailPage />,
      },
      {
        path: Routes.RegisterSelectPlan,
        element: <SelectPlanPage />,
      },
      {
        path: Routes.RegisterCheckout,
        element: <Checkout />,
      },
    ],
  },
  {
    element: <OnlyLogoLayout />,
    children: [
      {
        path: Routes.RegisterTv,
        element: <PairingPage pairingCase="SIGN_UP" />,
      },
      {
        path: Routes.LoginTv,
        element: <PairingPage pairingCase="LOG_IN" />,
      },
      {
        path: Routes.UnderAge,
        element: <UnderAge isShowLayout={false} />,
      },
    ],
  },
  {
    element: <EmptyLayout />,
    children: [
      {
        path: Routes.ImportSession,
        element: <ImportSession />,
      },

      {
        path: Routes.Sso,
        element: <Sso />,
      },
    ],
  },
];

export default routes;
