import React from 'react';

import { NavigationLink } from 'types/global';

import MainNavigationSection from '../mainNavigationSection/mainNavigationSection';
import UserMenu from '../userMenu/userMenu';

import './styles.scss';

interface HeaderMenu {
  navigationLinks: NavigationLink[];
  isShowDropdown: boolean;
  email: string | null;
}

const LoggedInUserHeader: React.FC<HeaderMenu> = ({ navigationLinks, isShowDropdown, email = '' }) => {
  return (
    <div className={'main-navigate'}>
      <MainNavigationSection navigateItems={navigationLinks}>
        <UserMenu userEmail={email} isDropdownMenu={isShowDropdown} />
      </MainNavigationSection>
    </div>
  );
};
export default LoggedInUserHeader;
