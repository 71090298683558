import PromoCodeForm from 'app/widgets/setPromoCodeModal/promocodeForm/promoCodeForm';
import clsx from 'clsx';
import { redeemBreadcrumbs } from 'lists/redeemBreadcrumbs';
import React, { useEffect } from 'react';

import Breadcrumbs from '../register/breadcrumbs';

import styles from './redeem.module.scss';

const RedeemPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles['promo-page-wrapper']}>
      <div className="container">
        <div className={styles['breadcrumb-container']}>
          <Breadcrumbs breadcrumbs={redeemBreadcrumbs} />
        </div>

        <div className={styles['promo-page-content']}>
          <div className={clsx(styles['promo-form'], 'page-subsection')}>
            <h1 className={styles['promo-form-title']}>Redeem your code</h1>
            <PromoCodeForm placeholder="Fill in your code here" label="Your code" buttonBlock={false} buttonSize="lg" />
          </div>
          <div className={styles['redeem-list']}>
            <h2>Claim your code now</h2>
            <ul className="list-checked">
              <li>
                <strong>Plug & Play</strong>
                <span>Quick access to your Utomik Game Platform in 3 easy steps.</span>
              </li>
              <li>
                <strong>1400+ games in one subscription</strong>
                <span>Access to the full library, with new games added monthly.</span>
              </li>
              <li>
                <strong>Play on PC, TV, and mobile devices</strong>
                <p>
                  Experience your favorite games anywhere, anytime <br />
                  <small>
                    Available devices depend on your code and region.{' '}
                    <a
                      href="https://www.utomik.com/cloud"
                      title="Everything you want to know about playing Utomik's games in the cloud"
                    >
                      Read more about Utomik Cloud availability.
                    </a>
                  </small>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RedeemPage;
