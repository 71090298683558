import withAuth from 'app/hoc/withAuth';
import { observer } from 'mobx-react';

import LogInForm from './loginForm';

import './loginPage.scss';

const LogInPage = observer(() => (
  <div className="login-page container">
    <h1 className="login-page__title">Log in</h1>

    <LogInForm />
  </div>
));

export default withAuth(LogInPage);
