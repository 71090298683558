import CookieService from 'services/cookieService/cookieService';
import LoginService from 'services/loginService/loginServise';

import { IPaymentType } from 'types/billing';
import { IPromoResponse } from 'types/promo';
import {
  ISubscriptionByUsableSubscription,
  ISubscriptionsResponseWithOrders,
  SubscriptionPlan,
} from 'types/subscriptions';
import { ISsoProviders, User } from 'types/users';

import {
  analyticsDisplayCurrency,
  analyticsDisplayPrice,
  analyticsItemDescription,
  analyticsListOfItems,
  hubSpotContentIdsObject,
} from 'utils/analyticFilters';

import HttpTransport from '../../dataStore/transports/httpTransport/httpTransport';

interface CustomWindow extends Window {
  dataLayer: any[]; // Defining the dataLayer property
  _hsq: any[];
}
declare const window: CustomWindow;

interface HubspotEvent {
  [key: string]: string | number | boolean | undefined;
}

export default class AnalyticService {
  public constructor(
    private readonly _loginService: LoginService,
    private readonly _cookieService: CookieService,
    private readonly _httpTransport: HttpTransport,
  ) {}

  public sendGoogleAnalytics4Event({ eventName, eventParams }: { eventName: string; eventParams: any }) {
    eventParams.event = 'UtomikGA4Event';
    eventParams.true_event_name = eventName;

    if (eventParams && eventParams.user_id) {
      eventParams.user_id = eventParams.user_id.toString();
    }
    this.tryPushToDataLayer(eventParams);
  }

  public sendHubSpot = async (url: string, event: HubspotEvent) => {
    try {
      const hutk = this._cookieService.getCookie('hubspotutk') as string;
      await this._httpTransport.post<string, { utk: string }>(url, { ...event, utk: hutk });
    } catch (e) {
      console.log(e, 'Unable to send hubspot event');
    }
  };

  public tryPushToDataLayer(eventData: any) {
    window.dataLayer = window.dataLayer || [];
    if (eventData && eventData.event) {
      window.dataLayer.push(eventData);
    } else {
      console.info('Could not send event to Google Tag Manager data layer', eventData);
    }
  }

  /*Send the right analytics when user has successfully logged in. */
  public handleLogin({ ssoProviders = [], isInTVFlow }: { ssoProviders: ISsoProviders[]; isInTVFlow: boolean }) {
    const user = this._loginService.getTokenData();
    const ssoTypes = ssoProviders
      .map((provider) => provider.provider)
      .filter((value, index, self) => self.indexOf(value) === index);
    if (isInTVFlow) {
      ssoTypes.push('tv');
    }
    const eventData = {
      method: ssoTypes.length ? ssoTypes.join(', ') : undefined,
      user_id: user?.user_id,
    };

    this.sendGoogleAnalytics4Event({ eventName: 'login', eventParams: eventData });
  }

  public async handleLinkHubspotUser() {
    await this.sendHubSpot('/v2/hubspot/user-link', { consent: true });
  }

  public async handleAddToCartHubspot({
    product,
    canStartTrial,
    promo,
  }: {
    product: ISubscriptionByUsableSubscription;
    canStartTrial: boolean;
    promo?: IPromoResponse;
  }) {
    const productInfo = hubSpotContentIdsObject({ product, canStartTrial, promo });

    await this.sendHubSpot('/v2/hubspot/add-to-cart', {
      subscription_type: productInfo.subscriptionOrTrial,
      plan_type: productInfo.subscriptionType,
      ...(productInfo.promoCode && { promo_code: productInfo.promoCode }),
      ...(productInfo.promoName && { promo_name: productInfo.promoName }),
      subscriptionplan_name: product.name,
      subscriptionplan_id: product.id,
    });
  }

  public async handlePurchaseErrorHubspot(error: any) {
    await this.sendHubSpot('/v2/hubspot/purchase-error', {
      error_type: error?.statusCode && error.statusCode === 504 ? 'UserTimeOut' : 'PaymentFailed',
    });
  }

  /**Send the right analytics when user has successfully created an account. */
  public handleRegistration({ user, ssoTypes, isInTVFlow }: { user?: User; ssoTypes: string[]; isInTVFlow: boolean }) {
    const ssoTypesGA4 = ssoTypes?.length ? ssoTypes : [];
    const userToken = this._loginService.getTokenData();
    if (isInTVFlow) {
      ssoTypesGA4.push('tv');
    }
    const eventData = {
      method: ssoTypesGA4.length ? ssoTypesGA4.join(', ') : undefined,
      user_id: user ? user.id : userToken && userToken?.user_id,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'sign_up', eventParams: eventData });
    this.handleLinkHubspotUser().catch(console.log);
  }
  /**Send the right analytics when user has got some problems while creating an account. Possible reasons - email is already used. invalid password etc. */
  public handleRegistrationError({ ssoTypes }: { ssoTypes: string[] }) {
    const ssoTypeList = ssoTypes.length ? ssoTypes.join(', ') : undefined;
    const ssoSuffix = ssoTypeList?.length ? ' with method ' + ssoTypeList : '';
    const eventData = {
      description: 'Failed to sign_up' + ssoSuffix,
      fatal: true,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'exception', eventParams: eventData });
  }

  /**Send the right analytics when user payment info was successfully verified for a family plan, trial subscription or subscription.
    Note: this event is also called in the API. When significant changes are made, check the API implementation too! */
  public handlePurchase({
    product,
    order,
    promo,
  }: {
    product: ISubscriptionByUsableSubscription;
    order?: ISubscriptionsResponseWithOrders['order'] | null;
    promo: IPromoResponse | null;
  }) {
    const user = this._loginService.getTokenData();

    const eventData = {
      currency: analyticsDisplayCurrency(product),
      transaction_id: order?.id,
      value: analyticsDisplayPrice(product),
      affiliation: promo && (promo as IPromoResponse)?.promoter_name,
      coupon: promo?.code,
      items: analyticsListOfItems(product),
      user_id: user?.user_id,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'purchase', eventParams: eventData });
  }

  /**Send the right analytics when user payment info was NOT successfully verified for a family plan, trial subscription or subscription. */

  public handlePurchaseError(error: any) {
    const user = this._loginService.getTokenData();
    const eventData = {
      description: 'Failed to purchase',
      fatal: true,
      user_id: user?.user_id,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'exception', eventParams: eventData });

    this.handlePurchaseErrorHubspot(error).catch(console.log);
  }

  /**Send the right analytics when someone was challenged to verify their credit card via the 3D-Secure process. */

  public handleThreeDSChallenge({
    paymentType,
    product,
    promo,
  }: {
    paymentType?: IPaymentType | null;
    product: ISubscriptionByUsableSubscription;
    promo?: IPromoResponse | null;
  }) {
    const user = this._loginService.getTokenData();
    const eventData = {
      currency: analyticsDisplayCurrency(product),
      value: analyticsDisplayPrice(product),
      coupon: promo?.code,
      items: analyticsListOfItems(product),
      payment_type: paymentType,
      user_id: user?.user_id,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'challenge_psd2_billing_verification', eventParams: eventData });
  }

  /**Send the right analytics when someone completed verification of their credit card via the 3D-Secure process. */
  public handleThreeDSCompletion({
    paymentType,
    product,
    promo,
  }: {
    paymentType?: IPaymentType | null;
    product: ISubscriptionByUsableSubscription;
    promo?: IPromoResponse | null;
  }) {
    const user = this._loginService.getTokenData();
    const eventData = {
      currency: analyticsDisplayCurrency(product),
      value: analyticsDisplayPrice(product),
      coupon: promo && promo?.code,
      items: analyticsListOfItems(product),
      payment_type: paymentType,
      user_id: user?.user_id,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'complete_psd2_billing_verification', eventParams: eventData });
  }

  /**Send the right analytics when someone failed to complete the challenge to verify their credit card via
		the 3D-Secure process. */
  public handleThreeDSError() {
    const user = this._loginService.getTokenData();
    const eventData = {
      description: 'Failed to complete_psd2_billing_verification',
      fatal: false,
      user_id: user?.user_id,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'exception', eventParams: eventData });
  }

  /**Send the right analytics when user has canceled a subscription plan. */
  public handleCancelSubscription(subscription: ISubscriptionsResponseWithOrders | null) {
    const user = this._loginService.getTokenData();
    if (subscription) {
      const eventData = {
        description: analyticsItemDescription(subscription),
        user_id: user?.user_id,
      };
      this.sendGoogleAnalytics4Event({ eventName: 'cancel_product', eventParams: eventData });
    }
  }

  /**Send the right analytics when user tried but failed to cancel a subscription plan. */
  public handleCancelSubscriptionError() {
    const user = this._loginService.getTokenData();
    const eventData = {
      description: 'Failed to cancel_product',
      fatal: false,
      user_id: user?.user_id,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'exception', eventParams: eventData });
  }

  /**Send the right analytics when user has reactivated his family plan, trial subscription or subscription. */
  public handleReactivateSubscription = (subscription: ISubscriptionsResponseWithOrders | null) => {
    const user = this._loginService.getTokenData();

    if (subscription) {
      const eventData = {
        description: analyticsItemDescription(subscription),
        user_id: user?.user_id,
      };
      this.sendGoogleAnalytics4Event({ eventName: 'undo_product_cancellation', eventParams: eventData });
    }
  };

  /**Send the right analytics when user tried but failed to reactivate a subscription plan. */
  public handleReactivateSubscriptionError() {
    const user = this._loginService.getTokenData();
    const eventData = {
      description: 'Failed to undo_product_cancellation',
      fatal: false,
      user_id: user?.user_id,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'exception', eventParams: eventData });
  }

  /**Send the right analytics when promotion code was successfully verified. */
  public handleValidatePromo({ promo, product }: { promo: IPromoResponse; product?: SubscriptionPlan }) {
    const eventData = {
      promotion_id: promo.id,
      promotion_name: promo.name,
      items: product ? analyticsListOfItems(product) : undefined,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'view_promotion', eventParams: eventData });
  }

  /**Send the right analytics when promotion code was NOT successfully verified. */
  public handleValidatePromoError() {
    const eventData = {
      description: 'Failed to view_promotion',
      fatal: false,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'exception', eventParams: eventData });
  }

  /**Send the right analytics when user has successfully added a familyplan, trial subscription or subscription to cart. */
  public handleAddToCart({
    product,
    canStartTrial,
    promo,
  }: {
    product: ISubscriptionByUsableSubscription | null;
    canStartTrial: boolean;
    promo?: IPromoResponse;
  }) {
    const user = this._loginService.getTokenData();
    if (product) {
      const eventData = {
        currency: analyticsDisplayCurrency(product),
        value: analyticsDisplayPrice(product),
        items: analyticsListOfItems(product),
        user_id: user?.user_id,
      };

      this.sendGoogleAnalytics4Event({ eventName: 'add_to_cart', eventParams: eventData });

      this.handleAddToCartHubspot({ product, canStartTrial, promo }).catch(console.log);
    }
  }

  /**Send the right analytics when user has failed to add a familyplan, trial subscription or subscription to cart. */
  public handleAddToCartError() {
    const user = this._loginService.getTokenData();
    const eventData = {
      description: 'Failed to add_to_cart',
      fatal: true,
      user_id: user?.user_id,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'exception', eventParams: eventData });
  }

  /**Send the right analytics when user has successfully updated their payment info. */
  public handleAddPaymentInfo({
    paymentType,
    product,
    promo,
  }: {
    paymentType?: IPaymentType | null;
    product: ISubscriptionByUsableSubscription;
    promo?: IPromoResponse | null;
  }) {
    const user = this._loginService.getTokenData();
    const eventData = {
      currency: analyticsDisplayCurrency(product),
      value: analyticsDisplayPrice(product),
      coupon: promo?.code,
      items: analyticsListOfItems(product),
      payment_type: paymentType,
      user_id: user?.user_id,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'add_payment_info', eventParams: eventData });
  }

  /**Send the right analytics when user has chosen to switch between plans. */
  public handleSwitchPlan({
    promo,
    order,
    newProduct,
  }: {
    newProduct: ISubscriptionByUsableSubscription;
    promo?: IPromoResponse | null;
    order?: ISubscriptionsResponseWithOrders['order'] | null;
  }) {
    const user = this._loginService.getTokenData();
    const eventData = {
      currency: analyticsDisplayCurrency(newProduct),
      transaction_id: order?.id,
      value: analyticsDisplayPrice(newProduct),
      affiliation: promo?.promoter_name,
      coupon: promo?.code,
      items: analyticsListOfItems(newProduct),
      user_id: user?.user_id,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'purchase', eventParams: eventData });
  }

  /**Send the right analytics when user has tried to switch between plans without cancelling reseller subscription first. */
  public handleSwitchPlanError(error: any) {
    const user = this._loginService.getTokenData();
    const eventData = {
      description: 'Failed to purchase (switch)',
      fatal: true,
      user_id: user?.user_id,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'exception', eventParams: eventData });
    this.handlePurchaseErrorHubspot(error).catch(console.log);
  }

  /**Send the right analytics when user has successfully used a pairing code to log in on the paired device.
		For now, we support only one method: 'tv'. */
  public handleRemoteLogin() {
    const user = this._loginService.getTokenData();
    const eventData = {
      method: 'tv',
      user_id: user?.user_id,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'login_on_remote_device', eventParams: eventData });
  }

  /**Send the right analytics when user has successfully used a pairing code to log in on the paired device.
		For now, we support only one method: 'tv'. */
  public handleRemoteLoginError() {
    const user = this._loginService.getTokenData();
    const eventData = {
      description: 'Failed to handle login_on_remote_device',
      fatal: true,
      user_id: user?.user_id,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'exception', eventParams: eventData });
  }

  /**Send the right analytics when the user downloads utomik */
  public handleDownloadInstaller(linkUrl: string) {
    const eventData = {
      link_url: linkUrl,
    };
    this.sendGoogleAnalytics4Event({ eventName: 'download_installer', eventParams: eventData });
  }
}
