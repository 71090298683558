import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';

import { countriesRange } from 'utils/transformDataToRange';
import { translateErrors } from 'utils/translateErrors';

import { UserControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Select from 'app/components/select/select';

import styles from './selectCountryForm.module.scss';

type ProfileFormValues = {
  country: string;
};

interface ISelectCountryForm {
  subtitle?: string;
  isModal?: boolean;
  textButtonConfirm?: string;
  textButtonCancel?: string;
  handleCancel?: () => void;
  callbackAfterUpdate?: () => void;
}
const SelectCountryForm: React.FC<ISelectCountryForm> = observer(
  ({ subtitle, isModal, textButtonConfirm = 'Confirm', textButtonCancel, handleCancel, callbackAfterUpdate }) => {
    const [countryNotSupportedError, setCountryNotSupportedError] = useState('');
    const [isLoadingPatchData, setIsLoadingPatchData] = useState(false);
    const [fetchError, setFetchError] = useState('');

    const userController = useContext(UserControllerContext);
    const initialValues: ProfileFormValues = {
      country: `${userController.userCountry.id}` || '',
    };

    const getIsInSupportedCountry = async () => {
      await userController.setUserIsInSupportedCloudCountry();
    };

    useEffect(() => {
      getIsInSupportedCountry();
    }, []);

    useEffect(() => {
      handleValidateErrors({ country: `${userController.userCountry.id}` });
    }, [userController.userCountry.id]);

    const onSubmitForm = async (values: ProfileFormValues) => {
      setIsLoadingPatchData(true);
      try {
        await userController.patchUserCountry({ country: +values.country });
        callbackAfterUpdate && (await callbackAfterUpdate());
        handleCancel && handleCancel();
      } catch (e: any) {
        setFetchError(translateErrors(e));
      } finally {
        setIsLoadingPatchData(false);
      }
    };

    const handleValidateErrors = (values: ProfileFormValues) => {
      const errors = {} as ProfileFormValues;
      const notSupportedCountry = userController.countryNotSupported;
      const { country } = values;

      if (country) {
        const isCountryNotSupported = notSupportedCountry.some(
          (countryNotSupported) => countryNotSupported.countryId === +country,
        );
        setCountryNotSupportedError(isCountryNotSupported ? 'USER_COUNTRY_NOT_SUPPORTED' : '');
      }
      return errors;
    };

    const formik = useFormik({
      initialValues: initialValues,
      onSubmit: onSubmitForm,
      enableReinitialize: true,
      validateOnBlur: false,
      validate: handleValidateErrors,
    });
    return (
      <div className={clsx(styles['select-country-form'], isModal && styles['select-country-form-modal'])}>
        <FormikProvider value={formik}>
          <p className={styles['select-country-form--subtitle']}>
            {subtitle || 'Please confirm or update your location before proceeding.'}
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <Select
                name="country"
                isValidateErrors={false}
                value={formik.values.country || userController.userCountry.id}
                options={countriesRange(userController.allCountries)}
                disabled={isLoadingPatchData}
              />
              {countryNotSupportedError && <Alert danger>{translateErrors(countryNotSupportedError)}</Alert>}
              {fetchError && <Alert danger>{translateErrors(fetchError)}</Alert>}
            </div>
            <div
              className={clsx(
                styles['select-country-form--button'],
                textButtonCancel && styles['select-country-form--button-group'],
              )}
            >
              <Button
                type="submit"
                disabled={!!countryNotSupportedError || isLoadingPatchData}
                primary={isModal}
                block
                xl
                loading={isLoadingPatchData}
              >
                {textButtonConfirm}
              </Button>
              {textButtonCancel && handleCancel && (
                <Button
                  disabled={isLoadingPatchData}
                  block
                  xl
                  onClick={(e) => {
                    e.preventDefault();
                    handleCancel();
                  }}
                >
                  {textButtonCancel}
                </Button>
              )}
            </div>
          </form>
        </FormikProvider>
      </div>
    );
  },
);
export default SelectCountryForm;
