import withAuth from 'app/hoc/withAuth';
import DeleteAccount from 'app/widgets/deleteAccount/deleteAccount';
import ProfileForm from 'app/widgets/profileForm/profileForm';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';

import { UserControllerContext } from 'app/context/storesContext';

import Spinner from 'app/components/spinner/spinner';

import styles from './profile.module.scss';

const Profile = observer(() => {
  const userController = useContext(UserControllerContext);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = userController;
  const initialPage = async () => {
    try {
      await userController.profileInitPage();
    } catch (e) {
      console.error(e, 'Profile page');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    initialPage();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="container">
      <section className="page-section">
        <h1 className={styles['profile-title']}>
          <span className="far fa-user-circle"></span> Edit profile
        </h1>
        {user.deletion_date && !isLoading && (
          <div className={styles['profile-deleted-account']}>
            <DeleteAccount />
          </div>
        )}

        <ProfileForm />
      </section>
    </div>
  );
});
export default withAuth(Profile);
