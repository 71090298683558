import * as yup from 'yup';

import { passwordRegEx } from 'utils/regex';

export const changePasswordModalSchema = yup.object().shape({
  password: yup
    .string()
    .required('')
    .matches(
      passwordRegEx,
      'Passwords must contain at least 8 characters, have at least 1 letter (uppercase or lowercase a-z) and at least one number or special character',
    ),
  confirmPassword: yup
    .string()
    .required('Provided passwords do not match.')
    .test('password-match', 'Provided passwords do not match.', function (value) {
      const password = this.parent.password;

      if (!password) {
        return false;
      }
      return value === password;
    }),
});
