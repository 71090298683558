import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import { dateOfBirthD, dateOfBirthM, emptyOptions, memberEditYearRange, yearRange } from 'lists/selectOptions';
import React, { useMemo, useState } from 'react';

import { FamilyMembersCombined, FamilyMembersSettings, IAccountSettingRequest } from 'types/familyMembers';
import { IDate } from 'types/users';

import { translateErrors } from 'utils/translateErrors';

import Button from 'app/components/button/button';
import Checkbox from 'app/components/checkbox/checkbox';
import Select from 'app/components/select/select';

import styles from './familyMemberWellAccountSettings.module.scss';

interface IFamilyMemberWellAccountSettings {
  isOfAge: (birthDate: string) => boolean;
  doCloseAccountSettings: (memberId: string | number) => void;
  isValidDate: (date: IDate) => boolean;
  doSaveAccountSettings: (data: IAccountSettingRequest) => Promise<void>;
  member: FamilyMembersCombined;
}

const FamilyMemberWellAccountSettings: React.FC<IFamilyMemberWellAccountSettings> = ({
  isOfAge,
  doCloseAccountSettings,
  isValidDate,
  doSaveAccountSettings,
  member,
}) => {
  const [isBirthDateChecked, setIsBirthDateChecked] = useState(true);
  const [isEqualValue, setIsEqualValue] = useState(true);
  const [isSavingAccountSettings, setIsSavingAccountSettings] = useState(false);
  const [errorSavingAccountSettings, setErrorSavingAccountSettings] = useState('');

  const memberBirthDate = member.birthdate && member.birthdate.split('-');
  const isCurrentYearContain = memberBirthDate && memberEditYearRange.some((item) => item.value === memberBirthDate[0]);
  const initialValues: FamilyMembersSettings = {
    suspended: member.suspended || false,
    dateOfBirthM: memberBirthDate ? memberBirthDate[1] : '',
    dateOfBirthD: memberBirthDate ? memberBirthDate[2] : '',
    dateOfBirthY: isCurrentYearContain ? memberBirthDate[0] : '',
  };
  const isInvite = member && member.sent_to;

  const onSubmit = async (values: FamilyMembersSettings) => {
    setIsSavingAccountSettings(true);
    try {
      const { dateOfBirthD, dateOfBirthM, dateOfBirthY } = values;
      const data = {
        id: member.id,
        suspend: values.suspended,
        ...(!isInvite && isCanChangeBirthdate() && { birthdate: `${dateOfBirthY}-${dateOfBirthM}-${dateOfBirthD}` }),
      };
      await doSaveAccountSettings(data);
    } catch (e) {
      setErrorSavingAccountSettings(translateErrors(e));
    } finally {
      setIsSavingAccountSettings(false);
    }
  };
  const validateBirthDate = (values: FamilyMembersSettings) => {
    const errors = {} as FamilyMembersSettings;

    const { dateOfBirthD, dateOfBirthM, dateOfBirthY } = values;
    if (isCanChangeBirthdate() === false) {
      return errors;
    }
    if (dateOfBirthD && dateOfBirthM && dateOfBirthY) {
      const newBirthDate = {
        year: dateOfBirthY,
        month: dateOfBirthM,
        day: dateOfBirthD,
      };
      const isValidDateBirthDate = isValidDate(newBirthDate);
      if (!isValidDateBirthDate) {
        errors.dateOfBirthY = 'This is not a valid date, please check the date you filled in.';
      }
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validate: validateBirthDate,
  });
  const isCanChangeBirthdate = () => {
    return !!member.birthdate && !isOfAge(member.birthdate);
  };
  const isDisabledSave = useMemo(() => {
    if (isBirthDateChecked && memberBirthDate && !isInvite && isCanChangeBirthdate()) {
      setIsEqualValue(
        formik.values.dateOfBirthD === memberBirthDate[2] &&
          formik.values.dateOfBirthY === memberBirthDate[0] &&
          formik.values.dateOfBirthM === memberBirthDate[1] &&
          formik.values.suspended === member.suspended,
      );
    } else if (isBirthDateChecked) {
      setIsEqualValue(formik.values.suspended === member.suspended);
    }

    if (!isEqualValue) {
      setIsBirthDateChecked(false);
    }
    return !isInvite && isCanChangeBirthdate()
      ? !!isInvite || !!formik.errors.dateOfBirthY || isEqualValue || !formik.values.dateOfBirthY
      : isEqualValue;
  }, [isInvite, formik.errors.dateOfBirthY, formik.values, isBirthDateChecked, isEqualValue]);
  return (
    <div className={styles['user-card']}>
      <div className={styles['user-card-content']}>
        <FormikProvider value={formik}>
          <form className={styles['invite-member-settings-form']} onSubmit={formik.handleSubmit}>
            <h2>Account settings</h2>
            <div className={styles['invite-member-settings-form-item']}>
              <div className={styles['form-group-checkbox']}>
                <div className={styles['form-group-checkbox-label']}>Suspended</div>
                <Checkbox name="suspended" checked={formik.values.suspended || false} label="" />
              </div>
            </div>
            {!isInvite && isCanChangeBirthdate() && (
              <div className={clsx(styles['invite-member-settings-form-select'])}>
                <div className={clsx(styles['invite-member-settings-form-select-label'])}>Date of birth</div>
                <div
                  className={clsx(
                    styles['invite-member-settings-form-select-item'],
                    (formik.errors.dateOfBirthM || formik.errors.dateOfBirthY) &&
                      styles['invite-member-settings-form-select-item-error'],
                  )}
                >
                  <Select
                    name="dateOfBirthM"
                    isValidateErrors={false}
                    options={formik.values.dateOfBirthM ? dateOfBirthM : [emptyOptions, ...dateOfBirthM]}
                  />
                  <Select
                    name="dateOfBirthD"
                    isValidateErrors={false}
                    options={formik.values.dateOfBirthD ? dateOfBirthD() : [emptyOptions, ...dateOfBirthD()]}
                  />
                  <Select
                    name="dateOfBirthY"
                    isValidateErrors={false}
                    options={formik.values.dateOfBirthY ? memberEditYearRange : [emptyOptions, ...memberEditYearRange]}
                  />
                </div>
              </div>
            )}

            <div className={styles['invite-member-settings-form-buttons']}>
              <Button
                type="button"
                onClick={() => doCloseAccountSettings(member.id)}
                disabled={isSavingAccountSettings}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="button-save-invite-settings"
                primary
                disabled={isDisabledSave}
                loading={isSavingAccountSettings}
              >
                Save
              </Button>
            </div>
          </form>
        </FormikProvider>
      </div>
    </div>
  );
};
export default FamilyMemberWellAccountSettings;
