import React from 'react';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';

import styles from './newsLetter.module.scss';

interface INewsLetter {
  patchUserError: string;
  newsletterSignUp: boolean;
  isFetchingNewsLetter: boolean;
  toggleNewsletter: () => void;
}
export const NewsLetter: React.FC<INewsLetter> = ({
  patchUserError,
  newsletterSignUp,
  isFetchingNewsLetter,
  toggleNewsletter,
}) => {
  return (
    <div className={styles['newsLetter-body']}>
      <p className={styles['newsLetter-body-text']}>
        You are currently <strong>{newsletterSignUp ? '' : 'not '}subscribed</strong> to the Utomik newsletter.
      </p>
      <p className={styles['newsLetter-body-text']}>
        The Utomik newsletter informs you weekly about the new games on the platform. With it, you'll never miss a beat!
      </p>
      {patchUserError && <Alert danger>{patchUserError}</Alert>}
      {!patchUserError && (
        <Button primary loading={isFetchingNewsLetter} disabled={isFetchingNewsLetter} onClick={toggleNewsletter}>
          {newsletterSignUp ? 'Unsubscribe' : 'Yes, I want to receive the Utomik newsletter'}
        </Button>
      )}
    </div>
  );
};
export default NewsLetter;
