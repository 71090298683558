import { inviteMemberSchema } from 'app/validationSchemes/inviteMemberSchema';
import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import {
  dateOfBirthD,
  dateOfBirthM,
  emptyOptions,
  memberOverEighteenOptions,
  memberUnderEighteenOptions,
  memberYearRange,
} from 'lists/selectOptions';
import React, { useEffect, useMemo } from 'react';

import { IInviteMember, InviteFamilyFormValues, StateInviteMember } from 'types/familyMembers';
import { IDate } from 'types/users';

import { FAQ_URL, MINIMUM_USER_AGE, PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from 'app/global/constants';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Input from 'app/components/input/input';
import RadioGroup from 'app/components/radioGroup/radioGroup';
import Select from 'app/components/select/select';

import styles from './inviteFamilyMember.module.scss';

interface IInviteFamilyForm {
  forbiddenEmails: string[];
  state: StateInviteMember;
  inviteMemberError: string;
  isValidDate: (date: IDate) => boolean;
  isOfAge: (date: string, minAge?: number) => boolean;
  doCancel: () => void;
  doInvite: (inviteMember: IInviteMember) => void;
}

const InviteFamilyForm: React.FC<IInviteFamilyForm> = ({
  forbiddenEmails,
  state,
  inviteMemberError,
  isValidDate,
  isOfAge,
  doCancel,
  doInvite,
}) => {
  const initialValues: InviteFamilyFormValues = {
    email: '',
    dateOfBirthM: '',
    dateOfBirthD: '',
    dateOfBirthY: '',
    overEighteen: '1',
  };

  useEffect(() => {
    return () => formik.resetForm();
  }, []);

  const onSubmit = async ({ email, dateOfBirthD, dateOfBirthM, dateOfBirthY, overEighteen }: typeof initialValues) => {
    const dataInvite = {
      email: email,
      ...(overEighteen === '2' && { birthdate: `${dateOfBirthY}-${dateOfBirthM}-${dateOfBirthD}` }),
    };
    await doInvite(dataInvite);
  };

  const handleValidateErrors = (values: InviteFamilyFormValues) => {
    const errors = {} as InviteFamilyFormValues;
    const { dateOfBirthD, dateOfBirthM, dateOfBirthY } = values;
    if (!values.email) {
      errors.email = '';
    }
    if (dateOfBirthD && dateOfBirthM && dateOfBirthY) {
      const newBirthDate = {
        year: dateOfBirthY,
        month: dateOfBirthM,
        day: dateOfBirthD,
      };
      const isValidDateBirthDate = isValidDate(newBirthDate);
      const isOfMinimumAge =
        isValidDateBirthDate && isOfAge(`${dateOfBirthY}-${dateOfBirthM}-${dateOfBirthD}`, MINIMUM_USER_AGE);
      if (!isValidDateBirthDate) {
        errors.dateOfBirthM = 'This is not a valid date, please check the date you filled in.';
      } else if (!isOfMinimumAge) {
        errors.dateOfBirthM = `New family members need to be at least ${MINIMUM_USER_AGE} years old`;
      }
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: inviteMemberSchema,
    onSubmit: onSubmit,
    validate: handleValidateErrors,
  });

  const checkEmailHasError = useMemo(() => {
    return forbiddenEmails.includes(formik.values.email);
  }, [formik.values.email, forbiddenEmails]);

  const forbiddenEmailError = (
    <span>
      You cannot invite an existing or pending family member.{' '}
      <a href={`${FAQ_URL}/articles/115001775689-Managing-Family-Members`} target="_blank" rel="noopener noreferrer">
        Read more here.
      </a>
    </span>
  );

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length && !formik.touched.email) {
      formik.setFieldTouched('email', true);
    }
    formik.handleChange(e);
  };

  const isDisabledButton = useMemo(() => {
    let birthdayError = false;
    if (formik.values.overEighteen === '1') {
      birthdayError = false;
    } else if (
      formik.values.overEighteen === '2' &&
      (!formik.values.dateOfBirthD || !formik.values.dateOfBirthM || !formik.values.dateOfBirthY)
    ) {
      birthdayError = true;
    }
    return formik.values.overEighteen === '1'
      ? state === 'saving' || !!formik.errors.email || !formik.values.email || birthdayError || checkEmailHasError
      : state === 'saving' ||
          !!Object.keys(formik.errors).length ||
          !formik.values.email ||
          birthdayError ||
          checkEmailHasError;
  }, [formik.errors, state, formik.values]);
  return (
    <FormikProvider value={formik}>
      <form className={styles['invite-member-form']} onSubmit={formik.handleSubmit}>
        <div className={styles['invite-member-form-item']}>
          <Input
            name="email"
            title="Please fill in this field."
            placeholder="Email"
            label="Email"
            isValidate={false}
            isValidateInputStroke={
              !!formik.errors.email || checkEmailHasError || (formik.touched.email && !formik.values.email)
            }
            onChange={handleEmailChange}
            disabled={false}
          />
          {checkEmailHasError && (
            <Alert danger isShowIcon={false} className="error-danger-email">
              {forbiddenEmailError}
            </Alert>
          )}

          <div className={styles['invite-member-form-group']}>
            <h3 className={styles['invite-member-form-group-title']}>Library availability</h3>
            <div className={styles['invite-member-form-group-radio']}>
              <RadioGroup name="overEighteen" options={memberOverEighteenOptions} value={formik.values.overEighteen}>
                <span className="small">This family member will have full game access.</span>
              </RadioGroup>
              <RadioGroup name="overEighteen" options={memberUnderEighteenOptions} value={formik.values.overEighteen}>
                <span className="small">Game access will be restricted based on game age restrictions.</span>
              </RadioGroup>
              {formik.values.overEighteen === '2' && (
                <div className={styles['invite-member-form-group-select']}>
                  <div className={styles['invite-member-form-group-label']}>Date of birth</div>
                  <div
                    className={clsx(
                      styles['invite-member-form-group-select-item'],
                      formik.errors.dateOfBirthM && styles['invite-member-form-group-select-item-error'],
                    )}
                  >
                    <Select
                      name="dateOfBirthM"
                      isValidateErrors={false}
                      options={formik.values.dateOfBirthM ? dateOfBirthM : [emptyOptions, ...dateOfBirthM]}
                    />
                    <Select
                      name="dateOfBirthD"
                      isValidateErrors={false}
                      options={formik.values.dateOfBirthD ? dateOfBirthD() : [emptyOptions, ...dateOfBirthD()]}
                    />
                    <Select
                      name="dateOfBirthY"
                      isValidateErrors={false}
                      options={formik.values.dateOfBirthY ? memberYearRange : [emptyOptions, ...memberYearRange]}
                    />
                  </div>
                  {formik.errors.dateOfBirthM && <div className="small text-danger">{formik.errors.dateOfBirthM}</div>}
                  <p className={clsx('small', formik.errors.dateOfBirthM && styles['text-italic'])}>
                    By inviting a minor I acknowledge to be aware of and will adhere to the responsibilities which are
                    incurred by inviting a minor as is specified in the{' '}
                    <a
                      href={TERMS_OF_SERVICE_URL}
                      title="Read the terms of service"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      terms of service
                    </a>{' '}
                    and{' '}
                    <a
                      href={PRIVACY_POLICY_URL}
                      title="Read the privacy policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy policy
                    </a>
                    .
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {inviteMemberError && <Alert danger>{<span dangerouslySetInnerHTML={{ __html: inviteMemberError }} />}</Alert>}
        <div className={styles['invite-member-form-buttons']}>
          <Button type="button" onClick={doCancel} disabled={state === 'saving'}>
            Cancel
          </Button>
          <Button
            type="submit"
            className="button-invite"
            primary
            disabled={isDisabledButton}
            loading={state === 'saving'}
          >
            Invite
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};
export default InviteFamilyForm;
