import { IServicesResponse } from 'types/localizations';

import ServiceSettingStore from 'dataStore/stores/serviceSettingStore/serviceSettingStore';
import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

export default class ServiceSettingService {
  public constructor(
    private readonly _httpTransport: AxiosTransport,
    private readonly _serviceSettingStore: ServiceSettingStore,
  ) {}

  private async loadServiceSettings(): Promise<void> {
    const serviceSettingResponse = await this._httpTransport.get<IServicesResponse[]>('v1/servicesettings');
    if (serviceSettingResponse?.data) {
      this._serviceSettingStore.setServiceSetting(serviceSettingResponse.data);
    }
  }

  public async getServiceSetting(serviceSettingName: string): Promise<any | null | void> {
    if (!this._serviceSettingStore.serviceSetting.length) {
      await this.loadServiceSettings();
    }

    const serviceSetting = this._serviceSettingStore.serviceSetting.find(
      (service) => service.name === serviceSettingName,
    );
    return serviceSetting ? serviceSetting.value : null;
  }
}
