import moment from 'moment';
import React from 'react';

import { IInvoices } from 'types/invoices';

import { formatPriceToAmountInCents } from 'utils/textFormat';

import styles from './invoicesTable.module.scss';

interface IInvoicesTable {
  invoices: IInvoices[];
  selectInvoice: (invoiceId: number) => void;
}
const InvoicesTable: React.FC<IInvoicesTable> = ({ invoices, selectInvoice }) => {
  return (
    <table className={styles['invoices-table']} data-name="invoices-table">
      <thead>
        <tr className={styles['invoices-table-tr']}>
          <th className={styles['invoices-table-th-date']}>Date</th>
          <th>Description</th>
          <th className={styles['hidden-xs']}>Type</th>
          <th className={styles['hidden-xs']}>Status</th>
          <th className={styles['text-right']}>Total</th>
        </tr>
      </thead>
      <tbody>
        {invoices.map((invoice) => (
          <tr
            className={styles['invoices-table-body-tr']}
            key={invoice.id}
            onClick={() => selectInvoice(invoice.id)}
            data-invoice={invoice.id}
          >
            <td className={styles['invoices-table-body-td-date']}>{moment(invoice.creationdate).format('LL')}</td>
            <td>
              {invoice.invoice_shop_items[0]?.name}{' '}
              {invoice.term_begin && invoice.term_end && (
                <span>
                  {'('}
                  {moment(invoice.term_begin).format('LL')} - {moment(invoice.term_end).format('LL')}
                  {')'}
                </span>
              )}
            </td>
            <td className={styles['hidden-xs']}>{invoice.type}</td>
            <td className={styles['hidden-xs']}>{invoice.status}</td>
            <td>
              {formatPriceToAmountInCents(
                {
                  amount_in_cents: invoice.total_in_cents,
                  currency: invoice.currency,
                  includes_tax: false,
                },
                'amount_in_cents',
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default InvoicesTable;
