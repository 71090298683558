import withAuth from 'app/hoc/withAuth';
import BillingInformation from 'app/widgets/billingInformation/billingInformation';
import DeleteAccount from 'app/widgets/deleteAccount/deleteAccount';
import SubAccountMessages from 'app/widgets/subAccountMessages/subAccountMessages';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';

import { UserControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';

import styles from './billing.module.scss';

const Billing = observer(() => {
  const userController = useContext(UserControllerContext);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const utomikNotSupportedCountry = !userController.utomikIsLiveInUsersCountry && !isLoadingPage;
  const user = userController.user;
  const preconditions = userController.preconditions;

  return (
    <section className="page-section">
      <div className={`${styles['page-billing']} container`}>
        {utomikNotSupportedCountry && (
          <Alert info icon="info-circle">
            Utomik is not officially supported in your country. You may not be able to add or edit billing information.
          </Alert>
        )}
        <h1 className={styles['billing-title']}>
          <span className="far fa-credit-card"></span> Billing information
        </h1>
        {preconditions && !preconditions.canManageSubscriptionAndBillingInfo && <SubAccountMessages />}
        {preconditions &&
          preconditions.canManageSubscriptionAndBillingInfo &&
          (user.deletion_date ? <DeleteAccount /> : <BillingInformation setIsLoadingPage={setIsLoadingPage} />)}
      </div>
    </section>
  );
});
export default withAuth(Billing);
