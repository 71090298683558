import React from 'react';

export const IconChecked = () => {
  return (
    <svg width="24" height="28" viewBox="0 0 24 28" fill="none">
      <rect y="2" width="24" height="24" rx="4" fill="#9969E0" />
      <path
        d="M3.42857 1.75C1.5375 1.75 0 3.31953 0 5.25V22.75C0 24.6805 1.5375 26.25 3.42857 26.25H20.5714C22.4625 26.25 24 24.6805 24 22.75V5.25C24 3.31953 22.4625 1.75 20.5714 1.75H3.42857ZM18.0536 11.4297L11.1964 18.4297C10.6929 18.9438 9.87857 18.9438 9.38036 18.4297L5.95179 14.9297C5.44821 14.4156 5.44821 13.5844 5.95179 13.0758C6.45536 12.5672 7.26964 12.5617 7.76786 13.0758L10.2857 15.6461L16.2321 9.57031C16.7357 9.05625 17.55 9.05625 18.0482 9.57031C18.5464 10.0844 18.5518 10.9156 18.0482 11.4242L18.0536 11.4297Z"
        fill="#332E59"
      />
    </svg>
  );
};
