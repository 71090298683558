import { action, computed, makeObservable, observable } from 'mobx';
import AnalyticService from 'services/analyticsService/analyticsService';
import PageService from 'services/pageService/pageService';
import SubscriptionService from 'services/subscriptionService/subscriptionService';
import UserService from 'services/userService/userService';

import { CurrentSituation } from 'types/familyMembers';
import { IOrdersResponse } from 'types/orders';
import { IReauthModalContent } from 'types/shared';
import { ISubscriptionsResponseWithOrders } from 'types/subscriptions';

import { isValidInviteToken } from 'utils/helpers';
import { activeUtomikSubscriptions } from 'utils/subscriptionFilters';
import { translateErrors } from 'utils/translateErrors';

import { Routes } from '../routes';

export default class JoinFamilyController {
  public constructor(
    private readonly _userService: UserService,
    private readonly _subscriptionService: SubscriptionService,
    private readonly _pageService: PageService,
    private readonly _analyticService: AnalyticService,
  ) {
    makeObservable(this);
  }

  @observable
  private _error = '';

  @action
  public setError(error: string) {
    this._error = error;
  }

  @computed
  public get error() {
    return this._error;
  }

  @observable
  private _isLoading = false;

  @action
  public setIsLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  @computed
  public get isLoading() {
    return this._isLoading;
  }

  @observable
  private _inviteToken = '';

  @action
  public setInviteToken = (inviteToken: string) => {
    this._inviteToken = inviteToken;
  };

  @computed
  public get inviteToken() {
    return this._inviteToken;
  }

  @observable
  private _subscription: ISubscriptionsResponseWithOrders | null = null;

  @action
  public setSubscription(subscription: ISubscriptionsResponseWithOrders | null) {
    this._subscription = subscription;
  }

  @computed
  public get subscription(): ISubscriptionsResponseWithOrders | null {
    return this._subscription;
  }

  @observable
  private _isValidInviteToken = false;

  @action
  public setIsValidInviteToken(isValidInviteToken: boolean) {
    this._isValidInviteToken = isValidInviteToken;
  }

  @computed
  public get isValidInviteToken() {
    return this._isValidInviteToken;
  }

  @observable
  private _currentSituation: CurrentSituation = null;

  @action
  public setCurrentSituation(situation: CurrentSituation) {
    this._currentSituation = situation;
  }

  @computed
  public get currentSituation() {
    return this._currentSituation;
  }

  @observable
  private _reseller: string | null = null;

  @action
  public setReseller(reseller: string) {
    this._reseller = reseller;
  }

  @computed
  public get reseller() {
    return this._reseller;
  }

  @observable
  private _reauthModalIsOpen = false;

  @action
  public setIsOpenReauyhModal(isOpenReauth: boolean) {
    this._reauthModalIsOpen = isOpenReauth;
  }

  @computed
  public get reauthModalIsOpen() {
    return this._reauthModalIsOpen;
  }

  @observable
  private _reauthModaContent: IReauthModalContent | null = null;

  @action
  public setReauthModaContent(modalContent: IReauthModalContent | null) {
    this._reauthModaContent = modalContent;
  }

  @computed
  public get reauthModaContent() {
    return this._reauthModaContent;
  }

  public get utomikIsLiveInUsersCountry() {
    return this._userService.utomikIsLiveInUsersCountry;
  }

  public get user() {
    return this._userService.user;
  }

  public isLoggedIn = async () => {
    return await this._userService.checkIsLoggedIn();
  };

  public get userCountry() {
    return this._userService.userCountry;
  }

  public checkIsValidInviteToken = async (inviteToken: string) => {
    if (inviteToken && isValidInviteToken(inviteToken)) {
      try {
        await this._userService.checkInviteToken({ inviteToken: inviteToken });
        this.setIsValidInviteToken(true);
      } catch (e: any) {
        if (
          ['BADLY_FORMED_INVITE_TOKEN', 'INVITE_TOKEN_ALREADY_USED', 'NO_INVITE_TOKEN_SPECIFIED'].includes(e.message)
        ) {
          this.setError(translateErrors(e));
        } else {
          this.setError('Sorry, your invitation was revoked.');
        }
        this.setIsValidInviteToken(false);
        this.setInviteToken('');
      }
    } else {
      this.setIsValidInviteToken(false);
      this.setInviteToken('');
      this.setError('Something went wrong when retrieving your invitation (E003). Please go back and try again.');
    }
  };

  public determineCurrentSituation = async () => {
    await this._userService.fetchLoggedInAccount();
    await this._userService.fetchAccountSubscriptions();
    const subscriptions = await this._userService.getAccountSubscriptions();
    const activeSubscriptions = activeUtomikSubscriptions({
      subscriptions: subscriptions,
      filterType: 'activeUtomikSubscriptions',
    });
    this.setSubscription(activeSubscriptions[0]);
    await this.checkIsValidInviteToken(this.inviteToken);
    if (!this.subscription) {
      this.setCurrentSituation('noSubscription');
    } else if (this.subscription?.order && (this.subscription.order as IOrdersResponse).reseller) {
      const partnerId = (this.subscription.order as IOrdersResponse).reseller;
      if (partnerId) {
        const partner = await this._subscriptionService.getPartner(partnerId);
        partner && this.setReseller(partner.name);
      }
      this.setCurrentSituation('reseller');
    } else if (!this.subscription.recurring) {
      this.setCurrentSituation('nonRecurring');
    } else if (this.subscription.type === 'SP') {
      if (this.subscription.status === 'CANCELED') {
        this.setCurrentSituation('canceledPersonal');
      } else {
        //status 'ACTIVE'
        this.setCurrentSituation('activePersonal');
      }
    } else {
      //type 'SF'
      if (this.user && this.user.account && this.user.account.role === 'Member') {
        this.setCurrentSituation('familyMember');
      } else {
        if (this.subscription.status === 'CANCELED') {
          this.setCurrentSituation('canceledFamilyOwner');
        } else {
          //status 'ACTIVE'
          this.setCurrentSituation('activeFamilyOwner');
        }
      }
    }
  };

  public onError(e: any) {
    this.setIsLoading(false);
    this.setError(translateErrors(e));
  }

  public acceptInvitation = async (isCancelling?: boolean, isOwner?: boolean) => {
    this.setIsLoading(true);
    try {
      await this._userService.useInviteToken({ token: this.inviteToken });

      if (isCancelling && isOwner) {
        this._analyticService.handleCancelSubscription(this.subscription);
      }

      this._pageService.setLocation(Routes.SuccessMember);
    } catch (e) {
      this.onError(e);
    }
  };

  public toggleModalReauth = () => {
    this.setIsOpenReauyhModal(!this.reauthModalIsOpen);
  };

  public doCancelAndAcceptInvitation = async (confirmMessages: string[], isOwner: boolean) => {
    this.setReauthModaContent({
      title: 'Are you sure?',
      message: confirmMessages,
      buttonLabel: 'Yes, I am sure',
      buttonIsDanger: false,
      callback: async () => await this.acceptInvitation(true, isOwner),
    });
    this.setIsOpenReauyhModal(true);
  };

  public cancelPersonalPlanAndAcceptInvitation = async () => {
    const confirmMessages = [
      'You are about to change to a family plan. We will cancel your current subscription for you and add your account to the family plan.',
    ];
    await this.doCancelAndAcceptInvitation(confirmMessages, true);
  };

  public leaveFamilyPlanAndAcceptInvitation = async () => {
    const confirmMessages = [
      'You are about to leave your current family plan and join a new family plan. We will automatically move your account for you.',
    ];
    await this.doCancelAndAcceptInvitation(confirmMessages, false);
  };

  public cancelFamilyPlanAndAcceptInvitation = async () => {
    const confirmMessages = [
      'You are about to cancel your family plan and join another family plan. We will cancel your current subscription for you and add your account to the family plan.',
      'Any members of your old family plan will still be able to play on Utomik until the subscription expires.',
    ];
    await this.doCancelAndAcceptInvitation(confirmMessages, true);
  };

  public clearState = () => {
    this.setCurrentSituation(null);
    this.setSubscription(null);
    this.setError('');
    this.setInviteToken('');
    this.setIsValidInviteToken(false);
  };
}
