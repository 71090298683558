import CookieService from 'services/cookieService/cookieService';
import LocalizationService from 'services/localizationService/localizationService';
import UserService from 'services/userService/userService';

import { CartData, ICartDataCheckout } from 'types/cart';
import { IOrdersResponse } from 'types/orders';
import { IPromoResponse } from 'types/promo';
import {
  ISubscriptionByUsableSubscription,
  ISubscriptionsResponseWithOrders,
  SubscriptionPlan,
} from 'types/subscriptions';

import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import { toUsableAccountSubscription, toUsableSubscription } from 'utils/subscriptionFilters';
import { removePlatformURLFromURL } from 'utils/utils';

import { DEFAULT_SUCCESS_PAGE_URL, platformURL, shoppingcartCookieName } from 'app/global/constants';

const DEFAULT_NEXT = DEFAULT_SUCCESS_PAGE_URL;
const defaultCart = {
  next: DEFAULT_NEXT,
  productURL: null,
  returnProductURL: null,
  promo: null,
  meta: {},
};

type FieldName = keyof CartData;

export default class CartService {
  public constructor(
    private readonly _cookieService: CookieService,
    private readonly _httpTransport: AxiosTransport,
    private readonly _userService: UserService,
    private readonly _localizationService: LocalizationService,
  ) {}

  public getDefaultCartContents() {
    return defaultCart;
  }

  public clearProduct() {
    this.setCartField('productURL', null);
  }

  public setCartField(field: FieldName, value: string | null | object) {
    this.setCartData({ [field]: value });
  }

  public getCartData(): CartData {
    const cookie = this._cookieService.getCookie(shoppingcartCookieName);
    if (cookie !== undefined) {
      return JSON.parse(atob(`${cookie}`));
    }
    return this.getDefaultCartContents();
  }

  public setCartData(data: Partial<typeof defaultCart>) {
    const cartData = { ...this.getDefaultCartContents(), ...this.getCartData(), ...data };

    this._cookieService.setCookie(shoppingcartCookieName, btoa(JSON.stringify(cartData)), {
      path: '/',
      maxAge: 30,
    });
  }

  public clearPromo(): void {
    this.setCartField('promo', null);
  }

  public getPromo() {
    return this.getCartField('promo') || this.getCartField('promoCode') || null; // promoCode is just for backwards compatibility!
  }

  public getMeta() {
    return this.getCartField('meta');
  }

  public getCartField(field: FieldName) {
    const cartData = this.getCartData();
    if (field in cartData) {
      return cartData[field];
    } else {
      return undefined;
    }
  }

  public getNext() {
    return this.getCartField('next') || DEFAULT_NEXT;
  }

  public clearNext(): void {
    this.setCartField('next', DEFAULT_NEXT);
  }

  public clearReturnProduct(): void {
    this.setCartField('returnProductURL', null);
  }

  public clearMeta(): void {
    this.setCartField('meta', {});
  }

  public setProduct(product: string | object) {
    this.clearProduct();
    if (typeof product === 'string') {
      this.setCartField('productURL', product);
      return;
    } else if (typeof product === 'object' && 'url' in product) {
      this.setCartField('productURL', product.url as string);
      return;
    }
    this.setCartField('productURL', null);
  }

  public setNext(next: string) {
    this.clearNext();
    this.setCartField('next', next);
  }

  public setMeta(data: object) {
    this.setCartField('meta', data);
  }

  public setReturnProduct(returnProduct: string | object | null) {
    this.clearReturnProduct();
    if (typeof returnProduct === 'string') {
      this.setCartField('returnProductURL', returnProduct);
      return;
    }
    if (returnProduct && typeof returnProduct === 'object' && 'url' in returnProduct) {
      this.setCartField('returnProductURL', returnProduct.url as string);
      return;
    }
    this.setCartField('returnProductURL', null);
  }

  public setPromo(promo: IPromoResponse | null) {
    this.clearPromo();
    this.setCartField('promo', promo);
  }

  public getProductFetchURL() {
    let productURL = this.getCartField('productURL');
    const promo = this.getPromo();
    if (promo && productURL) {
      productURL = productURL + '?ref=' + (promo as IPromoResponse).code;
    }
    if (productURL) {
      return productURL;
    } else {
      return null;
    }
  }

  public getReturnProductFetchURL() {
    return this.getCartField('returnProductURL') || null;
  }

  public async getCart(): Promise<ICartDataCheckout> {
    let countryData = this._userService.userCountry || undefined;
    let filteredProductData: ISubscriptionByUsableSubscription | null = null;
    let filteredReturnProductData: ISubscriptionsResponseWithOrders | null = null;
    let orderData: IOrdersResponse | null = null;
    const cartData: ICartDataCheckout = {} as ICartDataCheckout;
    const wasFetched = {
      getLoggedIn: true,
      countryData: false,
      orderData: false,
    };
    try {
      const productFetchURL = this.getProductFetchURL() as string;
      const returnProductFetchURL = this.getReturnProductFetchURL() as string;
      if (productFetchURL) {
        const normalizedProductFetchUrl = removePlatformURLFromURL(productFetchURL, platformURL);
        const productData = await this._httpTransport.get<SubscriptionPlan>(normalizedProductFetchUrl);
        if (!productData?.data) {
          filteredProductData = null;
        } else {
          filteredProductData = toUsableSubscription(productData.data);
        }
      }
      if (returnProductFetchURL) {
        const normalizedReturnProductUrl = removePlatformURLFromURL(returnProductFetchURL, platformURL);

        const returnProductData = await this._httpTransport.get<ISubscriptionsResponseWithOrders>(
          normalizedReturnProductUrl,
        );

        if (!returnProductData?.data) {
          filteredReturnProductData = null;
        } else {
          filteredReturnProductData = toUsableAccountSubscription(returnProductData.data);
        }
      }

      if (Object.keys(countryData).length && !countryData.currency) {
        wasFetched.countryData = true;
        countryData = await this._localizationService.getCountry(countryData.id);
      } else if (!countryData) {
        wasFetched.countryData = true;

        countryData = await this._localizationService.getCountryByIP();
      }

      if (filteredReturnProductData) {
        wasFetched.orderData = true;
        orderData = (await this._userService.getOrder(filteredReturnProductData.order.id)) as IOrdersResponse;
      }
      if (orderData && filteredReturnProductData) {
        filteredReturnProductData.order = orderData;
      }

      cartData.next = this.getNext() as string;
      if (filteredProductData) {
        cartData.product = filteredProductData;
        cartData.returnProduct = filteredReturnProductData || null;
      }
      cartData.meta = this.getMeta() as ICartDataCheckout['meta'];
      cartData.promo = this.getPromo() as ICartDataCheckout['promo'];

      return cartData;
    } catch (e) {
      throw new Error('COULD_NOT_READ_CART');
    }
  }

  public hasProduct() {
    const productURL = this.getCartField('productURL');
    return !!productURL;
  }
  public hasReturnProduct() {
    const returnProductURL = this.getCartField('returnProductURL');
    return !!returnProductURL;
  }

  public getProductPurchaseURL(): string | null {
    const productURL = this.getCartField('productURL');
    if (!productURL) {
      return null;
    }
    let purchaseURL = productURL + '/do_purchase';
    const promo = this.getPromo() as IPromoResponse;
    if (promo && purchaseURL) {
      purchaseURL = purchaseURL + '?ref=' + promo.code;
    }
    return purchaseURL;
  }

  public emptyCart() {
    this.clearProduct();
    this.clearPromo();
    this.clearNext();
    this.clearReturnProduct();
    this.clearMeta();
  }
}
