import withAuth from 'app/hoc/withAuth';
import DeleteAccount from 'app/widgets/deleteAccount/deleteAccount';
import Invoice from 'app/widgets/invoice/invoice';
import InvoicesTable from 'app/widgets/invoicesTable/invoicesTable';
import SubAccountMessages from 'app/widgets/subAccountMessages/subAccountMessages';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { InvoicesControllerContext, UtomikSupportedInCountryControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Spinner from 'app/components/spinner/spinner';

import styles from './invoices.module.scss';

const Invoices = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const invoicesController = useContext(InvoicesControllerContext);
  const utomikSupportedInCountryController = useContext(UtomikSupportedInCountryControllerContext);
  const location = useLocation();

  const {
    utomikIsLiveInUsersCountry,
    step,
    invoices,
    user,
    preconditions,
    error,
    currentInvoice,
    firstName,
    lastName,
    isCurrentTrial,
    selectInvoice,
    showInvoiceDetail,
    goBackToViewInvoices,
    clearState,
  } = invoicesController;

  const checkSelectedInvoiceByUrl = () => {
    const hashToSearch = location.hash.substring(1);
    const searchParams = new URLSearchParams(hashToSearch);
    const inviteToken = searchParams.get('invoiceid');
    if (inviteToken) {
      showInvoiceDetail(inviteToken);
    }
  };

  const init = async () => {
    try {
      await utomikSupportedInCountryController.checkIsUtomikSupportedInCountry();
      await invoicesController.initPage();
      checkSelectedInvoiceByUrl();
    } catch (e) {
      console.error(e, 'invoices page');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    return () => clearState();
  }, []);

  const isShowPreconditionMessage = !isLoading && preconditions && !preconditions.canManageSubscriptionAndBillingInfo;

  const isShowInvoices = !isLoading && !error && !isShowPreconditionMessage && step === 'view_invoices';

  const isShowCurrentInvoice = !isLoading && !error && !isShowPreconditionMessage && step === 'view_invoice';

  return (
    <div className="page-section">
      <div className={clsx('container', styles['invoices-container'])}>
        <div className={styles['invoices-container-notification']}>
          {!utomikIsLiveInUsersCountry && !isLoading && (
            <Alert info icon="info-circle">
              Utomik is not officially supported in your country. You may not be able to view invoices.
            </Alert>
          )}
        </div>

        <h1 className={styles['invoices-title']}>
          <span className="far fa-calendar-alt"></span> View invoices
        </h1>

        {isLoading && <Spinner />}
        {isShowPreconditionMessage && <SubAccountMessages />}

        {user.deletion_date && (
          <div className={styles['invoices-deleted-account']}>
            <DeleteAccount />
          </div>
        )}
        {isShowInvoices && (
          <div>
            {!invoices.length && <p>There aren't any invoices yet.</p>}
            {!!invoices.length && <InvoicesTable invoices={invoices} selectInvoice={selectInvoice} />}
          </div>
        )}
        {isShowCurrentInvoice && currentInvoice && (
          <>
            <Invoice
              currentInvoice={currentInvoice}
              firstName={firstName || ''}
              lastName={lastName || ''}
              isCurrentTrial={isCurrentTrial}
            />
            <Button className={styles['btn-invoices-back']} onClick={goBackToViewInvoices}>
              <span className="fas fa-angle-left"></span>Back to list of invoices
            </Button>
          </>
        )}
        {isShowCurrentInvoice && !currentInvoice && <p>The selected invoice couldn't be generated.</p>}
        {!isLoading && !isShowPreconditionMessage && error && <Alert danger>{error}</Alert>}
      </div>
    </div>
  );
});
export default withAuth(Invoices);
