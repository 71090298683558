import { action, makeObservable } from 'mobx';
import UploadService from 'services/uploadService/uploadService';
import UserService from 'services/userService/userService';

import { IGetLoggedInUserImages, IUploadData, IUploadResponse } from 'types/upload';

export default class AvatarController {
  public constructor(private readonly _uploadService: UploadService, private readonly _userService: UserService) {
    makeObservable(this);
  }

  @action
  public isAcceptableImageType(file: File): boolean {
    const imageRegex = new RegExp(/image\/(bmp|gif|jpeg|jpg|png)/i);
    const isValidFormat = file.type.match(imageRegex);
    return !!isValidFormat;
  }

  @action
  public async uploadImage(uploadData: IUploadData): Promise<void> {
    const newImage = await this._uploadService.uploadFile('/v1/users/me/images', uploadData);
    if (!newImage) {
      return;
    }
    let newUserImage: IUploadResponse | IGetLoggedInUserImages = newImage;
    const deletePromises: Promise<void>[] = [];

    const userImages: IGetLoggedInUserImages[] = await this._userService.getLoggedInUserImages();

    for (const image of userImages) {
      if (image.tag === 'AV' && image.id !== newUserImage.id) {
        deletePromises.push(this._uploadService.deleteMedia(`/v1/users/me/images/${image.id}`));
      } else if (image.id === newUserImage.id) {
        newUserImage = image;
      }
    }

    if (deletePromises.length) {
      try {
        await Promise.all(deletePromises);
      } catch (e) {
        console.error(e, 'delete old avatars');
      }
    }

    this._userService.setUserAvatar(newUserImage.download_url);
  }
}
