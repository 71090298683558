import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { IUpdateIdealBank } from 'types/billing';
import { Messages } from 'types/messages';

import { PaynlIdealControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Spinner from 'app/components/spinner/spinner';

import styles from './paynlIdeal.module.scss';

interface IPaynlIdeal {
  handleSubmit: (data: IUpdateIdealBank) => void;
  isProductTrial?: boolean;
  handleBack?: () => void;
}

const PaynlIdeal: React.FC<IPaynlIdeal> = observer(({ handleSubmit, isProductTrial, handleBack }) => {
  const paynlIdealController = useContext(PaynlIdealControllerContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [errorRequest, setErrorRequest] = useState('');
  const [isFetchingBanks, setIsFetchingBanks] = useState(true);

  const { errorFetchBanks, idealBanks } = paynlIdealController;

  useEffect(() => {
    const getBanks = async () => {
      try {
        await paynlIdealController.getIdealBanks();
      } finally {
        setIsFetchingBanks(false);
      }
    };
    getBanks();
  }, []);

  const handleSubmitBank = (bankId: number | string) => {
    if (!executeRecaptcha) {
      setErrorRequest(Messages.RECAPTCHA_FAILED);
      return;
    }
    executeRecaptcha('enquiryFormSubmit')
      .then((gReCaptchaToken) => {
        const data: IUpdateIdealBank = {
          g_recaptcha_response: gReCaptchaToken,
          paymentType: 'ideal',
          provider: 'paynl',
          userBankId: Number(bankId),
        };
        handleSubmit(data);
      })
      .catch(() => {
        setErrorRequest(Messages.RECAPTCHA_FAILED);
      });
  };

  if (isFetchingBanks) {
    return <Spinner>Please wait</Spinner>;
  }
  return (
    <>
      {errorFetchBanks && <Alert danger>{errorFetchBanks}</Alert>}
      {isProductTrial && <Alert info>You will be charged a small transaction fee to verify your bank account.</Alert>}
      {!errorFetchBanks && !isFetchingBanks && (
        <div className={styles['paynl-container']}>
          <p>Please select your bank:</p>

          {!!idealBanks.length && (
            <ul className={styles['paynl-list-group']}>
              {idealBanks.map((bank) => (
                <li key={bank.id} className={styles['paynl-list-group-item']} onClick={() => handleSubmitBank(bank.id)}>
                  <span>
                    {!bank.logo && <span className={styles['paynl-list-group-bank-name']}>{bank.visibleName}</span>}
                    {bank.logo && (
                      <span className={styles['paynl-list-group-bank-logo']}>
                        <img src={bank.logo} alt={bank.visibleName} title={bank.visibleName} />
                      </span>
                    )}
                    <i className="fa-solid fa-chevron-right"></i>
                  </span>
                </li>
              ))}
            </ul>
          )}
          {handleBack && (
            <Button onClick={handleBack} xl className="paynl-back">
              Go back
            </Button>
          )}
        </div>
      )}
    </>
  );
});
export default PaynlIdeal;
