import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Field, useField } from 'formik';
import { forwardRef } from 'react';

import ErrorMessage from '../errorMessage/errorMessage';

import styles from './input.module.scss';

export type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  name: string;
  type?: 'text' | 'password' | 'email' | 'url' | 'tel' | 'number' | 'search';
  showCheck?: boolean;
  isLoading?: boolean;
  isValidate?: boolean;
  isValidateInputStroke?: boolean;
  label?: string;
  customMessage?: string | React.ReactNode;
};

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    className,
    type = 'text',
    label = '',
    title,
    showCheck,
    isLoading = false,
    isValidate = true,
    isValidateInputStroke = false,
    customMessage,
    ...props
  },
  ref,
) {
  const [field, meta] = useField(props);

  const hasError = meta.touched && meta.error && isValidate;
  const inputClassName = clsx(styles.input__input, className && `${className}__input`);

  const errorClassName = clsx(styles.input__error, className && `${className}__error`);

  const fieldProps = {
    ...field,
    ...props,
    type,
    className: inputClassName,
    innerRef: ref,
  };
  return (
    <label
      className={clsx(
        styles.input,
        styles['input--rotate'],
        (hasError || isValidateInputStroke) && styles['input--error'],
        className,
      )}
    >
      {label && label}
      <div className="input-wrapper">
        <div className={clsx(styles['input-wrapper-icon'], isLoading && styles['input--loading'])}>
          <Field {...fieldProps} title={field.value ? undefined : title} />
          {showCheck && <FontAwesomeIcon icon="check-circle" />}
        </div>
        {hasError && <ErrorMessage className={errorClassName} name={field.name} customMessage={customMessage} />}
      </div>
    </label>
  );
});

export default Input;
