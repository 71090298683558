import withAuth from 'app/hoc/withAuth';
import ChangePasswordModal from 'app/modals/changePasswordModal/changePasswordModal';
import CountryUnSupported from 'app/widgets/countryUnSupported/countryUnSupported';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { JoinFamilyControllerContext, UtomikSupportedInCountryControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Modal from 'app/components/modal/modal';
import Spinner from 'app/components/spinner/spinner';

import styles from './joinFamilyPlan.module.scss';

const JoinFamilyPlan = observer(() => {
  const location = useLocation();
  const joinFamilyController = useContext(JoinFamilyControllerContext);
  const utomikSupportedInCountryController = useContext(UtomikSupportedInCountryControllerContext);
  const [isFetchSupportedCountry, setIsFetchSupportedCountry] = useState(true);
  const [fetchInitPage, setFetchInitPage] = useState(true);
  const {
    utomikIsLiveInUsersCountry,
    error,
    currentSituation,
    isLoading,
    reseller,
    userCountry,
    reauthModalIsOpen,
    reauthModaContent,
    toggleModalReauth,
    setInviteToken,
    determineCurrentSituation,
    cancelPersonalPlanAndAcceptInvitation,
    leaveFamilyPlanAndAcceptInvitation,
    cancelFamilyPlanAndAcceptInvitation,
    acceptInvitation,
    clearState,
  } = joinFamilyController;

  const checkUtomikIsLiveInUsersCountry = async () => {
    try {
      await utomikSupportedInCountryController.checkIsUtomikSupportedInCountry();
    } finally {
      setIsFetchSupportedCountry(false);
    }
  };

  const fetchDetermineCurrentSituation = async () => {
    const hashToSearch = location.hash.substring(1);
    const searchParams = new URLSearchParams(hashToSearch);
    const inviteToken = searchParams.get('invitetoken');
    if (inviteToken) {
      setInviteToken(inviteToken);
    }
    try {
      await determineCurrentSituation();
    } finally {
      setFetchInitPage(false);
    }
  };
  const initPage = async () => {
    await checkUtomikIsLiveInUsersCountry();
    await fetchDetermineCurrentSituation();
  };

  useEffect(() => {
    initPage();
  }, []);

  useEffect(() => {
    return () => clearState();
  }, []);
  return (
    <div className={styles['join-family']}>
      {!isFetchSupportedCountry && !utomikIsLiveInUsersCountry && !fetchInitPage && (
        <CountryUnSupported
          countryName={userCountry.name}
          isSetAsUserCountry={userCountry.isSetAsUserCountry || false}
        />
      )}
      <div className="container">
        <section className="page-section">
          {isFetchSupportedCountry || (fetchInitPage && <Spinner />)}

          {!isFetchSupportedCountry && !fetchInitPage && utomikIsLiveInUsersCountry && (
            <div>
              {' '}
              <h1 className={styles['join-family-title']}>You have been invited to join a Utomik family plan!</h1>
              {error && <Alert danger>{error}</Alert>}
              {!error && (
                <div>
                  {currentSituation === 'activePersonal' && (
                    <div>
                      <p>
                        To join the new family plan, you will need to first cancel your current subscription. We will
                        automatically do this for you and add your account to the family plan you've been invited to.
                        All you have to do is click the button below!
                      </p>
                      <Button
                        primary
                        lg
                        onClick={cancelPersonalPlanAndAcceptInvitation}
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Cancel subscription &amp; accept invitation
                      </Button>
                    </div>
                  )}
                  {currentSituation === 'familyMember' && (
                    <div>
                      <p>
                        To join the new family plan, you will need to first leave your current family plan. We will
                        automatically do this for you and add your account to the new family plan you've been invited
                        to. All you have to do is click the button below!
                      </p>
                      <Button
                        primary
                        lg
                        onClick={leaveFamilyPlanAndAcceptInvitation}
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Leave current plan &amp; accept invitation
                      </Button>
                    </div>
                  )}
                  {currentSituation === 'canceledFamilyOwner' && (
                    <div>
                      <p>
                        When you join the new family plan, the members of your cancelled family plan will still be able
                        to play on Utomik until the subscription expires.
                      </p>
                      <p>To join the new family plan, all you have to do is click the button below!</p>
                      <Button
                        primary
                        lg
                        onClick={() => acceptInvitation(false, true)}
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Accept invitation
                      </Button>
                    </div>
                  )}
                  {currentSituation === 'activeFamilyOwner' && (
                    <div>
                      <p>
                        To join the new family plan, you will need to first cancel your current family plan. Any members
                        of your old family plan will still be able to play on Utomik until the subscription expires.
                        <br /> We will automatically do this for you and add your account to the new family plan you've
                        been invited to. All you have to do is click the button below!
                      </p>
                      <Button
                        primary
                        lg
                        onClick={cancelFamilyPlanAndAcceptInvitation}
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Cancel current plan &amp; accept invitation
                      </Button>
                    </div>
                  )}
                  {currentSituation === 'reseller' && (
                    <div>
                      <p>
                        Unfortunately, you are unable to accept this invitation as your account is managed through{' '}
                        {reseller}. To accept this family plan invitation, you must first cancel your Utomik
                        subscription with {reseller}. Once your subscription expires, you will be able to accept this
                        invitation.
                      </p>
                    </div>
                  )}
                  {currentSituation === 'nonRecurring' && (
                    <div>
                      <p>
                        You are unable to accept this family plan invitation because you have a prepaid subscription. To
                        accept this family plan invitation, you must first wait for your current subscription to expire.
                      </p>
                    </div>
                  )}
                  {(!currentSituation ||
                    currentSituation === 'noSubscription' ||
                    currentSituation === 'canceledPersonal') && (
                    <div>
                      <p>To join the family plan, all you have to do is click the button below!</p>
                      <Button
                        primary
                        lg
                        onClick={() => acceptInvitation(false, false)}
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Accept invitation
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <Modal show={reauthModalIsOpen} onHide={toggleModalReauth} title={reauthModaContent?.title}>
            <ChangePasswordModal
              closeModal={toggleModalReauth}
              subtitle={reauthModaContent?.message}
              placeholder="Password"
              danger={reauthModaContent?.buttonIsDanger}
              textButtonNext={reauthModaContent?.buttonLabel}
              closeModalAfterConfirm={true}
              completeConfirm={reauthModaContent?.callback}
            />
          </Modal>
        </section>
      </div>
    </div>
  );
});
export default withAuth(JoinFamilyPlan);
