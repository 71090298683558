import withAuth from 'app/hoc/withAuth';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Routes } from 'app/global/routes';

const LogInAndRedeem = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = location.search;
    navigate(Routes.Redeem + `/${searchParams}`);
  }, []);
  return <div />;
};
export default withAuth(LogInAndRedeem);
