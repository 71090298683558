import { action, computed, makeObservable, observable } from 'mobx';
import PairingService from 'services/pairingService/pairingService';

import { ActionForPost } from 'types/pairing';

import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import LoginController from '../loginController/loginController';
import PageController from '../pageController/pageController';

type ParingValidateBody = {
  pairing_code: string;
};

interface IParingValidationResponse {
  validation_status: string;
}

export default class PairingController {
  @observable
  private _busy = false;

  @observable
  private _isOpenPromoModal = false;

  @observable
  private _error = '';

  public constructor(
    private readonly _httpTransport: AxiosTransport,
    private readonly _pairingService: PairingService,
    private readonly _loginController: LoginController,
    private readonly _pageController: PageController,
  ) {
    makeObservable(this);
  }

  @computed
  public get busy() {
    return this._busy;
  }

  @action
  public setBusy(busy: boolean) {
    this._busy = busy;
  }

  @computed
  public get error() {
    return this._error;
  }

  @action
  public setError(error: string) {
    this._error = error;
  }

  @computed
  public get isOpenPromoModal() {
    return this._isOpenPromoModal;
  }

  @action
  public setIsOpenPromoModal(isOpenModal: boolean) {
    return (this._isOpenPromoModal = isOpenModal);
  }

  public getIsTvPlatform() {
    return !!this._pairingService.getPairingCodeFromCookie();
  }
  // On page load, on the register page, show a 'cloud not available' message if that service setting is turned on.
  public async isCloudRegistrationAllowed() {
    this.setBusy(true);
    const isAllowed = await this._pairingService.isCloudRegistrationAllowed();
    if (isAllowed) {
      this.setBusy(false);
    } else {
      this._pageController.setLocation('https://www.utomik.com/cloud-n-a', true);
    }
  }

  public async validatePairingCode(pairingCode: string, pairingCase: ActionForPost): Promise<void> {
    this.setBusy(true);
    try {
      const response = await this._httpTransport.post<IParingValidationResponse, ParingValidateBody>(
        'v2/pairing/do_validate_code',
        {
          pairing_code: pairingCode,
        },
      );
      if (response?.data) {
        if (response.data.validation_status === 'INVALID') {
          throw new Error('PAIR-INVALID');
        }
        this._pairingService.rememberPairingCode(pairingCode);
        if (await this._loginController.checkIsLoggedIn()) {
          await this._pairingService.linkPairingCode(pairingCode, 'LOG_IN');
          return;
        } else if (pairingCase === 'SIGN_UP') {
          this.setBusy(false);
          this.setIsOpenPromoModal(true);
          return;
        } else {
          this._pageController.setLocation('/login');
        }
      }
    } catch (error: any) {
      this._pairingService.transformPairingErrors(error, pairingCase);
    } finally {
      this.setBusy(false);
    }
  }

  public async completeRegistrationWithPairing() {
    this.setBusy(true);
    try {
      const isPairingCodePending = await this._pairingService.getIsPairingCodePending();
      if (isPairingCodePending) {
        return this._pairingService.loginWithPairingCode();
      }
    } catch (e: any) {
      this.setError(e.message);
    } finally {
      this.setBusy(false);
    }
  }
}
