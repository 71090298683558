import React from 'react';

type IIcons = {
  isSelected?: boolean;
};
export const IconFamily: React.FC<IIcons> = ({ isSelected = false }) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
      <g>
        <path
          d="M12.8906 18.7916C11.5401 17.758 9.85668 17.1376 8.025 17.1376C3.59291 17.1376 0 20.7305 0 25.1626V30.1125C0 30.6927 0.469927 31.1625 1.04998 31.1625H9.51854V26.1006C9.51854 23.1786 10.8305 20.5605 12.8906 18.7916Z"
          fill={isSelected ? '#5C2BA4' : '#181A33'}
        />
        <path
          d="M8.02501 15.7875C11.049 15.7875 13.5 13.3365 13.5 10.3126C13.5 7.2885 11.049 4.83752 8.02501 4.83752C5.00102 4.83752 2.55005 7.2885 2.55005 10.3126C2.55005 13.3366 5.00102 15.7875 8.02501 15.7875Z"
          fill={isSelected ? '#5C2BA4' : '#181A33'}
        />
        <path
          d="M19.1589 18.8601C15.1606 18.8601 11.9185 22.1021 11.9185 26.1006V30.1125C11.9185 30.6926 12.3884 31.1625 12.9685 31.1625H16.0499H21.2859V26.8588C21.2859 24.4278 22.3101 22.2351 23.9431 20.676C22.667 19.5493 20.9953 18.8601 19.1589 18.8601Z"
          fill={isSelected ? '#5C2BA4' : '#181A33'}
        />
        <path
          d="M16.4841 16.8457C17.2558 17.345 18.1711 17.642 19.1589 17.642C20.1469 17.642 21.0621 17.345 21.8343 16.8457C23.1943 15.9656 24.0989 14.4428 24.0989 12.7026C24.0989 9.9739 21.8876 7.76257 19.1589 7.76257C16.4308 7.76257 14.2195 9.9739 14.2195 12.7026C14.2195 14.4428 15.1242 15.9656 16.4841 16.8457Z"
          fill={isSelected ? '#5C2BA4' : '#181A33'}
        />
        <path
          d="M29.8429 20.7018C26.4422 20.7018 23.686 23.4587 23.686 26.8589V30.1125C23.686 30.6927 24.1559 31.1625 24.736 31.1625H26.4H34.9499C35.5301 31.1625 36 30.6927 36 30.1125V26.8589C36 23.4587 33.2431 20.7018 29.8429 20.7018Z"
          fill={isSelected ? '#5C2BA4' : '#181A33'}
        />
        <path
          d="M27.2163 18.7184C27.9376 19.3013 28.8433 19.6652 29.8429 19.6652C30.8431 19.6652 31.749 19.3013 32.4703 18.7184C33.4224 17.9485 34.0435 16.7853 34.0435 15.4652C34.0435 13.145 32.1632 11.2641 29.8429 11.2641C27.5232 11.2641 25.6424 13.145 25.6424 15.4652C25.6424 16.7853 26.2634 17.9485 27.2163 18.7184Z"
          fill={isSelected ? '#5C2BA4' : '#181A33'}
        />
      </g>
    </svg>
  );
};
