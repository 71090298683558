import clsx from 'clsx';
import React from 'react';

import { ISubscriptionsResponseWithOrders } from 'types/subscriptions';

import Button from 'app/components/button/button';
import Spinner from 'app/components/spinner/spinner';

import styles from './subscriptionOverview.module.scss';

interface ISubscriptionOverview {
  accountRole: string;
  subscription: ISubscriptionsResponseWithOrders | null;
  isReactivating: boolean;
  handleReactivate: () => void;
  goToFamilyManage?: () => void;
  isLoading: boolean;
}

const SubscriptionOverview: React.FC<ISubscriptionOverview> = ({
  accountRole,
  subscription,
  isReactivating,
  isLoading,
  handleReactivate,
  goToFamilyManage,
}) => {
  return (
    <div className={styles['subscription-overview']}>
      <div className={styles['subscription-overview-block']}>
        <div className={styles['subscription-overview-block-left']}>
          {accountRole !== 'Member' && (
            <div className={styles['subscription-overview-subtext']}>This is your current plan:</div>
          )}
          {accountRole === 'Member' && (
            <div className={styles['subscription-overview-subtext']}>You are a member in the following plan:</div>
          )}
          {isLoading ? (
            <Spinner />
          ) : (
            <article>
              <div
                className={clsx(
                  styles['icon-groupe'],
                  subscription && subscription.status !== 'ACTIVE' && styles['icon-groupe-error'],
                )}
              >
                {!subscription && (
                  <div className={styles['icon-groupe-content']}>You don't have a Utomik subscription.</div>
                )}
                {subscription && (
                  <div className={styles['subscription-overview-plan']}>
                    <h2>{subscription.name}</h2>
                    <div className={styles['subscription-overview-plan-status']}>
                      {subscription.status === 'ACTIVE' && (
                        <div className={styles['subscription-overview-plan-success-status']}>
                          {/* <span className="fas fa-check"></span> */}
                          {subscription.in_trial && <span> Your trial period is now active</span>}
                          {!subscription.in_trial && <span> Account status: active</span>}
                        </div>
                      )}
                      {subscription.status !== 'ACTIVE' && (
                        <div className={styles['subscription-overview-plan-reactivate']}>
                          <span className="fas fa-redo" />
                          Account status:
                          <Button disabled={isReactivating} primary onClick={handleReactivate}>
                            {isReactivating ? 'reactivating...' : 'reactivate'}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </article>
          )}
          {subscription && subscription.type === 'SF' && accountRole === 'Owner' && (
            <div className={styles['subscription-overview-owner']} onClick={goToFamilyManage}>
              <span className="fas fa-plus-circle"></span>
              <span className="appear-as-link">Add your family members</span>
            </div>
          )}
        </div>

        <div className={styles['subscription-overview-block-right']}>
          <i className="fa-solid fa-unlock-keyhole" />{' '}
          <span>
            <strong>1400+ games</strong> unlocked!
          </span>
        </div>
      </div>
    </div>
  );
};
export default SubscriptionOverview;
