import { observer } from 'mobx-react';
import React, { ComponentType, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { normalizePath } from 'utils/textFormat';

import { PENDING_VERIFICATION_STATUSES, urlsWithoutPreconditions } from 'app/global/constants';
import { Routes } from 'app/global/routes';

import { CookieServiceContext, PreconditionControllerContext, UserControllerContext } from 'app/context/storesContext';

import Spinner from 'app/components/spinner/spinner';

const withAuth = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const WithAuthComponent: React.FC<P> = observer((props) => {
    const [isClient, setIsClient] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const cookieServices = useContext(CookieServiceContext);
    const userController = useContext(UserControllerContext);
    const preconditionController = useContext(PreconditionControllerContext);

    const isUserLoggedIn = cookieServices.getCookie('JWT');

    const routesWithOutNextPath = ['/'];

    const loginRoutes = [
      '/login',
      '/login/',
      '/reset-password',
      '/reset-password/',
      '/change-password',
      '/change-password/',
    ];
    const verifyRoutes = ['/verify', '/verify/'];
    useEffect(() => {
      setIsClient(true);
    }, []);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);

    const getPreconditions = async () => {
      if (!urlsWithoutPreconditions.includes(location.pathname)) {
        try {
          await preconditionController.fetchPreconditions(location.pathname);
        } catch (e) {
          console.error(e, 'withAuth getPreconditions');
        }
      } else {
        preconditionController.clearPreconditions();
      }
      setIsLoading(false);
    };
    useEffect(() => {
      getPreconditions();
    }, [location.pathname]);

    useEffect(() => {
      if (!!isUserLoggedIn && loginRoutes.includes(location.pathname)) {
        navigate(Routes.Home);
      } else if (
        !!isUserLoggedIn &&
        PENDING_VERIFICATION_STATUSES.includes(userController.verificationStatus) &&
        !verifyRoutes.includes(location.pathname)
      ) {
        navigate(Routes.Verify);
      } else if (!isUserLoggedIn && normalizePath(location.pathname) === normalizePath(Routes.JoinFamilyPlan)) {
        const locationHash = location.hash;
        navigate(Routes.Register + locationHash);
      } else if (!isUserLoggedIn && !loginRoutes.includes(location.pathname)) {
        const locationSearch = location.search;
        const nextPath = routesWithOutNextPath.includes(location.pathname)
          ? ''
          : `/#?next=${encodeURIComponent(location.pathname + locationSearch)}`;
        navigate(Routes.LogIn + nextPath);
      }
    }, [isUserLoggedIn, userController.verificationStatus]);

    if (!isClient || isLoading) {
      return (
        <div style={{ height: '100vh', maxWidth: '100vw', background: '#121429' }}>
          <Spinner />
        </div>
      );
    }
    return <WrappedComponent {...props} />;
  });
  return WithAuthComponent;
};

export default withAuth;
