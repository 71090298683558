import clsx from 'clsx';

import { BreadcrumbProps } from 'types/shared';

import { useMediaQuery } from 'app/hooks/useMediaQuery';

import breakpoints from 'app/theme/breakpoints.module.scss';

import styles from './breadcrumb.module.scss';

const Breadcrumb: React.FC<BreadcrumbProps> = ({ order, title, subtitle, active }) => {
  const isHideSubtitle = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

  return (
    <div data-testid="breadcrumb" className={clsx(styles.breadcrumb, active && styles['breadcrumb--active'])}>
      <span className={styles.breadcrumb__order}>{order}</span>

      <div>
        <h3 className={styles.breadcrumb__title}>{title}</h3>
        {!isHideSubtitle && <p className={styles.breadcrumb__subtitle}>{subtitle}</p>}
      </div>
    </div>
  );
};
export default Breadcrumb;
