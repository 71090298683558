import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validatePairingSchema } from 'app/validationSchemes/pairingFormSchema';
import { FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ActionForPost } from 'types/pairing';

import { translateErrors } from 'utils/translateErrors';

import { PageControllerContext, PairingControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Input from 'app/components/input/input';

import styles from './pairingForm.module.scss';

type FormValues = {
  pairingCodeLeft: string;
  pairingCodeRight: string;
};

const initialValues: FormValues = {
  pairingCodeLeft: '',
  pairingCodeRight: '',
};

interface IParingFormFields {
  name: keyof FormValues;
  isShowCheck: boolean;
}

interface IParingForm {
  paringCase: ActionForPost;
}

const paringFormFields: IParingFormFields[] = [
  {
    name: 'pairingCodeLeft',
    isShowCheck: false,
  },
  {
    name: 'pairingCodeRight',
    isShowCheck: true,
  },
];

const PairingForm: React.FC<IParingForm> = observer(({ paringCase }) => {
  const pairingController = useContext(PairingControllerContext);
  const pageController = useContext(PageControllerContext);
  const [errorResponse, setErrorResponse] = useState('');

  const pairingCodeLeftRef = useRef<HTMLInputElement>(null);
  const pairingCodeRightRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const queryParams = pageController.getQueryParams();
    const chunkSize = 4;
    if (queryParams && queryParams.pairingcode) {
      const paringCode = queryParams.pairingcode;
      const updatedInitialValues = {
        pairingCodeLeft: paringCode.slice(0, chunkSize),
        pairingCodeRight: paringCode.slice(chunkSize),
      };
      formik.setValues(updatedInitialValues);
    }
  }, []);

  const onSubmit = async (values: FormValues) => {
    try {
      await pairingController.validatePairingCode(values.pairingCodeLeft + values.pairingCodeRight, paringCase);
    } catch (e: any) {
      setErrorResponse(translateErrors(e));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validatePairingSchema,
    onSubmit: onSubmit,
    validateOnChange: true,
    validateOnMount: false,
  });
  const containsOnlyDigits = (paringCode: string) => /^[0-9]+$/.test(paringCode);
  const hasSpaces = (paringCode: string) => /\s/.test(paringCode);

  const isHasSpacesOrLettersFieldLeft = useMemo(() => {
    const paringCodeLeft = formik.values.pairingCodeLeft;

    return !containsOnlyDigits(paringCodeLeft) || hasSpaces(paringCodeLeft);
  }, [formik.values]);

  const isHasSpacesOrLettersFieldRight = useMemo(() => {
    const paringCodeRight = formik.values.pairingCodeRight;
    return !containsOnlyDigits(paringCodeRight) || hasSpaces(paringCodeRight);
  }, [formik.values]);

  const isValidForm = useMemo(() => {
    const paringCodeLeft = formik.values.pairingCodeLeft;
    const paringCodeRight = formik.values.pairingCodeRight;
    if (isHasSpacesOrLettersFieldLeft || isHasSpacesOrLettersFieldRight) {
      return false;
    }

    if (paringCodeLeft.length < 4 || paringCodeRight.length < 4) {
      return false;
    }
    return !Object.keys(formik.errors).length;
  }, [formik.values, formik.errors]);

  const isNotValidFieldLeft = useMemo(() => {
    const paringCodeLeft = formik.values.pairingCodeLeft;

    if (formik.touched.pairingCodeRight && !formik.touched.pairingCodeLeft) {
      return true;
    }

    if (formik.touched.pairingCodeLeft) {
      if (isHasSpacesOrLettersFieldLeft || paringCodeLeft.length < 4) {
        return true;
      }
    }

    return false;
  }, [formik.touched, formik.values]);

  const isNotValidFieldRight = useMemo(() => {
    const paringCodeRight = formik.values.pairingCodeRight;
    if (formik.touched.pairingCodeLeft && !formik.touched.pairingCodeRight) {
      return true;
    }

    if (formik.touched.pairingCodeRight) {
      if (isHasSpacesOrLettersFieldRight || paringCodeRight.length < 4) {
        return true;
      }
    }

    return false;
  }, [formik.touched, formik.values]);

  const checkIsNotValidRightField = useMemo(() => {
    const paringCodeRight = formik.values.pairingCodeRight;
    const paringCodeLeft = formik.values.pairingCodeLeft;

    if (
      isNotValidFieldRight &&
      paringCodeLeft.length === 4 &&
      paringCodeRight.length > 0 &&
      paringCodeRight.length <= 4
    ) {
      return true;
    } else {
      return false;
    }
  }, [isNotValidFieldRight, isNotValidFieldLeft, formik.values]);

  const checkIsNotValidLeftField = useMemo(() => {
    const paringCodeRight = formik.values.pairingCodeRight;
    const paringCodeLeft = formik.values.pairingCodeLeft;

    if (
      isNotValidFieldLeft &&
      paringCodeRight.length === 4 &&
      paringCodeLeft.length > 0 &&
      paringCodeLeft.length <= 4
    ) {
      return true;
    } else {
      return false;
    }
  }, [isNotValidFieldRight, isNotValidFieldLeft, formik.values]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: keyof FormValues) => {
    !formik.touched[`${fieldName}`] && formik.setFieldTouched(fieldName);

    formik.setFieldValue(fieldName, e.target.value);
    if (fieldName === 'pairingCodeLeft' && e.target.value.length === 4) {
      return pairingCodeRightRef.current && pairingCodeRightRef.current.focus();
    }
    if (fieldName === 'pairingCodeRight' && e.target.value.length === 0) {
      return pairingCodeLeftRef.current && pairingCodeLeftRef.current.focus();
    }
  };

  return (
    <FormikProvider value={formik}>
      <form className={styles['pairing-form']} onSubmit={formik.handleSubmit} name="pairingForm">
        <div className={`${styles['pairing-form-group']}`}>
          <div className={`${styles['pairing-form-row']} row`}>
            {paringFormFields.map((field) => (
              <div className={`${styles['pairing-form-item']} col-md-6`} key={field.name}>
                <div className="form-group-inner">
                  <Input
                    name={field.name}
                    className="form-control"
                    placeholder="X X X X"
                    type="text"
                    isValidate={false}
                    isValidateInputStroke={
                      field.name === 'pairingCodeLeft'
                        ? isNotValidFieldLeft || checkIsNotValidRightField
                        : isNotValidFieldRight || checkIsNotValidLeftField
                    }
                    onChange={(e) => {
                      handleChange(e, field.name);
                    }}
                    ref={field.name === 'pairingCodeLeft' ? pairingCodeLeftRef : pairingCodeRightRef}
                    showCheck={field.isShowCheck && isValidForm}
                    disabled={pairingController.busy}
                    maxLength={4}
                  />
                </div>
              </div>
            ))}
          </div>
          {(isNotValidFieldLeft || isNotValidFieldRight) && (
            <div className={styles['error']}>
              <FontAwesomeIcon icon="triangle-exclamation" />
              <p>The pairing code should consist of 8 digits.</p>
            </div>
          )}
        </div>
        {errorResponse && <Alert danger>{errorResponse}</Alert>}

        <div className="row">
          <div className="col-md-3">
            <Button type="submit" disabled={!isValidForm} xl primary loading={pairingController.busy}>
              Pair
            </Button>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
});
export default PairingForm;
