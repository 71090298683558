import { countryDict } from 'lists/countryDict';

import { IAllCountries } from 'types/localizations';

interface IOptions {
  label: string;
  value: string;
}

export const transformCountryToCountryAlpha = ({
  allCountries,
  countryId,
  onlyAlpha,
}: {
  allCountries: IAllCountries[];
  countryId: string;
  onlyAlpha?: boolean;
}): string => {
  const currentCountry = allCountries.find((country) => country.countryId === +countryId);

  const currentCountryCode: string = currentCountry?.code.toString() || '';
  const currentCountryAlpha = currentCountryCode in countryDict && countryDict[currentCountryCode];
  if (onlyAlpha) {
    return currentCountryAlpha || '';
  }
  return currentCountryAlpha || countryId;
};

export const transformCountriesRangeWIthAlpha = (countries: IAllCountries[]): IOptions[] => {
  return countries.map((country) => ({
    label: country.countryName,
    value: transformCountryToCountryAlpha({
      allCountries: countries,
      countryId: `${country.countryId}`,
    }),
  }));
};
