import * as yup from 'yup';

import { passwordRegEx } from 'utils/regex';

export const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Required')
    .matches(
      passwordRegEx,
      'Passwords must contain at least 8 characters, have at least 1 letter (uppercase or lowercase a-z) and at least one number or special character',
    ),
  confirmPassword: yup
    .string()
    .required('Please confirm your password')
    .test('password-match', 'Passwords do not match.', function (value) {
      const password = this.parent.password;
      return !password || value === password;
    }),
});
