import ChangePasswordForm from 'app/widgets/changePasswordForm/changePasswordForm';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Routes } from 'app/global/routes';

import { LoginControllerContext } from 'app/context/storesContext';

import Spinner from 'app/components/spinner/spinner';

const ChangePassword = () => {
  const [isCheckedLoggedIn, setIsCheckedLoggedIn] = useState(true);
  const loginController = useContext(LoginControllerContext);
  const navigate = useNavigate();

  useEffect(() => {
    const checkIsAutoLogOut = async () => {
      try {
        if (await loginController.checkIsLoggedIn()) {
          navigate(Routes.Home);
        }
      } finally {
        setIsCheckedLoggedIn(false);
      }
    };
    checkIsAutoLogOut();
  }, []);
  if (isCheckedLoggedIn) {
    return <Spinner />;
  }

  return (
    <div className="container">
      <ChangePasswordForm />
    </div>
  );
};
export default ChangePassword;
