import clsx from 'clsx';
import { useEffect } from 'react';
import { useDropdownToggle } from 'react-overlays';

import styles from './dropdownToggle.module.scss';

type DropdownToggleProps = React.PropsWithChildren<{
  className?: string;
  id: string;
}>;

const DropdownToggle: React.FC<DropdownToggleProps> = ({ id, className, children }) => {
  const [props, { toggle, show }] = useDropdownToggle();
  const switchDropdown = () => toggle(!show);
  useEffect(() => {
    const closeMenu = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains('link-menu-item-toggle')) {
        toggle(false);
      }
    };
    document.addEventListener('click', closeMenu);

    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, []);

  return (
    <button
      id={id}
      type="button"
      data-testid="toggle-button"
      className={clsx(styles.toggle, className)}
      {...props}
      onClick={switchDropdown}
    >
      {children}
    </button>
  );
};

export default DropdownToggle;
