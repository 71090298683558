import clsx from 'clsx';
import { useDropdownMenu } from 'react-overlays';
import { UseDropdownMenuOptions } from 'react-overlays/DropdownMenu';

import styles from './dropdownMenu.module.scss';

type DropdownMenuProps = React.PropsWithChildren<{
  className?: string;
  options?: UseDropdownMenuOptions;
}>;

const DropdownMenu: React.FC<DropdownMenuProps> = ({ children, className, options }) => {
  const [props] = useDropdownMenu(options);

  return (
    <div role="menu" className={clsx(styles.menu, className)} {...props}>
      {children}
    </div>
  );
};

export default DropdownMenu;
