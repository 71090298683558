import _ from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import AnalyticService from 'services/analyticsService/analyticsService';
import CookieService from 'services/cookieService/cookieService';
import FamilyMembersService from 'services/familyMembersService/familyMembersService';
import PairingService from 'services/pairingService/pairingService';
import UserService from 'services/userService/userService';

import { Errors } from 'types/errors';
import { FilterSso, SsoProviders, SsoProvidersResponse, determineDisplayableProviders } from 'types/sso';

import { PROMO_CODE_COOKIE, REACT_APP_FRONT_END, SsoActionStatuses, platformURL } from 'app/global/constants';
import LoginController from 'app/global/loginController/loginController';
import PageController from 'app/global/pageController/pageController';
import { Routes } from 'app/global/routes';

interface IQueryParam {
  [key: string]: string;
}

export default class SsoService {
  public constructor(
    private readonly _pageController: PageController,
    private readonly _userService: UserService,
    private readonly _loginController: LoginController,
    private readonly _cookieService: CookieService,
    private readonly _pairingService: PairingService,
    private readonly _familyMemberService: FamilyMembersService,
    private readonly _analyticService: AnalyticService,
  ) {
    makeObservable(this);
  }
  @observable
  private _ssoActionStatus: SsoActionStatuses | null = null;

  @action
  public setSsoActionStatus(status: SsoActionStatuses | null) {
    this._ssoActionStatus = status;
  }

  @computed
  public get ssoActionStatus() {
    return this._ssoActionStatus;
  }

  public returnRedirect({ page, inviteToken, error }: { page: string; inviteToken?: string; error?: any }) {
    let querystring = '';
    const queryElements = [];
    if (error) {
      queryElements.push('error=' + error);
    }
    if (inviteToken) {
      queryElements.push('invitetoken=' + inviteToken);
    }
    if (queryElements.length > 0) {
      querystring = '#?';
      querystring += queryElements.join('&');
    }
    this._pageController.setLocation(page + querystring, true);
  }

  public redirectOrReject(queryString: IQueryParam) {
    if (queryString.error === Errors.SSO_AUTHENTICATION_CANCELED) {
      this.returnRedirect({ page: queryString.frompage, inviteToken: queryString.invite_token });
    } else {
      if (queryString.user_was_logged_in === 'false') {
        this.returnRedirect({
          page: '/register/',
          inviteToken: queryString.invite_token || '',
          error: queryString.error.replace(/\+/g, '%20'),
        });
      } else {
        throw new Error(queryString.error);
      }
    }
  }

  public loginWithSSO({ ssoUrl, slug }: { ssoUrl: string; slug: string }) {
    const nextPathHash = this._pageController.getHash().split('=');
    const redirectUrlInvite =
      this._loginController.redirectUrl && encodeURIComponent(this._loginController.redirectUrl);
    const nextPath = nextPathHash?.includes('#?next')
      ? decodeURIComponent(nextPathHash[1])
      : this._pageController.getPath();
    const inviteToken = this._loginController.redirectUrl && this._loginController.redirectUrl.split('invitetoken=')[1];
    const redirectURL =
      REACT_APP_FRONT_END +
      '/sso/?frompage=' +
      (redirectUrlInvite || nextPath) +
      (inviteToken ? encodeURIComponent('&invite_token=' + inviteToken) : '');

    const loginURL =
      platformURL + '/login/' + slug + '?transfer-session-for=website' + '&auth_action=login' + '&next=' + redirectURL;
    this._pageController.setLocation(loginURL, true);
    this.setSsoActionStatus(SsoActionStatuses.SSO_ACTION_COMPLETE);
  }

  public async processLoginWithSSO(queryParams: IQueryParam): Promise<void> {
    const prohibitedRedirectURLs = [
      '/register/',
      '/login/',
      '/register',
      '/login',
      '/you-have-been-logged-out',
      '/you-have-been-logged-out/',
    ];

    let redirectURL = '/';
    if (!queryParams.error) {
      try {
        await this._userService.fetchUser();

        const isInTVFlow = !!this._pairingService.getPairingCodeFromCookie();
        const userSsoProviders = this._userService.user.sso_providers;
        this._analyticService.handleLogin({
          ssoProviders: userSsoProviders,
          isInTVFlow: isInTVFlow,
        });
        if (isInTVFlow) {
          await this._pairingService.tryLinkPairingCode('LOG_IN');
          return;
        }

        if (queryParams.invite_token) {
          const inviteToken = queryParams.invite_token;
          const nextUrl = this._familyMemberService.getJoinFamilyURL(inviteToken);
          this._pageController.setLocation(nextUrl || redirectURL);
          return;
        }

        if (queryParams.frompage && !prohibitedRedirectURLs.includes(queryParams.frompage)) {
          redirectURL = queryParams.frompage;
        }
        this._pageController.setLocation(redirectURL);
      } catch (e: any) {
        if (e.errorDetails?.detail === Errors.USER_IS_UNDER_EIGHTEEN_AND_NO_MEMBER) {
          this._pageController.setLocation(Routes.UnderAge);
          return;
        }
        throw e;
      }
    } else {
      this.redirectOrReject(queryParams);
    }
  }

  public async processRegistrationWithSSO(queryParam: IQueryParam): Promise<void> {
    if (!queryParam.error) {
      try {
        const token = this._cookieService.getCookie('JWT') as string;
        const isInTVFlow = !!this._pairingService.getPairingCodeFromCookie();

        if (!token) {
          throw new Error('LOGIN_FAILED');
        }
        await this._userService.fetchUser();

        const user = this._userService.user;
        const withInviteToken = queryParam.invite_token;

        if (user) {
          const ssoTypes = user.sso_providers
            .map((provider) => provider.provider)
            .filter((value, index, self) => self.indexOf(value) === index);
          this._analyticService.handleRegistration({ ssoTypes: ssoTypes, isInTVFlow: isInTVFlow, user: user });
          if (queryParam.promo_code) {
            const encodedRefParam = decodeURIComponent(atob(`${queryParam.promo_code}`));
            this._cookieService.setCookie(PROMO_CODE_COOKIE, queryParam.promo_code, { path: '/' });
            await this._loginController.handleLogin({
              token: token,
              isRefresh: false,
              promoCodeFromUrl: encodedRefParam,
            });
            return;
          }
          this._loginController.setActionForPost('SIGN_UP');
          if (withInviteToken) {
            this._loginController.handleLogin({ token: token, registerWithInviteToken: !!withInviteToken });
            return;
          }
          await this._loginController.handleLogin({ token: token });

          return this._pageController.setLocation(Routes.RegisterSelectPlan);
        }
      } catch (e: any) {
        throw new Error(e);
      }
    } else {
      this._analyticService.handleRegistrationError({ ssoTypes: [] });
      this.redirectOrReject(queryParam);
    }
  }

  public async processReauthWithSSO(queryParam: IQueryParam) {
    this.setSsoActionStatus(SsoActionStatuses.SSO_ACTION_REAUTH_SUCCESS);
    if (window.opener) {
      window.opener.postMessage('closeSocialWindow', '*');
    }
  }

  private async _processInNewWindowAsPromise(url: string) {
    try {
      const height = 780;
      const width = 1100;
      const y = window.top && window.top.outerHeight / 2 + window.top.screenY - height / 2;
      const x = window.top && window.top.outerWidth / 2 + window.top.screenX - width / 2;
      const socialWindow = window.open(
        url,
        'socialWindow',
        'top=' + y + ', left=' + x + ', width=' + width + ', height=' + height,
      );
      const timer = setInterval(() => {
        if (socialWindow && socialWindow.closed) {
          clearInterval(timer);
          this.setSsoActionStatus(SsoActionStatuses.SSO_ACTION_STOPPED);
        }
      }, 500);
    } catch (e) {
      this.setSsoActionStatus(SsoActionStatuses.SSO_ACTION_STOPPED);
    }
  }

  public async reauthWithSSO(slug: string) {
    const redirectURL = REACT_APP_FRONT_END + '/sso/?frompage=' + this._pageController.getPath() + '&action=reauth';

    const reauthUrl =
      platformURL +
      '/login/' +
      slug +
      '?auth_type=reauthenticate&auth_action=reauth&next=' +
      encodeURIComponent(redirectURL);
    try {
      await this._processInNewWindowAsPromise(reauthUrl);
    } catch (e) {
      console.error('CATCH reauthWithSSO');
    }
  }

  public registerWithSSO(slug: string, inviteToken?: string) {
    const promoCode = this._cookieService.getCookie(PROMO_CODE_COOKIE);
    let queryString = '';
    if (promoCode) {
      queryString += '&promo_code=' + promoCode;
    }
    if (inviteToken) {
      queryString += '&invite_token=' + inviteToken;
    }
    const redirectURL =
      REACT_APP_FRONT_END +
      '/sso/?frompage=' +
      this._pageController.getPath() +
      encodeURIComponent('&sso_type=' + slug + queryString);

    const registerURL =
      platformURL +
      '/login/' +
      slug +
      '?transfer-session-for=website' +
      '&auth_action=signup' +
      queryString +
      (slug === 'facebook' ? '&auth_type=https,rerequest' : '') +
      '&next=' +
      redirectURL;
    this._pageController.setLocation(registerURL, true);
    this.setSsoActionStatus(SsoActionStatuses.SSO_ACTION_COMPLETE);
  }

  public determineDisplayableProviders(providers: SsoProvidersResponse[]): determineDisplayableProviders {
    const ssoProvidersDisplayed = providers.filter((sso) => sso.display_name);
    const ssoDeprecation = providers.filter((sso) => sso.deprecation_date);
    return {
      ssoProvidersDisplayed,
      ssoDeprecation,
    };
  }

  public transformSsoProviders(providers: SsoProvidersResponse[]): SsoProviders[] {
    return providers.map((sso) => ({
      color: sso.color,
      deprecationDate: sso.deprecation_date,
      disabled: sso.disabled,
      displayName: sso.display_name,
      id: sso.id,
      logo: sso.logo.download_url,
      slug: sso.slug,
      ssoUrl: sso.sso_url,
      url: sso.url,
    }));
  }

  public sortByLastUsedSsoProviders(ssoProviders: SsoProvidersResponse[]): SsoProvidersResponse[] {
    const lastSsoProviderId = this._cookieService.getCookie('previouslyUsedSso');
    if (!lastSsoProviderId) return ssoProviders;
    const lastUsedSso = ssoProviders.find((sso) => sso.id === +lastSsoProviderId);
    if (lastUsedSso) {
      ssoProviders.splice(
        ssoProviders.findIndex((item) => item.id === lastUsedSso?.id),
        1,
      );
      ssoProviders.unshift(lastUsedSso);
    }
    return ssoProviders;
  }

  public filterSsoButtonsByParams(ssoProviders: SsoProvidersResponse[], filterType: FilterSso): SsoProvidersResponse[] {
    if (filterType === 'userProviders') {
      const userProviders = this._userService.userSsoProviders;
      const uniqueUserProviders = Array.from(new Set(userProviders));

      const filteredSso = ssoProviders.filter((ssoItem) => uniqueUserProviders.includes(ssoItem.slug));
      return this.sortByLastUsedSsoProviders(filteredSso);
    } else if (filterType === 'onlyActive') {
      return ssoProviders.filter((provider) => !provider.deprecation_date);
    }
    return ssoProviders;
  }
}
