import { action, computed, makeObservable, observable } from 'mobx';
import CookieService from 'services/cookieService/cookieService';

import LoginController from 'app/global/loginController/loginController';

import { isNullOrUndefined } from '../../utils/utils';
import AuthorizationStore, { TokenAuthResponse } from '../stores/authorizationStore/authorizationStore';

export type RefreshResultCallback = (result: boolean) => void;

export default class SecurityToken {
  @observable
  private _authToken?: TokenAuthResponse | null | undefined;

  public constructor(
    private readonly _cookieService: CookieService,
    private authorizationStore?: AuthorizationStore | null,
    private loginController?: LoginController | null,
  ) {
    makeObservable(this);
  }

  public init(): boolean {
    this.authorizationStore = null;
    this.loginController = null;

    return true;
  }

  public setAuthorizationStore(authorizationStore: AuthorizationStore) {
    this.authorizationStore = authorizationStore;
    const token = (this._cookieService.getCookie('JWT') || authorizationStore.token || undefined) as string | undefined;
    this.setValue(new TokenAuthResponse(token));
  }

  public setLoginController(loginController: LoginController) {
    this.loginController = loginController;
  }

  @action
  public setValue(authResponse: TokenAuthResponse): void {
    this._authToken = authResponse;
  }

  @computed
  public get value(): TokenAuthResponse | null | undefined {
    return this._authToken;
  }

  @computed
  public get isSet(): boolean {
    return !isNullOrUndefined(this._authToken);
  }

  @computed
  public get expiresAt(): Date | undefined {
    return this._authToken?.expiresAt;
  }

  @computed
  public get expiresAtUnixTime(): number | undefined {
    return this._authToken?.expiresAt ? this._authToken?.expiresAt.getTime() / 1000 : undefined;
  }

  @computed
  public get userId(): number | undefined {
    return this._authToken?.userId;
  }

  @computed
  public get encodedValue(): string | undefined {
    return this._authToken?.token;
  }

  @computed
  public get httpToken(): string | undefined {
    return this._authToken?.getHttpToken?.();
  }

  public async refresh(isRefresh?: boolean): Promise<void> {
    if (isNullOrUndefined(this.loginController)) return Promise.resolve();
    if (isNullOrUndefined(this.authorizationStore)) return Promise.resolve();
    await this.loginController?.loginWithJWT(isRefresh);
    await this.setValue(new TokenAuthResponse(this.authorizationStore?.token));
  }
}
