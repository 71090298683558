import withAuth from 'app/hoc/withAuth';
import NewsLetterModal from 'app/modals/newsLetterModal/newsLetterModal';
import CountryUnSupported from 'app/widgets/countryUnSupported/countryUnSupported';
import SelectCountryForm from 'app/widgets/selectCountryForm/selectCountryForm';
import SelectPlanForm from 'app/widgets/selectPlanForm/selectPlanForm';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';

import {
  NewsLetterControllerContext,
  SelectPlanControllerContext,
  UtomikSupportedInCountryControllerContext,
} from 'app/context/storesContext';

import Modal from 'app/components/modal/modal';
import Spinner from 'app/components/spinner/spinner';

import styles from './selectPlan.module.scss';

const SelectPlanPage = observer(() => {
  const selectPlanController = useContext(SelectPlanControllerContext);
  const utomikSupportedInCountryController = useContext(UtomikSupportedInCountryControllerContext);
  const newsLetterController = useContext(NewsLetterControllerContext);
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { showNewsLetter, checkIsShowNewsLetter, closeNewsLetterModal } = newsLetterController;
  const toggleModal = () => {
    setModalIsOpen((prev) => !prev);
  };

  useEffect(() => {
    checkIsShowNewsLetter();
  }, [selectPlanController.user.newsletter_signup]);

  const initPage = async () => {
    try {
      await utomikSupportedInCountryController.checkIsUtomikSupportedInCountry();
      await selectPlanController.initPage();
    } catch (e) {
      console.error(e, 'SUBSCRIPTION PAGE init');
    } finally {
      setIsLoading(false);
      selectPlanController.setIsLoading(true);
    }
  };

  useEffect(() => {
    return () => selectPlanController.clearState();
  }, []);

  useEffect(() => {
    initPage();
  }, []);

  const renderSupportedOrNotSupportedCountry = () => {
    const { name, isSetAsUserCountry = false } = selectPlanController.userCountry;

    return <CountryUnSupported countryName={name} isSetAsUserCountry={isSetAsUserCountry} />;
  };
  if (isLoading) {
    return <Spinner>Fetching details</Spinner>;
  }
  if (!selectPlanController.utomikIsLiveInUsersCountry) {
    return renderSupportedOrNotSupportedCountry();
  }
  return (
    <div id="page-selectplan" className={styles['select-page']}>
      <div className={`${styles['page-selectplan-wrapper']}  relative`}>
        <section className="container">
          <h1>Choose your plan</h1>
          <SelectPlanForm toggleModal={toggleModal} />
        </section>
      </div>
      <Modal show={modalIsOpen} onHide={toggleModal} title="Where do you live?">
        <div className={styles['page-selectplan-modal']}>
          <SelectCountryForm
            subtitle="When you change your country of residence, any prices on the page will update to match your country."
            isModal={true}
            textButtonConfirm="Change country"
            textButtonCancel="Cancel"
            handleCancel={toggleModal}
            callbackAfterUpdate={() => selectPlanController.initForm()}
          />
        </div>
      </Modal>
      <Modal onHide={closeNewsLetterModal} show={showNewsLetter} title="Don't miss a thing!">
        <NewsLetterModal
          confirm={newsLetterController.confirmAndCallback}
          reject={newsLetterController.rejectAndCallback}
          isLoading={newsLetterController.isFetchingNewsLetter}
        />
      </Modal>
    </div>
  );
});
export default withAuth(SelectPlanPage);
