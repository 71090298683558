import { action, computed, makeObservable, observable } from 'mobx';
import ServiceSettingService from 'services/serviceSettingService/serviceSettingService';

import { ICountriesResponse, ICountry, ICountryByIpResponse } from 'types/localizations';

import LocalizationStore from 'dataStore/stores/localizationStore/localizationStore';
import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import { CLOUD_COUNTRIES_SERVICE_SETTING } from 'app/global/constants';

export default class LocalizationService {
  public constructor(
    private readonly _httpTransport: AxiosTransport,
    private readonly _serviceSettingsService: ServiceSettingService,
    private readonly _localizationStore: LocalizationStore,
  ) {
    makeObservable(this);
  }

  @observable
  private _allCountries: ICountriesResponse[] = [];

  @action
  public setAllCountries(allCountries: ICountriesResponse[]) {
    this._allCountries = allCountries;
  }

  @computed
  public get allCountries() {
    return this._allCountries;
  }

  public async fetchCountries(): Promise<ICountriesResponse[]> {
    if (this._allCountries.length) {
      return this._allCountries;
    }
    const countriesResponse = await this._httpTransport.get<ICountriesResponse[]>('v2/countries');
    if (!countriesResponse?.data) {
      this.setAllCountries([]);
      return [];
    }
    this.setAllCountries(countriesResponse.data);
    return countriesResponse.data;
  }

  public async getCountries(): Promise<ICountry[]> {
    let countries: ICountry[] = [];
    const countriesResponse = await this.fetchCountries();
    const countriesWithCloudSupport = await this._serviceSettingsService.getServiceSetting(
      CLOUD_COUNTRIES_SERVICE_SETTING,
    );
    if (countriesResponse && countriesWithCloudSupport && Array.isArray(countriesWithCloudSupport)) {
      countries = countriesResponse.map((country) => {
        const countryWithCloudSupport: ICountry = {
          ...country,
          isCloudSupported: countriesWithCloudSupport.indexOf(country.code) > -1,
        };

        return countryWithCloudSupport;
      });
    }
    this._localizationStore.setAllCountries(countries);

    return countries;
  }

  public async getCountryByIP(): Promise<ICountry> {
    const countryByIp = await this._httpTransport.get<ICountryByIpResponse>('v1/actions/do_check_country_by_ip');
    const countries = await this.getCountries();
    const numericCountry: number = countryByIp?.data?.country_numeric ?? 840;
    const foundCountry = countries.find((country) => country.code === numericCountry);
    if (foundCountry !== undefined) {
      return foundCountry;
    } else {
      throw new Error('COULD_NOT_FIND_COUNTRY');
    }
  }

  public async getCountry(id: number): Promise<ICountry> {
    const countryResponse = await this._httpTransport.get<ICountriesResponse>('v2/countries/' + id);
    const countriesWithCloudSupport = await this._serviceSettingsService.getServiceSetting(
      CLOUD_COUNTRIES_SERVICE_SETTING,
    );
    const country: ICountry = countryResponse?.data as ICountry;
    const isCloudSupported = countriesWithCloudSupport?.includes(country.code) ?? false;

    return {
      ...(country || {}),
      isCloudSupported,
    };
  }
}
