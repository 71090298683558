import { RefObject } from 'react';

export function isNullOrUndefined<T>(val: T | undefined | null): boolean {
  return val === undefined || val === null;
}

/**
 * Returns the difference of two dates in hours.
 * @param a Date A
 * @param b Date B
 */
export const differenceInHours = (a: Date, b: Date): number => {
  return (a.getTime() - b.getTime()) / 1000 / 60 / 60;
};

/**
 * Converts the given HTTP status code to a HTTP status code range.
 * E.g. 404 becomes 400 etc.
 * @param statusCode The HTTP status code to convert to a range.
 */
export const convertToHTTPRange = (statusCode: number): number => {
  if (!isNullOrUndefined(statusCode)) {
    return statusCode;
  }

  // Get the error range by taking the first digit and multiplying it by 100.
  return parseInt(statusCode.toString().substring(0, 1)) * 100;
};

/**
 * Returns the number of minutes from a throttled request error message.
 * If the given error message is not a throttled request error, a default of 5 minutes is returned.
 * @param errorMessage The error message that may contain a throttled request error message.
 */
export const parseMinutesFromThrottledError = (errorMessage: string): number => {
  let minutes = 5;

  if (errorMessage?.startsWith('Request was throttled')) {
    // Parse the message. Currently no better way with how the endpoint is handled.
    const regEx = /Expected available in ([0-9]+)/g;
    const match = regEx.exec(errorMessage.toString());

    if (match && match.length > 0) minutes = (Math.floor(parseInt(match[1]) / 300) + 1) * 5;
  }

  return minutes;
};

/**
 * Repeats the function call n-times by interval
 * @param { Function } callback Callback function to repeat call
 * @param { number } count number of callback calls
 * @param { number } intervalMs interval between function calls in ms
 */
export const repeatFnCall = (callback: () => unknown, count = 1, intervalMs = 10) => {
  let counter = 1;

  const intId: ReturnType<typeof setInterval> = setInterval(() => {
    if (counter > count) return clearInterval(intId);

    callback();

    counter++;
  }, intervalMs);

  return intId;
};

/**
 * Stops video buffering
 * @param { RefObject } videoRef HTMLVideo element reference
 */

export const unloadVideo = (videoRef: RefObject<HTMLVideoElement>) => {
  if (videoRef.current) {
    videoRef.current.pause();
    videoRef.current.removeAttribute('src');
    videoRef.current.innerHTML = '';
    videoRef.current.load();
  }
};

/**
 * Converts rem to px based on the current body font size
 * @param { number } rems Value in rem
 */

export const sizeRemToPx = (rems: number) => {
  return Number((parseFloat(getComputedStyle(document.body).fontSize) * rems).toFixed(0));
};

const addOrRemovePrefixUrl = (urlWithOutPlatform: string) => {
  if (!urlWithOutPlatform.startsWith('/v2/')) {
    urlWithOutPlatform = '/v2/' + urlWithOutPlatform;
  }

  return urlWithOutPlatform;
};

export const removePlatformURLFromURL = (inputString: string, substringToRemove: string) => {
  if (inputString.includes(substringToRemove)) {
    const urlWithOutPlatform = inputString.replace(substringToRemove, '');
    return addOrRemovePrefixUrl(urlWithOutPlatform);
  } else {
    return addOrRemovePrefixUrl(inputString);
  }
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const calculateCookieExpiration = () => {
  const date = new Date();
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
  return date;
};
