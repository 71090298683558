import { action, computed, makeObservable, observable } from 'mobx';

import { ICountry } from 'types/localizations';
import { User } from 'types/users';

const initialUser: User = {
  id: null,
  url: null,
  email: null,
  username: null,
  gender: null,
  first_name: null,
  last_name: null,
  birthdate: null,
  language: null,
  country: null,
  avatar_url: null,
  is_banned: false,
  is_staff: false,
  newsletter_signup: null,
  playtime_override: null,
  groups: [],
  is_partner_admin: false,
  is_superuser: false,
  has_usable_password: false,
  account: null,
  deletion_date: null,
  userprofile: null,
  sso_providers: [],
  promo_code: null,
  invite_token: null,
  verification_code_expires: null,
  verification_status: null,
  pairing_status: null,
};

export default class UserStore {
  public constructor() {
    makeObservable(this);
  }

  @observable
  private _user: User = initialUser;

  @observable
  private _isInSupportedCloudCountry = false;

  @observable
  private _isApiHasCloudSupport = false;

  @observable
  private _isCloudRegistrationAllowed = false;

  @observable
  private _userCountry = {} as ICountry;

  @observable
  private _utomikIsLiveInUsersCountry = false;

  @observable
  private _userCountryId: number | string | null = null;

  @observable
  private _isUserCanManageDeletion = false;

  @action
  public setIsUserCanManageDeletion(isUserCanManageDeletion: boolean) {
    this._isUserCanManageDeletion = isUserCanManageDeletion;
  }

  @computed
  public get isUserCanManageDeletion() {
    return this._isUserCanManageDeletion;
  }

  @action
  public setIsInSupportedCloudCountry(isInSupportedCloudCountry: boolean) {
    this._isInSupportedCloudCountry = isInSupportedCloudCountry;
  }

  @computed get isInSupportedCloudCountry(): boolean {
    return this._isInSupportedCloudCountry;
  }

  @action
  public setIsCloudRegistrationAllowed(isCloudRegistrationAllowed: boolean) {
    this._isCloudRegistrationAllowed = isCloudRegistrationAllowed;
  }

  @computed get isCloudRegistrationAllowed(): boolean {
    return this._isCloudRegistrationAllowed;
  }

  @action
  public setIsApiHasCloudSupport(isApiHasCloudSupport: boolean) {
    this._isApiHasCloudSupport = isApiHasCloudSupport;
  }

  @computed get isApiHasCloudSupport(): boolean {
    return this._isApiHasCloudSupport;
  }

  @action
  public setUserCountry(country: ICountry) {
    this._userCountry = country;
  }

  @computed get userCountry() {
    return this._userCountry;
  }

  @action
  public setUser(user: User): void {
    this._user = user;
  }

  @action
  public setUserAvatar(avatar: string): void {
    if (this._user) {
      this._user = {
        ...this._user,
        avatar_url: avatar,
      };
    }
  }

  @action
  public resetUser() {
    this._user = initialUser;
  }

  @computed
  public get user(): User {
    return this._user;
  }

  @action
  public setUtomikIsLiveInUsersCountry(utomikIsLiveInUsersCountry: boolean) {
    this._utomikIsLiveInUsersCountry = utomikIsLiveInUsersCountry;
  }

  @computed
  public get utomikIsLiveInUsersCountry() {
    return this._utomikIsLiveInUsersCountry;
  }

  @action
  public setUserCountryId(userCountryId: number | string | null) {
    this._userCountryId = userCountryId;
  }

  @computed
  public get userCountryId(): number | string | null {
    return this._userCountryId;
  }

  @computed
  public get pairingStatus() {
    return this._user?.pairing_status;
  }

  @computed
  public get verificationStatus(): string {
    return this._user?.verification_status || '';
  }

  @computed
  public get userHasUsablePassword(): boolean {
    return this._user.has_usable_password;
  }

  @computed get userHasEnoughCredentials(): boolean {
    return !!this._user.sso_providers.length;
  }

  @computed get isTheOwner(): boolean {
    return this._user.account?.role === 'Owner';
  }
}
