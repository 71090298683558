import { Area } from 'react-easy-crop';

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
): Promise<{ file: Blob; url: string } | null> {
  const image = (await createImage(imageSrc)) as HTMLImageElement;

  const cropWidth = pixelCrop.width;
  const cropHeight = pixelCrop.height;

  const x = pixelCrop.x;
  const y = pixelCrop.y;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const targetWidth = 300;
  const targetHeight = 300;

  if (image.width > targetWidth || image.height > targetHeight) {
    canvas.width = targetWidth;
    canvas.height = targetHeight;

    ctx.translate(targetWidth / 2, targetHeight / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-targetWidth / 2, -targetHeight / 2);

    ctx.drawImage(image, x, y, cropWidth, cropHeight, 0, 0, targetWidth, targetHeight);
  } else {
    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, x, y, cropWidth, cropHeight, 0, 0, image.width, image.height);
  }

  const quality = 1;

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (file) => {
        if (file) {
          return resolve({
            file: file,
            url: URL.createObjectURL(file),
          });
        }
      },
      'image/jpeg',
      quality,
    );
  });
}
