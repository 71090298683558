import clsx from 'clsx';
import React from 'react';

import styles from './radioSelect.module.scss';

interface IRadioSelect {
  isChecked?: boolean;
}

export const RadioSelect: React.FC<IRadioSelect> = ({ isChecked = false }) => {
  return (
    <div className={clsx(isChecked && styles['radio-select-outside'], styles['radio-select'])}>
      {isChecked && <div className={styles['radio-select-inside']} />}
    </div>
  );
};
