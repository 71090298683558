import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validateLoginSchema } from 'app/validationSchemes/loginSchema';
import SsoButtons from 'app/widgets/ssoButtons/ssoButtons';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Errors } from 'types/errors';
import { NavigationLink } from 'types/global';

import { throttleRetryDuration } from 'utils/durationFilters';
import { translateErrors } from 'utils/translateErrors';

import { Routes } from 'app/global/routes';

import { LoginControllerContext, PairingControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Input from 'app/components/input/input';

import './loginForm.scss';

type LogInFormProps = {
  popup?: boolean;
  closeModal?: () => void;
};

const AuthLink: React.FC<NavigationLink> = ({ label, ...props }) => (
  <li>
    <FontAwesomeIcon icon="angle-right" />
    <Link className="login-form__link" {...props}>
      {label}
    </Link>
  </li>
);

const LogInForm: React.FC<LogInFormProps> = observer(({ popup = false, closeModal }) => {
  const loginController = useContext(LoginControllerContext);
  const pairingController = useContext(PairingControllerContext);

  const [isShowRegisterFlow, setIsShowRegisterFlow] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: '',
    password: '',
  };

  useEffect(() => {
    return () => closeModal && closeModal();
  }, []);

  useEffect(() => {
    setIsShowRegisterFlow(pairingController.getIsTvPlatform() || loginController.checkIsFromRedeemPage());
  }, []);

  const onSubmit = async ({ email, password }: typeof initialValues) => {
    try {
      setIsLoading(true);
      await loginController.loginWithCredentials({ email, password, actionForPost: 'LOG_IN' });
      closeModal && closeModal();
    } catch (e: any) {
      if (e.errorDetails?.error === Errors.USER_IS_BANNED) {
        const errorMessage = `Can not log you in: you have been banned. Reason: ${(
          e.errorDetails?.reason as string
        ).replace(
          /Removed from family account [0-9]*, /,
          'you were removed from the Utomik family account you were a part of, and ',
        )}`;
        setErrorMessage(errorMessage);
        return;
      } else if (e.errorDetails?.detail && e.errorDetails?.detail.indexOf('throttled') !== -1) {
        const errorMessage = `Due to too many incorrect sign-in attempts, you have been temporarily blocked. Please try again in ${throttleRetryDuration(
          e.errorDetails?.detail as string,
        )}.`;

        setErrorMessage(errorMessage);
        return;
      }
      setErrorMessage(translateErrors(e));
    } finally {
      setIsLoading(false);
    }
  };

  const listAuthLinks: NavigationLink[] = [
    {
      label: "I don't have an account",
      title: 'Register for a Utomik account',
      to: Routes.Register,
    },
    {
      label: 'I forgot my password',
      title: 'Request a new password',
      to: Routes.ResetPassword,
    },
  ];
  const authLinks: NavigationLink[] = isShowRegisterFlow ? [listAuthLinks[1]] : listAuthLinks;

  const isDisabled = (errors: any, values: any) =>
    !!(errors.email || errors.password || !values.email || !values.password);

  const AuthLinks: React.FC = () => (
    <ul className="login-form__links">
      {authLinks.map((props) => (
        <AuthLink key={props.label} {...props} />
      ))}
    </ul>
  );

  const PopupCreateAccountSection: React.FC = () =>
    !isShowRegisterFlow ? (
      <>
        <Link to={Routes.Register}>
          <Button
            className={clsx('login-form__form-button', 'login-form__form-button--popup')}
            title="Register for a Utomik account"
          >
            I don&apos;t have an account
          </Button>
        </Link>
      </>
    ) : (
      <></>
    );
  const PopupForgotPasswordSection: React.FC = () => (
    <>
      <hr className="login-form__popup-divider" />

      <Link className="login-form__link small" title="Request a new password" to={Routes.ResetPassword}>
        I forgot my password
      </Link>
    </>
  );
  return (
    <div className="login-form">
      <Formik initialValues={initialValues} validationSchema={validateLoginSchema} onSubmit={onSubmit}>
        {({ errors, values, handleChange }) => (
          <Form className={clsx('login-form__form', popup && 'login-form__form--popup')}>
            <div className={clsx('login-form__credentials-form', popup && 'login-form__credentials-form--popup')}>
              <Input
                name="email"
                title="Please fill in this field."
                placeholder="Email"
                isValidate={false}
                isValidateInputStroke={!!errorMessage.length}
                onChange={(e) => {
                  handleChange(e);
                  setErrorMessage('');
                }}
                disabled={isLoading}
              />
              <Input
                name="password"
                type="password"
                title="Please fill in this field."
                placeholder="Password"
                onChange={(e) => {
                  handleChange(e);
                  setErrorMessage('');
                }}
                isValidate={false}
                isValidateInputStroke={!!errorMessage.length}
                disabled={isLoading}
              />
              {errorMessage && (
                <Alert danger>
                  <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
                </Alert>
              )}
              <Button
                type="submit"
                className={clsx(
                  'login-form__submit-button',
                  'login-form__form-button',
                  popup && 'login-form__form-button--popup',
                )}
                primary={popup}
                disabled={isDisabled(errors, values)}
                loading={isLoading}
              >
                Log in
              </Button>

              {popup ? <PopupCreateAccountSection /> : <AuthLinks />}
            </div>

            <hr className={clsx('login-form__divider', popup && 'login-form__divider--popup')} />

            <div>
              <SsoButtons action="Log in" />
            </div>

            {popup && <PopupForgotPasswordSection />}
          </Form>
        )}
      </Formik>
    </div>
  );
});

export default LogInForm;
