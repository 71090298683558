import * as yup from 'yup';

import { emailRegEx, usernameRegEx } from 'utils/regex';

export const validateProfileSchema = yup.object().shape({
  userName: yup
    .string()
    .required('A username can contain letters (a-Z) and numbers (0-9), and must be between 3-32 characters long.')
    .matches(
      usernameRegEx,
      'A username can contain letters (a-Z) and numbers (0-9), and must be between 3-32 characters long.',
    ),
  email: yup
    .string()
    .required('Please enter a valid email address.')
    .matches(emailRegEx, 'Please enter a valid email address.'),
});
