import FooterIconLink, { FooterIconLinkProps } from './footerIconLink';

import './footerIconLinks.scss';

export type FooterIconLinksProps = {
  title: string;
  links: FooterIconLinkProps[];
};

const FooterIconLinks: React.FC<FooterIconLinksProps> = ({ title, links }) => (
  <div className="footer-icon-links">
    <h3 className="footer-icon-links__title">{title}</h3>

    <ul className="footer-icon-links__list">
      {links.map((props) => (
        <FooterIconLink key={`${props.to}-${props.label}`} {...props} />
      ))}
    </ul>
  </div>
);

export default FooterIconLinks;
