import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Messages } from 'types/messages';

import { PageControllerContext, SsoControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Spinner from 'app/components/spinner/spinner';

const Sso = observer(() => {
  const pageController = useContext(PageControllerContext);
  const ssoController = useContext(SsoControllerContext);
  const [fromPage, setFromPage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [errorReauth, setErrorReauth] = useState('');

  const handleLoginUserWithSso = async () => {
    const urlSearch = pageController.getQueryParams();
    if (urlSearch.action === 'reauth' && urlSearch.error) {
      setErrorReauth(Messages.FAILED_WINDOW_COMMUNICATION);
    } else {
      try {
        await ssoController.authWithSso();
      } catch (e: any) {
        setFromPage(urlSearch.frompage);
        const match = e.message?.match(/LOGIN_FAILED/);
        if (match) {
          setError(Messages.LOGIN_FAILED);
        } else {
          setError('Something went wrong. Please try again later.');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    handleLoginUserWithSso();
  }, []);

  isLoading && <Spinner />;

  if (errorReauth) {
    return <Alert danger>{errorReauth}</Alert>;
  }

  if (error) {
    return (
      <div>
        <Alert danger>{error}</Alert>
        <div>
          Click <Link to={fromPage}>here</Link> to return to your previous page on Utomik.
        </div>
      </div>
    );
  }

  return <div></div>;
});
export default Sso;
