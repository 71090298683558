import React from 'react';

export const IconRocket = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
      <path
        d="M8.25813 20.2975L6.62864 18.668C6.1804 18.2198 6.02219 17.5711 6.22259 16.97C6.38079 16.5006 6.59173 15.8889 6.84485 15.1876H1.26555C0.812038 15.1876 0.390163 14.945 0.163406 14.5495C-0.0633522 14.154 -0.0580787 13.6688 0.173953 13.2786L2.94251 8.61158C3.62805 7.4567 4.86731 6.75005 6.20676 6.75005H10.5468C10.6734 6.53912 10.7999 6.344 10.9265 6.15416C15.2454 -0.216157 21.679 -0.427094 25.5181 0.279547C26.1298 0.390289 26.6044 0.870172 26.7204 1.48189C27.4271 5.32623 27.2109 11.7545 20.8458 16.0735C20.6613 16.2001 20.4609 16.3266 20.2499 16.4532V20.7932C20.2499 22.1327 19.5433 23.3772 18.3884 24.0575L13.7214 26.826C13.3312 27.0581 12.846 27.0633 12.4505 26.8366C12.055 26.6098 11.8124 26.1932 11.8124 25.7344V20.0813C11.0689 20.3397 10.4202 20.5506 9.92981 20.7088C9.33919 20.8987 8.69583 20.7352 8.25286 20.2975H8.25813ZM20.2499 8.85943C20.8094 8.85943 21.3459 8.63719 21.7415 8.24161C22.1371 7.84602 22.3593 7.3095 22.3593 6.75005C22.3593 6.19061 22.1371 5.65409 21.7415 5.2585C21.3459 4.86292 20.8094 4.64068 20.2499 4.64068C19.6905 4.64068 19.154 4.86292 18.7584 5.2585C18.3628 5.65409 18.1406 6.19061 18.1406 6.75005C18.1406 7.3095 18.3628 7.84602 18.7584 8.24161C19.154 8.63719 19.6905 8.85943 20.2499 8.85943Z"
        fill="#181A33"
      />
    </svg>
  );
};
