import { IdealBanks } from 'types/payment';

import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

export default class PaymentProviderService {
  public constructor(private readonly _httpTransport: AxiosTransport) {}

  public async getPaynlBanks(): Promise<IdealBanks[]> {
    const result = await this._httpTransport.get<IdealBanks[]>('/v1/paymentproviders/paynl/banks');
    if (!result?.data) {
      return [];
    }
    return result.data;
  }
}
