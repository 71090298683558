import * as yup from 'yup';

import { emailRegEx } from 'utils/regex';

export const verifyEmailSchema = yup.object().shape({
  email: yup
    .string()
    .required('Please enter a valid email address.')
    .matches(emailRegEx, 'Please enter a valid email address.'),
});
