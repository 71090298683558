import { isoCountries } from 'lists/countryDict';
import { localization } from 'lists/localization';
import { supportedCurrencies } from 'lists/supportedCurrencies';
import numeral from 'numeral';

import { Pricing } from 'types/subscriptions';

type AmountInCentsAttrType = 'amount_in_cents';
type SupportedCurrencyCodes = keyof typeof supportedCurrencies;
interface TranslationObject {
  [key: string]: string;
}

export const salary = (value: string | number, isSalaryLong?: boolean) => {
  const amount = isSalaryLong ? numeral(value).format('$0,0[.]0000') : numeral(value).format('$0,0[.]00');
  const numericAmount = numeral(amount).value() || 0;
  const formattedAmount = (numericAmount / 100).toFixed(2);
  return '$' + formattedAmount;
};

export const formatPrice = (price: number, currencyCode: SupportedCurrencyCodes) => {
  const currencySettings = supportedCurrencies[currencyCode];

  // No currencyFormat to format along? No can do, return old data.
  if (typeof price !== 'number' || !currencySettings) {
    return price;
  }

  const currencyFormat = currencySettings.format;
  const amount = (price / 100).toFixed(2);
  const amountData = amount.split('.');

  if (amountData[1] === '00' && currencySettings.hideEmptyDecimals) {
    return currencyFormat.replace(/[.,]00/, '').replace('##', amountData[0]);
  } else {
    return currencyFormat.replace('00', amountData[1] || '').replace('##', amountData[0]);
  }
};

export const formatCurrencyLongName = (currency: string) => {
  const supportedCurrency = supportedCurrencies[currency];
  return supportedCurrency !== undefined ? supportedCurrency.longName : currency;
};

export const formatPriceToAmountInCents = (pricing: Pricing, amountInCentsAttr: AmountInCentsAttrType) => {
  if (!amountInCentsAttr) {
    amountInCentsAttr = 'amount_in_cents';
  }

  if (!pricing || !pricing.currency || typeof pricing[amountInCentsAttr] === 'undefined') {
    return undefined;
  }

  const currencyCode = pricing.currency;
  const amountInCents = pricing[amountInCentsAttr];

  return formatPrice(amountInCents, currencyCode);
};

export const normalizePath = (path: string) => (path.endsWith('/') ? path.slice(0, -1) : path);

export const filterIsoCountryAlpha2 = (alpha2Code: string): string => {
  if (alpha2Code in isoCountries) {
    return isoCountries[alpha2Code];
  }
  return alpha2Code;
};

export const translateFromLangDict = (translationObject: TranslationObject | string) => {
  if (typeof translationObject === 'string') {
    return translationObject;
  }
  return translationObject[localization.lang] ?? `Translation not found for ${localization.lang}`;
};
