import { action, computed, makeObservable, observable } from 'mobx';
import AnalyticService from 'services/analyticsService/analyticsService';
import CampaignService from 'services/campaignService/campaignService';
import FamilyMembersService from 'services/familyMembersService/familyMembersService';
import MiscService from 'services/miscService/miskService';
import PairingService from 'services/pairingService/pairingService';
import PromoService from 'services/promoService/promoService';
import SubscriptionService from 'services/subscriptionService/subscriptionService';
import UserService from 'services/userService/userService';

import { errorsRegisterPageGetPromo } from 'types/errors';
import { ICountry } from 'types/localizations';
import { Messages } from 'types/messages';
import { IPromoResponse } from 'types/promo';
import {
  ChangeEmailPostData,
  IVerification,
  RegisterWithEmail,
  RegisterWithEmailPostData,
  VerificationWithEmailResponse,
} from 'types/register';
import { FreePeriodData } from 'types/shared';
import { User } from 'types/users';

import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import { isValidInviteToken } from 'utils/helpers';
import { translateErrors } from 'utils/translateErrors';

import LoginController from '../loginController/loginController';
import PageController from '../pageController/pageController';

export default class RegisterController {
  /** "Busy" state for when register is in progress. */
  @observable
  private _busy = false;
  @computed
  public get busy(): boolean {
    return this._busy;
  }

  public setBusy(busy: boolean): void {
    this._busy = busy;
  }

  // Error value is currently only used to display the minutes remaining when too many requests are done.
  @observable
  private _errorValue = 0;

  @computed
  public get errorValue(): number {
    return this._errorValue;
  }

  public constructor(
    private readonly _loginController: LoginController,
    private readonly _httpTransport: AxiosTransport,
    private readonly _pageController: PageController,
    private readonly _userService: UserService,
    private readonly _promoService: PromoService,
    private readonly _subscriptionService: SubscriptionService,
    private readonly _campaignService: CampaignService,
    private readonly _pairingService: PairingService,
    private readonly _miscService: MiscService,
    private readonly _familyMembersService: FamilyMembersService,
    private readonly _analyticService: AnalyticService,
  ) {
    makeObservable(this);
  }

  @observable
  private _promoValidationError = '';

  @action
  public setPromoValidationError(promoError: string) {
    this._promoValidationError = promoError;
  }

  @computed
  public get promoValidationError() {
    return this._promoValidationError;
  }

  @observable
  private _promotion: IPromoResponse | null = null;

  @action
  public setPromotion(promo: IPromoResponse | null) {
    this._promotion = promo;
  }

  @computed
  public get promotion() {
    return this._promotion;
  }

  @observable
  private _promoFromCookie = '';

  @action
  public setPromoFromCookie(promoCode: string) {
    this._promoFromCookie = promoCode;
  }

  @computed
  public get promoFromCookie() {
    return this._promoFromCookie;
  }

  @observable
  private _warning = '';

  @action
  public setWarning(warning: string) {
    this._warning = warning;
  }

  @computed
  public get warning() {
    return this, this._warning;
  }

  @observable
  private _assumedCountry: ICountry | null = null;

  @action
  public setAssumedCountry(assumedCountry: ICountry | null) {
    this._assumedCountry = assumedCountry;
  }

  @computed
  public get assumedCountry() {
    return this._assumedCountry;
  }

  @observable
  private _fetchedCampaign: FreePeriodData | null = null;

  @action
  public setFetchedCampaign(fetchedCampaign: FreePeriodData | null) {
    this._fetchedCampaign = fetchedCampaign;
  }

  @computed
  public get fetchedCampaign() {
    return this._fetchedCampaign;
  }

  @observable
  private _activeFreeCampaign: FreePeriodData | null = null;

  @action
  public setActiveFreeCampaign(activeFreeCampaign: FreePeriodData | null) {
    this._activeFreeCampaign = activeFreeCampaign;
  }

  @computed
  public get activeFreeCampaign() {
    return this._activeFreeCampaign;
  }

  @observable
  private _inviteToken = '';

  @action
  public setInviteToken(inviteToken: string) {
    this._inviteToken = inviteToken;
  }

  @computed
  public get inviteToken() {
    return this._inviteToken;
  }

  @observable
  private _isValidInviteToken = false;

  @action
  public setIsValidInviteToken(isValidInviteToken: boolean) {
    this._isValidInviteToken = isValidInviteToken;
  }

  @computed
  public get isValidInviteToken() {
    return this._isValidInviteToken;
  }

  @observable
  private _inviteEmail = '';

  @action
  public setInviteEmail(email: string) {
    this._inviteEmail = email;
  }

  @computed
  public get inviteEmail() {
    return this._inviteEmail;
  }

  @observable
  private _errorInviteToken = '';

  @action
  public setErrorInviteToken(error: string) {
    this._errorInviteToken = error;
  }

  @computed
  public get errorInviteToken() {
    return this._errorInviteToken;
  }

  @observable
  private _userCountry: number | null = null;

  @action
  public setUserCountry(country: number | null) {
    this._userCountry = country;
  }

  @computed
  public get userCountry() {
    return this._userCountry;
  }

  public clearStates() {
    this.setFetchedCampaign(null);
    this.setAssumedCountry(null);
    this.setActiveFreeCampaign(null);
    this.setPromoValidationError('');
    this.setWarning('');
    this.setInviteEmail('');
    this.setErrorInviteToken('');
    this.setIsValidInviteToken(false);
  }

  public async initPage() {
    const isCloudRegistrationAllowed = await this._pairingService.isCloudRegistrationAllowed();
    const fetchedCampaign = await this._campaignService.getActiveFreeSubscriptionCampaign();
    if (!isCloudRegistrationAllowed) {
      this.setWarning('CLOUD_REGISTRATION_NOT_ALLOWED');
    }
    if (fetchedCampaign) {
      this.setFetchedCampaign(fetchedCampaign);
      const country = await this._miscService.getUserCountry();

      this.setAssumedCountry(country);
    }

    this.updateCampaign();
  }

  public async checkIsEmailAvailable(email: string) {
    return await this._userService.checkIsEmailAvailable(email);
  }

  public async checkUserPassword(password: string): Promise<boolean> {
    if (!this._userService.user?.email) return false;

    await this._loginController.loginWithCredentials({
      email: this._userService.user.email,
      password: password,
      actionForPost: null,
    });

    return true;
  }

  public async changeEmail(email: string) {
    try {
      await this._httpTransport.patch<unknown, ChangeEmailPostData>('/v2/users/me', { email });
    } catch (error) {
      console.error(error);
    }
  }

  public async registerWithEmail({ email, password, promoCode, inviteToken, country }: RegisterWithEmail) {
    try {
      this.setBusy(true);

      await this._httpTransport.post<User, RegisterWithEmailPostData>('/v1/users', {
        email,
        password,
        metadata: [],
        ...(promoCode && { promo_code: promoCode }),
        ...(inviteToken && { invite_token: inviteToken }),
        ...(country && { country: country }),
      });

      await this._loginController.loginWithCredentials({
        email,
        password,
        actionForPost: 'SIGN_UP',
      });
    } catch (e) {
      this._analyticService.handleRegistrationError({ ssoTypes: [] });
      throw e;
    } finally {
      this.setBusy(false);
    }
  }

  public goToEnrollment = async () => {
    return await this._subscriptionService.goToEnrollment();
  };

  public decodeVerificationBase64(verificationBase64: string): IVerification | undefined {
    const decoded = Buffer.from(verificationBase64, 'base64').toString('utf-8');

    return decoded ? (JSON.parse(decoded) as IVerification) : undefined;
  }

  public async verifyEmailWithLink() {
    const verificationToken = this._pageController.getQueryParams();

    let decodingVerificationToken = {};
    if (verificationToken['verification']) {
      decodingVerificationToken = atob(verificationToken.verification);
    } else {
      throw { message: 'INVALID_VERIFICATION_LINK' };
    }

    const { user_id, verification_code } = JSON.parse(decodingVerificationToken as string);

    const response = await this._httpTransport.post<
      VerificationWithEmailResponse,
      Pick<IVerification, 'verification_code'>
    >(`/v1/users/${user_id}/do_verify`, { verification_code });
    if (response?.data) {
      await this._loginController.handleLogin({ token: response.data.token, isRefresh: true });
    }
  }

  public async verifyEmailWithCode(verification_code: string) {
    this.setBusy(true);
    try {
      await this._httpTransport.post<IVerification, Pick<IVerification, 'verification_code'>>(
        '/v1/users/me/do_verify',
        { verification_code },
      );
      await this._userService.fetchUser();
      this.setBusy(false);
      return true;
    } catch (error: any) {
      this.setBusy(false);
      throw error;
    }
  }

  public async resendVerificationEmail() {
    await this._httpTransport.post<Record<string, any>, undefined>(
      '/v1/users/me/do_resend_verification_code',
      undefined,
    );
  }

  public setPromoCodeFromCookie = async () => {
    this.setPromoFromCookie('');
    this.setPromotion(null);
    const promoCodeFromCookie = this._promoService.getPromoCodeFromCookie();
    if (!promoCodeFromCookie) {
      this.setPromoFromCookie('');
      return;
    }
    this.setPromoFromCookie(promoCodeFromCookie);
    promoCodeFromCookie && (await this.validatePromo());
  };

  public validatePromo = async () => {
    try {
      const promoCode = this.promoFromCookie;
      const promotion = await this._promoService.validatePromoCode(promoCode);
      this.setPromoValidationError('');
      this.setPromotion(promotion);
    } catch (e: any) {
      const error = e.message || e;
      const errorMessage = error as string as keyof typeof errorsRegisterPageGetPromo;
      if (errorMessage in errorsRegisterPageGetPromo) {
        this.setPromoValidationError(errorsRegisterPageGetPromo[errorMessage]);
        return;
      }
      this.setPromoValidationError(translateErrors(e));
    } finally {
      this.updateCampaign();
    }
  };

  public updateCampaign() {
    const isPromoInActiveCampaign =
      !!this.fetchedCampaign && !!this.promotion && !!this.fetchedCampaign.promo_codes.includes(this.promotion.code);

    if ((this.promotion && !isPromoInActiveCampaign) || this.promoValidationError || !this.fetchedCampaign) {
      this.setActiveFreeCampaign(null);
      this.setUserCountry(null);
    } else {
      // If promo was found, use campaign information if available.
      this.setActiveFreeCampaign(this.fetchedCampaign);
      this.setUserCountry(this._userService.userCountry.id || null);
    }
  }

  public checkIsValidInviteToken = async (inviteToken: string) => {
    if (inviteToken && isValidInviteToken(inviteToken)) {
      try {
        await this._userService.checkInviteToken({ inviteToken: inviteToken });
        this.setIsValidInviteToken(true);
        this.setInviteToken(inviteToken);
        const nextUrl = this._familyMembersService.getJoinFamilyURL(inviteToken);
        this._loginController.setRedirectUrl(nextUrl);
      } catch (e: any) {
        if (
          ['BADLY_FORMED_INVITE_TOKEN', 'INVITE_TOKEN_ALREADY_USED', 'NO_INVITE_TOKEN_SPECIFIED'].includes(
            e.errorDetails?.detail,
          )
        ) {
          this.setErrorInviteToken(translateErrors(e));
        } else {
          this.setErrorInviteToken('Sorry, your invitation was revoked.');
        }
        this.setIsValidInviteToken(false);
        this.setInviteToken('');
      }
    } else {
      this.setIsValidInviteToken(false);
      this.setInviteToken('');
      this.setErrorInviteToken(Messages.INVALID_INVITE_TOKEN);
    }
  };
}
