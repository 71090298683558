import ReactDropdown, { DropdownProps as ReactDropdownProps } from 'react-overlays/Dropdown';
import { UseDropdownMenuOptions } from 'react-overlays/DropdownMenu';

import DropdownMenu from './dropdownMenu';
import DropdownToggle from './dropdownToggle';

export type DropdownProps = ReactDropdownProps & {
  id: string;
  toggleContent: React.ReactNode;
  toggleClassName?: string;
  menuClassName?: string;
  menuOptions?: UseDropdownMenuOptions;
};

const Dropdown: React.FC<DropdownProps> = ({
  id,
  toggleContent,
  toggleClassName,
  menuClassName,
  menuOptions,
  itemSelector = 'button:not(:disabled)',
  children,
  ...props
}) => (
  <ReactDropdown itemSelector={itemSelector} {...props}>
    <DropdownToggle id={id} className={toggleClassName}>
      {toggleContent}
    </DropdownToggle>

    <DropdownMenu options={menuOptions} className={menuClassName}>
      {children}
    </DropdownMenu>
  </ReactDropdown>
);

export default Dropdown;
