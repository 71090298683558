import { Link } from 'react-router-dom';

export type FooterLinkWrapperProps = React.PropsWithChildren<{
  className?: string;
  to: string;
  title?: string;
}>;

const FooterLinkWrapper: React.FC<FooterLinkWrapperProps> = ({ to, ...props }) => {
  const isExternalLink = to.startsWith('http');
  return isExternalLink ? <a href={to} target="_blank" rel="noreferrer" {...props} /> : <Link to={to} {...props} />;
};

export default FooterLinkWrapper;
