const notIsSupportedCloudCountry = `Unfortunately, Utomik Cloud is not available in your region right now. You can still play Utomik on other
devices. <a href="https://www.utomik.com/cloud" target="_blank" rel="noreferrer">
  Learn more
</a>`;

const isCloudRegistrationsBlocked = `Unfortunately, cloud subscriptions are not available right know. <a href="https://www.utomik.com/cloud-n-a" target="_blank" rel="noreferrer">Learn more</a>.`;

export const errorHandler = ({
  apiHasCloudSupport,
  cloudRegistrationsBlocked,
  isInSupportedCloudCountry,
}: {
  apiHasCloudSupport: boolean;
  cloudRegistrationsBlocked: boolean;
  isInSupportedCloudCountry: boolean;
}): string => {
  if (!apiHasCloudSupport) {
    return '';
  }
  if (!cloudRegistrationsBlocked && !isInSupportedCloudCountry) {
    return notIsSupportedCloudCountry;
  } else if (cloudRegistrationsBlocked) {
    return isCloudRegistrationsBlocked;
  }
  return '';
};
