import { resetPasswordEmailSchema } from 'app/validationSchemes/resetPasswordEmailSchema';
import { FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useState } from 'react';

import { translateErrors } from 'utils/translateErrors';

import { UserServiceContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Input from 'app/components/input/input';

import styles from './resetPasswordForm.module.scss';

type FormValues = {
  email: string;
};

const initialValues: FormValues = {
  email: '',
};

const ResetPasswordForm = observer(() => {
  const userService = useContext(UserServiceContext);
  const [isFetchingForm, setIsFetchingForm] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [errorMessageRequest, setErrorMessageRequest] = useState('');
  const [isErrorValidateEmail, setIsErrorValidateEmail] = useState(false);
  const onSubmit = async (values: FormValues) => {
    setIsFetchingForm(true);
    try {
      await userService.fetchResetPassword(values);
      setIsPasswordReset(true);
    } catch (e: any) {
      if (e.errorDetails?.detail === 'NO_EMAIL_SPECIFIED') {
        setIsErrorValidateEmail(true);
      }
      setErrorMessageRequest(translateErrors(e));
    } finally {
      setIsFetchingForm(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: resetPasswordEmailSchema,
    onSubmit: onSubmit,
    validateOnBlur: false,
  });

  const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value.length && !formik.touched[`email`] && formik.setFieldTouched('email', true);

    formik.handleChange(e);
  }, []);

  return (
    <section className="page-section">
      <h1 className={styles['form-title']}>Reset password</h1>

      {!isPasswordReset && (
        <FormikProvider value={formik}>
          <div className={styles['form-reset']}>
            <form onSubmit={formik.handleSubmit}>
              <Input
                label="Email address"
                name="email"
                title="Please fill in this field."
                onChange={(e) => {
                  isErrorValidateEmail && setIsErrorValidateEmail(false);
                  isErrorValidateEmail && setErrorMessageRequest('');
                  handleEmailChange(e);
                }}
                placeholder="Email address"
                isValidate={false}
                isValidateInputStroke={!!formik.errors.email || isErrorValidateEmail}
                showCheck={!formik.errors.email && !!formik.values.email && !isFetchingForm && !isErrorValidateEmail}
                isLoading={isFetchingForm}
                disabled={isFetchingForm}
              />
              {errorMessageRequest && <Alert danger>{errorMessageRequest}</Alert>}
              <Button
                type="submit"
                disabled={!formik.values.email || !!formik.errors.email || isFetchingForm}
                xl
                loading={isFetchingForm}
                name="Reset password"
              >
                Reset password
              </Button>
            </form>
          </div>
        </FormikProvider>
      )}
      {isPasswordReset && (
        <div className={styles['reset-complete']}>
          <p>Request sent.</p>
          <p>Please follow the instructions in the mail we sent you to complete resetting your password.</p>
        </div>
      )}
    </section>
  );
});
export default ResetPasswordForm;
