import CookieService from 'services/cookieService/cookieService';

import AuthorizationStore from 'dataStore/stores/authorizationStore/authorizationStore';
import UserStore from 'dataStore/stores/userStore/userStore';
import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import { countryAvailableCookieName, shoppingcartCookieName, userCountryIdCookieName } from 'app/global/constants';

export type Token = string | undefined;

export default class LoginService {
  public constructor(
    private readonly _httpTransport: AxiosTransport,
    private readonly _authorizationStore: AuthorizationStore,
    private readonly _cookieService: CookieService,
    private readonly _userStore: UserStore,
  ) {}

  public getTokenData(token?: string) {
    const currentToken = token || this._authorizationStore.token;
    if (!currentToken) return null;
    try {
      const base64Data = currentToken.split('.')[1];
      return JSON.parse(atob(base64Data));
    } catch (e) {
      return null;
    }
  }

  public parseTokenToExpDate = (token: string) => {
    const tokenData = this.getTokenData(token);
    return tokenData ? new Date(parseInt(tokenData['exp'], 10) * 1000) : null;
  };

  public async check(): Promise<void> {
    const storedToken = this._authorizationStore.token;
    const jwtCookie = this._cookieService.getCookie('JWT');

    if (!storedToken && jwtCookie) {
      this._authorizationStore.setToken(jwtCookie as Token);
      return;
    }

    if (storedToken) {
      try {
        const expDateToken = this.parseTokenToExpDate(storedToken);
        if (expDateToken && new Date() > expDateToken) {
          this._authorizationStore.setToken(null);
          await this.logout();
          return;
        }
      } catch (e) {
        // Eh, if we have some kind of error parsing the token just assume it's okay; any XHR call we make using this token will result in a properly handled error anyways.
      }
    } else {
      this._authorizationStore.setToken(null);
    }
  }

  public async checkIsLoggedIn(): Promise<boolean> {
    await this.check();
    this._authorizationStore.setIsLoggedIn(!!this._authorizationStore.token);
    return this._authorizationStore.isLoggedIn;
  }

  public async logout(withoutLogOutAction?: boolean): Promise<void> {
    const options = {
      headers: {
        Authorization: 'JWT ' + this._cookieService.getCookie('JWT'),
      },
      withCredentials: true,
    };

    const clearAllCookies = () => {
      this._cookieService.removeCookie('JWT', { path: '/' });
      this._cookieService.removeCookie('JWT_USER', { path: '/' });
      this._cookieService.removeCookie(countryAvailableCookieName, { path: '/' });
      this._cookieService.removeCookie(userCountryIdCookieName, { path: '/' });
      this._cookieService.removeCookie(shoppingcartCookieName, { path: '/' });
      this._cookieService.removeCookie('UTOMIK_REF_CODE', { path: '/' });
      this._cookieService.removeCookie('UTOMIK_REF_CODE_IS_VALID', { path: '/' });
      this._userStore.resetUser();
      this._authorizationStore.setToken(null);
      this.checkIsLoggedIn();
    };
    if (withoutLogOutAction) {
      clearAllCookies();
      return;
    }
    try {
      await this._httpTransport.post('/api-session-logout', undefined, options);
    } catch (e) {
      console.error(e, 'logout');

      throw e;
    } finally {
      clearAllCookies();
    }
  }
}
