import { action, computed, makeObservable, observable, toJS } from 'mobx';
import AnalyticService from 'services/analyticsService/analyticsService';
import BoxOfficeService from 'services/boxOficceService/boxOficceService';
import PreconditionService from 'services/preconditionService/preconditionService';
import SubscriptionManagementService from 'services/subscriptionManagementService/subscriptionManagementService';
import SubscriptionService from 'services/subscriptionService/subscriptionService';
import UserService from 'services/userService/userService';

import { IOrdersResponse } from 'types/orders';
import { IPartners, ISubscriptionsResponseWithOrders, SubscriptionDetails } from 'types/subscriptions';

import { activeUtomikSubscriptions } from 'utils/subscriptionFilters';

export default class ManageSubscriptionsController {
  public constructor(
    private readonly _boxOfficeService: BoxOfficeService,
    private readonly _userService: UserService,
    private readonly _subscriptionManagementService: SubscriptionManagementService,
    private readonly _subscriptionService: SubscriptionService,
    private readonly _preconditionService: PreconditionService,
    private readonly _analyticService: AnalyticService,
  ) {
    makeObservable(this);
  }

  @observable
  private _canStartUtomikSubscription = false;

  @observable
  private _userCanStartTrial = false;

  @observable
  private _userHasPendingSubscriptionOrders = false;

  @observable
  private _subscriptions: ISubscriptionsResponseWithOrders[] = [];

  @observable
  private _userHadSubscriptionsBefore = false;

  @observable
  private _subscriptionDetails: SubscriptionDetails | null = null;

  @action
  public setSubscriptionDetails(subscriptionDetails: SubscriptionDetails | null) {
    this._subscriptionDetails = subscriptionDetails;
  }

  @computed
  public get subscriptionDetails() {
    return this._subscriptionDetails;
  }
  @action
  public setUserHadSubscriptionsBefore(isHadSubscriptionBefore: boolean) {
    this._userHadSubscriptionsBefore = isHadSubscriptionBefore;
  }

  @computed
  public get userHadSubscriptionsBefore() {
    return this._userHadSubscriptionsBefore;
  }

  @action
  public setSubscriptions = (subscriptions: ISubscriptionsResponseWithOrders[]) => {
    this._subscriptions = subscriptions;
  };

  @computed
  public get subscriptions() {
    return this._subscriptions;
  }

  @action
  public setUserHasPendingSubscriptionOrders(isHasUserPendingSubscription: boolean) {
    this._userHasPendingSubscriptionOrders = isHasUserPendingSubscription;
  }

  @computed
  public get userHasPendingSubscriptionOrders() {
    return this._userHasPendingSubscriptionOrders;
  }

  @action
  public setUserCanStartTrial(isHasTrial: boolean) {
    this._userCanStartTrial = isHasTrial;
  }

  @computed
  public get userCanStartTrial() {
    return this._userCanStartTrial;
  }

  @action
  public setCanStartUtomikSubscription(isCanStartUtomikSubscription: boolean) {
    this._canStartUtomikSubscription = isCanStartUtomikSubscription;
  }

  @computed
  public get canStartUtomikSubscription() {
    return this._canStartUtomikSubscription;
  }
  @observable
  private _subscriptionUrl = '';

  @action
  public setSubscriptionUrl = (url: string) => {
    this._subscriptionUrl = url;
  };

  @computed
  public get subscriptionUrl() {
    return this._subscriptionUrl;
  }

  @observable
  private _isFetchingReactivate = false;

  @action
  public setFetchingReactivate(isFetching: boolean) {
    this._isFetchingReactivate = isFetching;
  }

  @computed
  public get isFetchingReactivate() {
    return this._isFetchingReactivate;
  }

  @observable
  private _canSwitchToUtomikSubscription = false;

  @action
  public setCanSwitchUtomikSubscription(canSwitchSubscription: boolean) {
    this._canSwitchToUtomikSubscription = canSwitchSubscription;
  }

  @computed
  public get canSwitchToUtomikSubscription() {
    return this._canSwitchToUtomikSubscription;
  }

  @observable
  private _cannotSwitchWarning = '';

  @action
  public setCannotSwitchWarning(warning: string) {
    this._cannotSwitchWarning = warning;
  }

  @computed
  public get cannotSwitchWarning() {
    return this._cannotSwitchWarning;
  }

  @observable
  private _hasSwitchedMaxNumberOfTimes = false;

  @action
  public setHasSwitchedMaxNumberOfTimes(hasSwitchedMaxNumberOfTimes: boolean) {
    this._hasSwitchedMaxNumberOfTimes = hasSwitchedMaxNumberOfTimes;
  }

  @computed
  public get hasSwitchedMaxNumberOfTimes() {
    return this._hasSwitchedMaxNumberOfTimes;
  }

  @observable
  private _reseller: IPartners | null = null;

  @action
  public setReseller(reseller: IPartners) {
    this._reseller = reseller;
  }

  @computed
  public get reseller() {
    return this._reseller;
  }

  public async initialPage() {
    try {
      await this._userService.fetchUser();
      await this._userService.fetchLoggedInAccount();
      await this._subscriptionService.fetchSubscriptionPlans();
      await this._userService.fetchAccountSubscriptions();
      await this._userService.fetchOrders();
      const isHasTrial = this._userService.getCanStartTrial();
      const isCanStartUtomikSubscription = await this._boxOfficeService.canStartUtomikSubscription();
      const isHasUserPendingSubscription = await this._userService.getUserHasPendingSubscriptionOrders();
      const subscriptions = await this._userService.getAccountSubscriptions();
      this.setCanStartUtomikSubscription(isCanStartUtomikSubscription);
      this.setUserCanStartTrial(isHasTrial);
      this.setUserHasPendingSubscriptionOrders(isHasUserPendingSubscription);
      const activeSubscriptions = activeUtomikSubscriptions({
        subscriptions: subscriptions,
        filterType: 'activeUtomikSubscriptions',
      });
      this.setSubscriptions(activeSubscriptions);

      if (subscriptions.length && !activeSubscriptions.length) {
        this.setUserHadSubscriptionsBefore(true);
      }
      const partnerId = (subscriptions[0]?.order as IOrdersResponse)?.reseller;
      if (partnerId) {
        const partner = await this._subscriptionService.getPartner(partnerId);
        partner && this.setReseller(partner);
      }
      this.initializeSubscriptionDetails();
      await this.checkSwitchToUtomikSubscription();
    } catch (e: any) {
      this.setUserCanStartTrial(false);
      this.setUserHasPendingSubscriptionOrders(false);
      this.setSubscriptions([]);
      throw e;
    }
  }

  public initializeSubscriptionDetails() {
    const subscriptionDetails = this._subscriptions.reduce((memo, subscription) => {
      memo[subscription.id] = { error: undefined, isLoading: false };
      return memo;
    }, {} as SubscriptionDetails);

    this.setSubscriptionDetails(subscriptionDetails);
  }

  public checkSwitchToUtomikSubscription = async () => {
    const canSwitch = await this._boxOfficeService.canSwitchSubscription();
    this.setCanSwitchUtomikSubscription(canSwitch);
    this.setCannotSwitchWarning(canSwitch ? '' : 'CANNOT_CHANGE_FROM_NONRECURRING_PLAN');
    if (!canSwitch) {
      const hasSwitchedMaxNumbers = await this._boxOfficeService.hasSwitchedMaxNumberOfTimes();
      this.setHasSwitchedMaxNumberOfTimes(hasSwitchedMaxNumbers);
    }
  };

  public get utomikIsLiveInUsersCountry() {
    return this._userService.utomikIsLiveInUsersCountry;
  }

  public reactivateSubscription = async () => {
    try {
      this.setFetchingReactivate(true);
      const subscription = this.subscriptions.find((sub) => sub.url === this.subscriptionUrl) || null;
      await this._subscriptionManagementService.reactivateSubscription(this.subscriptionUrl, subscription);
      this.setSubscriptionUrl('');
      await this.initialPage();
    } finally {
      this.setFetchingReactivate(false);
    }
  };

  public cancelSubscription = async () => {
    try {
      const subscription = this.subscriptions.find((sub) => sub.url === this.subscriptionUrl) || null;
      await this._subscriptionManagementService.cancelSubscription(this.subscriptionUrl);
      this._analyticService.handleCancelSubscription(subscription);
      this.setSubscriptionUrl('');
      await this.initialPage();
    } catch (e: any) {
      this._analyticService.handleCancelSubscriptionError();
      throw e;
    }
  };

  public get preconditions() {
    return this._preconditionService.preconditions;
  }

  public get user() {
    return this._userService.user;
  }
}
