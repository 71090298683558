import withAuth from 'app/hoc/withAuth';
import ResetPasswordForm from 'app/widgets/resetPasswordForm/resetPasswordForm';
import React from 'react';

const ResetPassword = () => {
  return (
    <div className="container">
      <ResetPasswordForm />
    </div>
  );
};
export default withAuth(ResetPassword);
