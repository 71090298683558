import { validateProfileSchema } from 'app/validationSchemes/validateProfileSchema';
import { useFormik } from 'formik';
import React, { useContext } from 'react';

import { isString } from 'types/guards';
import { Messages } from 'types/messages';
import { IIsUserNameAvailable, IPathUserRequest } from 'types/profile';
import { emailError } from 'types/register';

import { isValidEmail, isValidUsername } from 'utils/helpers';
import { parseUserBirthDate } from 'utils/user';

import { UserControllerContext } from 'app/context/storesContext';

import { fieldOverrideErrors } from './formMessages';
import { ProfileFormValues } from './profileForm';

interface IUseProfileForm {
  setCountryNotSupportedError: (error: string) => void;
  setIsLoadingCheckUserName: (isLoading: boolean) => void;
  setIsLoadingCheckEmail: (isLoading: boolean) => void;
  setIsPatchSuccess: (isSuccess: boolean) => void;
  setIsPatchError: (isPatchError: boolean) => void;
  setIsLoadingPatchData: (isLoadingPatchData: boolean) => void;
  setErrorUserName: (error: string | undefined) => void;
  setErrorFormEmail: (error: string | undefined) => void;
  errorUserName: string | undefined;
  errorFormEmail: string | undefined;
}
const useProfileForm = ({
  setCountryNotSupportedError,
  setIsLoadingCheckUserName,
  setIsPatchSuccess,
  setIsPatchError,
  setIsLoadingPatchData,
  setErrorUserName,
  setIsLoadingCheckEmail,
  setErrorFormEmail,
  errorUserName,
  errorFormEmail,
}: IUseProfileForm) => {
  const userController = useContext(UserControllerContext);
  const user = userController.user;
  const userBirthDate = parseUserBirthDate(user.birthdate || '');
  let debounceTimer: NodeJS.Timeout | null = null;

  const initialValues: ProfileFormValues = {
    email: user.email || '',
    password: user.has_usable_password ? '********' : 'Add a password to your account',
    gender: user.gender || 'u',
    userName: user.username || '',
    firstName: user.first_name || '',
    lastName: user.last_name || '',
    dateOfBirthM: userBirthDate.month || '',
    dateOfBirthD: userBirthDate.day || '',
    dateOfBirthY: userBirthDate.year || '',
    country: user.country ? `${user.country?.id}` : '',
    newsletterSignUp: user.newsletter_signup || false,
  };

  const handleValidateErrors = (values: ProfileFormValues) => {
    const errors = {} as ProfileFormValues;
    if (errorUserName) {
      errors.userName = errorUserName;
    }
    if (errorFormEmail && isValidEmail(values.email)) {
      errors.email = errorFormEmail;
    }
    const { dateOfBirthD, dateOfBirthM, dateOfBirthY, country } = values;
    const notSupportedCountry = userController.countryNotSupported;

    if (canChangeOwnBirthdate() && dateOfBirthD && dateOfBirthM && dateOfBirthY) {
      const newBirthDate = {
        year: dateOfBirthY,
        month: dateOfBirthM,
        day: dateOfBirthD,
      };
      const isValidDate = userController.isValidDate(newBirthDate);
      const isUnder18 = isValidDate && !userController.isOfAge({ birthdate: newBirthDate });
      if (!isValidDate) {
        errors.dateOfBirthM = 'This is not a valid date, please check the date you filled in.';
      } else if (isUnder18) {
        errors.dateOfBirthM = Messages.AGE_UNDER_18;
      }
    }

    if (country) {
      const isCountryNotSupported = notSupportedCountry.some(
        (countryNotSupported) => countryNotSupported.countryId === +country,
      );
      setCountryNotSupportedError(isCountryNotSupported ? 'USER_COUNTRY_NOT_SUPPORTED' : '');
    }
    return errors;
  };

  const onSubmitForm = async (values: ProfileFormValues) => {
    setIsLoadingPatchData(true);
    setIsPatchError(false);
    const { dateOfBirthY, dateOfBirthM, dateOfBirthD } = values;
    const birthdate =
      dateOfBirthY && dateOfBirthM && dateOfBirthD ? [dateOfBirthY, dateOfBirthM, dateOfBirthD].join('-') : null;
    const patchData: IPathUserRequest = {
      ...(canChangeOwnBirthdate() && { birthdate: birthdate }),
      country: +values.country || userController.userCountry.id,
      first_name: values.firstName,
      last_name: values.lastName,
      gender: values.gender,
      username: values.userName,
      newsletter_signup: values.newsletterSignUp,
      id: user.id || 0,
      ...(!user.email && { email: values.email }),
    };
    try {
      await userController.patchUser(patchData);
      setIsPatchSuccess(true);

      setTimeout(() => {
        setIsPatchSuccess(false);
      }, 3000);
    } catch (e: any) {
      setIsPatchError(true);
      const { errorDetails } = e;
      if (errorDetails.username) {
        const userNameError = errorDetails.username[0] as keyof typeof fieldOverrideErrors;
        const errorMessage = fieldOverrideErrors[userNameError];
        if (errorMessage) {
          formik.setFieldError('userName', errorMessage);
        }
      }
    } finally {
      setIsLoadingPatchData(false);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validateProfileSchema,
    onSubmit: onSubmitForm,
    enableReinitialize: true,
    validateOnBlur: false,
    validate: handleValidateErrors,
  });

  const debounceUserNameValidation = async (userName: string): Promise<void> => {
    if (!isValidUsername(userName)) {
      setIsLoadingCheckUserName(false);
      return;
    }

    try {
      const isAvailableUserName = await userController.checkIsUserNameAvailable({
        username: userName,
        email: user.email || '',
      });
      if (!(isAvailableUserName as IIsUserNameAvailable).usernameAvailable) {
        formik.setFieldError('userName', Messages.USERNAME_ALREADY_TAKEN);
        setErrorUserName(Messages.USERNAME_ALREADY_TAKEN);
      } else {
        setErrorUserName(undefined);
        formik.setFieldError('userName', undefined);
      }
    } catch (error: any) {
      console.error(error, 'debounceUserNameValidation');
    } finally {
      setIsLoadingCheckUserName(false);
    }
  };

  const validateUserName = (userName: string) => {
    setIsLoadingCheckUserName(isValidUsername(userName));

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    debounceTimer = setTimeout(async () => {
      await debounceUserNameValidation(userName);
    }, 1000);
  };

  const debounceEmailValidation = async (email: string): Promise<void> => {
    if (!isValidEmail(email)) {
      return;
    }
    setIsLoadingCheckEmail(true);
    try {
      const isAvailableEmail = await userController.checkIsEmailAvailable(email);
      if (!isAvailableEmail) {
        const errorMessage = `This email address is already taken`;
        formik.setFieldError('email', errorMessage);
        setErrorFormEmail(errorMessage);
      } else {
        formik.setFieldError('email', undefined);
        setErrorFormEmail(undefined);
      }
    } catch (error: any) {
      const message: string | undefined = error?.message;
      if (isString(message) && Object.keys(emailError).includes(message)) {
        const errorMessage = emailError[message as keyof typeof emailError];
        formik.setFieldError('email', errorMessage);
        setErrorFormEmail(errorMessage);
      }
    } finally {
      setIsLoadingCheckEmail(false);
    }
  };

  const validateEmail = (emailValue: string) => {
    if (isValidEmail(emailValue)) {
      setIsLoadingCheckEmail(true);
    } else {
      setIsLoadingCheckEmail(false);
      setErrorFormEmail(undefined);
    }
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(async () => {
      await debounceEmailValidation(emailValue);
    }, 1000);
  };

  const canChangeOwnBirthdate = () => {
    return userController.calculateCanChangeOwnBirthdate();
  };

  return {
    onSubmitForm,
    initialValues,
    handleValidateErrors,
    validateUserName,
    canChangeOwnBirthdate,
    validateEmail,
    formik,
  };
};
export default useProfileForm;
