import withAuth from 'app/hoc/withAuth';
import ChangePasswordModal from 'app/modals/changePasswordModal/changePasswordModal';
import DeleteAccount from 'app/widgets/deleteAccount/deleteAccount';
import FamilyDashboard from 'app/widgets/familyDashboard/familyDashboard';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';

import { FamilyControllerContext, UtomikSupportedInCountryControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Modal from 'app/components/modal/modal';

import styles from './family.module.scss';

const Family = observer(() => {
  const utomikSupportedInCountryController = useContext(UtomikSupportedInCountryControllerContext);
  const familyController = useContext(FamilyControllerContext);
  const [isFetchSupportedCountry, setIsFetchSupportedCountry] = useState(true);

  const {
    utomikIsLiveInUsersCountry,
    user,
    subscription,
    familyMembers,
    isLoading,
    error,
    resendingInvitationState,
    removingInvitationState,
    familyWellError,
    familyMemberInvitesRemaining,
    maxMembersInFamilyPlan,
    forbiddenEmails,
    activeMemberEmail,
    reauthModalIsOpen,
    reauthModaContent,
    stateInvite,
    inviteMemberError,
    accountSettingsFormIsOpen,
    currentAccountsEdit,
    doRemoveAccountId,
    setCurrentAccountsEdit,
    initPage,
    isOfAge,
    resendInvitation,
    doRemoveAccount,
    toggleModalReauth,
    setStateInvite,
    isValidDate,
    doInvite,
    clearState,
    setAccountSettingsFormIsOpen,
    doSaveAccountSettings,
  } = familyController;
  useEffect(() => {
    return () => clearState();
  }, []);

  const checkUtomikIsLiveInUsersCountry = async () => {
    try {
      await utomikSupportedInCountryController.checkIsUtomikSupportedInCountry();
    } finally {
      setIsFetchSupportedCountry(false);
    }
  };

  const loadData = async () => {
    try {
      await initPage();
    } catch (e) {
      console.error(e, 'HOME PAGE INIT');
    }
  };
  useEffect(() => {
    checkUtomikIsLiveInUsersCountry();
    loadData();
  }, []);

  return (
    <div>
      <div className="container">
        {!isFetchSupportedCountry && !utomikIsLiveInUsersCountry && (
          <section className={styles['icon-info']}>
            <Alert info isShowIcon={false}>
              <span className="fas fa-info-circle"></span> Utomik is not officially supported in your country. You may
              not be able to add or edit your family members.
            </Alert>
          </section>
        )}
        {!isFetchSupportedCountry && (
          <section className="page-section">
            <div>
              <h1 className={styles['family-title']}>
                <span className="fas fa-users"></span> Manage family members
              </h1>

              {user.deletion_date && (
                <div className={styles['delete-account']}>
                  <DeleteAccount />
                </div>
              )}

              <FamilyDashboard
                error={error}
                isLoading={isLoading}
                subscription={subscription}
                familyMembers={familyMembers}
                user={user}
                resendingInvitationState={resendingInvitationState}
                removingInvitationState={removingInvitationState}
                familyWellError={familyWellError}
                familyMemberInvitesRemaining={familyMemberInvitesRemaining}
                maxMembers={maxMembersInFamilyPlan}
                forbiddenEmails={forbiddenEmails}
                activeMemberEmail={activeMemberEmail}
                stateInvite={stateInvite}
                inviteMemberError={inviteMemberError}
                accountSettingsFormIsOpen={accountSettingsFormIsOpen}
                currentAccountsEdit={currentAccountsEdit}
                doRemoveAccountId={doRemoveAccountId}
                setCurrentAccountsEdit={setCurrentAccountsEdit}
                setStateInvite={setStateInvite}
                isValidDate={isValidDate}
                isOfAge={isOfAge}
                resendInvitation={resendInvitation}
                doRemoveAccount={doRemoveAccount}
                doInvite={doInvite}
                setAccountSettingsFormIsOpen={setAccountSettingsFormIsOpen}
                doSaveAccountSettings={doSaveAccountSettings}
              />
            </div>
            <Modal show={reauthModalIsOpen} onHide={toggleModalReauth} title={reauthModaContent?.title}>
              <ChangePasswordModal
                closeModal={toggleModalReauth}
                subtitle={reauthModaContent?.message}
                placeholder="Password"
                danger={reauthModaContent?.buttonIsDanger}
                textButtonNext={reauthModaContent?.buttonLabel}
                closeModalAfterConfirm={true}
                completeConfirm={reauthModaContent?.callback}
              />
            </Modal>
          </section>
        )}
      </div>
    </div>
  );
});
export default withAuth(Family);
