import clsx from 'clsx';
import React from 'react';
import { useCookies } from 'react-cookie';

import { calculateCookieExpiration } from 'utils/utils';

import { PRIVACY_POLICY_URL } from 'app/global/constants';

import Button from 'app/components/button/button';

import styles from './cookieConsent.module.scss';

interface ICookieConsent {
  closeCookieWindow?: () => void;
}

const CookieConsent: React.FC<ICookieConsent> = ({ closeCookieWindow }) => {
  const [cookies, setCookie] = useCookies(['cookiescriptaccept']);

  const acceptCookies = () => {
    setCookie('cookiescriptaccept', 'visit', { path: '/', expires: calculateCookieExpiration() });
    closeCookieWindow && closeCookieWindow();
  };

  const rejectCookie = () => {
    setCookie('cookiescriptaccept', 'reject', { path: '/', expires: calculateCookieExpiration() });
    closeCookieWindow && closeCookieWindow();
  };

  return (
    <div className={styles['cookie-consent']}>
      <p>
        On our website, cookies are placed by Utomik and third parties. This is done to enable the functionality of the
        website, to analyze visitor behavior, to offer social media applications, to create client profiles, and
        personalise advertisements on this website and other websites. By clicking on 'I agree', you agree to the use of
        cookies as described in our{' '}
        <a href={PRIVACY_POLICY_URL} title="Read the privacy policy" target="_blank" rel="noopener noreferrer">
          Privacy and Cookie Policy.
        </a>
      </p>
      <div className={styles['cookie-consent-buttons']}>
        <Button className={clsx(styles['btn-cookie-accept'], styles['btn-cookie'])} primary onClick={acceptCookies}>
          I agree
        </Button>
        <Button className={clsx(styles['btn-cookie-reject'], styles['btn-cookie'])} onClick={rejectCookie}>
          I disagree
        </Button>
      </div>
    </div>
  );
};
export default CookieConsent;
