export const emailError = {
  INVALID_EMAIL: 'This is not a valid email address.',
  BLACKLISTED_DOMAIN: 'This email address is invalid.',
};

export enum LoginLocation {
  Authorization,
  Login,
  ForgotPassword,
  ForgotPasswordResult,
}

export interface LoginErrorState {
  message: string;
  minutes?: number;
}

export type IVerification = {
  user_id: string;
  verification_code: string;
};

export interface VerificationWithEmailResponse {
  token: string;
}

export interface CheckIsEmailAvailablePostData {
  email: string;
}

export interface CheckIsEmailAvailableResponseData {
  email_available: boolean;
}

export interface RegisterPostData {
  email: string;
  password: string;
}

export interface RegisterWithEmailPostData extends RegisterPostData {
  metadata: unknown[];
  promo_code?: string;
}

export interface ChangeEmailPostData {
  email: string;
}

export interface RegisterWithEmail {
  email: string;
  password: string;
  promoCode?: string | null;
  inviteToken?: string;
  country: number | null;
}
