import { observer } from 'mobx-react';
import React, { ComponentType, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { NavigateServiceContext } from 'app/context/storesContext';

import Spinner from 'app/components/spinner/spinner';

const withNavigate = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const WithNavigateComponent: React.FC<P> = observer((props) => {
    const [isClient, setIsClient] = useState(false);
    const navigate = useNavigate();
    const navigateService = useContext(NavigateServiceContext);
    useEffect(() => {
      if (navigateService.navigateLink) {
        const link = navigateService.navigateLink;
        navigateService.setNavigateLink('');

        navigate(link);
        return;
      }
    }, [navigateService.navigateLink]);

    useEffect(() => {
      setIsClient(true);
    }, []);

    if (!isClient) {
      return (
        <div style={{ height: '100vh', width: '100vw', background: '#121429' }}>
          <Spinner />
        </div>
      );
    }
    return <WrappedComponent {...props} />;
  });
  return WithNavigateComponent;
};

export default withNavigate;
