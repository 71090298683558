import AnalyticService from 'services/analyticsService/analyticsService';
import CookieService from 'services/cookieService/cookieService';
import SubscriptionService from 'services/subscriptionService/subscriptionService';
import UserService from 'services/userService/userService';

import { Messages } from 'types/messages';
import { ActionForPost } from 'types/pairing';

import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import { translateErrors } from 'utils/translateErrors';

import { pairingStatusCookieName } from 'app/global/constants';
import PageController from 'app/global/pageController/pageController';

interface ILinkPairingCodeBody {
  pairing_code: string;
  action: string;
}

interface IsRegistrationAllowedResponse {
  registration_allowed: boolean;
}

export default class PairingService {
  public constructor(
    private readonly _cookieService: CookieService,
    private readonly _httpTransport: AxiosTransport,
    private readonly _pageController: PageController,
    private readonly _userService: UserService,
    private readonly _subscriptionService: SubscriptionService,
    private readonly _analyticService: AnalyticService,
  ) {
    // makeObservable(this);
  }

  public clearPairingCodeFromCookie() {
    return this._cookieService.removeCookie(pairingStatusCookieName, { path: '/' });
  }

  public rememberPairingCode(pairingCode: string) {
    return this._cookieService.setCookie(pairingStatusCookieName, pairingCode, { path: '/' });
  }

  public getPairingCodeFromCookie(): string | boolean | undefined {
    return this._cookieService.getCookie(pairingStatusCookieName);
  }

  public async loginWithPairingCode(): Promise<void | boolean> {
    const url = 'v2/pairing/do_login';

    try {
      await this._httpTransport.post<undefined, undefined>(url, undefined);
      await this._analyticService.handleRemoteLogin();
    } catch (e: any) {
      if (e?.message !== 'Not found.') {
        this._analyticService.handleRemoteLoginError();
        throw new Error(e);
      } else {
        return true;
      }
    }
  }

  public isPairingCodePending(): boolean {
    return this._userService.pairingStatus === 'PENDING' || this._userService.pairingStatus === 'USED';
  }

  public async getIsPairingCodePending(): Promise<boolean | void> {
    await this._userService.fetchUser();

    return this.isPairingCodePending();
  }

  public async isCloudRegistrationAllowed(): Promise<boolean> {
    const url = 'v2/cloud/do_check_registration_allowed';
    try {
      const isRegistrationAllowed = await this._httpTransport.post<IsRegistrationAllowedResponse, object>(url, {});

      if (isRegistrationAllowed?.data?.registration_allowed === true) {
        this._userService.setIsCloudRegistrationAllowed(true);
        return true;
      }
    } catch (e) {
      this._userService.setIsCloudRegistrationAllowed(true);
      return true;
    }
    this._userService.setIsCloudRegistrationAllowed(false);
    return false;
  }

  public transformPairingErrors(error: any, pairingCase: ActionForPost) {
    if (error && error.message) {
      const errorWithPairingCase = pairingCase === 'LOG_IN' ? error.message + '-LOGIN' : error.message + '-REGISTER';
      const messages: { [key: string]: string } = Messages;
      if (messages[errorWithPairingCase]) {
        throw new Error(translateErrors(errorWithPairingCase));
      } else if (messages[error.message]) {
        throw new Error(translateErrors(error));
      } else {
        throw new Error(Messages.GENERAL_FALLBACK);
      }
    }
  }

  public async tryLinkPairingCode(actionForPost: ActionForPost): Promise<void> {
    const pairingCode = this.getPairingCodeFromCookie() as string;
    if (pairingCode) {
      await this.linkPairingCode(pairingCode, actionForPost);
    }
  }

  public async linkPairingCode(pairingCode: string, actionForPost: ActionForPost): Promise<void> {
    if (!pairingCode) {
      throw new Error('NO_CODE_SPECIFIED');
    }

    try {
      await this._httpTransport.post<unknown, ILinkPairingCodeBody>('v2/pairing/do_link_user', {
        pairing_code: pairingCode,
        action: actionForPost,
      });
      const nextUrl = await this._subscriptionService.goToEnrollment();
      this._pageController.setLocation(nextUrl);
    } catch (error: any) {
      this.transformPairingErrors(error, actionForPost);
    } finally {
      this.clearPairingCodeFromCookie();
    }
  }
}
