import { SubscriptionPricing, supportedCurrencies } from 'lists/supportedCurrencies';
import { action, computed, makeObservable, observable } from 'mobx';
import AnalyticService from 'services/analyticsService/analyticsService';
import CampaignService from 'services/campaignService/campaignService';
import CartService from 'services/cartService/cartService';
import PageService from 'services/pageService/pageService';
import PromoService from 'services/promoService/promoService';
import UserService from 'services/userService/userService';

import {
  IPartners,
  ISubscriptionByUsableSubscription,
  SubscriptionPlan,
  SubscriptionPlanFromPromo,
} from 'types/subscriptions';

import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import { toUsableSubscription } from 'utils/subscriptionFilters';

import {
  AFFILIATE_PROMO_TYPE,
  FREE_CAMPAIGN_SUCCESS_PAGE_URL,
  GLOBAL_COUPON_TYPE,
  PERSONAL_COUPON_TYPE,
  USER_CLOUD_GROUP_NAME,
} from 'app/global/constants';
import { Routes } from 'app/global/routes';

export default class SubscriptionService {
  public constructor(
    private readonly _httpTransport: AxiosTransport,
    private readonly _campaignService: CampaignService,
    private readonly _userService: UserService,
    private readonly _promoService: PromoService,
    private readonly _cartService: CartService,
    private readonly _pageService: PageService,
    private readonly _analyticService: AnalyticService,
  ) {
    makeObservable(this);
  }

  @observable
  private _isFetchingSubscriptionPlans = false;

  @action
  public setIsFetchingSubscriptionPlans(isFetchingSubscriptionPlans: boolean) {
    this._isFetchingSubscriptionPlans = isFetchingSubscriptionPlans;
  }

  @computed
  public get isFetchingSubscriptionPlans() {
    return this._isFetchingSubscriptionPlans;
  }

  @observable
  private _allSubscriptionPlans: SubscriptionPlan[] = [];

  @action
  public setAllSubscriptionPlans(allSubscriptionPlans: SubscriptionPlan[]) {
    this._allSubscriptionPlans = allSubscriptionPlans;
  }

  @computed
  public get allSubscriptionPlans() {
    return this._allSubscriptionPlans;
  }

  public getCanPurchase(subscriptionPlan: SubscriptionPlan | ISubscriptionByUsableSubscription) {
    return subscriptionPlan && subscriptionPlan.can_purchase && subscriptionPlan.can_purchase.allow;
  }

  private filterByPurchaseableSubscriptions(subscriptionPlans: SubscriptionPlan[]): SubscriptionPlan[] {
    return subscriptionPlans.filter((subscription) => this.getCanPurchase(subscription));
  }

  public async fetchSubscriptionPlans(): Promise<SubscriptionPlan[]> {
    if (this.isFetchingSubscriptionPlans) {
      await new Promise<void>((resolve) => {
        const checkInterval = setInterval(() => {
          if (!this.isFetchingSubscriptionPlans) {
            clearInterval(checkInterval);
            resolve();
          }
        }, 100);
      });
      return this.allSubscriptionPlans;
    }
    try {
      this.setIsFetchingSubscriptionPlans(true);
      const subscriptionPlans = await this._httpTransport.get<SubscriptionPlan[]>('/v2/shop/subscriptionplans');
      if (!subscriptionPlans?.data) {
        this.setAllSubscriptionPlans([]);
        return [];
      }
      this.setAllSubscriptionPlans(subscriptionPlans.data);

      return subscriptionPlans.data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      this.setIsFetchingSubscriptionPlans(false);
    }
  }

  public async getHasAvailablePlansForPurchase(): Promise<boolean> {
    const subscriptionPlans = await this.fetchSubscriptionPlans();
    if (!subscriptionPlans.length) {
      return false;
    }
    const purchaseablePlans = this.filterByPurchaseableSubscriptions(subscriptionPlans);

    return !!purchaseablePlans.length;
  }

  public async getSubscriptionPlans(): Promise<ISubscriptionByUsableSubscription[]> {
    const subscriptionPlansResponse = await this.fetchSubscriptionPlans();
    if (!subscriptionPlansResponse.length) return [];
    const subscriptionPlans = subscriptionPlansResponse;

    const subscriptionData: SubscriptionPlan[] = JSON.parse(JSON.stringify(subscriptionPlans));
    const subscriptionDataByUsableSubscription = subscriptionData.map((subscription) =>
      toUsableSubscription(subscription),
    );

    return subscriptionDataByUsableSubscription;
  }

  public async getPartner(partnerId: string): Promise<IPartners | void> {
    const response = await this._httpTransport.get<IPartners>('/v1/partners/' + partnerId);
    if (response?.data) {
      return response.data;
    }
  }

  public async getSubscriptionPlanForPromo(promoCode: string): Promise<SubscriptionPlanFromPromo | null> {
    const PROMO_TYPES_SUPPORTING_SUBSCRIPTIONS = [GLOBAL_COUPON_TYPE, PERSONAL_COUPON_TYPE];
    let subscriptionPlanForPromo: SubscriptionPlanFromPromo | null = null;
    if (!promoCode) throw new Error('NO_CODE_SPECIFIED');
    const promoData = await this._promoService.getPromo({ promoCode: promoCode });
    const promoIsExpectedToHaveSubscription = promoData
      ? PROMO_TYPES_SUPPORTING_SUBSCRIPTIONS.includes(promoData.type)
      : null;

    if (!promoData || !promoData.subscriptionplan?.url || !promoIsExpectedToHaveSubscription) return null;

    const subscriptionURL = promoData.subscriptionplan.url + '?ref=' + promoCode;
    const promoSubResponse = await this._httpTransport.get<SubscriptionPlan>(subscriptionURL);
    if (!promoSubResponse?.data) return null;
    const promoSub = promoSubResponse.data;
    subscriptionPlanForPromo = promoSub;
    if (promoSub && this.getCanPurchase(promoSub)) {
      subscriptionPlanForPromo.ref = promoData;
      subscriptionPlanForPromo.ref.code = promoCode;
      return subscriptionPlanForPromo;
    } else if (!promoIsExpectedToHaveSubscription) {
      return subscriptionPlanForPromo;
    } else {
      const errorMessage = subscriptionPlanForPromo.can_purchase.message as string;
      throw new Error(errorMessage);
    }
  }

  public ensureProductInCartFromPromo = (
    selectedSubscription: SubscriptionPlanFromPromo,
    isPendingPairingStatus: boolean,
  ) => {
    if (selectedSubscription && selectedSubscription !== null) {
      console.info('Adding subscription to cart...');
      this._cartService.emptyCart();

      const subscriptionURL = 'shop/subscriptionplans/' + selectedSubscription.id;
      this._cartService.setProduct(subscriptionURL);

      const nextURL = isPendingPairingStatus ? Routes.SuccessTv : Routes.Success;
      this._cartService.setNext(nextURL);

      const promo = selectedSubscription.ref;
      const isAffiliate = selectedSubscription.type === AFFILIATE_PROMO_TYPE;
      promo && this._cartService.setPromo(isAffiliate ? null : promo);

      this._cartService.setMeta({ firstTimeSubscriber: true, canStartTrial: true });
      const subscriptionToUsable = toUsableSubscription(selectedSubscription);
      this._analyticService.handleAddToCart({ product: subscriptionToUsable, canStartTrial: true, promo });
    } else {
      this._analyticService.handleAddToCartError();
      const errorMessage =
        'Something went wrong (E002). Please try again, or contact us if the problem continues to exist.';
      throw new Error(errorMessage);
    }
  };

  public async goToEnrollment(promo?: string): Promise<string> {
    const searchStrings = ['/verify/', '/verify-email/'];
    let selectedSubscription = null;
    const userPromoCode = this._userService.user.promo_code;
    const promoCodeToUse = userPromoCode || promo;
    let activatedCampaign = null;
    if (promoCodeToUse) {
      const promoCode = this._userService.user.promo_code || promo;
      try {
        const promoSubscription = promoCode && (await this.getSubscriptionPlanForPromo(promoCode));
        selectedSubscription = promoSubscription;
      } catch (e) {
        console.error('error getSubscriptionPlanForPromo');
      }
    }
    try {
      activatedCampaign = await this._campaignService.tryActivateFreeSubscription();
    } catch (e) {
      console.error('error tryActivateFreeSubscription');
    }

    await this._userService.fetchUser();
    const user = this._userService.user;

    const isPairingCodePending =
      this._userService.pairingStatus === 'PENDING' || this._userService.pairingStatus === 'USED';
    const isCloudUser = user.groups.includes(USER_CLOUD_GROUP_NAME);
    const hasSubscription = user.groups && user.groups.includes('Subscription');
    const isFamilyMember = user.account?.role === 'Member';
    const otherSubscriptionRecommended = isPairingCodePending && !isCloudUser;
    const hasFreeCampaignSubscription = user.promo_code && activatedCampaign;

    if (hasSubscription && !otherSubscriptionRecommended) {
      console.info('Subscription detected, redirecting to the success page...');
      const recommendedSuccessPage = isPairingCodePending
        ? Routes.SuccessTv
        : hasFreeCampaignSubscription
        ? FREE_CAMPAIGN_SUCCESS_PAGE_URL
        : isFamilyMember
        ? Routes.SuccessMember
        : Routes.Success;
      return recommendedSuccessPage;
    } else if (selectedSubscription) {
      console.info('Predetermined subscription detected, redirecting to checkout...');
      await this.ensureProductInCartFromPromo(selectedSubscription, isPairingCodePending);
      return Routes.RegisterCheckout;
    } else if (isFamilyMember) {
      console.info('New family member detected, redirecting to the family member success page...');
      const recommendedSuccessPage = isPairingCodePending ? Routes.SuccessTv : Routes.SuccessMember;
      return recommendedSuccessPage;
    }
    const currentPath = this._pageService.getPath();
    return searchStrings.includes(currentPath) ? Routes.RegisterSelectPlan : Routes.SelectPlan;
  }

  public getDefaultSubscriptionPricing(currency: string): SubscriptionPricing | undefined {
    const supportedCurrencyWithDefault = supportedCurrencies[currency]?.defaultPricing;

    if (supportedCurrencyWithDefault) {
      supportedCurrencyWithDefault.currency = currency;
    }

    return supportedCurrencyWithDefault;
  }

  public getSubscriptionPlan = async (id: number | string): Promise<ISubscriptionByUsableSubscription | null> => {
    const response = await this._httpTransport.get<SubscriptionPlan>('/v2/shop/subscriptionplans/' + `${id}`);
    if (!response?.data) {
      return null;
    }

    const usableSubscription = toUsableSubscription(response.data);
    return usableSubscription;
  };
}
