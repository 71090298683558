import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Errors } from 'types/errors';
import { Messages } from 'types/messages';

import { parseErrorMessage, translateErrors } from 'utils/translateErrors';

import { PENDING_VERIFICATION_STATUSES } from 'app/global/constants';
import { Routes } from 'app/global/routes';

import { RegisterControllerContext, UserServiceContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';

import './verifyEmailPage.scss';

const VerifyEmailPage: React.FC = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('Your email address has been verified.');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const registerController = useContext(RegisterControllerContext);
  const userService = useContext(UserServiceContext);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        await registerController.verifyEmailWithLink();
        await userService.checkIsLoggedIn();
        await userService.fetchUser();
        setIsSuccess(true);
      } catch (error: any) {
        const errorMessage = parseErrorMessage(error);
        setMessage(translateErrors(error));

        if (errorMessage === Errors.USER_ALREADY_VERIFIED) {
          return setIsError(false);
        }
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const redirectToNextPage = async () => {
    const redirectUrl = await registerController.goToEnrollment();
    setTimeout(() => {
      navigate(redirectUrl || Routes.RegisterSelectPlan);
    }, 5000);
  };

  useEffect(() => {
    if (isSuccess && !PENDING_VERIFICATION_STATUSES.includes(userService.verificationStatus)) {
      redirectToNextPage();
    }
  }, [isSuccess, userService.verificationStatus]);

  if (isLoading) {
    return (
      <div>
        <p className="verify-email-loader verify-email-loader--loading">{Messages.VERIFYING_EMAIL}</p>
      </div>
    );
  }

  const renderMessage = () => {
    if (isSuccess) {
      return (
        <>
          <p>You have successfully verified your email addres.</p>{' '}
          <span className="verify-email-page__wrapper-small-text">
            (you will be taken to the next screen in a few seconds)
          </span>
        </>
      );
    } else {
      return <p>{message}</p>;
    }
  };

  return (
    <div className="verify-email-page container">
      <div className="page-section">
        <>
          {!isError ? (
            <div className="verify-email-page__wrapper">
              <FontAwesomeIcon icon="check" />

              {renderMessage()}
            </div>
          ) : (
            <div className="verify-email-page__wrapper-failed">
              <h1 className="verify-email-page-failed">Failed to verify your email address</h1>
              <Alert danger>{message}</Alert>
            </div>
          )}
        </>
      </div>
    </div>
  );
});

export default VerifyEmailPage;
