import { IOrdersResponse } from 'types/orders';
import { IPromoResponse } from 'types/promo';
import {
  ISubscriptionByUsableSubscription,
  ISubscriptionsResponseWithOrders,
  SubscriptionPlan,
} from 'types/subscriptions';

export const analyticsDisplayCurrency = (product: ISubscriptionByUsableSubscription) => {
  if (!product || !product['_totalPricing'] || !product['_totalPricing']?.currency) {
    if (product && product.has_reseller) {
      return 'RESELLER';
    } else {
      return undefined;
    }
  }
  return product['_totalPricing'].currency;
};

export const analyticsDisplayPrice = (product: ISubscriptionByUsableSubscription) => {
  if (!product || !product['_totalPricing'] || !product['_totalPricing'].amount_in_cents) {
    return 0;
  }

  return product['_totalPricing'].amount_in_cents / 100.0;
};

export const analyticsListOfItems = (
  product: ISubscriptionByUsableSubscription | ISubscriptionsResponseWithOrders | SubscriptionPlan,
) => {
  if (!product || !product.name) {
    return [];
  }
  const itemToAnalyticsItem = (item: { id: string | number; name: string }) => ({
    item_id: item.id,
    item_name: item.name,
  });
  const items = [itemToAnalyticsItem(product)];
  items.push(...product.add_ons.map(itemToAnalyticsItem));

  return items;
};

export const analyticsItemDescription = (subscription: ISubscriptionsResponseWithOrders) => {
  const items = analyticsListOfItems(subscription);

  if (items.length) {
    return items
      .map((item) => {
        return item.item_name;
      })
      .join(', ');
  } else {
    return undefined;
  }
};

const fancyPeriodName = (str: string, plural: boolean) => {
  return str === 'DY'
    ? !plural
      ? 'day'
      : 'days'
    : str === 'MO'
    ? !plural
      ? 'month'
      : 'months'
    : str === 'YR'
    ? !plural
      ? 'year'
      : 'years'
    : !plural
    ? str
    : str + 's';
};

const subscriptionOrTrialTime = ({
  subscription,
  neverPluralize,
}: {
  subscription: ISubscriptionByUsableSubscription;
  neverPluralize: boolean;
}) => {
  if (!subscription) {
    // null or undefined? return it at once
    return undefined;
  }

  let plural = subscription.period_length > 1;
  if (neverPluralize) {
    plural = false;
  }
  return subscription.period_length + ' ' + fancyPeriodName(subscription.period, plural);
};

export const hubSpotContentIdsObject = ({
  product,
  canStartTrial,
  promo,
}: {
  product: ISubscriptionByUsableSubscription;
  canStartTrial: boolean;
  promo?: IPromoResponse;
}) => {
  return {
    subscriptionOrTrial: canStartTrial ? 'Trial_Subscription' : 'Subscription',
    subscriptionType: product ? (product.type === 'SF' ? 'Family' : 'Personal') : undefined,
    subscriptionLength: subscriptionOrTrialTime({ subscription: product, neverPluralize: false }),
    promoCode: promo ? promo.code : undefined,
    promoName: promo ? promo.name : undefined,
  };
};

export const hubSpotContentIdsArray = ({
  product,
  canStartTrial,
  promo,
}: {
  product: ISubscriptionByUsableSubscription;
  canStartTrial: boolean;
  promo?: IPromoResponse;
}) => {
  const hubSpotObj = hubSpotContentIdsObject({
    canStartTrial,
    product,
    promo,
  });
  return [hubSpotObj.subscriptionOrTrial, hubSpotObj.subscriptionType, hubSpotObj.promoCode].filter(Boolean);
};

export const analyticsDisplayName = ({
  product,
}: {
  product: ISubscriptionsResponseWithOrders | ISubscriptionByUsableSubscription;
}) => {
  if (!product || !product.name) {
    return '(Unknown product)';
  }
  let name = product.name;
  let subscriptionID = null;
  if ('order' in product && (product?.order as IOrdersResponse)?.order_shop_items) {
    const subscription = (product.order as IOrdersResponse).order_shop_items.find((item) =>
      item.shop_item.url.includes('subscriptionplans'),
    );
    subscriptionID = subscription ? subscription.id : null;
  }
  if (('order' in product && product?.order) || product.id) {
    name += ' (' + (subscriptionID ? subscriptionID : product.id) + ')';
  }
  return name;
};
