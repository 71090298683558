import { BreadcrumbProps } from 'types/shared';

export const redeemBreadcrumbs: BreadcrumbProps[] = [
  {
    order: 1,
    title: 'Redeem code',
    subtitle: '',
    active: true,
  },
  {
    order: 2,
    title: 'Download Utomik',
    subtitle: '',
  },
  {
    order: 3,
    title: 'Start playing',
    subtitle: '',
  },
];
