import { useRecurly } from '@recurly/react-recurly';
import { recurlyPayPalSchema } from 'app/validationSchemes/recurlyPayPalSchema';
import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { IExtraData, IPaymentType, IRecurlyPayPalToken } from 'types/billing';
import { Messages } from 'types/messages';

import { transformCountriesRangeWIthAlpha, transformCountryToCountryAlpha } from 'utils/transformCountries';

import { UserControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Input from 'app/components/input/input';
import Select from 'app/components/select/select';

import SuccessAddCard from '../successAddCard/successAddCard';

import styles from './recurlyPayPal.module.scss';

interface IRecurlyPayPal {
  handleSubmitCard: (extraData: IExtraData, recurlyToken: string, paymentType: IPaymentType) => void;
  handleBack: () => void;
  selectedProductName?: string;
  isCheckoutPage: boolean;
}

const RecurlyPayPal: React.FC<IRecurlyPayPal> = observer(
  ({ handleBack, handleSubmitCard, selectedProductName, isCheckoutPage }) => {
    const userController = useContext(UserControllerContext);
    const recurly = useRecurly();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [isWaitingTransaction, setIsWaitingTransaction] = useState(false);
    const [error, setError] = useState('');

    const { user, allCountries } = userController;

    const initialValues = {
      address: '',
      postalCode: '',
      country: user.country
        ? transformCountryToCountryAlpha({ allCountries: allCountries, countryId: `${user.country?.id}` })
        : '',
    };

    const updateBillingInfo = async (recurlyToken: string, recaptchaToken: string) => {
      const extraData = {
        address: formik.values.address,
        zip: formik.values.postalCode,
        country: formik.values.country,
        g_recaptcha_response: recaptchaToken,
      };

      await handleSubmitCard(extraData, recurlyToken, 'paypal');
    };

    const goBack = () => {
      handleBack();
    };

    const onSubmitForm = () => {
      setIsWaitingTransaction(true);
      if (recurly) {
        const paypal = recurly.PayPal({
          display: {
            displayName: 'Utomik',
          },
        });
        paypal.on('token', (token: IRecurlyPayPalToken) => {
          if (token.id) {
            if (!executeRecaptcha) {
              setError(Messages.RECAPTCHA_FAILED);
              return;
            }
            executeRecaptcha('enquiryFormSubmit')
              .then((gReCaptchaToken) => {
                updateBillingInfo(token.id, gReCaptchaToken);
              })
              .catch(() => {
                setError(Messages.RECAPTCHA_FAILED);
              });
          }
        });
        paypal.on('error', (error) => {
          setError(
            error.cause.message ||
              error.message ||
              'Unable to request paypal token. Please check your internet connection and try again.',
          );
          setIsWaitingTransaction(false);
        });
        paypal.on('ready', () => {
          console.info('PayPal Ready.');
        });

        paypal.on('cancel', () => {
          setIsWaitingTransaction(false);
        });

        paypal.start({ options: { description: selectedProductName || 'Utomik' } });
      } else {
        setError('Unable to request paypal token. Please check your internet connection and try again.');
      }
    };

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: recurlyPayPalSchema,
      onSubmit: onSubmitForm,
      enableReinitialize: true,
      validateOnBlur: false,
      validateOnChange: true,
    });

    const handleChangeFormik = (e: React.ChangeEvent<HTMLInputElement>) => {
      const changedValue = e.target.name as keyof typeof formik.values;
      if (e.target.value && !formik.touched[changedValue]) {
        formik.setFieldTouched(changedValue, true);
      }
      formik.handleChange(e);
    };

    const handleBlurFormik = (e: React.ChangeEvent<HTMLInputElement>) => {
      const touchedValue = e.target.name as keyof typeof formik.values;
      if (!formik.touched[touchedValue]) {
        formik.setFieldTouched(touchedValue, false);
      }
      return;
    };

    const isDisabledButton = formik.values.address && formik.values.postalCode && formik.values.country;
    return (
      <FormikProvider value={formik}>
        {isWaitingTransaction && (
          <SuccessAddCard
            isFetching={isWaitingTransaction}
            description={
              <>
                <p>Waiting for PayPal transaction to finish&hellip;</p>
                <span className="small">Please complete all steps in the PayPal window</span>
              </>
            }
          />
        )}
        {error && (
          <>
            <Alert className={clsx(styles['recurly-paypal-error'])} danger>
              {error}
            </Alert>
            <Button className={isCheckoutPage && styles['paypal-btn-error-checkout']} xl onClick={goBack}>
              Go back
            </Button>
          </>
        )}
        {!isWaitingTransaction && !error && (
          <div className={clsx(styles['recurly-paypal'])}>
            <form onSubmit={formik.handleSubmit} className={styles['recurly-paypal-form']}>
              <input type="hidden" name="recurly-token" data-recurly="token" />

              <legend className={styles['legend']}>Billing address</legend>
              <div className={styles['recurly-field']}>
                <label className={styles['label']}>Address</label>
                <Input name="address" data-recurly="address1" onChange={handleChangeFormik} onBlur={handleBlurFormik} />
              </div>
              <div className={styles['recurly-fields-flex']}>
                <div className={clsx(styles['field-zip-code'], styles['recurly-field'])}>
                  <label className={styles['label']}>Zip code</label>
                  <Input
                    name="postalCode"
                    data-recurly="postal_code"
                    onChange={handleChangeFormik}
                    onBlur={handleBlurFormik}
                  />
                </div>
              </div>

              <div className={clsx(styles['field-country'], styles['recurly-field'])}>
                <label className={styles['label']}>Country</label>

                <Select
                  name="country"
                  isValidateErrors={false}
                  data-recurly="country"
                  value={
                    formik.values.country ||
                    transformCountryToCountryAlpha({
                      allCountries: allCountries,
                      countryId: `${userController.userCountry.id}`,
                    })
                  }
                  options={transformCountriesRangeWIthAlpha(allCountries)}
                />
              </div>

              <div className={styles['form-buttons']}>
                <Button xl onClick={goBack}>
                  Go back
                </Button>
                <Button type="submit" primary lg className={styles['button-save']} disabled={!isDisabledButton}>
                  Continue
                </Button>
              </div>
            </form>
          </div>
        )}
      </FormikProvider>
    );
  },
);
export default RecurlyPayPal;
