import ChangePasswordModal from 'app/modals/changePasswordModal/changePasswordModal';
import clsx from 'clsx';
import { FormikProvider } from 'formik';
import { dateOfBirthD, dateOfBirthM, emptyOptions, genderOptions, yearRange } from 'lists/selectOptions';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { countriesRange } from 'utils/transformDataToRange';
import { translateErrors } from 'utils/translateErrors';

import { MIN_FIELD_VALUE_LENGTH } from 'app/global/constants';
import { Routes } from 'app/global/routes';

import { UserControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Checkbox from 'app/components/checkbox/checkbox';
import Input from 'app/components/input/input';
import Modal from 'app/components/modal/modal';
import RadioGroup from 'app/components/radioGroup/radioGroup';
import Select from 'app/components/select/select';

import ChangeAvatarModal from '../../modals/changeAvatarModal/changeAvatarModal';

import { afterSubmitError, successSubmitMessage } from './formMessages';
import useProfileForm from './useProfileForm';

import styles from './profileForm.module.scss';

export type ProfileFormValues = {
  email: string;
  password: string;
  gender: string;
  userName: string;
  firstName: string;
  lastName: string;
  dateOfBirthM: string;
  dateOfBirthD: string;
  dateOfBirthY: string;
  country: string;
  newsletterSignUp: boolean;
};

export const ProfileForm = observer(() => {
  const userController = useContext(UserControllerContext);
  const [countryNotSupportedError, setCountryNotSupportedError] = useState('');
  const [isLoadingCheckUserName, setIsLoadingCheckUserName] = useState(false);
  const [isLoadingCheckEmail, setIsLoadingCheckEmail] = useState(false);

  const [isLoadingPatchData, setIsLoadingPatchData] = useState(false);
  const [errorUserName, setErrorUserName] = useState<string | undefined>('');
  const [errorFormEmail, setErrorFormEmail] = useState<string | undefined>('');

  const [isPatchError, setIsPatchError] = useState(false);
  const [isPatchSuccess, setIsPatchSuccess] = useState(false);
  const [modalAvatarIsOpen, setModalAvatarIsOpen] = useState(false);
  const [modalPasswordIsOpen, setModalPasswordIsOpen] = useState(false);
  const navigate = useNavigate();

  const user = userController.user;

  const { validateUserName, formik, canChangeOwnBirthdate, validateEmail } = useProfileForm({
    setCountryNotSupportedError,
    setIsLoadingCheckUserName,
    setIsLoadingPatchData,
    setIsPatchError,
    setIsPatchSuccess,
    setErrorUserName,
    setIsLoadingCheckEmail,
    setErrorFormEmail,
    errorFormEmail,
    errorUserName,
  });
  const toggleModalAvatar = () => {
    setModalAvatarIsOpen((prev) => !prev);
  };

  const toggleModalPassword = () => {
    setModalPasswordIsOpen((prev) => !prev);
  };

  const handleChangePassword = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    toggleModalPassword();
  };

  const handleChangeAvatar = () => {
    toggleModalAvatar();
  };

  const handleChangeUserName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < MIN_FIELD_VALUE_LENGTH) {
      formik.setFieldTouched('userName', true);
    }

    formik.handleChange(e);
    if (e.target.value.length > 2 && !formik.errors.userName) {
      validateUserName(e.target.value);
    }
  }, []);

  const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length && !formik.touched.email) {
      formik.setFieldTouched('email', true);
    }
    formik.handleChange(e);
    if (!formik.errors.email) {
      validateEmail(e.target.value);
    }
  }, []);

  const isDisabledSubmitButton = useMemo(() => {
    const { dateOfBirthY, dateOfBirthD, dateOfBirthM } = formik.values;

    if (!canChangeOwnBirthdate()) {
      formik.setFieldError('dateOfBirthM', undefined);
      return (
        !!formik.errors.userName ||
        !formik.values.userName ||
        !!formik.errors.email ||
        !formik.values.email ||
        !!countryNotSupportedError ||
        isLoadingPatchData ||
        isLoadingCheckUserName ||
        isLoadingCheckEmail
      );
    }
    if (isPatchError && (!dateOfBirthY || !dateOfBirthD || !dateOfBirthM)) {
      return true;
    }
    return (
      !!formik.errors.userName ||
      !formik.values.userName ||
      !!formik.errors.email ||
      !formik.values.email ||
      !!formik.errors.dateOfBirthM ||
      !!countryNotSupportedError ||
      isLoadingPatchData ||
      isLoadingCheckUserName ||
      isLoadingCheckEmail
    );
  }, [
    formik.errors.userName,
    formik.errors.email,
    formik.values,
    formik.errors.dateOfBirthM,
    isLoadingPatchData,
    isLoadingCheckUserName,
    isLoadingCheckEmail,
    isPatchError,
    countryNotSupportedError,
  ]);

  const renderProfileSection = (title: string, children: React.ReactNode) => (
    <div className={styles['profile-form-section']}>
      <h3 className={styles['profile-form--legend']}>{title}</h3>
      {children}
    </div>
  );

  const redirectToAccountDeletion = () => {
    return navigate(Routes.DeleteAccount);
  };
  return (
    <FormikProvider value={formik}>
      <div className={styles['profile-form-wrapper']}>
        <form className={styles['profile-form--form']} onSubmit={formik.handleSubmit}>
          {renderProfileSection(
            'Account login',
            <>
              <div className={styles['profile-form-fields-container']}>
                <div className={styles['profile-form-field-label']}>Email address</div>
                {user.email ? (
                  <div className={clsx(styles['profile-form-field-item'], styles['profile-form-field-not-edit'])}>
                    {user.email}
                  </div>
                ) : (
                  <div
                    className={clsx(
                      styles['profile-form-field-item-input'],
                      !formik.values.email && styles['profile-form-field-item-input--invalid'],
                    )}
                  >
                    <Input
                      name="email"
                      placeholder="Add an email address to your account"
                      onChange={handleEmailChange}
                      isLoading={isLoadingCheckEmail}
                    />
                  </div>
                )}
              </div>
              <div className={styles['profile-form-fields-container']}>
                <div className={styles['profile-form-field-label']}>Password</div>
                <div className={styles['profile-form-field-item']}>
                  <Button edit onClick={handleChangePassword}>
                    {formik.values.password} <span className="fas fa-edit" />
                  </Button>
                </div>
              </div>
            </>,
          )}

          {renderProfileSection(
            'Avatar',
            <div className={styles['profile-form-fields-container']}>
              <div className={styles['profile-form-field-label']}>Avatar</div>
              {user.avatar_url ? (
                <div className={clsx(styles['profile-form-field-item'], styles['profile-form-avatar'])}>
                  <img src={user.avatar_url} alt="User Avatar" onClick={handleChangeAvatar} />
                </div>
              ) : (
                <div
                  className={clsx(styles['profile-form-field-item'], styles['profile-form-field-avatar'])}
                  onClick={handleChangeAvatar}
                />
              )}
            </div>,
          )}
          {renderProfileSection(
            'Profile',
            <>
              <div className={styles['profile-form-fields-container']}>
                <div className={styles['profile-form-field-label']}>Gender</div>
                <div className={styles['profile-form-field-item']}>
                  <RadioGroup name="gender" options={genderOptions} value={formik.values.gender} />
                </div>
              </div>
              <div className={styles['profile-form-fields-container']}>
                <div className={styles['profile-form-field-label']}>Username *</div>
                <div
                  className={clsx(
                    styles['profile-form-field-item-input'],
                    !formik.values.userName && styles['profile-form-field-item-input--invalid'],
                  )}
                >
                  <Input
                    name="userName"
                    placeholder="Username"
                    onChange={handleChangeUserName}
                    isLoading={isLoadingCheckUserName}
                  />
                </div>
              </div>
              <div className={styles['profile-form-fields-container']}>
                <div className={styles['profile-form-field-label']}>First name</div>
                <div className={clsx(styles['profile-form-field-item-input'])}>
                  <Input name="firstName" placeholder="First Name" />
                </div>
              </div>
              <div className={styles['profile-form-fields-container']}>
                <div className={styles['profile-form-field-label']}>Last name</div>
                <div className={clsx(styles['profile-form-field-item-input'])}>
                  <Input name="lastName" placeholder="Last Name" />
                </div>
              </div>
              <div className={styles['profile-form-fields-container']}>
                <div className={styles['profile-form-field-label']}>Date of birth *</div>
                {canChangeOwnBirthdate() ? (
                  <div
                    className={clsx(
                      styles['profile-form-field-item-select'],
                      (!formik.values.dateOfBirthM || !formik.values.dateOfBirthD || !formik.values.dateOfBirthY) &&
                        styles['profile-form-field-item-input--invalid'],
                      formik.errors.dateOfBirthM && styles['profile-form-field-item-input--error'],
                    )}
                  >
                    <div className={styles['profile-form-field-item-select-item']}>
                      <Select
                        name="dateOfBirthM"
                        isValidateErrors={false}
                        options={formik.values.dateOfBirthM ? dateOfBirthM : [emptyOptions, ...dateOfBirthM]}
                      />
                      <Select
                        name="dateOfBirthD"
                        isValidateErrors={false}
                        options={formik.values.dateOfBirthD ? dateOfBirthD() : [emptyOptions, ...dateOfBirthD()]}
                      />
                      <Select
                        name="dateOfBirthY"
                        isValidateErrors={false}
                        options={formik.values.dateOfBirthY ? yearRange : [emptyOptions, ...yearRange]}
                      />
                    </div>
                    {formik.errors.dateOfBirthM && (
                      <div>
                        <Alert danger>{formik.errors.dateOfBirthM}</Alert>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>{moment(user.birthdate).format('MMMM Do, YYYY')}</div>
                )}
              </div>
              <div className={styles['profile-form-fields-container']}>
                <div className={styles['profile-form-field-label']}>Country</div>
                <div className={clsx(styles['profile-form-field-item-input'])}>
                  {!user.country ? (
                    <div
                      className={clsx(
                        styles['profile-form-field-item-country'],
                        countryNotSupportedError && styles['profile-form-field-item-country-error'],
                      )}
                    >
                      <Select
                        name="country"
                        isValidateErrors={false}
                        value={formik.values.country || userController.userCountry.id}
                        options={countriesRange(userController.allCountries)}
                      />
                      {countryNotSupportedError && <Alert danger>{translateErrors(countryNotSupportedError)}</Alert>}
                    </div>
                  ) : (
                    <div className={styles['profile-form-field-not-edit']}>{user.country.name}</div>
                  )}
                </div>
              </div>
            </>,
          )}
          {renderProfileSection(
            'Newsletter preferences',
            <div className={styles['profile-form-fields-container']}>
              <div className={clsx(styles['profile-form-field-item'])}>
                <Checkbox
                  name="newsletterSignUp"
                  checked={formik.values.newsletterSignUp || false}
                  label="Subscribed to newsletter"
                />
              </div>
            </div>,
          )}

          {isPatchError && <Alert danger>{afterSubmitError}</Alert>}
          {isPatchSuccess && <Alert success>{successSubmitMessage}</Alert>}
          <div className={styles['profile-form-section']}>
            <div className={styles['profile-form-fields-container']}>
              <div className={clsx(styles['profile-form-field-item'])}>
                <Button type="submit" disabled={isDisabledSubmitButton} xl primary loading={isLoadingPatchData}>
                  Save profile
                </Button>
              </div>
            </div>
          </div>
        </form>
        <div>
          <Button link onClick={redirectToAccountDeletion}>
            Manage account deletion
          </Button>
        </div>
      </div>
      <Modal
        show={modalAvatarIsOpen}
        onHide={toggleModalAvatar}
        title="Upload avatar"
        className={styles['avatar-form-modal']}
      >
        <ChangeAvatarModal closeModal={toggleModalAvatar} />
      </Modal>
      <Modal show={modalPasswordIsOpen} onHide={toggleModalPassword} title="Change password">
        <ChangePasswordModal closeModal={toggleModalPassword} />
      </Modal>
    </FormikProvider>
  );
});
export default ProfileForm;
