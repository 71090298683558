import clsx from 'clsx';
import React, { useEffect } from 'react';

import { IPlatformObject, Platform } from 'types/shared';

import { supportedCloudDevicesURL } from 'app/global/constants';

import Button from 'app/components/button/button';
import { IconMobile } from 'app/components/icons';

import styles from './downloadOptions.module.scss';

interface IDownloadOptions {
  platform?: Platform;
  isCloudUser: boolean;
  canUpgradeToCloud: boolean;
  platformList: IPlatformObject[];
  initPlatformList: (platform?: Platform | null) => void;
  handleDownloadUtomik: () => void;
  handleDownloadGooglePlay: () => void;
}

const DownloadOptions: React.FC<IDownloadOptions> = ({
  platform = null,
  isCloudUser,
  canUpgradeToCloud,
  platformList,
  initPlatformList,
  handleDownloadUtomik,
  handleDownloadGooglePlay,
}) => {
  useEffect(() => {
    initPlatformList(platform);
  }, []);
  return (
    <div>
      <h2 className={styles['download-options-title']}>Start playing now!</h2>
      <div className={styles['download-options']}>
        {platformList.map((platforml) => (
          <div className={styles['download-options-devices']} key={platforml.label}>
            {platforml.label === 'DESKTOP' && (
              <div
                key={platforml.label}
                className={styles['download-platform-wrapper']}
                // className={clsx(styles['download-platform'], styles['download-platform-desktop'])}
              >
                <div className={clsx(styles['download-platform-header'], styles['download-platform-header-pc'])}>
                  <h3 className={styles['download-title']}>
                    <span className="fa-solid fa-laptop"></span> PC
                  </h3>
                  <div className={clsx(styles['download-platform-image'], styles['download-platform-image-pc'])}>
                    <img src="/images/logo/windows-logo-sp.png" alt="PC" />
                  </div>
                </div>
                <div className={styles['download-platform-body']}>
                  <div className={styles['download-text']}>Download the Utomik app on your PC and play right away!</div>
                  <Button
                    primary
                    className={clsx(
                      'btn-with-underscript',
                      platforml.is_active && ' btn-primary',
                      styles['btn-download'],
                    )}
                    title="Download the Utomik installer"
                    onClick={handleDownloadUtomik}
                  >
                    Download Utomik
                  </Button>

                  <small>
                    <em>Requires Windows 10 or a newer version.</em>
                  </small>
                </div>
              </div>
            )}
            {platforml.label === 'ANDROID' && (
              <div className={styles['download-platform-wrapper']}>
                <div className={clsx(styles['download-platform-header'], styles['download-platform-header-mobile'])}>
                  <h3 className={styles['download-title']}>
                    <IconMobile /> Mobile devices
                  </h3>
                  <div className={clsx(styles['download-platform-image'], styles['download-platform-image-mobile'])}>
                    <img src="/images/logo/android-logo.png" alt="mobile" />
                  </div>
                </div>
                <div className={styles['download-platform-body']}>
                  {isCloudUser && (
                    <>
                      <div className={styles['download-text']}>
                        If you selected a Cloud plan, download Utomik directly from the Google Play Store!
                      </div>
                    </>
                  )}

                  {!isCloudUser && (
                    <div>
                      {canUpgradeToCloud && (
                        <div className={styles['download-text']}>
                          Upgrade to a Utomik Cloud subscription to play games on Android devices.
                        </div>
                      )}
                      {!canUpgradeToCloud && (
                        <div className={styles['download-text']}>
                          After this subscription ends, try a Utomik Cloud subscription to also play games on Android
                          devices.
                        </div>
                      )}
                    </div>
                  )}
                  <div>
                    <Button
                      className="btn-image btn-google-play"
                      title="Download the Utomik app in the Google Play store"
                      onClick={handleDownloadGooglePlay}
                    ></Button>
                  </div>
                </div>
              </div>
            )}
            {platforml.label === 'TV' && (
              <div className={styles['download-platform-wrapper']}>
                <div className={clsx(styles['download-platform-header'], styles['download-platform-header-tv'])}>
                  <h3 className={styles['download-title']}>
                    <span className="fa-solid fa-tv" /> TV
                  </h3>
                  <div className={styles['download-platform-image-flex']}>
                    <div className={clsx(styles['download-platform-image'], styles['download-platform-image-samsung'])}>
                      <img src="/images/logo/samsung-logo.png" alt="SAMSUNG" />
                    </div>{' '}
                    |
                    <div className={clsx(styles['download-platform-image'], styles['download-platform-image-lg'])}>
                      <img src="/images/logo/lg-logo.png" alt="LG" />
                    </div>
                  </div>
                </div>
                <div className={styles['download-platform-body']}>
                  {platforml.is_active && (
                    <div>
                      <div className={styles['download-text']}>
                        You can now log in on TVs where Utomik is installed.
                      </div>
                      <div className={styles['download-text']}>
                        <a
                          href="https://support.utomik.com/hc/en-us/articles/6864603586716-How-do-I-access-the-app-"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Read how get started on your TV
                        </a>
                      </div>
                    </div>
                  )}
                  {!platforml.is_active && isCloudUser && (
                    <>
                      <div className={styles['download-text']}>
                        Download Utomik Cloud from your TV’s app store or play it on Samsung Gaming Hub. Available on
                        select TV models.
                      </div>
                      <div className={styles['download-text']}>
                        <a href={supportedCloudDevicesURL} target="_blank" rel="noopener noreferrer">
                          See all supported devices
                        </a>
                      </div>
                    </>
                  )}
                  {!platforml.is_active && !isCloudUser && (
                    <div className={styles['download-text']}>
                      Upgrade to a Utomik Cloud subscription to play games on newer Samsung and LG televisions.
                    </div>
                  )}
                  {!isCloudUser && (
                    <div>
                      <div className={styles['download-text']}>
                        <a href={supportedCloudDevicesURL} target="_blank" rel="noopener noreferrer">
                          See all supported devices
                        </a>
                      </div>
                      {canUpgradeToCloud && (
                        <div className={clsx(styles['download-text'], styles['btn-download-link'])}>
                          <a href="/selectplan">Upgrade to a Utomik Cloud subscription</a>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export default DownloadOptions;
