export const IconUser = () => {
  return (
    <svg width="24" height="27" viewBox="0 0 24 27" fill="none">
      <path
        d="M12 13.5C13.8186 13.5 15.5628 12.7888 16.8487 11.523C18.1347 10.2571 18.8571 8.54021 18.8571 6.75C18.8571 4.95979 18.1347 3.2429 16.8487 1.97703C15.5628 0.711159 13.8186 0 12 0C10.1814 0 8.43723 0.711159 7.15127 1.97703C5.8653 3.2429 5.14286 4.95979 5.14286 6.75C5.14286 8.54021 5.8653 10.2571 7.15127 11.523C8.43723 12.7888 10.1814 13.5 12 13.5ZM9.55179 16.0312C4.275 16.0312 0 20.2395 0 25.4338C0 26.2986 0.7125 27 1.59107 27H22.4089C23.2875 27 24 26.2986 24 25.4338C24 20.2395 19.725 16.0312 14.4482 16.0312H9.55179Z"
        fill="#181A33"
      />
    </svg>
  );
};
