import { IOrdersResponse } from './orders';
import { IPromoResponse } from './promo';

export type UserSubscriptionType =
  | 'ACTIVE'
  | 'REACTIVATED'
  | 'RESTORED'
  | 'CANCELED'
  | 'DEACTIVATED'
  | 'EXPIRED'
  | 'SWITCHED';
export type SubscriptionType = 'SP' | 'SF' | 'AF';
export type TrialPeriod = 'DY' | 'MO' | 'YR';
export enum SubscriptionsTrialPeriod {
  'DY' = 'day',
  'MO' = 'month',
  'YR' = 'year',
}

export interface IReseller {
  reseller: boolean | null;
}

export interface SubscriptionPlansTrialPeriod {
  trialPeriod: string | null;
  trialPeriodLength: number;
}

export interface Pricing {
  amount_in_cents: number;
  currency: string;
  includes_tax: boolean;
}

export interface IAddOne {
  id: number;
  url: string;
  name: string;
  add_on_type: string;
  type: string;
}

export interface IAddOneWithPricingArray extends IAddOne {
  pricing: Pricing[];
}

export interface IAddOneWithPricing extends IAddOne {
  pricing: Pricing;
}

interface ICanPurchase {
  allow: boolean;
  message: string | null;
}

interface IOrder {
  url: string;
  id: number;
}

export interface SubscriptionPlan {
  add_ons: IAddOneWithPricingArray[];
  affiliate: null;
  can_purchase: ICanPurchase;
  has_reseller: boolean;
  id: number;
  max_members: number;
  name: string;
  period: keyof typeof SubscriptionsTrialPeriod;
  period_length: number;
  pricing: Pricing[];
  recurring: boolean;
  requires_payment_info: boolean;
  trial_period: keyof typeof SubscriptionsTrialPeriod | null;
  trial_period_length: number;
  type: SubscriptionType;
  url: string | null;
  _isCloudSubscription: boolean;
}

export interface SubscriptionPlanFromPromo extends SubscriptionPlan {
  ref?: IPromoResponse;
}

export interface ISubscriptionByUsableSubscription {
  add_ons: IAddOneWithPricing[];
  affiliate: null;
  can_purchase: ICanPurchase;
  has_reseller: boolean;
  id: number;
  max_members: number;
  name: string;
  period: keyof typeof SubscriptionsTrialPeriod;
  period_length: number;
  pricing: Pricing[];
  recurring: boolean;
  requires_payment_info: boolean;
  trial_period: keyof typeof SubscriptionsTrialPeriod | null;
  trial_period_length: number;
  type: SubscriptionType;
  url: string | null;
  ref?: IPromoResponse;
  _isCloudSubscription: boolean;
  _totalPricing: Pricing;
  _isRecommended?: boolean;
}

export interface ISubscriptionsResponseWithOrders {
  add_ons: IAddOneWithPricingArray[];
  url: string;
  id: number;
  status: UserSubscriptionType;
  recurring: boolean;
  name: string;
  type: SubscriptionType;
  order: IOrdersResponse | IOrder;
  in_grace: boolean;
  renews: Date;
  in_trial: boolean;
  paid_until: Date;
  expire_due: Date | null;
  max_members: number | null;
  _isCloudSubscription: boolean;
  _totalPricing: Pricing;
  _isRecommended?: boolean;
}
export interface ISubscriptionsResponse {
  url: string;
  id: number;
  status: UserSubscriptionType;
  recurring: boolean;
  name: string;
  type: SubscriptionType;
  order: IOrder;
  in_grace: boolean;
  renews: Date;
  in_trial: boolean;
  add_ons: IAddOneWithPricingArray[];
  paid_until: Date;
  expire_due: Date | null;
  max_members: number | null;
}

export interface IUserSubscriptionResponse {
  add_ons: IAddOneWithPricingArray[];
  expire_due: Date | null;
  id: number;
  in_grace: boolean;
  in_trial: boolean;
  name: string;
  order: IOrder & IReseller;
  paid_until: Date;
  recurring: boolean;
  renews: Date;
  status: UserSubscriptionType;
  type: SubscriptionType;
  url: string;
}

export interface SubscriptionDetails {
  [key: number]: {
    error: undefined;
    isLoading: boolean;
  };
}

export interface IPartners {
  url: string;
  id: number;
  name: string;
  site: string;
  logo: {
    id: number;
    url: string;
  };
  address: {
    street: string;
    postcode: string;
    city: string;
    state: string;
    country: {
      url: string;
      id: number;
    };
  };
  contacts: string[];
}

export type SubscriptionFilters = [
  | 'filterActiveSubscriptions'
  | 'filterFamilySubscriptions'
  | 'filterPersonalSubscriptions'
  | 'activeUtomikSubscriptions',
];
export type SubscriptionFilterType<T extends SubscriptionFilters | string[] = string[]> = T extends string
  ? T
  : string[];
