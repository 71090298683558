import LoginService from 'services/loginService/loginServise';
import PageService from 'services/pageService/pageService';

import { IResponsePayForOrder, PayForOrder } from 'types/billing';
import { ICreateOrder, PaymentData3Ds } from 'types/payment';

import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import { removePlatformURLFromURL } from 'utils/utils';

import { platformURL } from 'app/global/constants';

export default class PaymentService {
  public constructor(
    private readonly _httpTransport: AxiosTransport,
    private readonly _loginService: LoginService,
    private readonly _pageService: PageService,
  ) {}

  public async payForOrder(orderURL: string, data?: PaymentData3Ds): Promise<IResponsePayForOrder | void> {
    const url = (orderURL + '/do_pay').replace('/v1/', '/v2/');
    const postData: PayForOrder = {
      ...data,
      next_on_succeed: window.location.protocol + '//' + window.location.host + '/checkout/#/success',
      next_on_fail: window.location.protocol + '//' + window.location.host + '/checkout/#/fail',
    };
    try {
      const response = await this._httpTransport.post<IResponsePayForOrder, PayForOrder>(url, postData);
      if (!response?.data) return;
      return response.data;
    } catch (e: any) {
      console.error('CATCH payForOrder');
      const errorWithUrl = {
        ...e,
        url: orderURL,
      };
      throw e?.errorDetails?.transaction ? errorWithUrl : e;
    }
  }

  public async createOrder(purchaseURL: string): Promise<ICreateOrder | void> {
    const normalizeUrl = removePlatformURLFromURL(purchaseURL, platformURL);
    const response = await this._httpTransport.post<ICreateOrder, object>(`${normalizeUrl}`, {});
    if (response?.data) {
      return response.data;
    }
  }
  public async orderProduct(purchaseURL: string | null): Promise<string | void> {
    if (!purchaseURL) {
      throw new Error('INVALID_USAGE_OF_ORDERPRODUCT_FUNCTION');
    }
    if (await this._loginService.checkIsLoggedIn()) {
      const response = await this.createOrder(purchaseURL);
      if (response) {
        const payData = await this.payForOrder(response.order.url);
        if (payData?.url_user_redirect) {
          const urlRedirect = payData.url_user_redirect;
          await this._pageService.setLocation(urlRedirect, true);
          return urlRedirect;
        }
      }
    }
  }
}
