import ChangePasswordModalForm from 'app/widgets/changePasswordModalForm/changePasswordModalForm';
import SsoButtons from 'app/widgets/ssoButtons/ssoButtons';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { LoginControllerContext, UserControllerContext } from 'app/context/storesContext';

import Button from 'app/components/button/button';

import FillOldPasswordForm from '../../widgets/fillOldPasswordForm/fillOldPasswordForm';

import styles from './changePasswordModal.module.scss';

interface IChangePassword {
  closeModal: () => void;
  subtitle?: string[];
  placeholder?: string;
  warning?: boolean;
  danger?: boolean;
  textButtonNext?: string;
  completeConfirm?: () => Promise<void>;
  closeModalAfterConfirm?: boolean;
  cancelButtonLabel?: string;
}

const ChangePasswordModal: React.FC<IChangePassword> = observer(
  ({
    closeModal,
    subtitle,
    placeholder = 'Your old password',
    danger = false,
    textButtonNext = 'Next step',
    completeConfirm,
    closeModalAfterConfirm = false,
    cancelButtonLabel,
  }) => {
    const userController = useContext(UserControllerContext);
    const loginController = useContext(LoginControllerContext);
    const [step, setStep] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { userHasEnoughCredentials, userHasUsablePassword } = userController;

    useEffect(() => {
      if (userHasUsablePassword && !userHasEnoughCredentials) {
        return setStep('confirmPassword');
      }

      if (!userHasUsablePassword && userHasEnoughCredentials) {
        return setStep('confirmPasswordFromEmail');
      }
      setStep('confirmPasswordSeveralMethods');
    }, [userHasEnoughCredentials, userHasUsablePassword]);

    const handleConfirm = async () => {
      completeConfirm && (await completeConfirm());
      closeModalAfterConfirm && closeModal();
    };

    const handleConfirmOldPassword = async (oldPassword: string) => {
      await loginController.loginWithCredentials({
        email: userController.email || '',
        password: oldPassword,
        actionForPost: 'LOG_IN',
        stopRedirect: true,
        isConfirmPassword: true,
      });
    };

    const handleConfirmChangePassword = async (newPassword: string) => {
      await userController.patchUserPassword({
        email: userController.email || '',
        password: newPassword,
      });
      await userController.fetchUser();
    };

    const renderConfirmPassword = (
      <>
        {!!subtitle?.length &&
          subtitle.map((text) => (
            <p key={text} className={styles['subtitle-text']}>
              {text}
            </p>
          ))}
        <FillOldPasswordForm
          handleConfirmOldPassword={handleConfirmOldPassword}
          setStep={setStep}
          nextStep="changePassword"
          setIsLoading={setIsLoading}
          placeholder={placeholder}
          primary={!danger}
          danger={danger}
          textButtonNext={textButtonNext}
          handleConfirm={handleConfirm}
          closeModalAfterConfirm={closeModalAfterConfirm}
        />
      </>
    );

    const renderChangePassword = (
      <ChangePasswordModalForm
        handleConfirmChangePassword={handleConfirmChangePassword}
        closeModal={closeModal}
        setIsLoading={setIsLoading}
      />
    );

    const renderChangePasswordFromEmail = (
      <div className={styles['change-password-email']}>
        <p>To continue with the next step, please authenticate yourself with your social media account:</p>
        <SsoButtons
          action="Continue"
          isDisabledButton={isLoading}
          fromFilter="userProviders"
          nextStep="changePassword"
          setStep={setStep}
          handleConfirm={handleConfirm}
          closeModalAfterConfirm={closeModalAfterConfirm}
        />
      </div>
    );

    const renderChangePasswordSeveralMethods = (
      <div className={styles['change-password-several']}>
        {renderConfirmPassword}
        <hr className={styles['social-divider']} />
        <SsoButtons
          action="Continue"
          isDisabledButton={isLoading}
          fromFilter="userProviders"
          nextStep="changePassword"
          setStep={setStep}
          handleConfirm={handleConfirm}
          closeModalAfterConfirm={closeModalAfterConfirm}
        />
      </div>
    );

    const renderCurrentStep = useCallback(() => {
      switch (step) {
        case 'confirmPassword':
          return renderConfirmPassword;
        case 'changePassword':
          return renderChangePassword;
        case 'confirmPasswordFromEmail':
          return renderChangePasswordFromEmail;
        case 'confirmPasswordSeveralMethods':
          return renderChangePasswordSeveralMethods;
        default:
          return renderConfirmPassword;
      }
    }, [step]);

    return (
      <div>
        {renderCurrentStep()}
        <Button type="submit" disabled={isLoading} xl loading={false} name="Cancel" block onClick={closeModal}>
          {cancelButtonLabel ?? 'Cancel'}
        </Button>
      </div>
    );
  },
);
export default ChangePasswordModal;
