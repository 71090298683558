import React from 'react';

import Spinner from 'app/components/spinner/spinner';

import styles from './successAddCard.module.scss';

interface ISuccessAddCard {
  isFetching: boolean;
  description?: React.ReactNode;
}

const SuccessAddCard: React.FC<ISuccessAddCard> = ({ isFetching, description }) => {
  if (isFetching) {
    return (
      <div className={styles['success-fetching']}>
        <Spinner />
        <div className={styles['success-fetching-description']}>
          {description ? (
            description
          ) : (
            <>
              <p>Verifying billing information</p>
              <span>(this may take a moment)</span>
            </>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className={styles['success-fetching']}>
      <i className={`${styles['success-fetching-icon']} fa fa-check `}></i>
      <div className={styles['success-fetching-description']}>
        {description ? (
          description
        ) : (
          <>
            <p>Billing information verified</p>
            <span>(you will be taken to the next screen in a few seconds)</span>
          </>
        )}
      </div>
    </div>
  );
};
export default SuccessAddCard;
