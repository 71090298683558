import { computed, makeObservable, observable, action } from 'mobx';
import CookieService from 'services/cookieService/cookieService';

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginWithJWTRequest {
  token: string;
}

export interface ForgotPasswordRequest {
  email: string;
}

export interface ApiTokenAuthResponse {
  token: string;
}

export interface ApiToken {
  user_id: number;
  exp: number; // expiration date in seconds since Unix epoch (January 1, 1970, 00:00:00, UTC)
}

//Response to a call to loginCloudAPI
export interface TokenAuthCloudApiResponse {
  jwt: string;
}

//Response to a call to loginWithALC
export class TokenAuthResponse {
  public token?: string;
  public expiresAt?: Date;
  public userId?: number;

  public constructor(_token?: string | null) {
    if (_token) this.parse(_token);
  }

  public parse(_token: string): boolean {
    this.token = _token;

    let token: ApiToken;

    try {
      token = JSON.parse(atob(_token.split('.')[1]));
    } catch (err) {
      // TODO: Should probably reconsider this. This is a pain for unit tests too.
      console.error(`Could not parse TokenAuthResponse token!`);

      // We know that it expires about 16 hours from now.
      // To be safe we take the current time + 10 hours. This is currently unused.
      const date = new Date(Date.now());
      date.setHours(date.getHours() + 10);

      // Treat invalid token as anonymous.
      token = {
        user_id: -1,
        exp: date.getTime() / 1000,
      };

      return Boolean(token);
    }

    this.userId = token.user_id;
    this.expiresAt = new Date(token.exp * 1000);

    return true;
  }

  public getHttpToken(): string {
    return `JWT ${this.token}`;
  }
}

type Token = string | undefined;

/**
 * Class that handles logging in and managing tokens, for Utomik as well as through 'social authorities' (i.e. Facebook).
 */
export default class AuthorizationStore {
  private readonly _cookieService: CookieService;

  public constructor(cookieService: CookieService) {
    this._cookieService = cookieService;
    makeObservable(this);
  }

  @observable
  private _token: Token | null = null;

  @observable
  private _isLoggedIn = false;

  @computed
  public get token(): Token | null {
    return this._token;
  }

  @action
  public setToken(token: Token | null) {
    this._token = token;
  }

  @computed
  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  @action
  public setIsLoggedIn(isLoggedIn: boolean) {
    this._isLoggedIn = isLoggedIn;
  }
}
