export interface SubscriptionPricing {
  amount_in_cents: number;
  includes_tax: boolean;
  currency?: string;
}
interface CurrencySettings {
  longName: string;
  format: string;
  defaultPricing?: SubscriptionPricing;
  hideEmptyDecimals?: boolean;
}

export const supportedCurrencies: Record<string, CurrencySettings> = {
  USD: {
    longName: 'United States Dollars',
    format: '$##.00',
    defaultPricing: {
      amount_in_cents: 699,
      includes_tax: false,
    },
  },
  EUR: {
    longName: 'Euros',
    format: '€ ##,00',
    defaultPricing: {
      amount_in_cents: 699,
      includes_tax: true,
    },
  },
  GBP: {
    longName: 'Pounds sterling',
    format: '£##.00',
  },
  CAD: {
    longName: 'Canadian dollars',
    format: 'C$##.00',
  },
  AUD: {
    longName: 'Australian Dollars',
    format: 'AU$##.00',
  },
  NZD: {
    longName: 'New Zealand Dollars',
    format: 'NZ$##.00',
  },
  MXN: {
    longName: 'Mexican Pesos',
    format: 'Mex$##.00',
  },
  SGD: {
    longName: 'Singapore Dollars',
    format: 'S$##.00',
  },
  DKK: {
    longName: 'Denmark krone',
    format: '##.00 kr',
    hideEmptyDecimals: true,
  },
  SEK: {
    longName: 'Swedish krona',
    format: '##.00 kr',
    hideEmptyDecimals: true,
  },
  NOK: {
    longName: 'Norwegian krone',
    format: '##.00 kr',
    hideEmptyDecimals: true,
  },
  // THE FOLLOWING ARE NOT YET SUPPLIED IN THE API
  // note that Indian Rupees are technically formatted like 12,34,56,790.00,
  // but since we will likely never get prices higher than 99,999.99 rupees (1400 USD), we don't need to support that.
  INR: {
    longName: 'Indian Rupee',
    format: '₹##.00',
    hideEmptyDecimals: true,
  },
  PLN: {
    longName: 'Polish Złoty',
    format: '##,00 zł',
  },
};
