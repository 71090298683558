import withNavigate from 'app/hoc/withNavigate';
import { Outlet } from 'react-router-dom';

import Copyrights from 'app/components/copyrights/copyrights';
import Header from 'app/components/header/header';

import './register.scss';

const RegisterLayout = () => (
  <div className="register-layout">
    <Header className="register-layout__header" register />

    <main className="register-layout__main">
      <Outlet />
    </main>

    <Copyrights className="register-layout__copyrights" />
  </div>
);

export default withNavigate(RegisterLayout);
