import LocalizationService from 'services/localizationService/localizationService';
import ServiceSettingService from 'services/serviceSettingService/serviceSettingService';
import UserService from 'services/userService/userService';

import { ICountry } from 'types/localizations';

import { CLOUD_COUNTRIES_SERVICE_SETTING, CLOUD_SUPPORT_ENABLED_SERVICE_SETTING } from 'app/global/constants';
import LoginController from 'app/global/loginController/loginController';

export default class MiscService {
  public constructor(
    private readonly _serviceSettingService: ServiceSettingService,
    private readonly _loginController: LoginController,
    private readonly _userService: UserService,
    private readonly _localizationService: LocalizationService,
  ) {}

  public async getUserCountry(): Promise<ICountry> {
    let copyCountry: ICountry = {} as ICountry;
    if (await !this._loginController.checkIsLoggedIn()) {
      await this._userService.fetchUser();
    }
    const user = this._userService.user;
    const userCountry = user.country;
    if (user && userCountry) {
      try {
        const country = await this._localizationService.getCountry(userCountry.id);
        copyCountry = {
          ...country,
          isSetAsUserCountry: true,
        };
      } catch (e: any) {
        console.error(e.message || e.errorDetails, 'getUserCountry');
      }
    } else {
      const country = await this._localizationService.getCountryByIP();
      copyCountry = {
        ...country,
        isSetAsUserCountry: false,
      };
    }
    this._userService.setUserCountry(copyCountry);
    return copyCountry;
  }

  public async getApiHasCloudSupport(): Promise<void> {
    const apiHasCloudSupport = await this._serviceSettingService.getServiceSetting(
      CLOUD_SUPPORT_ENABLED_SERVICE_SETTING,
    );
    this._userService.setIsApiHasCloudSupport(apiHasCloudSupport);
  }

  public async setUserIsInSupportedCloudCountry(): Promise<void> {
    const userCountry = await this.getUserCountry();
    const supportedCloudCountries = await this._serviceSettingService.getServiceSetting(
      CLOUD_COUNTRIES_SERVICE_SETTING,
    );
    if (userCountry && Array.isArray(supportedCloudCountries)) {
      const isSupportedCloudCountry = supportedCloudCountries.includes(userCountry.code);
      this._userService.setIsInSupportedCloudCountry(isSupportedCloudCountry);
    }
  }
}
