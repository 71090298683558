import { getPageNavigation } from 'app/configs/pageNavigations';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import './tileSection.scss';

interface ITileSection {
  inVisibleTiles: string[];
  accountMembers: string;
  leaveFamilyPlan: () => void;
}

const TileSection: React.FC<ITileSection> = ({ inVisibleTiles, accountMembers, leaveFamilyPlan }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  const tiles = getPageNavigation({ accountMembers: accountMembers }).map((item) => ({
    ...item,
    visible: !inVisibleTiles.includes(item.visible),
  }));

  const getMaxHeight = () => {
    const container = containerRef.current;
    if (!container) return 0;

    const items = container.querySelectorAll('.shortcutbutton-link');
    return Array.from(items).reduce((max, item) => {
      const height = item.getBoundingClientRect().height;
      return Math.max(max, height);
    }, 0);
  };

  const updateMaxHeight = () => {
    const newMaxHeight = getMaxHeight();
    setMaxHeight(newMaxHeight);
  };

  useEffect(() => {
    updateMaxHeight();

    const resizeListener = () => {
      updateMaxHeight();
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  return (
    <section className="full-page-section full-page-section-dark" id="shortcut-buttons">
      <div className="container">
        <div ref={containerRef} className="shortcutbuttons-grid">
          {tiles.map((item) => {
            if (item.visible && item.id !== 'leave-family') {
              return (
                <div className="shortcutbutton" data-name="manage-subscription" key={item.id}>
                  <Link
                    to={item.redirectTo}
                    className="shortcutbutton-link"
                    style={{ height: `${maxHeight ? `${maxHeight}px` : 'unset'}` }}
                  >
                    {item.icon}
                    <strong>{item.title}</strong>
                    <div dangerouslySetInnerHTML={{ __html: item.subTitle }} />
                  </Link>
                </div>
              );
            } else if (item.visible && item.id === 'leave-family') {
              return (
                <div className="shortcutbutton" data-name="manage-subscription" key={item.id}>
                  <button onClick={leaveFamilyPlan}>
                    {item.icon}
                    <strong>{item.title}</strong>
                    <div dangerouslySetInnerHTML={{ __html: item.subTitle }} />
                  </button>
                </div>
              );
            }
          })}
        </div>
      </div>
    </section>
  );
};
export default TileSection;
