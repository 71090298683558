import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import CookieService from 'services/cookieService/cookieService';
import GlobalErrorsService from 'services/globalErrorsService/globalErrorsService';
import NavigateService from 'services/navigateService/navigateService';

import { Errors } from 'types/errors';

import SecurityToken from 'dataStore/securityToken/securityToken';
import AuthorizationStore from 'dataStore/stores/authorizationStore/authorizationStore';
import HttpTransport, { IHttpConfig, IHttpResponse } from 'dataStore/transports/httpTransport/httpTransport';
import {
  NetworkException,
  NotYetImplementedException,
  TimeOutException,
} from 'dataStore/transports/httpTransport/utomikError';

import { errorToStringTranslator } from 'utils/errorToStringTranslator';
import { isNullOrUndefined } from 'utils/utils';

export interface ErrorResponse {
  error: any;
  detail?: string;
  username?: string; // TODO: refactor this once the platform has the new error messages
  email?: string; // TODO: refactor this once the platform has the new error messages
}

const autoLogoutPathNames = ['/you-have-been-logged-out', '/you-have-been-logged-out/'];
const urlLogout = ['/api-session-logout', '/api-session-logout/'];
const ssoPathNAme = ['/sso'];
export default class AxiosTransport extends HttpTransport {
  /**
   * public for tests... -_- MEH!
   */
  public api: AxiosInstance;

  public constructor(
    host: string,
    securityToken: SecurityToken,
    cookieService: CookieService,
    authorizationStore: AuthorizationStore,
    navigateService: NavigateService,
    globalErrorsService: GlobalErrorsService,
  ) {
    super(host, securityToken);
    this.cookieService = cookieService;
    this.authorizationStore = authorizationStore;
    this.navigateService = navigateService;
    this.globalErrorsService = globalErrorsService;
    this.api = axios.create({ baseURL: host, headers: { 'Accept-Language': 'en', 'X-Utomik-Rec-Caching': 'TRUE' } });
  }

  // private readonly _cookieService: CookieService;
  private isJwtObsolete(responseError: ErrorResponse): boolean {
    return responseError.detail === Errors.JWT_OBSOLETE || responseError.error === Errors.JWT_OBSOLETE;
  }

  /**
   * Handle response object.
   * @param response Generic AxiosResponse object.
   */
  private handleResponse<T>(response: AxiosResponse<T>): IHttpResponse<T> {
    if (
      [
        200, // OK
        201, // OK, RESOURCE CREATED
        204, // OK, NO CONTENT
        210, // OK, UPDATE JWT
      ].indexOf(response.status) === -1
    ) {
      // Any unknown success responses will be logged to debug.
      console.error(`Request '${response.config.url}' returned unhandled code '${response.status}'`);
    }
    return response;
  }

  /**
   * See https://github.com/axios/axios#handling-errors for details on how to develop.
   * The generic error handler. This function -should- always throw an exception.
   * @param error This is the error response object.
   */
  private handleException(error: AxiosError<ErrorResponse>, endPointUrl: string): void {
    // Extract a few handy bits
    const { code, message, response } = error;
    const status = response?.status as number;
    const url: string = error.request?.url || endPointUrl || '(unknown url)';
    let errorDetails = {};

    if (!Object.keys(error).length) {
      return this.globalErrorsService?.setUnhandledError('error unhandled');
    }
    this.globalErrorsService?.setUnhandledError('');
    if (code === 'ECONNABORTED') {
      // Connection timeout!
      this.logError(url, 'Server', code, status, message);

      throw new TimeOutException();
    } else if (response) {
      // Sometimes, like when there are too many requests or when an endpoint doesn't exist, the data.error is undefined,
      // but the data.detail is filled with handy information.
      const errorTranslated = errorToStringTranslator(response?.data);
      let errorMessage: string | any = errorTranslated || response?.data?.error?.message || response?.data?.error;
      if (isNullOrUndefined(errorMessage)) errorMessage = response?.data?.detail;
      // TODO: refactor this once the platform has the new error messages
      errorDetails = response?.data;
      if (
        response?.data?.detail === Errors.USER_IS_UNDER_EIGHTEEN_AND_NO_MEMBER &&
        !ssoPathNAme.includes(window.location.pathname) &&
        !urlLogout.includes(url)
      ) {
        this.globalErrorsService?.setUserUnderEighteenError(Errors.USER_IS_UNDER_EIGHTEEN_AND_NO_MEMBER);
        return;
      }

      if (isNullOrUndefined(errorMessage)) {
        if (!isNullOrUndefined(response?.data?.username)) {
          errorMessage = Errors.USER_NAME_TAKEN;
        } else if (!isNullOrUndefined(response?.data?.email)) errorMessage = Errors.EMAIL_TAKEN;
      }
      if (
        errorMessage === Errors.DECODING_SIGNATURE ||
        errorMessage === Errors.INVALID_JWT ||
        errorMessage === Errors.SIGNATURE_HAS_EXPIRED
      ) {
        if (autoLogoutPathNames.includes(window.location.pathname)) {
          return;
        }
        if (urlLogout.includes(url)) {
          this.navigateService?.setNavigateLink('/login');
          return;
        }
        this.navigateService?.setNavigateLink('/you-have-been-logged-out');
        return;
      }
      // Transport received an error response (5xx, 4xx).
      this.logError(url, 'Server', code as string, status, errorMessage as string);
      this.handleError(status, errorMessage as string, errorDetails as any);
    } else if (error.request) {
      // Request sent but no response. Most likely no internet. Status codes are passed manually because they don't actually exist without a response.
      if (message === Errors.NETWORK_ERROR) {
        const response: AxiosResponse<ErrorResponse> | undefined = error.response;

        let errorMessage: string | undefined = response?.data?.error;

        if (isNullOrUndefined(errorMessage)) errorMessage = response?.data?.detail;

        if (
          errorMessage === Errors.DECODING_SIGNATURE ||
          errorMessage === Errors.INVALID_JWT ||
          errorMessage === Errors.SIGNATURE_HAS_EXPIRED
        ) {
          if (autoLogoutPathNames.includes(window.location.pathname)) {
            return;
          }
          this.navigateService?.setNavigateLink('/you-have-been-logged-out');
          return;
        }

        // Let's try to keep all known cases in the same format as the API.
        this.logError(url, 'Network', code as string, status, errorMessage as string);
        throw new NetworkException('NETWORK_ERROR', status);
      } else {
        // Catch-all for errors not caught by above.
        this.logError(url, 'Network', code as string, status, message);
        throw new NetworkException(message, status);
      }
    } else {
      // Something happened in setting up the request that triggered an Error. Should really never occur.
      this.logError(url, 'Unknown', code as string, status, message);
      throw new NotYetImplementedException(`An unknown error has occurred: ${message}`);
    }
  }

  /**
   * Log in our defined format.
   * @param url Request URL
   * @param type Pre-defined error type
   * @param code Error code
   * @param status Request status
   * @param message Error message
   */
  private logError(
    url: string,
    type: 'Unknown' | 'Network' | 'Server',
    code: string,
    status: number,
    message: string,
  ): void {
    // Let's not log falsey values, to help make logs more readable.
    // That includes "", 0, false, undefined, null, etc
    const i = [];
    if (type) i.push(`type: "${type}"`);
    if (code) i.push(`code: "${code}"`);
    if (status) i.push(`status: "${status}"`);
    const ii = i.length > 0 ? ` (${i.join(', ')})` : '';

    console.error(`Request "${url}" failed${ii}: "${message}"`);
  }

  /**
   * getConfig is a helper that will create an IHttpConfig.
   * @param config Request configuration
   */
  private getConfig<D>(config?: IHttpConfig): AxiosRequestConfig<D> | undefined {
    const jwtToken = this.authorizationStore?.token || this.cookieService?.getCookie('JWT');
    const validJwtToken: string | undefined = typeof jwtToken === 'string' ? `JWT ${jwtToken}` : undefined;

    if (config && config != this.defaultConfig) return config;

    // Default to default Config
    if (!isNullOrUndefined(this.defaultConfig) && !isNullOrUndefined(validJwtToken)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (this.defaultConfig) this.defaultConfig.headers!.Authorization = validJwtToken;
    } else {
      delete this.defaultConfig?.headers?.Authorization;
    }
    return this.defaultConfig;
  }

  public resetDefaultConfig(language: string): void {
    this.defaultConfig = { headers: { 'Accept-Language': language } };
  }

  /**
   * Do the actual request.
   *
   * @param requestCB - The request callback.
   */
  private async perform<Response>(requestCB: () => Promise<AxiosResponse<Response>>): Promise<AxiosResponse<Response>> {
    try {
      const result = await requestCB();

      switch (result.status) {
        case 210: // 210 a Utomik only extension on the HTTP protocol where the JWT has to be refreshed.
          await this.securityToken?.refresh();

          return result;

        default:
          return result;
      }
    } catch (error: any) {
      // If the error we got a 401 with header stale=true or with signature has expired from the Platform,
      // we will try to refresh the token and then retry the request.
      const responseError = error?.response?.data;
      if (this.isJwtObsolete(responseError)) {
        //check error code or message JWT_OBSOLETE
        await this.securityToken?.refresh(true);

        return await requestCB();
      } else throw error;
    }
  }

  // Get from api endpoint and handle response.
  public async get<GetResponse>(
    endPointUrl: string,
    config?: IHttpConfig,
  ): Promise<IHttpResponse<GetResponse> | undefined> {
    try {
      const response = await this.perform<GetResponse>(() =>
        this.api.get<GetResponse, AxiosResponse<GetResponse>, never>(endPointUrl, this.getConfig<never>(config)),
      );
      return this.handleResponse<GetResponse>(response);
    } catch (error) {
      this.handleException(error as AxiosError<ErrorResponse, any>, endPointUrl);
    }
  }

  // Delete from api endpoint and handle response.
  public async delete<DeleteResponse>(
    endPointUrl: string,
    config?: IHttpConfig,
  ): Promise<IHttpResponse<DeleteResponse> | undefined> {
    try {
      return await this.perform(() =>
        this.api.delete<DeleteResponse, AxiosResponse<DeleteResponse>, never>(
          endPointUrl,
          this.getConfig<never>(config),
        ),
      );
    } catch (error) {
      this.handleException(error as AxiosError<ErrorResponse, any>, endPointUrl);
    }
  }

  // Post to api endpoint and handle response.
  public async post<PostResponse, PostData>(
    endPointUrl: string,
    postData: PostData,
    config?: IHttpConfig,
  ): Promise<IHttpResponse<PostResponse> | undefined> {
    try {
      const response = await this.perform<PostResponse>(() =>
        this.api.post<PostResponse, AxiosResponse<PostResponse>, PostData>(
          endPointUrl,
          postData,
          this.getConfig<PostData>(config),
        ),
      );
      return this.handleResponse<PostResponse>(response);
    } catch (error) {
      this.handleException(error as AxiosError<ErrorResponse, any>, endPointUrl);
    }
  }

  // Put to api endpoint and handle response.
  public async put<PutResponse, PutData>(
    endPointUrl: string,
    putData: PutData,
    config?: IHttpConfig,
  ): Promise<IHttpResponse<PutResponse> | undefined> {
    try {
      const response = await this.perform<PutResponse>(() =>
        this.api.put<PutResponse, AxiosResponse<PutResponse>, PutData>(endPointUrl, putData, this.getConfig(config)),
      );

      return this.handleResponse<PutResponse>(response);
    } catch (error) {
      this.handleException(error as AxiosError<ErrorResponse, any>, endPointUrl);
    }
  }

  // Patch to api endpoint and handle response.
  public async patch<PatchResponse, PatchData>(
    endPointUrl: string,
    data: PatchData,
    config?: IHttpConfig,
  ): Promise<IHttpResponse<PatchResponse> | undefined> {
    try {
      const response = await this.perform<PatchResponse>(() =>
        this.api.patch<PatchResponse, AxiosResponse<PatchResponse>, PatchData>(
          endPointUrl,
          data,
          this.getConfig(config),
        ),
      );
      return this.handleResponse<PatchResponse>(response);
    } catch (error) {
      this.handleException(error as AxiosError<ErrorResponse, any>, endPointUrl);
    }
  }
}
