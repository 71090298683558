type BirthDate = {
  year: string | null;
  month: string | null;
  day: string | null;
};

export const parseUserBirthDate = (userBirthDate: string | null): BirthDate => {
  if (!userBirthDate) {
    return {
      year: null,
      month: null,
      day: null,
    };
  }
  const birthDate = userBirthDate.split('-');
  return {
    year: birthDate[0],
    month: birthDate[1],
    day: birthDate[2],
  };
};
