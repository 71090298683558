import clsx from 'clsx';
import moment from 'moment';
import React from 'react';

import { FamilyMembersCombined, IAccountSettingRequest } from 'types/familyMembers';
import { SendStates } from 'types/shared';
import { IDate } from 'types/users';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';

import FamilyMemberWellAccountSettings from '../familyMemberWellAccountSettings/familyMemberWellAccountSettings';

import styles from './familyMemberWell.module.scss';

interface IFamilyMemberWell {
  familyMember: FamilyMembersCombined;
  resendingInvitationState: SendStates | null;
  removingInvitationState: SendStates | null;
  familyWellError: string;
  activeMemberEmail: string[];
  accountSettingsFormIsOpen: boolean;
  currentAccountsEdit: Array<number | string>;
  doRemoveAccountId: number | string | null;

  isOfAge: (birthDate: string) => boolean;
  resendInvitation: (familyMember: FamilyMembersCombined) => void;
  doRemoveAccount: (familyMember: FamilyMembersCombined) => void;
  setAccountSettingsFormIsOpen: (isOpen: boolean) => void;
  isValidDate: (date: IDate) => boolean;
  doSaveAccountSettings: (data: IAccountSettingRequest) => Promise<void>;
  setCurrentAccountsEdit: (members: Array<number | string>) => void;
}

const FamilyMemberWell: React.FC<IFamilyMemberWell> = ({
  familyMember,
  resendingInvitationState,
  removingInvitationState,
  activeMemberEmail,
  accountSettingsFormIsOpen,
  currentAccountsEdit,
  doRemoveAccountId,
  isOfAge,
  resendInvitation,
  doRemoveAccount,
  setAccountSettingsFormIsOpen,
  isValidDate,
  doSaveAccountSettings,
  setCurrentAccountsEdit,
  familyWellError,
}) => {
  const doOpenAccountSettings = () => {
    setAccountSettingsFormIsOpen(true);
    setCurrentAccountsEdit([...currentAccountsEdit, familyMember.id]);
  };

  const doCloseAccountSettings = (memberId: string | number) => {
    if (currentAccountsEdit.length <= 1) {
      setAccountSettingsFormIsOpen(false);
      return;
    }
    const activeEditMembers = currentAccountsEdit.filter((account) => account !== memberId);
    setCurrentAccountsEdit(activeEditMembers);
  };
  const isLoadingButtonRemove = removingInvitationState === 'sending' && doRemoveAccountId === familyMember.id;
  const isSentRemove = removingInvitationState === 'sent' && doRemoveAccountId === familyMember.id;
  const removeMember = async () => {
    try {
      await doRemoveAccount(familyMember);
    } catch (e) {
      console.error(e, 'invite family form');
    }
  };

  return (
    <>
      <div
        className={clsx(
          styles['user-card'],
          currentAccountsEdit.includes(familyMember.id) && accountSettingsFormIsOpen && styles['user-card-settings'],
        )}
      >
        <div className={styles['user-card-header']}>
          {familyMember.role === 'Owner' && <div className={styles['user-card-flag']}>Account owner</div>}
          {familyMember.role !== 'Owner' && (
            <nav className={styles['user-card-nav']}>
              <ul>
                {familyMember.status !== 'Pending' && (
                  <li>
                    <Button onClick={doOpenAccountSettings} link title="Edit this user's account settings">
                      <span className="fas fa-pencil-alt"></span> Edit
                    </Button>
                  </li>
                )}

                <li>
                  <Button
                    onClick={removeMember}
                    link
                    title="Remove this user from your family plan"
                    disabled={isLoadingButtonRemove}
                    loading={isLoadingButtonRemove}
                  >
                    <span className="fas fa-trash-alt"></span> Remove
                    {isSentRemove && <i className="fa-solid fa-check"></i>}
                  </Button>
                </li>
              </ul>
            </nav>
          )}
        </div>
        <div className={styles['user-card-avatar']}>
          <div className={styles['user-card-avatar-scalable-wrapper']}>
            <div className={clsx(styles['avatar'], !familyMember.avatar && styles['avatar-empty'])}>
              {familyMember.avatar && (
                <img src={familyMember.avatar.download_url || ''} alt={`${familyMember.username}'s avatar`} />
              )}
            </div>
          </div>
        </div>
        <div className={styles['user-card-content']}>
          <h2>
            {familyMember.username && <span>{familyMember.username}</span>}
            {familyMember.username && (familyMember.email || familyMember.sent_to) && <span>&nbsp;/&nbsp;</span>}
            {(familyMember.email || familyMember.sent_to) && <span>{familyMember.email || familyMember.sent_to}</span>}
          </h2>
          <div className={styles['user-card-info']}>
            <div className={styles['user-card-info-descriptions']}>
              <strong className={styles['user-card-info-strong']}>Status</strong>
              {familyMember.status !== 'Pending' && (
                <div className={styles['user-card-info-flex']}>
                  {!familyMember.suspended && (
                    <div>
                      <span className="fas fa-check text-success"></span> Active
                    </div>
                  )}
                  {familyMember.suspended && (
                    <div className="text-danger">
                      <span className="fas fa-hand-paper"></span> Suspended
                    </div>
                  )}
                </div>
              )}
              {familyMember.status === 'Pending' && (
                <div className={styles['user-card-info-flex']}>
                  <div>
                    <span className="fas fa-envelope"></span> Invite pending
                  </div>
                  {!activeMemberEmail.includes(familyMember.sent_to) && (
                    <span
                      className={clsx('appear-as-link', styles['user-card-info-action'])}
                      onClick={() => resendInvitation(familyMember)}
                    >
                      Send new invitation
                    </span>
                  )}

                  {resendingInvitationState === 'sending' && activeMemberEmail.includes(familyMember.sent_to) && (
                    <span className={styles['user-card-info-action']}>Sending new invitation&hellip;</span>
                  )}
                  {resendingInvitationState === 'sent' && activeMemberEmail.includes(familyMember.sent_to) && (
                    <span className={styles['user-card-info-action']}>
                      Sent new invitation <span className="fas fa-check text-success"></span>
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className={styles['user-card-info-descriptions']}>
              <strong className={styles['user-card-info-strong']}>Date of birth</strong>
              {familyMember.birthdate && (
                <div className={styles['user-card-info-flex']}>
                  {moment(familyMember.birthdate).format('MMMM Do, YYYY')}
                </div>
              )}
            </div>
            <div className={styles['user-card-info-descriptions']}>
              <strong className={styles['user-card-info-strong']}>Library availability</strong>
              {familyMember.suspended && <div className={styles['user-card-info-flex']}>No access</div>}
              {!familyMember.suspended && (familyMember.birthdate ? isOfAge(familyMember.birthdate) : true) && (
                <div className={styles['user-card-info-flex']}>Fully available</div>
              )}
              {!familyMember.suspended && familyMember.birthdate && !isOfAge(familyMember.birthdate) && (
                <div className={styles['user-card-info-flex']}>Limited access based on game age restrictions</div>
              )}
            </div>
          </div>
          {familyWellError && activeMemberEmail.includes(familyMember.sent_to) && (
            <Alert danger className="alert-danger-member-well">
              {familyWellError}
            </Alert>
          )}
        </div>
      </div>
      {currentAccountsEdit.includes(familyMember.id) && accountSettingsFormIsOpen && (
        <FamilyMemberWellAccountSettings
          isOfAge={isOfAge}
          doCloseAccountSettings={doCloseAccountSettings}
          isValidDate={isValidDate}
          member={familyMember}
          doSaveAccountSettings={doSaveAccountSettings}
        />
      )}
    </>
  );
};
export default FamilyMemberWell;
