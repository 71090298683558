import clsx from 'clsx';

import styles from './button.module.scss';

export type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  // Colors
  primary?: boolean;
  warning?: boolean;
  danger?: boolean;
  social?: boolean;
  // Sizes
  lg?: boolean;
  xl?: boolean;
  // Variants
  block?: boolean;
  round?: boolean;
  loading?: boolean;
  link?: boolean;
  edit?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  primary,
  warning,
  danger,
  social,
  lg,
  xl,
  block,
  round,
  className,
  loading,
  link,
  edit,
  ...props
}) => {
  const classNames = clsx(styles.button, className, {
    [styles['button--color-primary']]: primary,
    [styles['button--color-warning']]: warning,
    [styles['button--color-danger']]: danger,
    [styles['button--color-social']]: social,
    [styles['button--variant-block']]: block,
    [styles['button--variant-round']]: round,
    [styles['button--variant-edit']]: edit,

    [styles['button--size-lg']]: lg,
    [styles['button--size-xl']]: xl,
    [styles['button--loading']]: loading,
    [styles['button--link']]: link,
  });

  return <button className={classNames} {...props} />;
};

export default Button;
