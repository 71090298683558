import React, { createContext } from 'react';
import CookieService from 'services/cookieService/cookieService';
import GlobalErrorsService from 'services/globalErrorsService/globalErrorsService';
import LocalizationService from 'services/localizationService/localizationService';
import MiscService from 'services/miscService/miskService';
import NavigateService from 'services/navigateService/navigateService';
import PairingService from 'services/pairingService/pairingService';
import ServiceSettingService from 'services/serviceSettingService/serviceSettingService';
import UserService from 'services/userService/userService';

import AvatarController from 'app/global/avatarController/avatarController';
import CheckoutController from 'app/global/checkoutController/checkoutController';
import CheckoutSuccessController from 'app/global/checkoutSuccessController/checkoutSuccessController';
import DeleteAccountController from 'app/global/deleteAccountController/deleteAccountController';
import FamilyController from 'app/global/familyController/familyController';
import HeaderController from 'app/global/headerController/headerController';
import HomePageController from 'app/global/homePageController/homePageController';
import InvoicesController from 'app/global/invoicesController/invoicesController';
import JoinFamilyController from 'app/global/joinFamilyController/joinFamilyController';
import LoginController from 'app/global/loginController/loginController';
import ManageSubscriptionsController from 'app/global/manageSubscriptionsController/manageSubscriptionsController';
import NewsLetterController from 'app/global/newsLetterController/newsLetterController';
import PageController from 'app/global/pageController/pageController';
import PairingController from 'app/global/pairingController/pairingController';
import PaynlIdealController from 'app/global/paynlIdealController/paynlIdealController';
import PromoPageController from 'app/global/promoPageController/promoPageController';
import RegisterController from 'app/global/registerController/registerController';
import SelectPlanController from 'app/global/selectPlanController/selectPlanController';
import SsoController from 'app/global/ssoController/ssoController';
import UpdateBillingController from 'app/global/updateBillingController/updateBillingController';
import UserController from 'app/global/userController/userController';
import UtomikSupportedInCountryController from 'app/global/utomikSupportedInCountryController/utomikSupportedInCountryController';

import RootStore from '../global/rootStore';

import PreconditionController from './../global/preconditionController/preconditionController';

// Controllers
export let LoginControllerContext: React.Context<LoginController>;
export let RegisterControllerContext: React.Context<RegisterController>;
export let UserControllerContext: React.Context<UserController>;
export let UserServiceContext: React.Context<UserService>;
export let PageControllerContext: React.Context<PageController>;
export let SsoControllerContext: React.Context<SsoController>;
export let PairingControllerContext: React.Context<PairingController>;
export let PairingServiceContext: React.Context<PairingService>;
export let CookieServiceContext: React.Context<CookieService>;
export let ServiceSettingServiceContext: React.Context<ServiceSettingService>;
export let LocalizationServiceContext: React.Context<LocalizationService>;
export let MiscServiceContext: React.Context<MiscService>;
export let UtomikSupportedInCountryControllerContext: React.Context<UtomikSupportedInCountryController>;
export let NavigateServiceContext: React.Context<NavigateService>;
export let AvatarControllerContext: React.Context<AvatarController>;
export let UpdateBillingControllerContext: React.Context<UpdateBillingController>;
export let SelectPlanControllerContext: React.Context<SelectPlanController>;
export let CheckoutControllerContext: React.Context<CheckoutController>;
export let ManageSubscriptionsControllerContext: React.Context<ManageSubscriptionsController>;
export let InvoicesControllerContext: React.Context<InvoicesController>;
export let CheckoutSuccessControllerContext: React.Context<CheckoutSuccessController>;
export let HeaderControllerContext: React.Context<HeaderController>;
export let PreconditionControllerContext: React.Context<PreconditionController>;
export let NewsLetterControllerContext: React.Context<NewsLetterController>;
export let DeleteAccountControllerContext: React.Context<DeleteAccountController>;
export let HomePageControllerContext: React.Context<HomePageController>;
export let PromoPageControllerContext: React.Context<PromoPageController>;
export let PaynlIdealControllerContext: React.Context<PaynlIdealController>;
export let FamilyControllerContext: React.Context<FamilyController>;
export let JoinFamilyControllerContext: React.Context<JoinFamilyController>;
export let GlobalErrorsServiceContext: React.Context<GlobalErrorsService>;
export interface IRootStore {
  registerController: RegisterController;
  userController: UserController;
  userService: UserService;
  loginController: LoginController;
  pageController: PageController;
  ssoController: SsoController;
  pairingController: PairingController;
  pairingService: PairingService;
  cookieService: CookieService;
  serviceSettingService: ServiceSettingService;
  localizationService: LocalizationService;
  miscService: MiscService;
  utomikSupportedInCountryController: UtomikSupportedInCountryController;
  navigateService: NavigateService;
  avatarController: AvatarController;
  updateBillingController: UpdateBillingController;
  selectPlanController: SelectPlanController;
  checkoutController: CheckoutController;
  manageSubscriptionsController: ManageSubscriptionsController;
  invoicesController: InvoicesController;
  checkoutSuccessController: CheckoutSuccessController;
  headerController: HeaderController;
  preconditionController: PreconditionController;
  newsLetterController: NewsLetterController;
  deleteAccountController: DeleteAccountController;
  homePageController: HomePageController;
  promoPageController: PromoPageController;
  paynlIdealController: PaynlIdealController;
  familyController: FamilyController;
  joinFamilyController: JoinFamilyController;
  globalErrorsService: GlobalErrorsService;
}

export function initContexts(v: IRootStore): void {
  // Controllers
  LoginControllerContext = createContext(v.loginController);
  RegisterControllerContext = createContext(v.registerController);
  UserControllerContext = createContext(v.userController);
  PageControllerContext = createContext(v.pageController);
  SsoControllerContext = createContext(v.ssoController);
  PairingControllerContext = createContext(v.pairingController);
  PairingServiceContext = createContext(v.pairingService);
  CookieServiceContext = createContext(v.cookieService);
  ServiceSettingServiceContext = createContext(v.serviceSettingService);
  LocalizationServiceContext = createContext(v.localizationService);
  MiscServiceContext = createContext(v.miscService);
  UserServiceContext = createContext(v.userService);
  UtomikSupportedInCountryControllerContext = createContext(v.utomikSupportedInCountryController);
  NavigateServiceContext = createContext(v.navigateService);
  AvatarControllerContext = createContext(v.avatarController);
  UpdateBillingControllerContext = createContext(v.updateBillingController);
  SelectPlanControllerContext = createContext(v.selectPlanController);
  CheckoutControllerContext = createContext(v.checkoutController);
  ManageSubscriptionsControllerContext = createContext(v.manageSubscriptionsController);
  InvoicesControllerContext = createContext(v.invoicesController);
  CheckoutSuccessControllerContext = createContext(v.checkoutSuccessController);
  HeaderControllerContext = createContext(v.headerController);
  PreconditionControllerContext = createContext(v.preconditionController);
  NewsLetterControllerContext = createContext(v.newsLetterController);
  DeleteAccountControllerContext = createContext(v.deleteAccountController);
  HomePageControllerContext = createContext(v.homePageController);
  PromoPageControllerContext = createContext(v.promoPageController);
  PaynlIdealControllerContext = createContext(v.paynlIdealController);
  FamilyControllerContext = createContext(v.familyController);
  JoinFamilyControllerContext = createContext(v.joinFamilyController);
  GlobalErrorsServiceContext = createContext(v.globalErrorsService);
}

export let contextRootStore: RootStore;

export function initGlobals(): void {
  contextRootStore = new RootStore();

  initContexts(contextRootStore);
}

/**
 * A function for restarting Utomik if you don't have regular access to the SettingsController and can't use contexts
 */
export async function restartUtomikContextless(): Promise<void> {
  //no-op
}
