import { oldPasswordSchema } from 'app/validationSchemes/oldPasswordSchema';
import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';

import { Errors } from 'types/errors';

import { translateErrors } from 'utils/translateErrors';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Input from 'app/components/input/input';

import styles from './fillOldPasswordForm.module.scss';

type FormValues = {
  password: string;
};

const initialValues: FormValues = {
  password: '',
};

interface IFillOldPasswordForm {
  handleConfirmOldPassword: (password: string) => void;
  setStep: (step: string) => void;
  nextStep: string;
  setIsLoading: (isLoading: boolean) => void;
  placeholder: string;
  primary: boolean;
  danger: boolean;
  textButtonNext: string;
  handleConfirm?: () => void;
  closeModalAfterConfirm?: boolean;
}

const FillOldPasswordForm: React.FC<IFillOldPasswordForm> = ({
  handleConfirmOldPassword,
  setStep,
  nextStep,
  setIsLoading,
  placeholder,
  danger,
  primary,
  textButtonNext,
  handleConfirm,
  closeModalAfterConfirm,
}) => {
  const [isFetchingConfirm, setIsFetchingConfirm] = useState(false);
  const [errorRequest, setErrorRequest] = useState('');
  const onSubmit = async (values: FormValues) => {
    setIsLoading(true);
    setIsFetchingConfirm(true);
    try {
      await handleConfirmOldPassword(values.password);
      closeModalAfterConfirm && handleConfirm && (await handleConfirm());
      !closeModalAfterConfirm && setStep(nextStep);
    } catch (e: any) {
      if (e.message === Errors.INCORRECT_LOGIN_CREDENTIALS) {
        return setErrorRequest('Password incorrect.');
      }
      setErrorRequest(translateErrors(e.message || 'Something went wrong. Please try again later.'));
    } finally {
      setIsLoading(false);
      setIsFetchingConfirm(false);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: oldPasswordSchema,
    onSubmit: onSubmit,
    validateOnBlur: false,
  });
  return (
    <div>
      <p className={styles['subtitle']}>Please fill in your password to continue.</p>

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className={clsx(styles['form-field'], errorRequest && styles['form-field-error'])}>
            <Input
              label=""
              name="password"
              title=""
              onBlur={() => {
                return;
              }}
              type="password"
              placeholder={placeholder}
              showCheck={false}
              isLoading={isFetchingConfirm}
              isValidate={false}
              disabled={isFetchingConfirm}
            />
            {errorRequest && <Alert danger>{errorRequest}</Alert>}
          </div>

          <Button
            type="submit"
            disabled={!formik.values.password || isFetchingConfirm}
            xl
            loading={isFetchingConfirm}
            name="Change password"
            block
            primary={primary}
            danger={danger}
            className={styles['form-button']}
          >
            {textButtonNext}
          </Button>
        </form>
      </FormikProvider>
    </div>
  );
};
export default FillOldPasswordForm;
