import React from 'react';

export const IconCloud = () => {
  return (
    <svg width="29" height="21" viewBox="0 0 29 21" fill="none">
      <path
        d="M0 14.0938C0 17.665 2.92266 20.5625 6.525 20.5625H23.2C26.4036 20.5625 29 17.9885 29 14.8125C29 12.0318 27.0063 9.70938 24.36 9.1793C24.5458 8.69863 24.65 8.17305 24.65 7.625C24.65 5.24414 22.7016 3.3125 20.3 3.3125C19.4073 3.3125 18.5736 3.58203 17.8848 4.04023C16.6297 1.88398 14.287 0.4375 11.6 0.4375C7.59438 0.4375 4.35 3.65391 4.35 7.625C4.35 7.74629 4.35453 7.86758 4.35906 7.98887C1.82156 8.87383 0 11.2727 0 14.0938Z"
        fill="#181A33"
      />
    </svg>
  );
};
