import { action, computed, makeObservable, observable } from 'mobx';
import BoxOfficeService from 'services/boxOficceService/boxOficceService';
import DownloadService from 'services/downloadService/downloadService';
import InitPagesService from 'services/initPagesService/initPagesService';
import SubscriptionManagementService from 'services/subscriptionManagementService/subscriptionManagementService';
import UserService from 'services/userService/userService';

import { IPlatformObject, Platform } from 'types/shared';
import { ISubscriptionsResponseWithOrders } from 'types/subscriptions';

import { translateErrors } from 'utils/translateErrors';

import { USER_CLOUD_GROUP_NAME } from '../constants';

import { filterActiveSubscriptions } from './../../../utils/subscriptionFilters';

const supportedDesktopPlatforms = ['DESKTOP'];
const supportedCloudPlatforms = ['ANDROID', 'TV'];
let defaultPlatform = 'DESKTOP';

export default class CheckoutSuccessController extends InitPagesService {
  public constructor(
    private readonly _userService: UserService,
    private readonly _subscriptionManagementService: SubscriptionManagementService,
    private readonly _boxOfficeService: BoxOfficeService,
    private readonly _downloadService: DownloadService,
  ) {
    super();
    makeObservable(this);
  }

  @observable
  private _error = '';

  @action
  public setError(error: string) {
    this._error = error;
  }

  @computed
  public get error() {
    return this._error;
  }

  @observable
  private _subscription: ISubscriptionsResponseWithOrders | null = null;

  @action
  public setSubscriptions(subscriptions: ISubscriptionsResponseWithOrders | null) {
    this._subscription = subscriptions;
  }

  @computed
  public get subscription() {
    return this._subscription;
  }

  @observable
  private _isReactivating = false;

  @action
  public setIsReactivating(isReactivating: boolean) {
    this._isReactivating = isReactivating;
  }

  @computed
  public get isReactivating() {
    return this._isReactivating;
  }

  @observable
  private _accountRole = '';

  @action
  public setAccountRole(role: string) {
    this._accountRole = role;
  }

  @computed
  public get accountRole() {
    return this._accountRole;
  }

  @observable
  private _isCloudUser = false;

  @action
  public setIsCloudUser(isCloudUser: boolean) {
    this._isCloudUser = isCloudUser;
  }

  @computed
  public get isCloudUser() {
    return this._isCloudUser;
  }

  @observable
  private _canUpgradeToCloud = false;

  @action
  public setCanUpgradeToCloud(canUpgradeToCloud: boolean) {
    this._canUpgradeToCloud = canUpgradeToCloud;
  }

  @computed
  public get canUpgradeToCloud() {
    return this._canUpgradeToCloud;
  }

  @observable
  private _platform: Platform | null = null;

  @action
  public setPlatform(platform: Platform | null) {
    this._platform = platform;
  }

  @computed
  public get platform() {
    return this._platform;
  }

  @observable
  private _platformList: IPlatformObject[] = [];

  @action
  public setPlatformList(platformList: IPlatformObject[]) {
    this._platformList = platformList;
  }

  @computed
  public get platformList() {
    return this._platformList;
  }

  public async initPage() {
    this.setIsLoading(true);
    try {
      await this._userService.fetchAccountSubscriptions();
      const subscriptions = await this._userService.getAccountSubscriptions();
      const canSwitch = await this._boxOfficeService.canSwitchToUtomikSubscription();
      const user = this.user;
      const activeSubscriptions = filterActiveSubscriptions(subscriptions);
      this.setSubscriptions(activeSubscriptions[0]);
      this.setAccountRole(user.account?.role || '');
      const isCloudUser = user.groups.indexOf(USER_CLOUD_GROUP_NAME) > -1;
      const canUpgradeToCloud = !isCloudUser && canSwitch;
      this.setIsCloudUser(isCloudUser);
      this.setCanUpgradeToCloud(canUpgradeToCloud);
      if (isCloudUser) {
        defaultPlatform = 'ANDROID';
      }
    } catch (e) {
      this.setError(translateErrors(e));
    } finally {
      this.setIsLoading(false);
    }
  }

  public reactivateSubscription = async () => {
    if (!this.subscription) return false;
    this.setIsReactivating(true);
    this.setError('');
    try {
      await this._subscriptionManagementService.reactivateSubscription(this.subscription.url, this.subscription);
      this.setSubscriptions({
        ...this.subscription,
        status: 'ACTIVE',
      });
    } catch (e) {
      this.setError(translateErrors(e));
    } finally {
      this.setIsReactivating(false);
    }
  };

  public createPlatformObject(label: string, isActive: boolean): IPlatformObject {
    return { label, is_active: isActive };
  }

  public generatePlatformList(): IPlatformObject[] {
    const allSupportedPlatformsGrouped = [...supportedCloudPlatforms, ...supportedDesktopPlatforms];
    return allSupportedPlatformsGrouped
      .map((platform) =>
        this.createPlatformObject(
          platform,
          platform === this.platform || (!this.platform && platform === defaultPlatform),
        ),
      )
      .sort((a, b) => (a.is_active ? -1 : 1));
  }

  public initPlatformList = (platform?: Platform | null) => {
    if (platform) {
      this.setPlatform(platform);
    }

    const platformList = this.generatePlatformList();
    this.setPlatformList(platformList);
  };
  public get user() {
    return this._userService.user;
  }

  public handleDownloadUtomik = () => {
    this._downloadService.handleDownloadUtomik();
  };

  public handleDownloadGooglePlay = () => {
    this._downloadService.handleDownloadGooglePlay();
  };
}
