import { states } from 'lists/states';
import React, { useState } from 'react';

import { ICountriesAlpha } from 'types/localizations';

const useRecurlyCreditCard = () => {
  const [countriesAlpha, setCountriesAlpha] = useState<ICountriesAlpha[]>([]);

  const isCountryWithKnownStates = (countryCode: string) => {
    const hasKnownStates = countryCode && states[countryCode];
    setCountriesAlpha(hasKnownStates ? states[countryCode] : []);
    return !!hasKnownStates;
  };

  return {
    isCountryWithKnownStates,
    countriesAlpha,
  };
};
export default useRecurlyCreditCard;
