import { IconName } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { NavigationLink } from 'types/global';

import { DOWNLOAD_UTOMIK_INSTALLER_LINK } from 'app/global/constants';

import { UserControllerContext } from 'app/context/storesContext';

import Logo from '../logo/logo';

import FooterIcon from './footerIcon';
import { FooterIconLinkProps } from './footerIconLink';
import FooterIconLinks from './footerIconLinks';
import FooterLinks from './footerLinks';

import './footer.scss';

const getStartedLinks: NavigationLink[] = [
  {
    label: 'Start your trial',
    title: 'Register for a Utomik account',
    to: '/register/',
  },
  {
    label: 'Prepaid',
    title: 'Prepaid gift cards',
    to: 'https://www.utomik.com/prepaid/',
  },
  {
    label: 'Redeem a code',
    title: 'Redeem a code',
    to: '/redeem',
  },
  {
    label: 'Download Utomik',
    title: 'Download the Utomik installer',
    to: DOWNLOAD_UTOMIK_INSTALLER_LINK,
  },
  {
    label: 'Log in',
    title: 'Log in',
    to: '#',
  },
];

const getStartedLinksUserIsAuthorized: NavigationLink[] = [
  {
    label: 'Prepaid',
    title: 'Prepaid gift cards',
    to: 'https://www.utomik.com/prepaid/',
  },
  {
    label: 'Redeem a code',
    title: 'Redeem a code',
    to: '/redeem',
  },
  {
    label: 'Download Utomik',
    title: 'Download the Utomik installer',
    to: DOWNLOAD_UTOMIK_INSTALLER_LINK,
  },
];

const utomikLinks: NavigationLink[] = [
  {
    label: 'About',
    title: 'More about us',
    to: 'https://www.utomik.com/company/team/',
  },
  {
    label: 'News',
    title: 'News',
    to: 'https://www.utomik.com/news/',
  },
  {
    label: 'All games',
    title: 'Games',
    to: 'https://www.utomik.com/games/',
  },
];

const supportLinks: NavigationLink[] = [
  {
    label: 'Frequently Asked Questions',
    title: 'Frequently asked questions',
    to: 'https://support.utomik.com/hc/en-us',
  },
  {
    label: 'Utomik help center',
    title: 'Utomik help center',
    to: 'https://support.utomik.com/hc/en-us/requests/new',
  },
  {
    label: 'Customer support',
    title: 'Customer support',
    to: 'https://support.utomik.com/hc/en-us',
  },
  {
    label: 'Corporate contact',
    title: 'Contact',
    to: 'https://www.utomik.com/company/contact/',
  },
];

const workWithUsLinks: FooterIconLinkProps[] = [
  {
    label: 'Work at Utomik',
    title: 'Jobs',
    description: 'Join our team, become a Utomik employee.',
    to: 'https://www.utomik.com/company/jobs/',
    icon: 'laptop-code',
  },
  {
    label: 'Publishers',
    title: 'Publishers',
    description: 'Add your game to our gaming platform.',
    to: 'https://www.utomik.com/publishers/',
    icon: 'hand-holding-heart',
  },
  {
    label: 'Businesses',
    title: 'Businesses',
    description: 'Add 1415+ games to your platform.',
    to: 'https://www.utomik.com/partners/',
    icon: 'handshake',
  },
  {
    label: 'Press',
    title: 'Press releases',
    description: 'Write about us or use our brand assets.',
    to: 'https://www.utomik.com/company/press/',
    icon: 'newspaper',
  },
];

const joinOurCommunityLinks: FooterIconLinkProps[] = [
  {
    label: 'Ninjas',
    title: 'Ninjas',
    description: 'Become part of our testing community.',
    to: 'https://www2.utomik.com/ninja',
    icon: 'user-ninja',
  },
];
type SocialLinkProps = {
  to: string;
  icon: IconName;
};

const socialLinks: SocialLinkProps[] = [
  { to: 'https://www.facebook.com/utomikgaming', icon: 'facebook-f' },
  { to: 'https://twitter.com/Utomik', icon: 'twitter' },
  { to: 'https://www.youtube.com/channel/UCWyoGd2X0vE2Te6rGIHJ2og', icon: 'youtube' },
  { to: 'https://discord.com/invite/TkUj5Pc', icon: 'discord' },
];

const SocialLink: React.FC<SocialLinkProps> = ({ to, icon }) => (
  <a href={to} target="_blank" rel="noopener noreferrer">
    <FooterIcon icon={['fab', icon]} />
  </a>
);

export type FooterProps = {
  className?: string;
};

const Footer: React.FC<FooterProps> = observer(({ className }) => {
  const userController = useContext(UserControllerContext);

  const linksForRender =
    !userController.isLoggedInUser && !userController.checkIsFromRedeemPage()
      ? getStartedLinks
      : getStartedLinksUserIsAuthorized;
  return (
    <>
      <footer data-testid="footer" className={clsx('footer', className)}>
        <div className="footer__container">
          <Logo />
          {!userController.isLoggedInUser && (
            <p className="footer__text">
              Register now and get a 14-day trial to play all our video games instantly! Explore our game genres, access
              our games list, and enjoy instant play. With Utomik, get unlimited access to 1415+ games and new titles
              added regularly. Subscribe now for the ultimate gaming experience!
            </p>
          )}

          <FooterLinks title="Get Started" links={linksForRender} />
        </div>

        <div className="footer__container">
          <FooterLinks title="Utomik" links={utomikLinks} />
          <FooterLinks title="Support" links={supportLinks} />
        </div>

        <div className="footer__container">
          <FooterIconLinks title="Work with us" links={workWithUsLinks} />
          <FooterIconLinks title="Join our community" links={joinOurCommunityLinks} />

          <div className="footer__socials">
            {socialLinks.map((props) => (
              <SocialLink key={props.to} {...props} />
            ))}
          </div>
        </div>
      </footer>
    </>
  );
});

export default Footer;
