import withAuth from 'app/hoc/withAuth';
import NewsLetterModal from 'app/modals/newsLetterModal/newsLetterModal';
import CheckoutSection from 'app/widgets/checkoutSection/checkoutSection';
import CountryUnSupported from 'app/widgets/countryUnSupported/countryUnSupported';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';

import {
  CheckoutControllerContext,
  NewsLetterControllerContext,
  UtomikSupportedInCountryControllerContext,
} from 'app/context/storesContext';

import Modal from 'app/components/modal/modal';
import Spinner from 'app/components/spinner/spinner';

import styles from './checkout.module.scss';

const Checkout = observer(() => {
  const checkoutController = useContext(CheckoutControllerContext);
  const utomikSupportedInCountryController = useContext(UtomikSupportedInCountryControllerContext);
  const newsLetterController = useContext(NewsLetterControllerContext);
  const { showNewsLetter, checkIsShowNewsLetter, closeNewsLetterModal } = newsLetterController;
  const [isLoading, setIsLoading] = useState(true);
  const initPage = async () => {
    try {
      await utomikSupportedInCountryController.checkIsUtomikSupportedInCountry();
      await checkoutController.initPage();
    } catch (e) {
      console.error(e, 'Checkout page');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    initPage();
  }, []);
  useEffect(() => {
    checkIsShowNewsLetter();
  }, [checkoutController.user.newsletter_signup]);

  const renderSupportedOrNotSupportedCountry = () => {
    const { name, isSetAsUserCountry = false } = checkoutController.userCountry;

    return <CountryUnSupported countryName={name} isSetAsUserCountry={isSetAsUserCountry} />;
  };

  const loadingPage = () => {
    return <Spinner>Fetching shopping cart...</Spinner>;
  };
  if (!checkoutController.utomikIsLiveInUsersCountry && !isLoading) {
    return renderSupportedOrNotSupportedCountry();
  }

  return (
    <div className="container-main">
      <div className={styles['page-checkout-wrapper']}>
        <section className={clsx('container')}>
          <h1 className={styles['checkout-section-title']}>Checkout</h1>
          {isLoading && loadingPage()}
          {!isLoading && <CheckoutSection />}
        </section>
      </div>
      <Modal onHide={closeNewsLetterModal} show={showNewsLetter} title="Don't miss a thing!">
        <NewsLetterModal
          confirm={newsLetterController.confirmAndCallback}
          reject={newsLetterController.rejectAndCallback}
          isLoading={newsLetterController.isFetchingNewsLetter}
        />
      </Modal>
    </div>
  );
});
export default withAuth(Checkout);
