import AnalyticService from 'services/analyticsService/analyticsService';
import CartService from 'services/cartService/cartService';
import LoginService from 'services/loginService/loginServise';
import PaymentService from 'services/paymentService/paymentService';

import { ISubscriptionsResponseWithOrders } from 'types/subscriptions';

import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import { removePlatformURLFromURL } from 'utils/utils';

import { platformURL } from 'app/global/constants';

export default class SubscriptionManagementService {
  public constructor(
    private readonly _paymentService: PaymentService,
    private readonly _cartService: CartService,
    private readonly _loginService: LoginService,
    private readonly _httpTransport: AxiosTransport,
    private readonly _analyticService: AnalyticService,
  ) {}

  public async switchSubscription() {
    const productUrl = this._cartService.getProductPurchaseURL();
    return await this._paymentService.orderProduct(productUrl);
  }

  public async reactivateSubscription(subscriptionUrl: string, subscription?: ISubscriptionsResponseWithOrders | null) {
    try {
      if (await this._loginService.checkIsLoggedIn()) {
        const normalizedUrl = removePlatformURLFromURL(subscriptionUrl, platformURL).replace(/\/$/, '');
        await this._httpTransport.post(`${normalizedUrl}/do_reactivate`, undefined);
        subscription && this._analyticService.handleReactivateSubscription(subscription);
      } else {
        throw new Error('EXPIRED_JWT_TOKEN');
      }
    } catch (e) {
      this._analyticService.handleReactivateSubscriptionError();
      throw e;
    }
  }

  public async cancelSubscription(subscriptionUrl: string) {
    const normalizedUrl = removePlatformURLFromURL(subscriptionUrl, platformURL).replace(/\/$/, '');

    await this._httpTransport.post(`${normalizedUrl}/do_cancel`, {});
  }
}
