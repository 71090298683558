import { action, computed, makeObservable, observable } from 'mobx';
import AnalyticService from 'services/analyticsService/analyticsService';
import CartService from 'services/cartService/cartService';
import SelectPaymentMechanismService from 'services/selectPaymentMechanismService/selectPaymentMechanismService';
import UserService from 'services/userService/userService';

import {
  BillingInfoStep,
  IAnalyticVerify,
  IBillingInfoResponse,
  IPaymentType,
  IUpdateIdealBank,
  IUpdateRecurlyToken,
} from 'types/billing';

export default class UpdateBillingController {
  public constructor(
    private readonly _userService: UserService,
    private readonly _selectPaymentMechanismService: SelectPaymentMechanismService,
    private readonly _analyticService: AnalyticService,
    private readonly _cartService: CartService,
  ) {
    makeObservable(this);
  }

  @observable
  private _billingInfo: IBillingInfoResponse | null = null;

  @observable
  private _step: BillingInfoStep = 'select_country';

  @action
  public setStep(step: BillingInfoStep) {
    this._step = step;
  }

  @computed
  public get step() {
    return this._step;
  }

  @action
  public setBillingInfo(billingInfo: IBillingInfoResponse | null) {
    this._billingInfo = billingInfo;
  }

  @computed
  public get billingInfo(): IBillingInfoResponse | null {
    return this._billingInfo;
  }

  @action public async initBillingPage() {
    await this._userService.fetchUser();
    this._selectPaymentMechanismService.setPaymentMechanism([]);
    const billingInfo = await this._userService.getBillingInfo();
    this._selectPaymentMechanismService.selectPaymentType(billingInfo ? billingInfo.type : null);
    this.setBillingInfo(billingInfo);
  }

  @action public setBillingInfoStep() {
    if (!this._userService.user.country?.id) {
      this.setStep('select_country');
    } else {
      this.setStep('view_billing_info');
    }
  }

  @action
  public async setPaymentMechanisms() {
    await this._selectPaymentMechanismService.setPaymentMechanisms();
  }

  @action callbackCancel() {
    this.setStep('view_billing_info');
  }

  @computed
  public get paymentMechanisms() {
    return this._selectPaymentMechanismService.paymentMechanisms;
  }

  @computed
  public get paymentType() {
    return this._selectPaymentMechanismService.paymentType;
  }

  @action
  public selectPaymentType(paymentType: IPaymentType | null) {
    this._selectPaymentMechanismService.selectPaymentType(paymentType);
  }

  @action
  public handleConfirmPaymentMechanism(paymentType: IPaymentType) {
    if (paymentType === 'creditcard') {
      this.setStep('update_with_creditcard');
      return;
    }
    if (paymentType === 'paypal') {
      this.setStep('update_with_paypal');
      return;
    }
    if (paymentType === 'ideal') {
      this.setStep('update_with_ideal');
      return;
    }
  }

  public async updateBillingInfo(data: IUpdateRecurlyToken) {
    this.setStep('saving_billing_info');
    await this._userService.updateBillingInformation(data);
  }

  public async updateIdealSelectedBank(data: IUpdateIdealBank) {
    this.setStep('saving_billing_info');
    await this._userService.updateBillingInformation(data);
  }

  public sendAnalyticVerify = async (data: IAnalyticVerify): Promise<void> => {
    if (data.type === 'error') {
      return this._analyticService.handleThreeDSError();
    }
    const cart = await this._cartService.getCart();

    if (data.type === 'process') {
      return this._analyticService.handleThreeDSChallenge({
        product: cart.product,
        promo: cart.promo,
        paymentType: 'creditcard',
      });
    }
    this._analyticService.handleThreeDSCompletion({
      product: cart.product,
      promo: cart.promo,
      paymentType: 'creditcard',
    });
  };
}
