import { getPageNavigation } from 'app/configs/pageNavigations';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { IUserMenu } from 'types/global';

import { Routes } from 'app/global/routes';

import { HeaderControllerContext, PreconditionControllerContext } from 'app/context/storesContext';

import styles from './userMenu.module.scss';

const UserMenu: React.FC<IUserMenu> = observer(({ userEmail, isDropdownMenu }) => {
  const menuRef = useRef<HTMLLIElement | null>(null);
  const headerController = useContext(HeaderControllerContext);
  const preconditionController = useContext(PreconditionControllerContext);

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(() => (isDropdownMenu ? true : false));
  const [menuFocused, setMenuFocused] = useState(() => (isDropdownMenu ? true : false));
  const { pathname } = useLocation();
  const handleLogout = async () => {
    await headerController.logout();
    preconditionController.clearPreconditions();
    navigate(Routes.LogIn);
  };

  const preconditions = preconditionController.getPreconditions();

  const inVisibleTiles = preconditions
    ? Object.keys(preconditions).filter((key) => preconditions[key] === false)
    : ['canManageFamilyMembers', 'canSwitchFromUtomikPersonalToUtomikFamilyPlan'];

  const inVisibleElements = [
    ...inVisibleTiles,
    'canLeaveFamily',
    'support',
    'canSwitchFromUtomikPersonalToUtomikFamilyPlan',
  ];
  const navigationMenu = getPageNavigation({ handleLogout: handleLogout })
    .map((item) => ({
      ...item,
      visible: !inVisibleElements.includes(item.visible),
    }))
    .sort((a, b) => (a.id === 'manage-subscription' ? -1 : b.id === 'manage-subscription' ? 1 : 0));

  useEffect(() => {
    if (isDropdownMenu) {
      return;
    }
    const closeMenu = (event: MouseEvent) => {
      if (!menuFocused && menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', closeMenu);

    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, [menuFocused, isDropdownMenu]);

  const toggleMenuOpen = (e: React.MouseEvent<HTMLLIElement | HTMLAnchorElement>) => {
    if (isDropdownMenu) {
      return;
    }
    e.preventDefault();
    setIsOpen((prevState) => !prevState);
  };

  const setFocus = () => {
    setMenuFocused(true);
  };

  const setBlur = () => {
    setMenuFocused(false);
  };

  return (
    <ul className={styles['menu-items-user']}>
      <li
        ref={menuRef}
        className={`${styles['menu-dropdown']}`}
        onClick={toggleMenuOpen}
        onFocus={setFocus}
        onBlur={setBlur}
        tabIndex={0}
        data-testid="item-menu-dropdown"
      >
        {!isDropdownMenu && (
          <div
            className={clsx(styles['menu-dropdown-toggle'], isOpen && styles['menu-dropdown-toggle-open'])}
            data-testid="menu-dropdown-toggle"
          >
            <span className={styles['email-in-header']}>{userEmail}</span>
            <img src="/images/ui/user.svg" className={styles['user-icon']} alt="Utomik Logo" />
          </div>
        )}

        {isDropdownMenu && (
          <>
            <span>
              <img src="/images/ui/user.svg" alt="User" />
              <span className={styles['email-in-header']}>{userEmail}</span>
            </span>
          </>
        )}

        <ul
          className={`${isOpen ? styles['menu-dropdown-open'] : styles['menu-dropdown-closed']}`}
          data-testid={`${isOpen ? 'menu-dropdown-open' : 'menu-dropdown-closed'}`}
        >
          {navigationMenu.map(
            (link) =>
              link.visible && (
                <li key={link.id} data-testid="link-menu-wrapper">
                  <Link
                    to={link.redirectTo || pathname}
                    className={`${styles['link-menu']} link-menu-item-toggle`}
                    onClick={link.action}
                    data-testid={`link-menu-${link.id}`}
                  >
                    {link.linkTitle}
                  </Link>
                </li>
              ),
          )}
        </ul>
      </li>
    </ul>
  );
});
export default UserMenu;
