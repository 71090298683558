import withNavigate from 'app/hoc/withNavigate';
import { Outlet } from 'react-router-dom';

import './empty.scss';

const EmptyLayout = () => (
  <div className="container empty-layout">
    <main className="empty-layout__main">
      <Outlet />
    </main>
  </div>
);

export default withNavigate(EmptyLayout);
