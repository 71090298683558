import { useEffect, useState } from 'react';

export const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState<boolean>(false);

  function handleChange(mediaQueryListOrEvent: MediaQueryListEvent | MediaQueryList) {
    setMatches(mediaQueryListOrEvent.matches);
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    handleChange(matchMedia);

    matchMedia.addEventListener('change', handleChange);

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
};
