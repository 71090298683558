import clsx from 'clsx';
import React from 'react';

import styles from './404.module.scss';

const NotFoundPage = () => {
  return (
    <div className={clsx('container', styles['page-not-found'])}>
      <section className="page-section">
        <h1>
          <span className="fas fa-bomb"></span> Page not found
        </h1>
        <p>Our Utomik droids could not find the page you were looking for.</p>
      </section>
    </div>
  );
};
export default NotFoundPage;
