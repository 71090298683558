import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import PreconditionService from 'services/preconditionService/preconditionService';
import UserService from 'services/userService/userService';

import { IBillingInfoResponse } from 'types/billing';
import { IInvoiceBilling, IInvoiceWithBilling, IInvoices, StepInvoices } from 'types/invoices';

import { translateErrors } from 'utils/translateErrors';

export default class InvoicesController {
  public constructor(
    private readonly _userService: UserService,
    private readonly _preconditionService: PreconditionService,
  ) {
    makeObservable(this);
  }

  @observable
  private _step: StepInvoices = 'loading_invoices';

  @observable
  private _invoices: IInvoices[] = [];

  @action
  public setInvoices(invoices: IInvoices[]) {
    this._invoices = invoices;
  }

  @computed
  public get invoices() {
    return this._invoices;
  }

  @observable
  private _currentInvoice: IInvoiceWithBilling | null = null;

  @action
  public setCurrentInvoice(currentInvoice: IInvoiceWithBilling | null) {
    this._currentInvoice = currentInvoice;
  }

  @computed
  public get currentInvoice() {
    return this._currentInvoice;
  }

  @action
  public setStep(step: StepInvoices) {
    this._step = step;
  }

  @computed
  public get step() {
    return this._step;
  }

  @observable
  private _billingInfo: IBillingInfoResponse | null = null;

  @action
  public setBillingInfo(billingInfo: IBillingInfoResponse | null) {
    this._billingInfo = billingInfo;
  }

  @computed
  public get billingInfo(): IBillingInfoResponse | null {
    return this._billingInfo;
  }

  @observable
  private _error = '';

  @action
  public setError(error: string) {
    this._error = error;
  }

  @computed
  public get error() {
    return this._error;
  }

  @observable
  private _isCurrentTrial = false;

  @action
  public setIsCurrentTrial(isCurrentTrial: boolean) {
    this._isCurrentTrial = isCurrentTrial;
  }

  @computed
  public get isCurrentTrial() {
    return this._isCurrentTrial;
  }

  public async initPage() {
    try {
      await this._userService.fetchUser();
      const invoices = await this._userService.getInvoices();
      const billingInfo = await this._userService.getBillingInfo();
      this.setInvoices(invoices);
      this.setBillingInfo(billingInfo);
      this.sortInvoicesByDate();
      this.setStep('view_invoices');
    } catch (e) {
      this.setError(translateErrors(e));
    }
  }

  public get utomikIsLiveInUsersCountry() {
    return this._userService.utomikIsLiveInUsersCountry;
  }

  public get user() {
    return this._userService.user;
  }

  public sortInvoicesByDate() {
    const sortedInvoices = [...this._invoices];
    sortedInvoices.sort((a, b) => moment(b.creationdate).diff(moment(a.creationdate)));
    this.setInvoices(sortedInvoices);
  }

  public get preconditions() {
    return this._preconditionService.preconditions;
  }

  public getIsCurrentTrial(currentInvoice: IInvoices) {
    if (!currentInvoice || !currentInvoice.term_begin || !currentInvoice.term_end) {
      return false;
    }
    const { term_begin, term_end } = currentInvoice;

    const now = moment().valueOf();
    const hasStarted = now > moment(term_begin).valueOf();
    const hasEnded = now > moment(term_end).valueOf();
    return currentInvoice.trial && hasStarted && !hasEnded;
  }

  public translateDate(date: Date | string): Date {
    if (typeof date === 'string') {
      let momentResult = moment(date, ['YYYY-MM-DD', 'YYYY-MM-DD\\THH:mm:ssZ', 'YYYY-MM-DD\\THH:mm:ss'], true);
      if (momentResult.format('YYYY-MM-DD\\THH:mm:ss') === 'YYYY-MM-DD\\THH:mm:ss') {
        momentResult = moment.utc(date, ['YYYY-MM-DD\\THH:mm:ss'], true);
      }
      if (momentResult.isValid()) {
        return momentResult.toDate();
      }
    }

    return date as Date;
  }

  public transformInvoice(currentInvoice: IInvoices): IInvoiceWithBilling {
    this.setIsCurrentTrial(this.getIsCurrentTrial(currentInvoice));
    const copyBillingInfo: IInvoiceBilling = { ...this._billingInfo } as IBillingInfoResponse;
    const invoiceToRender: IInvoiceWithBilling = { ...currentInvoice };
    if (copyBillingInfo) {
      copyBillingInfo.address = copyBillingInfo.address1 || '';
    }

    const { creationdate, term_begin, term_end } = invoiceToRender;

    invoiceToRender.billingaddress = copyBillingInfo;
    invoiceToRender.creationdate = this.translateDate(creationdate);
    invoiceToRender.term_begin = this.translateDate(term_begin);
    invoiceToRender.term_end = this.translateDate(term_end);
    return invoiceToRender;
  }

  public showInvoiceDetail = (invoiceId: string) => {
    const currentInvoice = this.invoices.find((invoice) => `${invoice.id}` === invoiceId);
    if (currentInvoice) {
      this.setCurrentInvoice(this.transformInvoice(currentInvoice));
      this.setStep('view_invoice');
    } else {
      this.setCurrentInvoice(null);
      this.setIsCurrentTrial(false);
      this.setError("Couldn't find invoice. You may not have permission to see this invoice.");
    }
  };

  public selectInvoice = (invoiceId: number) => {
    const currentUrl = window.location.origin + window.location.pathname + '#?invoiceid=' + invoiceId;
    window.history.replaceState({}, '', currentUrl);
    this.showInvoiceDetail(`${invoiceId}`);
  };

  public get firstName() {
    return this._userService.user.first_name;
  }

  public get lastName() {
    return this._userService.user.last_name;
  }

  public goBackToViewInvoices = () => {
    this.setCurrentInvoice(null);
    this.setIsCurrentTrial(false);
    const currentUrl = window.location.origin + window.location.pathname;
    window.history.replaceState({}, '', currentUrl);
    this.setStep('view_invoices');
  };

  public clearState = () => {
    this.setCurrentInvoice(null);
    this.setIsCurrentTrial(false);
    this.setStep('view_invoices');
    this.setError('');
  };
}
