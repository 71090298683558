import { IPaymentMechanisms } from 'types/billing';

export const defaultPaymentMechanisms: IPaymentMechanisms[] = [
  {
    name: 'Pay with Card',
    provider: 'recurly',
    type: 'creditcard',
    imgSrc: '/images/payment/creditcard.png',
  },
  { name: 'Pay with PayPal', provider: 'recurly', type: 'paypal', imgSrc: '/images/payment/paypal.png' },
  { name: 'Pay with iDEAL', provider: 'paynl', type: 'ideal', imgSrc: '/images/payment/ideal.png' },
];
