import _ from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import PageService from 'services/pageService/pageService';

import { IAllGames, IGameResponse, IGames } from 'types/pages';

import PageStore from 'dataStore/stores/pageStore/pageStore';
import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import { HOME_TOP_APPLICATION_COUNT } from '../constants';

export default class PageController {
  @observable
  private readonly _pageStore: PageStore;

  public constructor(
    private readonly transport: AxiosTransport,
    pageStore: PageStore,
    private readonly _pageService: PageService,
  ) {
    this._pageStore = pageStore;
    makeObservable(this);
  }

  @computed
  public get games(): IGames[] {
    return this._pageStore.displayedGames;
  }

  @computed
  public get gamesPublishedLastMonth(): IAllGames[] {
    return this._pageStore.gamesPublishedLastMonth;
  }

  public getPath() {
    return this._pageService.getPath();
  }

  public getHostName() {
    return this._pageService.getHostName();
  }

  public getHash() {
    return this._pageService.getHash();
  }

  public checkPath(validPaths: string[]): boolean {
    return this._pageService.checkPath(validPaths);
  }

  public getQueryParams(locationStringOrLocation?: string | Location) {
    return this._pageService.getQueryParams(locationStringOrLocation);
  }

  public setLocation(newLocation: string, allowInsecureAbsoluteURLs?: boolean) {
    this._pageService.setLocation(newLocation, allowInsecureAbsoluteURLs);
  }

  public async fetchGames(): Promise<void> {
    const response = await this.transport.get<IGameResponse[]>('v1/applications');
    if (response?.data.length) {
      const allGames = response.data;

      const homePageGames = allGames.slice(0, HOME_TOP_APPLICATION_COUNT);
      this._pageStore.setAllGames(allGames);
      this._pageStore.setDisplayedGames(homePageGames);
    }
  }

  public checkIsFromRedeemPage = () => {
    return this._pageService.checkIsFromRedeemPage();
  };
}
