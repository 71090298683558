import React from 'react';

import styles from './countryUnSupported.module.scss';

interface ICountryUnsupported {
  countryName: string;
  isSetAsUserCountry: boolean;
}

const CountryUnSupported: React.FC<ICountryUnsupported> = ({ countryName, isSetAsUserCountry }) => {
  const renderMainSection = () => {
    if (!isSetAsUserCountry) {
      return (
        <div>
          <p>Unfortunately, Utomik is not available in {countryName}.</p>
          <p>
            If you are not currently in {countryName}, please disable any VPNs or other geolocation software and refresh
            this page.
          </p>
        </div>
      );
    } else {
      return (
        <div>
          Utomik is not available in <strong>{countryName}</strong>.
        </div>
      );
    }
  };

  return (
    <div className={`${styles['unsupported']} container`} id="country-unsupported">
      <h1>Utomik is not available in your country</h1>
      <div className={styles['unsupported-main']}>
        {renderMainSection()}
        <h2>Keep in touch</h2>
        <p>Stay tuned with us on our social media; we'll let you know when we go live in your country.</p>

        <div className={styles['unsupported-buttons']}>
          <a
            className="btn btn-round"
            href="https://www.facebook.com/utomikgaming"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fab fa-facebook-f"></span>
          </a>
          <a className="btn btn-round" href="https://twitter.com/Utomik" target="_blank" rel="noopener noreferrer">
            <span className="fab fa-twitter"></span>
          </a>
          <a
            className="btn btn-round"
            href="https://www.instagram.com/utomikgaming/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fab fa-instagram"></span>
          </a>
          <a className="btn btn-round" href="https://discord.gg/TkUj5Pc" target="_blank" rel="noopener noreferrer">
            <span className="fab fa-discord"></span>
          </a>
        </div>
      </div>
    </div>
  );
};
export default CountryUnSupported;
