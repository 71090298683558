import React from 'react';

import { IPaymentProvider, IPaymentType } from 'types/billing';

import Alert from 'app/components/alert/alert';

import styles from './billingInfoCard.module.scss';

interface IBillingInfoCard {
  billingProvider: IPaymentProvider;
  type: IPaymentType;
  cardType: string;
  lastFour: string;
}
type IPaymentInfo = {
  [key in IPaymentProvider]: {
    [type in IPaymentType]?: React.ReactNode;
  };
} & {
  default: React.ReactNode;
};

const BillingInfoCard: React.FC<IBillingInfoCard> = ({ billingProvider, type, cardType, lastFour }) => {
  const billingInfo: IPaymentInfo = {
    recurly: {
      creditcard: (
        <div className={styles['billing-info-card']}>
          <div>
            <span className={styles['billing-info-card-title']}>Type</span>
            <span className={styles['billing-info-card-description']}>Credit card</span>
          </div>
          <div>
            <span className={styles['billing-info-card-title']}>Card type</span>
            <span className={styles['billing-info-card-description']}>{cardType}</span>
          </div>
          <div>
            <span className={styles['billing-info-card-title']}>Number</span>
            <span className={styles['billing-info-card-description']}>************{lastFour}</span>
          </div>
        </div>
      ),
      paypal: (
        <div className={styles['billing-info-card']}>
          <span className={styles['billing-info-card-title']}>Type</span>
          <span className={styles['billing-info-card-description']}>PayPal</span>
        </div>
      ),
    },
    paynl: {
      ideal: (
        <div className={styles['billing-info-card']}>
          <span className={styles['billing-info-card-title']}>Type</span>
          <span className={styles['billing-info-card-description']}>iDEAL</span>
        </div>
      ),
    },
    default: <Alert info>No payment methods associated with this account.</Alert>,
  };
  const content = billingProvider && type ? billingInfo[billingProvider][type] : billingInfo['default'];

  return <>{content}</>;
};
export default BillingInfoCard;
