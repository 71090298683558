import React from 'react';

export const IconMobile = () => {
  return (
    <svg width="15" height="21" viewBox="0 0 15 21" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4286 1.27273H12.1429C12.9318 1.27273 13.5714 1.84255 13.5714 2.54545V18.4545C13.5714 19.1575 12.9318 19.7273 12.1429 19.7273H2.85714C2.06816 19.7273 1.42857 19.1575 1.42857 18.4545V2.54545C1.42857 1.84255 2.06816 1.27273 2.85714 1.27273H3.57143C3.57143 1.97564 4.21102 2.54545 5 2.54545H10C10.789 2.54545 11.4286 1.97564 11.4286 1.27273ZM0 2.54545C0 1.13964 1.27919 0 2.85714 0H12.1429C13.7208 0 15 1.13964 15 2.54545V18.4545C15 19.8604 13.7208 21 12.1429 21H2.85714C1.27919 21 0 19.8604 0 18.4545V2.54545Z"
        fill="white"
      />
    </svg>
  );
};
