import { defaultPaymentMechanisms } from 'lists/defaultPaymentMechanisms';
import { action, computed, makeObservable, observable } from 'mobx';
import UserService from 'services/userService/userService';

import { IPaymentMechanisms, IPaymentOptions, IPaymentType } from 'types/billing';

export default class SelectPaymentMechanismService {
  public constructor(private readonly _userService: UserService) {
    makeObservable(this);
  }

  @observable
  private _paymentType: IPaymentType | null = null;

  @observable
  private _paymentOptionsForUser: IPaymentOptions[] | null = null;

  @observable
  private _paymentMechanisms: IPaymentMechanisms[] = [];

  @action
  public setPaymentType(paymentType: IPaymentType | null) {
    this._paymentType = paymentType;
  }

  @computed
  public get paymentType() {
    return this._paymentType;
  }

  @action setPaymentMechanism(paymentMechanisms: IPaymentMechanisms[]) {
    this._paymentMechanisms = paymentMechanisms;
  }

  @computed
  public get paymentMechanisms() {
    return this._paymentMechanisms;
  }

  @action
  public setPaymentOptionsForUser(options: IPaymentOptions[] | null) {
    this._paymentOptionsForUser = options;
  }

  @computed
  public get paymentOptionsForUser() {
    return this._paymentOptionsForUser;
  }

  @action
  public filteredPaymentMechanisms() {
    return defaultPaymentMechanisms.filter(({ type, provider }) => {
      return this.paymentOptionsForUser?.some(({ usable, type: userType, provider: userProvider }) => {
        return usable && userType === type && userProvider === provider;
      });
    });
  }

  public refreshPaymentMechanisms() {
    const filteredPaymentMechanisms = this.filteredPaymentMechanisms();
    this.setPaymentMechanism(filteredPaymentMechanisms);
  }

  public async setPaymentMechanisms(): Promise<void> {
    try {
      const paymentOptions = await this._userService.getPaymentOptions();
      this.setPaymentOptionsForUser(paymentOptions);
      this.refreshPaymentMechanisms();
    } catch (e) {
      throw new Error('COULD_NOT_FETCH_AVAILABLE_PAYMENT_OPTIONS');
    }
  }

  public selectPaymentType(paymentType: IPaymentType | null) {
    this.setPaymentType(paymentType);
  }
}
