import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ActionSSo, FilterSso, ISsoClicked, SsoProviders } from 'types/sso';

import { FAQ_URL, SsoActionStatuses } from 'app/global/constants';

import { SsoControllerContext } from 'app/context/storesContext';

import Button from 'app/components/button/button';
import Spinner from 'app/components/spinner/spinner';

import './buttonStyle.scss';

interface ISsoButtons {
  action: ActionSSo;
  isDisabledButton?: boolean;
  fromFilter?: FilterSso;
  nextStep?: string;
  setStep?: (step: string) => void;
  handleConfirm?: () => void;
  closeModalAfterConfirm?: boolean;
  inviteToken?: string;
}

const SsoButtons: React.FC<ISsoButtons> = observer(
  ({
    action,
    isDisabledButton = false,
    fromFilter,
    nextStep,
    setStep,
    handleConfirm,
    closeModalAfterConfirm,
    inviteToken,
  }) => {
    const [ssoProviders, setSsoProviders] = useState<SsoProviders[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentSlug, setCurrentSlug] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const ssoController = useContext(SsoControllerContext);
    const { ssoDeprecation } = ssoController;

    const fetchSso = async () => {
      try {
        const ssoProviders = await ssoController.fetchSsoProviders({ fromFilter: fromFilter });
        setSsoProviders(ssoProviders);
      } catch (error: any) {
        console.error(error?.message, 'CATCH fetchSso');
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      const messageHandler = async (event: any) => {
        if (event.data === 'closeSocialWindow' && event.source) {
          handleConfirm && (await handleConfirm());
          setStep && nextStep && !closeModalAfterConfirm && setStep(nextStep);
          setIsDisabled(false);
          setCurrentSlug('');
          event.source.close();
        }
      };
      window.addEventListener('message', messageHandler);
      return () => {
        window.removeEventListener('message', messageHandler);
      };
    }, []);

    useEffect(() => {
      fetchSso();
      ssoController.setSsoActionStatus(null);
    }, []);

    useEffect(() => {
      const { ssoActionStatus } = ssoController;
      if (ssoActionStatus && ssoActionStatus === SsoActionStatuses.SSO_ACTION_STOPPED) {
        setIsDisabled(false);
        setCurrentSlug('');
      }
    }, [ssoController.ssoActionStatus]);

    useEffect(() => {
      return () => {
        setIsDisabled(false);
        setCurrentSlug('');
      };
    }, []);

    const authWithSso = ({ action, id, slug, ssoUrl }: ISsoClicked) => {
      setCurrentSlug(slug);
      setIsDisabled(true);
      ssoController.ssoClicked({ action, slug, id, ssoUrl, inviteToken });
    };

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <>
        {!!ssoProviders?.length &&
          ssoProviders.map((sso) => (
            <React.Fragment key={sso.id + action}>
              <div className="ssobuttons">
                <Button
                  type="button"
                  className={`btn-social ${sso.deprecationDate && 'btn-social-only-icon'} ${
                    !sso.color || (sso.color.toLowerCase() === '#ffffff' && 'btn-social-light')
                  }`}
                  style={{ background: !sso.color || sso.color.toLowerCase() === '#ffffff' ? '#fff' : sso.color }}
                  disabled={isDisabled || isDisabledButton}
                  onClick={() => authWithSso({ action: action, id: sso.id, slug: sso.slug, ssoUrl: sso.ssoUrl })}
                  data-role="login-social"
                  data-testid="sso-button"
                >
                  <span className="btn-social--body">
                    {currentSlug !== sso.slug && <img src={sso.logo} alt={sso.displayName} />}
                    {!!sso.deprecationDate && currentSlug === sso.slug && (
                      <span className="btn-social-only-icon--loading" />
                    )}
                    {!sso.deprecationDate && (
                      <span>
                        {action} with {sso.displayName}
                      </span>
                    )}
                  </span>
                </Button>
                {action === 'Continue' && (
                  <p className="continue-with-sso">Continuing with {sso.slug} will open a new popup window.</p>
                )}
              </div>
            </React.Fragment>
          ))}
        {action === 'Log in' && !!ssoDeprecation.length && (
          <div className="btn-social-deprecation">
            <ul className="btn-social-deprecation-list">
              {ssoDeprecation.map((deprecatedProvider) => (
                <li key={deprecatedProvider.id} className="btn-social-deprecation-list-item">
                  {deprecatedProvider.display_name} will no longer be available as a login method as of{' '}
                  <strong>
                    {deprecatedProvider.deprecation_date &&
                      moment(deprecatedProvider.deprecation_date).format('MMMM Do, YYYY')}
                  </strong>
                  .
                </li>
              ))}
            </ul>
            If you use {ssoDeprecation.length === 1 ? 'this option' : 'one of these options'}, please follow the
            instructions on our{' '}
            <Link to={`${FAQ_URL}/articles/360010976519`} target="_blank" rel="noopener noreferrer">
              FAQ page
            </Link>{' '}
            to prevent losing access to your account.
          </div>
        )}
      </>
    );
  },
);
export default SsoButtons;
