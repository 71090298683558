import clsx from 'clsx';
import { Field, useField } from 'formik';
import { forwardRef } from 'react';

import ErrorMessage from '../errorMessage/errorMessage';

import styles from './select.module.scss';

export type SelectOption = {
  label: string;
  value: string;
  isValidateErrors?: boolean;
};

export type SelectProps = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
  name: string;
  options: SelectOption[];
  isValidateErrors?: boolean;
};

const Select = forwardRef<HTMLSelectElement, SelectProps>(function Select(
  { className, options, isValidateErrors = true, ...props },
  ref,
) {
  const [field, meta, helpers] = useField(props);

  const hasError = meta.touched && meta.error && isValidateErrors;
  const selectClassName = clsx(styles.select__select, className && `${className}__select`);
  const errorClassName = clsx(styles.select__error, className && `${className}__error`);

  const handleBlur = (event: React.FocusEvent<HTMLSelectElement>) => {
    field.onBlur(event);
    helpers.setTouched(true);
  };

  const handleFocus = (event: React.FocusEvent<HTMLSelectElement>) => {
    helpers.setTouched(true);
  };

  const fieldProps = {
    ...field,
    ...props,
    as: 'select',
    className: selectClassName,
    onBlur: handleBlur,
    onFocus: handleFocus,
    innerRef: ref,
  };

  return (
    <label className={clsx(styles.select, className)}>
      <Field {...fieldProps}>
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Field>

      {hasError && <ErrorMessage className={errorClassName} name={field.name} />}
    </label>
  );
});

export default Select;
