import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';

import { CUSTOMER_SUPPORT_URL } from 'app/global/constants';

import { LoginControllerContext } from 'app/context/storesContext';

import Footer from 'app/components/footer/footer';
import Header from 'app/components/header/header';

import styles from './500.module.scss';

const UnhandledErrorPage = () => {
  const loginController = useContext(LoginControllerContext);

  const logout = async () => {
    await loginController.logout(true);
  };

  useEffect(() => {
    logout();
  }, []);
  return (
    <>
      <Header />
      <div className={clsx('container', styles['page-unhandled'])}>
        <section className="page-section">
          <h1>
            <span className="far fa-hand-paper"></span> Inter..nal... er...ror
          </h1>
          <p>Something went wrong trying to load this page.</p>
          <p>
            Please give us some time to gear up our engine and try again, or contact us if the problem continues to
            exist.
          </p>
          <p>
            <a href={CUSTOMER_SUPPORT_URL} data-link="support" className="btn" title="Customer Support">
              Contact us
            </a>
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default UnhandledErrorPage;
