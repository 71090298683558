import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import SubscriptionService from 'services/subscriptionService/subscriptionService';
import UserService from 'services/userService/userService';

import { IOrdersResponse } from 'types/orders';

import { translateErrors } from 'utils/translateErrors';

import { DAYS_UNTIL_DELETION } from '../constants';

interface ILimitations {
  disallowedReason: string | null;
  warningReason: string | null;
}

export default class DeleteAccountController {
  public constructor(
    private readonly _userService: UserService,
    private readonly _subscriptionService: SubscriptionService,
  ) {
    makeObservable(this);
  }

  @observable
  private _limitations: ILimitations = {
    disallowedReason: null,
    warningReason: null,
  };

  @action
  public setLimitations(limitations: ILimitations) {
    this._limitations = limitations;
  }

  @computed
  public get limitations() {
    return this._limitations;
  }

  @observable
  private _isFetching = false;

  @action
  public setIsFetching(isFetching: boolean) {
    this._isFetching = isFetching;
  }

  @computed
  public get isFetching() {
    return this._isFetching;
  }

  @observable
  private _errorRequest = '';

  @action
  public setErrorRequest(errorRequest: string) {
    this._errorRequest = errorRequest;
  }

  @computed
  public get errorRequest() {
    return this._errorRequest;
  }

  @observable
  private _reauthModalMessage: string[] = [];

  @action
  public setReauthModalMessage(message: string[]) {
    this._reauthModalMessage = message;
  }

  @computed
  public get reauthModalMessage() {
    return this._reauthModalMessage;
  }

  @observable
  private _modalIsOpen = false;

  @action
  public setModalIsOpen(isOpen: boolean) {
    this._modalIsOpen = isOpen;
  }

  @computed
  public get modalIsOpen() {
    return this._modalIsOpen;
  }

  @observable
  private _reseller = '';

  @action
  public setReseller(reseller: string) {
    this._reseller = reseller;
  }

  @computed
  public get reseller() {
    return this._reseller;
  }

  public initPage = async () => {
    const user = this._userService.user;
    if (!user.id) {
      await this._userService.fetchUser();
    }
    await this._userService.fetchAccountSubscriptions();

    const subscription = this._userService.accountSubscriptions[0];
    const isHasReseller = (subscription?.order as IOrdersResponse).reseller;
    if (isHasReseller) {
      const partnerId = (subscription.order as IOrdersResponse).reseller;
      const partner = partnerId && (await this._subscriptionService.getPartner(partnerId));
      partner && this.setReseller(partner.name);
    }
    this.calculateUserLimitations();
  };

  public get user() {
    return this._userService.user;
  }

  public get accountSubscriptions() {
    return this._userService.accountSubscriptions;
  }

  public calculateUserLimitations() {
    this.setLimitations({
      disallowedReason: null,
      warningReason: null,
    });
    const user = this.user;
    const subscription = this.accountSubscriptions;

    if (!user.id || user.deletion_date || !subscription.length) {
      return false;
    }
    const isMember = user.account?.role === 'Member';
    const subscriptionOrder = subscription[0]?.order as IOrdersResponse | undefined;
    const subscriptionRecurring = subscription[0]?.recurring;
    if (!isMember) {
      if (subscriptionOrder?.reseller || !subscriptionRecurring) {
        this.setLimitations({ ...this.limitations, disallowedReason: 'RESELLER' });
      } else if (subscription[0].status === 'ACTIVE') {
        this.setLimitations({ ...this.limitations, disallowedReason: 'ACTIVE_SUBSCRIPTION' });
      }
    }
    if (subscription[0].type === 'SF') {
      const warning = isMember ? 'FAMILY_PLAN_MEMBER' : 'FAMILY_PLAN_OWNER';
      this.setLimitations({ ...this.limitations, warningReason: warning });
    }
  }

  public revokeDeletion = async () => {
    this.setIsFetching(true);
    try {
      await this._userService.revokeDeletion();
      this._userService.setUser({ ...this.user, deletion_date: null });
      this.calculateUserLimitations();
    } catch (e) {
      this.setErrorRequest(translateErrors(e));
    } finally {
      this.setIsFetching(false);
    }
  };

  public toggleModal = () => {
    this.setModalIsOpen(!this.modalIsOpen);
  };

  public deleteAccount = async () => {
    try {
      const deletionDate = await this._userService.requestDeletion();

      this._userService.setUser({ ...this._userService.user, deletion_date: deletionDate?.deletion_date });
      this.calculateUserLimitations();
    } catch (e) {
      this.setErrorRequest(translateErrors(e));
    } finally {
      this.setIsFetching(false);
    }
  };

  public requestDeletion = () => {
    const subscription = this.accountSubscriptions[0];
    let expectDeletionDateMessage = "This will take effect in two weeks' time.";
    if (subscription) {
      const expirationDate = subscription.renews || subscription.expire_due || subscription.paid_until;
      const timeUntilExpiry = moment(expirationDate).diff(moment(), 'days');
      if (timeUntilExpiry > DAYS_UNTIL_DELETION) {
        expectDeletionDateMessage = 'Your account will be deleted after your subscription ends.';
      }
    }
    this.setReauthModalMessage([
      'You are about to delete your account. ' + expectDeletionDateMessage,
      'You can undo your pending deletion at any time before then.',
    ]);
    this.toggleModal();
  };

  public get subscription() {
    return this._userService.accountSubscriptions[0];
  }
}
