import _ from 'lodash';

export type Expires = Date | string | number;
export type Samesite = 'strict' | 'lax' | 'none';
export type MaxAgeUnit = 'days' | 'minutes' | 'hours';

export interface IOptions {
  expires?: Expires;
  path?: string;
  domain?: string;
  secure?: boolean;
  samesite?: Samesite;
  httpOnly?: boolean;
  maxAge?: number;
  maxAgeUnit?: MaxAgeUnit;
  'max-age'?: number;
}

type TCookieValue = string | null | undefined;

export default class CookieService {
  private _parseCookieOptions = (options: IOptions) => {
    const defaults = {
      samesite: 'Lax',
      domain: '',
    };

    // Cookie path; must match domain in website-scripts cookiewall script!
    const cookieDomain =
      window.location.hostname.endsWith('utomik.com') && window.location.hostname.indexOf('localhost') === -1
        ? 'utomik.com'
        : window.location.hostname.replace('www.', '');
    defaults.domain = cookieDomain;

    // Calculate the expiration if an maxAgeUnit has been supplied
    if (!options) {
      options = {};
    }
    if (options.maxAge) {
      let maxAgeValue = options.maxAge * 60 * 60 * 24; // Hours by default

      // Do we need to deviate from the default maxAge in hours?
      if (options.maxAgeUnit) {
        const multiplier = {
          days: 60 * 60 * 24,
          hours: 60 * 60,
          minutes: 60,
        };

        if (multiplier[options.maxAgeUnit]) {
          maxAgeValue = options.maxAge * multiplier[options.maxAgeUnit];
        }
      }

      options['max-age'] = maxAgeValue;
    }

    // Overwrite defaults with custom options and return
    const filteredOptions = _.pick(options, ['path', 'domain', 'max-age', 'expires', 'secure', 'samesite']);

    return _.merge(defaults, filteredOptions);
  };

  private _convertToCookieValue = (cookieValue: TCookieValue) => {
    switch (cookieValue) {
      case null:
      case 'null':
      case undefined:
      case 'undefined':
        return '';
      default:
        return cookieValue;
    }
  };

  private _getTrueCookieValue = (cookieValue: TCookieValue) => {
    switch (cookieValue) {
      case 'true':
        return true;
      case 'false':
        return false;
      case '':
      case 'null':
      case null:
      case 'undefined':
      case undefined:
        return undefined;
      default:
        return cookieValue;
    }
  };

  public getCookie(cookieName: string) {
    const re = new RegExp('(?:(?:^|.*;\\s*)' + cookieName + '\\s*\\=\\s*([^;]*).*$)|^.*$');
    const cookieValue = document.cookie.replace(re, '$1');

    return this._getTrueCookieValue(cookieValue);
  }

  public setCookie(cookieName: string, cookieValue: string | null | undefined, options: IOptions) {
    const cookieSettingsString = _.reduce(
      this._parseCookieOptions(options),
      function (m, value, key) {
        m += ';' + key;

        // By default, append the value; if the value === true, just having ';key' is good enough.
        if (value !== true) {
          m += '=' + value;
        }

        return m;
      },
      '',
    );

    document.cookie = cookieName + '=' + this._convertToCookieValue(cookieValue) + cookieSettingsString;
  }

  public removeCookie(cookieName: string, options: IOptions) {
    // No options? Make an empty object.
    if (!options) {
      options = {};
    }

    options.expires = 'Thu, 01-Jan-1970 00:00:01 GMT';

    const _removeCookieNow = (domainSettings: IOptions) => {
      this.setCookie(cookieName, '', this._parseCookieOptions(_.merge(options, domainSettings)));
    };

    // Remove cookies from current domain and from utomik.com and localhost.utomik.com
    _removeCookieNow({});
    _removeCookieNow({ domain: 'utomik.com' });
    _removeCookieNow({ domain: 'localhost.utomik.com' });
    _removeCookieNow({ domain: 'localhost' });
    _removeCookieNow({ domain: 'www.utomik.com' });
    _removeCookieNow({ domain: 'www.localhost.utomik.com' });
  }
}
