import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { Routes } from 'app/global/routes';

import { CookieServiceContext, LoginControllerContext, PageControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';

const ImportSession = observer(() => {
  const pageController = useContext(PageControllerContext);
  const loginController = useContext(LoginControllerContext);
  const cookieService = useContext(CookieServiceContext);

  const [error, setError] = useState('');
  const [fromPage, setFromPage] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const parsedQuery = pageController.getQueryParams();
    const urlObject = new URL(parsedQuery.next);
    const hash = urlObject.hash;
    const inviteToken = hash.split('invitetoken=')[1];

    if (parsedQuery && parsedQuery.next && parsedQuery.token && !parsedQuery.frompage) {
      loginController.updateJWTCookie(parsedQuery.token);
      loginController.loginWithJWT(false, parsedQuery.next);
    } else if (parsedQuery && parsedQuery.next) {
      try {
        loginController.updateJWTCookie(parsedQuery.token);
        const cookie = cookieService.getCookie('JWT');
        cookie && navigate(Routes.Sso + urlObject.search + (inviteToken ? '&invite_token=' + inviteToken : ''));
      } catch (e) {
        const urlSearch = pageController.getQueryParams(urlObject.search);
        setFromPage(urlSearch.frompage);
        setError('Something went wrong. Please try again later.');
      }
    }
  }, []);
  if (error) {
    return (
      <div>
        <Alert danger>{error}</Alert>
        <div>
          Click <Link to={fromPage}>here</Link> to return to your previous page on Utomik.
        </div>
      </div>
    );
  }

  return <div></div>;
});
export default ImportSession;
