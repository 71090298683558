import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';

import { IInvoiceWithBilling } from 'types/invoices';

import {
  filterIsoCountryAlpha2,
  formatCurrencyLongName,
  formatPriceToAmountInCents,
  translateFromLangDict,
} from 'utils/textFormat';

import { Routes } from 'app/global/routes';

import Button from 'app/components/button/button';

import styles from './invoice.module.scss';

interface IInvoiceProps {
  currentInvoice: IInvoiceWithBilling;
  firstName: string;
  lastName: string;
  isCurrentTrial: boolean;
}

const Invoice: React.FC<IInvoiceProps> = ({ currentInvoice, firstName, lastName, isCurrentTrial }) => {
  const navigate = useNavigate();
  const handleRedirectToSubscription = () => {
    navigate(Routes.Subscription);
  };
  return (
    <div className={styles['invoice']}>
      <div className={styles['invoice-information-total']}>
        <p className={styles['invoice-creation']}>{moment(currentInvoice.creationdate).format('LL')}</p>
        <div className={styles['invoice-description']}>
          <div className={clsx(styles['invoice-description-item'], styles['invoice-description-address'])}>
            <address>
              <strong>Utomik</strong>
              <br />
              Paradijslaan 5<br />
              Eindhoven, NB 5611 KM
              <br />
              The Netherlands
              <br />
              VAT number: 823949783B01
              <br />
            </address>
          </div>
          <div className={clsx(styles['invoice-description-item'], styles['invoice-description-invoice'])}>
            <div className={styles['invoice-description-invoice-flex']}>
              <p className={styles['invoice-description-invoice-title']}>Billed on</p>
              <p>{moment(currentInvoice.creationdate).format('LL')}</p>
            </div>
            <div className={styles['invoice-description-invoice-flex']}>
              <p className={styles['invoice-description-invoice-title']}>Due on</p>
              <p>{moment(currentInvoice.creationdate).format('LL')}</p>
            </div>
            <div className={styles['invoice-description-invoice-flex']}>
              <p className={styles['invoice-description-invoice-title']}>Invoice number</p>
              <p>{currentInvoice.number}</p>
            </div>
          </div>
        </div>
        <div className={clsx(styles['invoice-description'], styles['invoice-description-billed-to'])}>
          <div className={clsx(styles['invoice-description-item'], styles['invoice-description-billed'])}>
            <h5>Billed to:</h5>
            <address>
              {(firstName || lastName) && (
                <strong>
                  {firstName} {lastName} <br />
                </strong>
              )}
              {currentInvoice.billingaddress && (
                <>
                  {currentInvoice.billingaddress.address && (
                    <>
                      <span>{currentInvoice.billingaddress.address}</span>
                      <br />
                    </>
                  )}
                  <>
                    {currentInvoice.billingaddress.zip && <> {currentInvoice.billingaddress.zip} </>}
                    {currentInvoice.billingaddress.city && (
                      <>
                        <span>{currentInvoice.billingaddress.city}</span> <br />
                      </>
                    )}
                    {currentInvoice.billingaddress.state && <span>{currentInvoice.billingaddress.state}</span>}
                  </>
                  {filterIsoCountryAlpha2(currentInvoice.billingaddress.country)}
                </>
              )}
            </address>
          </div>
          <div className={clsx(styles['invoice-description-item'], styles['invoice-description-panel-paid'])}>
            <div className={styles['invoice-description-panel-paid-heading']}>
              <span>{currentInvoice.type === 'Refund' ? 'Refunded' : currentInvoice.status}</span> on{' '}
              {moment(currentInvoice.creationdate).format('LL')}
            </div>
            <div className={styles['invoice-description-panel-paid-body']}>
              <span className={styles['invoice-description-panel-paid-body-currency']}>
                {formatPriceToAmountInCents(
                  {
                    amount_in_cents: currentInvoice.total_in_cents,
                    currency: currentInvoice.currency,
                    includes_tax: false,
                  },
                  'amount_in_cents',
                )}
              </span>
            </div>
          </div>
        </div>
        <div className={clsx(styles['invoice-description'])}>
          <div className={clsx(styles['invoice-description-plan'], styles['invoice-description-panel-paid'])}>
            <div
              className={clsx(
                styles['invoice-description-plan-header'],
                styles['invoice-description-panel-paid-heading'],
              )}
            >
              <div>Description</div>
              <div>Price</div>
            </div>
            <div className={styles['invoice-description-plan-body']}>
              {currentInvoice.invoice_shop_items?.map((shopItem) => (
                <div key={shopItem.id} className={styles['invoice-description-plan-body-price']}>
                  <div>{translateFromLangDict(shopItem?.name)}</div>
                  {!!shopItem.amount_in_cents && (
                    <div>
                      {formatPriceToAmountInCents(
                        {
                          amount_in_cents: shopItem.amount_in_cents,
                          currency: currentInvoice.currency,
                          includes_tax: false,
                        },
                        'amount_in_cents',
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={clsx(styles['invoice-description'], styles['invoice-description-total'])}>
          <div className={styles['invoice-description-total-item']}>
            <strong>Subtotal</strong>
            <span>
              {formatPriceToAmountInCents(
                {
                  amount_in_cents: currentInvoice.subtotal_in_cents,
                  currency: currentInvoice.currency,
                  includes_tax: false,
                },
                'amount_in_cents',
              )}
            </span>
          </div>
          <div className={styles['invoice-description-total-item']}>
            <strong>Tax</strong>
            <span>
              {formatPriceToAmountInCents(
                {
                  amount_in_cents: currentInvoice.tax_in_cents,
                  currency: currentInvoice.currency,
                  includes_tax: false,
                },
                'amount_in_cents',
              )}
            </span>
          </div>
          <div className={styles['invoice-description-total-item']}>
            <strong>Total</strong>
            <span>
              {formatPriceToAmountInCents(
                {
                  amount_in_cents: currentInvoice.total_in_cents,
                  currency: currentInvoice.currency,
                  includes_tax: false,
                },
                'amount_in_cents',
              )}
            </span>
          </div>
          <div className={styles['invoice-description-total-item-all-amounts']}>
            <em>
              All amounts in {formatCurrencyLongName(currentInvoice.currency)} ({currentInvoice.currency})
            </em>
          </div>
        </div>
      </div>
      <div className={styles['invoice-information-summary']}>
        <div className={styles['invoice-information-summary-body']}>
          <span className={styles['invoice-information-summary-body-title']}>Summary</span>
          <div className={styles['invoice-information-summary-item']}>
            <strong>Total</strong>
            <span>
              {formatPriceToAmountInCents(
                {
                  amount_in_cents: currentInvoice.total_in_cents,
                  currency: currentInvoice.currency,
                  includes_tax: false,
                },
                'amount_in_cents',
              )}
            </span>
          </div>
          <div className={styles['invoice-information-summary-item']}>
            <strong>Type</strong>
            <span>{currentInvoice.type}</span>
          </div>
          <div className={styles['invoice-information-summary-item']}>
            <strong>Status</strong>
            <span>{currentInvoice.status}</span>
          </div>
        </div>
        {isCurrentTrial && (
          <div className="small">
            You are currently in a trial period. If you do not want to continue with a regular paid Utomik subscription,
            be sure to{' '}
            <Button className="small" onClick={handleRedirectToSubscription} link>
              cancel your trial
            </Button>{' '}
            before your trial ends on {moment(currentInvoice.term_end).format('LL')}.
          </div>
        )}
      </div>
    </div>
  );
};
export default Invoice;
