export const devices = [
  {
    id: 'device-windows',
    img: '/images/logo/windows-logo.png',
  },
  {
    id: 'device-samsung',
    img: '/images/logo/samsung-logo.png',
  },
  {
    id: 'device-lg',
    img: '/images/logo/lg-logo.png',
  },
  {
    id: 'device-android',
    img: '/images/logo/android-logo.png',
  },
];
