/* URLs */
export const platformURL = process.env.REACT_APP_API_URL || '';
export const cloudApi = 'https://cloudapi.utomik.com/api/v1.0';
export const gamesDomain = process.env.REACT_APP_GAMES_DOMAIN || '';
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';
export const REACT_APP_SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || '';
export const REACT_APP_FRONT_END = process.env.REACT_APP_FRONT_END || '';
export const REACT_APP_RECURLY_KEY = process.env.REACT_APP_RECURLY_KEY || '';
export const REACT_APP_RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY || '';
export const REACT_APP_GTM_KEY = process.env.REACT_APP_GTM_KEY || '';
export const websiteProtocol = 'https';

export const websiteDomain = 'www.utomik.com';

export const websiteUrl = `${websiteProtocol}://${websiteDomain}`;
export const supportUrl = `${websiteProtocol}://${websiteDomain.replace('www.', 'support.')}`;
export const supportedCloudDevicesURL = 'https://support.utomik.com/hc/en-us/articles/5205109982108';
export const PRIVACY_POLICY_URL = 'https://static.utomik.com/privacypolicy.pdf';

export const GOOGLE_PLAY_DOWNLOAD_LINK = 'https://play.google.com/store/apps/details?id=com.utomik.client';
export const DOWNLOAD_UTOMIK_INSTALLER_LINK = 'https://api.utomik.com/v1/components/client/installer';

export const myUrl = `${websiteProtocol}://${websiteDomain.replace('www.', 'my.')}`;

// Window settings
export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_STARTPATH = '/';
export const DEFAULT_STARTUP_MAXIMIZED = false; // Always start up unmaximized, unless the user has a certain resolution or lower, defined in the main process.
export const DAYS_UNTIL_DELETION = 14;
/* ENV */
export const isDevelopment = true;
export const isTest = false;

/*
  Default retries for api requests. It can be either a number or an array of numbers.
  When it's a number, the request will retry n times immediately.
  When it's an array, the request will retry until the end of the array is reached. Each entry in the array is the axios-timeout value in seconds.
*/
const DEFAULT_RETRIES: number | number[] = [5, 10];

export const LOCALSTORAGE_ALC = 'alc';

export const urlsWithoutPreconditions = [
  '/login',
  '/login/tv',
  '/sso',
  '/register',
  '/register/tv',
  '/you-have-been-logged-out',
  '/verify/',
];
export { DEFAULT_RETRIES };
export const PENDING_VERIFICATION_STATUSES = ['p', 'r'];
export const pairingStatusCookieName = 'UTOMIK_PAIRING_CODE';
export const HOME_TOP_APPLICATION_COUNT = 8;
export const HOME_TOP_APPLICATION_EXCERPT_LENGTH = 100;
export const shoppingcartCookieName = 'UTOMIK_SHOPPING_CART_CONTENTS';
export const countryAvailableCookieName = 'UTOMIK_COUNTRY_SUPPORTED';
export const userCountryIdCookieName = 'UTOMIK_USER_COUNTRY_ID';
export const CLOUD_COUNTRIES_SERVICE_SETTING = 'cloud_supported_country_codes';
export const CLOUD_SUPPORT_ENABLED_SERVICE_SETTING = 'cloud_support_enabled';
export const USER_CLOUD_GROUP_NAME = 'Cloud User';
export const FREE_PERIOD_SERVICE_SETTING = 'play_for_free_period';
export const GLOBAL_COUPON_TYPE = 'GC';
export const PERSONAL_COUPON_TYPE = 'PC';
export const AFFILIATE_PROMO_TYPE = 'AF';
export const MIN_FIELD_VALUE_LENGTH = 2;
export const MIN_AGE_REQUIREMENT = 18;
export const MAX_IMAGE_WIDTH = 300;
export const MAX_IMAGE_HEIGHT = 300;
export enum SsoActionStatuses {
  SSO_ACTION_IN_PROGRESS = 'SSO_ACTION_IN_PROGRESS',
  SSO_ACTION_COMPLETE = 'SSO_ACTION_COMPLETE',
  SSO_ACTION_STOPPED = 'SSO_ACTION_STOPPED',
  SSO_ACTION_REAUTH_SUCCESS = 'SSO_ACTION_REAUTH_SUCCESS',
}
export const ROOT_DOMAIN = 'https://www.utomik.com';
export const FAQ_URL = 'https://support.utomik.com/hc/en-us';
export const CUSTOMER_SUPPORT_URL = FAQ_URL + '/requests/new';
export const DEFAULT_SUCCESS_PAGE_URL = '/success/';
export const TERMS_OF_SERVICE_URL = 'https://static.utomik.com/termsofservice.pdf';
export const PROMO_CODE_COOKIE = 'UTOMIK_REF_CODE';
export const MAXIMUM_MINOR_AGE = 21;
export const MINIMUM_USER_AGE = 1;
export const FREE_CAMPAIGN_SUCCESS_PAGE_URL = '/success/?source=freecampaign';
