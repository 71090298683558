import { action, computed, makeObservable, observable } from 'mobx';
import CookieService from 'services/cookieService/cookieService';
import SsoService from 'services/ssoService/ssoService';

import { ActionSSo, FilterSso, ISsoClicked, SsoProviders, SsoProvidersResponse } from 'types/sso';

import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import { calculateCookieExpiration } from 'utils/utils';

import { SsoActionStatuses } from '../constants';
import PageController from '../pageController/pageController';

export default class SsoController {
  public constructor(
    private readonly _httpTransport: AxiosTransport,
    private readonly _pageController: PageController,
    private readonly _cookieService: CookieService,
    private readonly _ssoService: SsoService,
  ) {
    makeObservable(this);
  }

  @computed
  public get ssoActionStatus() {
    return this._ssoService.ssoActionStatus;
  }

  public setSsoActionStatus(status: SsoActionStatuses | null) {
    this._ssoService.setSsoActionStatus(status);
  }

  @observable
  private _ssoDeprecation: SsoProvidersResponse[] = [];

  @action
  public setSsoDeprecation(ssoDeprecation: SsoProvidersResponse[]) {
    this._ssoDeprecation = ssoDeprecation;
  }

  @computed
  public get ssoDeprecation() {
    return this._ssoDeprecation;
  }

  public async fetchSsoProviders({ fromFilter }: { fromFilter?: FilterSso }): Promise<SsoProviders[] | []> {
    const options = {
      headers: {},
    };
    const response = await this._httpTransport.get<SsoProvidersResponse[]>('v1/ssoproviders', options);
    if (!response?.data) return [];

    const filteredProviders = this._ssoService.determineDisplayableProviders(response.data);
    if (fromFilter) {
      const filteredSsoProviders: SsoProvidersResponse[] = this._ssoService.filterSsoButtonsByParams(
        filteredProviders.ssoProvidersDisplayed,
        fromFilter,
      );
      return this._ssoService.transformSsoProviders(filteredSsoProviders);
    }
    this.setSsoDeprecation(filteredProviders?.ssoDeprecation || []);
    return this._ssoService.transformSsoProviders(filteredProviders?.ssoProvidersDisplayed);
  }

  public async authWithSso(): Promise<void> {
    this._cookieService.setCookie('cookiescriptaccept', 'visit', {
      path: '/',
      expires: calculateCookieExpiration(),
    });
    let action: ActionSSo = '' as ActionSSo;
    const queryParams = this._pageController.getQueryParams();

    if (queryParams.action === 'reauth') {
      action = 'Continue';
    } else if (queryParams.social_auth_user_type == 'NEW_USER' || queryParams.user_was_logged_in) {
      action = 'Register';
    } else if (queryParams.social_auth_user_type == 'EXISTING_USER') {
      action = 'Log in';
    }

    switch (action) {
      case 'Log in':
        await this._ssoService.processLoginWithSSO(queryParams);

        break;

      case 'Register':
        await this._ssoService.processRegistrationWithSSO(queryParams);

        break;

      case 'Continue':
        try {
          await this._ssoService.processReauthWithSSO(queryParams);
        } catch (err) {
          this._ssoService.setSsoActionStatus(SsoActionStatuses.SSO_ACTION_STOPPED);
          throw new Error('FAILED_WINDOW_COMMUNICATION');
        }
        break;

      default:
        break;
    }
  }

  public ssoClicked({ action, slug, id, ssoUrl, inviteToken }: ISsoClicked) {
    this._ssoService.setSsoActionStatus(SsoActionStatuses.SSO_ACTION_IN_PROGRESS);
    switch (action) {
      case 'Log in':
        this._cookieService.setCookie('previouslyUsedSso', `${id}`, {});
        this._ssoService.loginWithSSO({ ssoUrl, slug });
        break;

      case 'Register':
        this._ssoService.registerWithSSO(slug, inviteToken);
        break;

      case 'Continue':
        this._ssoService.reauthWithSSO(slug);

        break;

      default:
        break;
    }
  }
}
