import { NavigationLink } from 'types/global';

import FooterLink from './footerLink';

import './footerLinks.scss';

export type FooterLinksProps = {
  title: string;
  links: NavigationLink[];
};

const FooterLinks: React.FC<FooterLinksProps> = ({ title, links }) => (
  <div className="footer-links">
    <h3 className="footer-links__title">{title}</h3>

    <ul className="footer-links__list">
      {links.map((props) => (
        <FooterLink key={`${props.to}-${props.label}`} {...props} />
      ))}
    </ul>
  </div>
);

export default FooterLinks;
