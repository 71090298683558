import UserService from 'services/userService/userService';

import { IAccountMineResponse } from 'types/account';
import {
  FamilyMembersCombined,
  IAccountOwner,
  IFamilyMembers,
  IInviteMember,
  IPendingInvites,
} from 'types/familyMembers';

import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

export default class FamilyMembersService {
  public constructor(private readonly _httpTransport: AxiosTransport, private readonly _userService: UserService) {}

  public handleStatusAndContinue(error: any) {
    if (error.statusCode && (error.statusCode === 401 || error.statusCode === 404)) {
      return false;
    }
    return true;
  }

  public async getFamilyMembers(): Promise<IFamilyMembers[]> {
    try {
      const response = await this._httpTransport.get<IFamilyMembers[]>('/v1/accounts/mine/members');
      if (!response?.data) {
        return [];
      }
      return response.data;
    } catch (error: any) {
      if (this.handleStatusAndContinue(error)) {
        throw error;
      }
      return [];
    }
  }

  public async getPendingInvites(): Promise<IPendingInvites[]> {
    try {
      const response = await this._httpTransport.get<IPendingInvites[]>('/v1/accounts/mine/invites');
      if (!response?.data) {
        return [];
      }
      const filteredData = response.data.filter((invite) => invite.status === 'Pending');
      return filteredData;
    } catch (error: any) {
      if (this.handleStatusAndContinue(error)) {
        throw error;
      }
      return [];
    }
  }

  public async getAccountOwner(): Promise<IAccountOwner | void> {
    try {
      const user = this._userService.user;
      const account = await this._httpTransport.get<IAccountMineResponse>('/v1/accounts/mine');

      if (account?.data.owner.id === user.id) {
        return { ...account?.data, owner: user };
      }
    } catch (error: any) {
      if (this.handleStatusAndContinue(error)) {
        throw error;
      }
    }
  }

  public async sendInvitesToMembers(invite: IInviteMember): Promise<FamilyMembersCombined | void> {
    try {
      const response = await this._httpTransport.post<FamilyMembersCombined, IInviteMember>(
        '/v1/accounts/mine/invites',
        { ...invite },
      );
      if (response?.data) {
        return response.data;
      }
    } catch (error: any) {
      if (this.handleStatusAndContinue(error)) {
        throw error;
      }
    }
  }

  public async deleteMember(member: FamilyMembersCombined) {
    try {
      let url = '/v1/accounts/mine/';
      if (member && member.email) {
        url += 'members/' + member.id;
      } else {
        const memberURL = member.url.split('/');
        url += 'invites/' + memberURL[memberURL.length - 1];
      }

      await this._httpTransport.delete(url);
    } catch (error: any) {
      if (this.handleStatusAndContinue(error)) {
        throw error;
      }
    }
  }

  public async updateFamilyMemberBirthdate({
    memberId,
    newBirthdate,
  }: {
    memberId: number | string;
    newBirthdate: string;
  }) {
    try {
      await this._httpTransport.patch(`/v1/accounts/mine/members/${memberId}`, { birthdate: newBirthdate });
    } catch (error: any) {
      if (this.handleStatusAndContinue(error)) {
        throw error;
      }
    }
  }

  public async blockMember({ memberId }: { memberId: number | string }) {
    try {
      await this._httpTransport.post(`/v1/accounts/mine/members/${memberId}/do_suspend`, {});
    } catch (error: any) {
      if (this.handleStatusAndContinue(error)) {
        throw error;
      }
    }
  }

  public async unblockMember({ memberId }: { memberId: number | string }) {
    try {
      await this._httpTransport.post(`/v1/accounts/mine/members/${memberId}/do_unsuspend`, {});
    } catch (error: any) {
      if (this.handleStatusAndContinue(error)) {
        throw error;
      }
    }
  }

  public async uncoupleMember() {
    try {
      await this._httpTransport.post('/v1/users/me/do_uncouple', {});
    } catch (error: any) {
      if (this.handleStatusAndContinue(error)) {
        throw error;
      }
    }
  }

  public getJoinFamilyURL(invitetoken: string) {
    return '/join-family-plan/#?invitetoken=' + invitetoken;
  }
}
