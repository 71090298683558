import clsx from 'clsx';
import React from 'react';

import styles from './spinner.module.scss';

const Spinner: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <div
      data-testid="spinner-component"
      className={clsx(styles['spinner-wrapper'], children && styles['spinner-wrapper-flex'])}
    >
      <div className={clsx(styles['spinner-component'], 'loader')} />
      {children && children}
    </div>
  );
};
export default Spinner;
