import { validatePromoCodeSchema } from 'app/validationSchemes/validatePromoCodeSchema';
import { FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';

import { translateErrors } from 'utils/translateErrors';

import { PromoPageControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Input from 'app/components/input/input';

import styles from './promoCodeForm.module.scss';

type FormValues = {
  code: string;
};

const initialValues: FormValues = {
  code: '',
};

interface IPromoCodeFormProps {
  isButtonPrimary?: boolean;
  placeholder?: string;
  label?: string;
  buttonBlock?: boolean;
  buttonSize?: 'xl' | 'lg';
  isShowButton?: boolean;
}
const PromoCodeForm: React.FC<IPromoCodeFormProps> = observer(
  ({
    isButtonPrimary = true,
    placeholder = 'promo code',
    label = '',
    buttonBlock = true,
    buttonSize = 'xl',
    isShowButton = true,
  }) => {
    const promoPageController = useContext(PromoPageControllerContext);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState('');
    const { promoFromCookie } = promoPageController;

    useEffect(() => {
      promoPageController.setPromoCodeFromCookie();

      if (promoFromCookie) {
        formik.setFieldValue('code', promoFromCookie);
      }
      return () => promoPageController.setPromoFromCookie('');
    }, [promoFromCookie]);

    const onSubmit = async (values: FormValues) => {
      setIsFetching(true);
      setError('');
      try {
        await promoPageController.storePromoAndContinue(values.code);
      } catch (e: any) {
        setError(translateErrors(e));
      } finally {
        setIsFetching(false);
      }
    };

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validatePromoCodeSchema,
      onSubmit: onSubmit,
      validateOnChange: true,
      validateOnMount: false,
    });
    const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
      error && setError('');
      formik.setFieldValue('code', e.target.value);
    };

    return (
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} name="promoCodeForm" className="promo-form">
          <div className={styles['form-item']}>
            <Input
              name="code"
              className="form-control"
              placeholder={placeholder}
              type="text"
              isValidate={false}
              onChange={handleChangeCode}
              showCheck={false}
              label={label}
              isLoading={isFetching}
              disabled={isFetching}
            />
          </div>
          {error && (
            <Alert danger className="promo-alert-danger">
              <span dangerouslySetInnerHTML={{ __html: error }} />
            </Alert>
          )}
          {isShowButton && (
            <Button
              type="submit"
              className={`${styles['form-item']} promo-button`}
              disabled={!formik.values.code || isFetching}
              xl={buttonSize === 'xl'}
              lg={buttonSize === 'lg'}
              primary={isButtonPrimary}
              block={buttonBlock}
              loading={isFetching}
            >
              Redeem this code
            </Button>
          )}
        </form>
      </FormikProvider>
    );
  },
);

export default PromoCodeForm;
