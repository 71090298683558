import { BreadcrumbProps } from 'types/shared';

import Breadcrumb from './breadcrumb';

import styles from './breadcrumbs.module.scss';

type BreadcrumbsProps = {
  breadcrumbs: BreadcrumbProps[];
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => (
  <div className={styles.breadcrumbs}>
    {breadcrumbs.map((breadcrumb) => (
      <Breadcrumb key={breadcrumb.title} {...breadcrumb} />
    ))}
  </div>
);

export default Breadcrumbs;
