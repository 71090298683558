import { useRecurly } from '@recurly/react-recurly';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { IAnalyticVerify, IExtraData, IPaymentType, IUpdateRecurly3DVerify } from 'types/billing';
import { Messages } from 'types/messages';
import { PaymentData3Ds } from 'types/payment';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';

import styles from './Recurly3DVerify.module.scss';

interface IRecurly3Ds {
  threeDSecureToken: string;
  isCheckoutPage: boolean;
  recurlyData: IUpdateRecurly3DVerify | null;
  errorMessage: string;
  handleSubmitCard: (
    extraData: IExtraData,
    recurlyToken: string,
    paymentType: IPaymentType,
    three_ds_token?: string,
  ) => void;
  isError3DByProduct: boolean;
  handleBack: () => void;
  handleBuyProduct?: (data: PaymentData3Ds | null) => void;
  sendAnalyticVerify: (data: IAnalyticVerify) => Promise<void>;
  setErrorVerifyCheckout?: (error: string) => void;
}

const Recurly3DVerify: React.FC<IRecurly3Ds> = ({
  threeDSecureToken,
  isCheckoutPage,
  errorMessage,
  handleBack,
  handleSubmitCard,
  sendAnalyticVerify,
  setErrorVerifyCheckout,
  handleBuyProduct,
  recurlyData,
  isError3DByProduct,
}) => {
  const recurly = useRecurly();
  const risk = recurly.Risk();
  const threeDSecure = risk.ThreeDSecure({ actionTokenId: threeDSecureToken });

  const [error, setError] = useState('');

  const initPage = async () => {
    const DOMElement = document.querySelector('#three-ds-container-pay');
    await sendAnalyticVerify({ type: 'process' });
    if (DOMElement) {
      DOMElement.innerHTML = '';
      threeDSecure.attach(DOMElement as HTMLElement);
    }
  };

  useEffect(() => {
    initPage();
  }, []);

  threeDSecure.on('token', async (token) => {
    if (isError3DByProduct && handleBuyProduct && token.id) {
      handleBuyProduct({ three_ds_token: token.id });
      return;
    }
    if (!recurlyData) {
      return setError(Messages.VERIFY_3D_ERROR);
    }
    if (token.id) {
      await sendAnalyticVerify({ type: 'success' });
      await handleSubmitCard(
        recurlyData.extraData,
        recurlyData.token,
        recurlyData.paymentType || 'creditcard',
        token.id,
      );
    }
  });

  threeDSecure.on('error', async (error) => {
    await sendAnalyticVerify({ type: 'error' });
    setErrorVerifyCheckout
      ? setErrorVerifyCheckout(Messages.VERIFY_3D_ERROR)
      : setError(errorMessage || error.message || Messages.VERIFY_3D_ERROR);
  });

  const goBack = () => {
    handleBack();
  };

  return (
    <div className={styles['verify3D']}>
      {error && (
        <>
          <Alert className={clsx(styles['recurly-verify-error'])} danger>
            {error}
          </Alert>
          <Button className={isCheckoutPage && styles['verify-btn-error-checkout']} xl onClick={goBack}>
            Go back
          </Button>
        </>
      )}
      {!error && (
        <>
          <h3 className={styles['verify3D-title']}>Verification</h3>
          <p className={styles['verify3D-text']}>Please verify your payment details.</p>
          <div className={styles['verify3D-form']} id="three-ds-container-pay"></div>
        </>
      )}
    </div>
  );
};
export default Recurly3DVerify;
