import React from 'react';

import { NavigationLink } from 'types/global';

import styles from '../header.module.scss';

interface IMainNavigation {
  navigateItems: NavigationLink[];
  children?: React.ReactNode;
}

const NavigationItem: React.FC<NavigationLink> = ({ to, title, label }) => (
  <li className={styles['navigation__list-item']}>
    <a href={to} title={title} rel="noopener noreferrer">
      {label}
    </a>
  </li>
);

const MainNavigationSection: React.FC<IMainNavigation> = ({ navigateItems, children }) => {
  return (
    <nav className={styles.navigation}>
      <ul className={styles.navigation__list}>
        {navigateItems.map((link) => (
          <NavigationItem key={link.label} {...link} />
        ))}
      </ul>
      {children && children}
    </nav>
  );
};
export default MainNavigationSection;
