import CookieService from 'services/cookieService/cookieService';
import MiscService from 'services/miscService/miskService';
import UserService from 'services/userService/userService';

import { countryAvailableCookieName, userCountryIdCookieName } from '../constants';

export default class UtomikSupportedInCountryController {
  public constructor(
    private readonly _cookieService: CookieService,
    private readonly _miscService: MiscService,
    private readonly _userService: UserService,
  ) {}

  private setUtomikIsLiveAndUserCountryId(utomikIsLiveInUsersCountry: boolean, userCountryId: string) {
    this._userService.setUtomikIsLiveInUsersCountry(utomikIsLiveInUsersCountry);
    this._userService.setUserCountryId(userCountryId);
  }

  private setCookies(cookieName: string, value: any, userCountryId: string) {
    this._cookieService.setCookie(cookieName, `${value}`, {
      path: '/',
      maxAgeUnit: 'minutes',
      maxAge: 5,
    });
    this._cookieService.setCookie(userCountryIdCookieName, `${userCountryId}`, {
      path: '/',
      maxAgeUnit: 'minutes',
      maxAge: 5,
    });
  }

  public async checkIsUtomikSupportedInCountry() {
    const countryAvailableCookie = this._cookieService.getCookie(countryAvailableCookieName);
    const userCountryIdCookie = this._cookieService.getCookie(userCountryIdCookieName);

    const isCountryAvailableInCookie = countryAvailableCookie ?? false;
    const isUserCountryIdAvailableInCookie = userCountryIdCookie ?? false;

    if (isCountryAvailableInCookie && isUserCountryIdAvailableInCookie) {
      this.setUtomikIsLiveAndUserCountryId(!!countryAvailableCookie, `${userCountryIdCookie}`);
    } else {
      const country = await this._miscService.getUserCountry();
      if (country) {
        const { is_supported, id } = country;
        const utomikIsLiveInUsersCountry = is_supported ?? true;
        const userCountryId = id ?? 113;
        this.setUtomikIsLiveAndUserCountryId(utomikIsLiveInUsersCountry, `${userCountryId}`);
        this.setCookies(countryAvailableCookieName, utomikIsLiveInUsersCountry, `${userCountryId}`);
      }
    }
  }
}
