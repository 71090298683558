import { action, computed, makeObservable, observable } from 'mobx';

export default class InitPagesService {
  public constructor() {
    makeObservable(this);
  }
  @observable
  private _isLoading = true;

  @action
  public setIsLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  @computed
  public get isLoading() {
    return this._isLoading;
  }

  @observable
  private _fetchError = '';

  @action
  public setFetchError(error: string) {
    this._fetchError = error;
  }

  @computed
  public get fetchError() {
    return this._fetchError;
  }
}
