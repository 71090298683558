import { action, makeObservable } from 'mobx';
import MiscService from 'services/miscService/miskService';
import UserService from 'services/userService/userService';

export default class ProfileService {
  public constructor(private readonly _userService: UserService, private readonly _miscService: MiscService) {
    makeObservable(this);
  }

  @action async setUserIsInSupportedCloudCountry() {
    await this._miscService.setUserIsInSupportedCloudCountry();
  }

  @action
  public async profileInitPage() {
    await this._userService.fetchUser();
    await this._miscService.setUserIsInSupportedCloudCountry();
    await this._userService.setUserCanManageDeletion();
  }
}
