import { action, computed, makeObservable, observable } from 'mobx';
import BoxOfficeService from 'services/boxOficceService/boxOficceService';
import UserService from 'services/userService/userService';

import { IPreconditions, PreconditionPathNames } from 'types/shared';

import { normalizePath } from 'utils/textFormat';

export default class PreconditionService {
  public constructor(private readonly _boxOfficeService: BoxOfficeService, private readonly _userService: UserService) {
    makeObservable(this);
  }

  @observable
  private _preconditions: IPreconditions | null = null;

  @action
  public setPreconditions(precondition: IPreconditions | null) {
    this._preconditions = precondition;
  }

  @computed
  public get preconditions() {
    return this._preconditions;
  }

  public async fetchPreconditions(pathName: PreconditionPathNames): Promise<void> {
    await this._userService.fetchUser();
    await this._userService.fetchLoggedInAccount();
    await this._userService.fetchAccountSubscriptions();

    const preconditions = {
      '/': {
        canLeaveFamily: this._boxOfficeService.canLeaveFamily(),
      },

      '/profile': {
        canChangeBirthdate: this._userService.canChangeOwnBirthdate(),
      },

      '/family': {
        hasFamilySubscription: await this._boxOfficeService.hasFamilySubscription(),
      },
      always: {
        canSwitchFromUtomikPersonalToUtomikFamilyPlan: await this._boxOfficeService.canSwitchToFamilySubscription(),
        canManageFamilyMembers: await this._boxOfficeService.canManageFamilyMembers(),
        canManageSubscriptionAndBillingInfo: this._boxOfficeService.isTheOwner(),
      },
    };

    const pagePath: keyof typeof preconditions =
      pathName !== '/' ? (normalizePath(pathName) as PreconditionPathNames) : pathName;
    this.setPreconditions({ ...preconditions.always, ...(preconditions[pagePath] || {}) });
  }

  public clearPreconditions() {
    this.setPreconditions(null);
  }
}
