import withAuth from 'app/hoc/withAuth';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';

import { CUSTOMER_SUPPORT_URL } from 'app/global/constants';
import { Routes } from 'app/global/routes';

import { LoginControllerContext } from 'app/context/storesContext';

import Button from 'app/components/button/button';
import Copyrights from 'app/components/copyrights/copyrights';
import Header from 'app/components/header/header';

import styles from './underAge.module.scss';

interface IUnderAge {
  isShowLayout?: boolean;
}

const UnderAge: React.FC<IUnderAge> = observer(({ isShowLayout = true }) => {
  const loginController = useContext(LoginControllerContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const logout = async () => {
    setIsLoading(true);
    try {
      await loginController.logout(true);
    } catch (e) {
      console.error(e, 'UNDERAGE');
    } finally {
      navigate(Routes.LogIn);
      setIsLoading(false);
    }
  };
  return (
    <div className={clsx(styles['under-age-page'], isShowLayout && styles['under-age-page-full'])}>
      {isShowLayout && <Header onlyLogo />}
      <div className="container">
        <section className="page-section">
          <h1>
            <span className="fas fa-ban"></span> Access denied
          </h1>
          <p>You must be 18 years of age to start a Utomik subscription.</p>

          <p>
            Minors may only use the service under the supervision of an adult. Ask your parent or guardian to invite you
            to a Family Plan.
          </p>
          <p>
            If there are any questions, please contact{' '}
            <a
              href={CUSTOMER_SUPPORT_URL}
              title="Get in touch with our customer support!"
              target="_blank"
              rel="noopener noreferrer"
            >
              Customer support
            </a>
            .
          </p>
          <Button onClick={logout} loading={isLoading} disabled={isLoading}>
            Log out
          </Button>
        </section>
      </div>
      {isShowLayout && <Copyrights className="only-logo-layout__copyrights" />}
    </div>
  );
});
export default withAuth(UnderAge);
