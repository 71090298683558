import { Form, Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { translateErrors } from 'utils/translateErrors';

import { Routes } from 'app/global/routes';

import { RegisterControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Input from 'app/components/input/input';

import './verifyForm.scss';

type FormValues = {
  code: string;
};

interface IVerifyForm {
  isHideMessage: boolean;
  setIsFetchingVerifyCode: (isFetching: boolean) => void;
  setIsHideResendButton: (isHideResend: boolean) => void;
  isFetchingVerifyCode: boolean;
}

const VerifyForm: React.FC<IVerifyForm> = observer(
  ({ isHideMessage = false, setIsFetchingVerifyCode, isFetchingVerifyCode, setIsHideResendButton }) => {
    const registerController = useContext(RegisterControllerContext);

    const initialValues: FormValues = { code: '' };
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const isDisabled = (errors: any, values: any) => errors.code || !values.code;

    const handleSubmit = async (values: typeof initialValues) => {
      if (values.code) {
        setIsFetchingVerifyCode(true);
        try {
          const isValid = await registerController.verifyEmailWithCode(values.code);
          if (isValid) {
            const redirectUrl = await registerController.goToEnrollment();
            navigate(redirectUrl || Routes.RegisterSelectPlan);
          }
        } catch (error: any) {
          if (error.statusCode === 500) {
            setIsHideResendButton(true);
          }
          setErrorMessage(translateErrors(error));
        } finally {
          setIsFetchingVerifyCode(false);
        }
      }
    };

    return (
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ errors, values, handleChange, resetForm }: FormikProps<FormValues>) => {
          useEffect(() => {
            isHideMessage && resetForm();
            errorMessage && setErrorMessage('');
          }, [isHideMessage]);

          const handleChangeCode = (e: React.ChangeEvent) => {
            handleChange(e);
            errorMessage && setErrorMessage('');
          };

          return (
            <Form className="verify-form">
              <Input
                onChange={handleChangeCode}
                className="verify-form__input"
                name="code"
                placeholder="verification code"
                isLoading={isFetchingVerifyCode}
                disabled={isFetchingVerifyCode}
              />
              {errorMessage && <Alert danger>{errorMessage}</Alert>}
              <Button
                primary
                lg
                type="submit"
                className={`${errorMessage}`}
                disabled={isDisabled(errors, values) || isFetchingVerifyCode}
                loading={isFetchingVerifyCode}
              >
                Verify email
              </Button>
            </Form>
          );
        }}
      </Formik>
    );
  },
);

export default VerifyForm;
