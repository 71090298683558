import CartService from 'services/cartService/cartService';
import PaymentService from 'services/paymentService/paymentService';
import SubscriptionManagementService from 'services/subscriptionManagementService/subscriptionManagementService';
import SubscriptionService from 'services/subscriptionService/subscriptionService';
import UserService from 'services/userService/userService';

import { Errors } from 'types/errors';

import { parseUserBirthDate } from 'utils/user';

export default class BoxOfficeService {
  public constructor(
    private readonly _userService: UserService,
    private readonly _subscriptionService: SubscriptionService,
    private readonly _cartService: CartService,
    private readonly _subscriptionManagementService: SubscriptionManagementService,
    private readonly _paymentService: PaymentService,
  ) {}

  public async canSwitchSubscription(
    filters?: 'canceledResellerSubscriptions' | 'activeUtomikSubscriptions',
  ): Promise<boolean> {
    const subscriptionFilters = filters || 'canceledResellerSubscriptions';
    const switchSubscriptions = await this._userService.getUserHasSubscriptionByFilters(subscriptionFilters);
    const subscriptionsForPurchase = await this._subscriptionService.getHasAvailablePlansForPurchase();
    return !!switchSubscriptions.length && subscriptionsForPurchase;
  }

  public async canSwitchToUtomikSubscription(): Promise<boolean> {
    try {
      return await this.canSwitchSubscription();
    } catch (e) {
      return false;
    }
  }

  public async canStartUtomikSubscription(): Promise<boolean> {
    return await this._userService.getAccountCanStartSubscription();
  }

  public async fulfillCart(): Promise<string | void> {
    if (!this._cartService.hasProduct()) {
      throw new Error('NO_PRODUCT_IN_CART');
    }
    if (this._cartService.hasReturnProduct()) {
      await this._subscriptionManagementService.switchSubscription();
    } else {
      const productUrl = await this._cartService.getProductPurchaseURL();
      if (productUrl) {
        const urlRedirect = await this._paymentService.orderProduct(productUrl);
        return urlRedirect;
      }
    }
  }

  public canLeaveFamily() {
    const user = this._userService.user;
    const userBirthDate = parseUserBirthDate(user.birthdate || '');
    const newBirthDate = {
      year: userBirthDate.year || '',
      month: userBirthDate.month || '',
      day: userBirthDate.day || '',
    };
    return (
      user.account?.role === 'Member' &&
      (user.birthdate === null || this._userService.isOfAge({ birthdate: newBirthDate }))
    );
  }

  public async hasFamilySubscription(): Promise<boolean> {
    const filters = ['filterActiveSubscriptions', 'filterFamilySubscriptions'];
    try {
      return await this._userService.getUserHasSubscriptionMatchedWithAllFilterFunctionNames(filters);
    } catch (e) {
      return false;
    }
  }

  public async canSwitchToFamilySubscription(): Promise<boolean> {
    const filters = ['activeUtomikSubscriptions', 'filterPersonalSubscriptions'];
    try {
      const isHasMatchedSubscriptions = await this._userService.getUserHasSubscriptionMatchedWithAllFilterFunctionNames(
        filters,
      );
      const isHasAvailablePlan = await this._subscriptionService.getHasAvailablePlansForPurchase();
      return isHasMatchedSubscriptions && isHasAvailablePlan;
    } catch (e) {
      return false;
    }
  }

  public async canManageFamilyMembers(): Promise<boolean> {
    const isTheOwner = this.isTheOwner();
    const hasFamilySubscription = await this.hasFamilySubscription();
    return isTheOwner && hasFamilySubscription;
  }

  public isTheOwner() {
    return this._userService.isTheOwner();
  }

  public async hasSwitchedMaxNumberOfTimes(): Promise<boolean> {
    const subscriptionPlans = await this._subscriptionService.getSubscriptionPlans();
    const canNoLongerUpgrade = subscriptionPlans.some((sub) => {
      return (
        !this._subscriptionService.getCanPurchase(sub) &&
        sub.can_purchase.message === Errors.ACCOUNT_CANNOT_SWITCH_AGAIN_THIS_TERM
      );
    });
    return canNoLongerUpgrade;
  }
}
