export class UtomikError extends Error {
  /**
   * This is a generic error object, serving as the base for higher level exceptions.
   * @param message Exception message
   * @param statusCode Exception status code (optional).
   * @param errorDetails Exception status code (optional).
   *
   */
  public statusCode: number | undefined;
  public errorDetails: any;

  public constructor(message: any, statusCode?: number, errorDetails?: any) {
    super(message);
    this.statusCode = statusCode;
    this.errorDetails = errorDetails;
  }
}

export class NetworkException extends UtomikError {
  /**
   * Thrown when a network exception occurs such as no internet.
   * @param message Exception message
   * @param statusCode Exception status code (optional).
   */
  public constructor(message: string, statusCode?: number) {
    super(message, statusCode);
  }
}

export class TimeOutException extends UtomikError {
  public constructor() {
    // If you change this string, please also check the login error handling. There is a separate error string for timeout errors.
    super('REQUEST_TIMED_OUT', 408);
  }
}

export class NotAuthorizedException extends UtomikError {
  /**
   * Thrown when an action is done that is not authorized.
   * @param message Exception message.
   */
  public constructor(message: string) {
    super(message, 401);
  }
}

export class InvalidTokenException extends UtomikError {
  /**
   * Thrown when an action is done that used an invalid token.
   * @param message Exception message.
   */
  public constructor(message: string, errorDetails?: any) {
    super(message, 403);
    this.errorDetails = errorDetails;
  }
}

export class PreconditionFailedException extends UtomikError {
  /**
   * Thrown when an action is done that another pairing code is pending.
   * @param message Exception message.
   */
  public constructor(message: string) {
    super(message, 403);
  }
}

export class NotFoundException extends UtomikError {
  /**
   * Thrown when an action is done that is not found.
   * @param message Exception message.
   */
  public constructor(message: string) {
    super(message, 404);
  }
}

export class TooManyRequestsException extends UtomikError {
  /**
   * Thrown when an action is rate limited on the server.
   * @param message Exception message
   */
  public constructor(message: string, errorDetails?: any) {
    super(message, 429);
    this.errorDetails = errorDetails;
  }
}

export class ServerException extends UtomikError {
  /**
   * Thrown when the server results in a server exception.
   * @param message Exception message
   */
  public constructor(message: string) {
    super(message, 500);
  }
}

export class ServerError extends UtomikError {
  public constructor(message: string, errorDetails: any) {
    super(message, 422, errorDetails);
  }
}

export class BadRequestException extends UtomikError {
  /**
   * Thrown when a request's parameters are invalid.
   * @param message Exception message.
   */
  public constructor(message: string, errorDetails: any) {
    super(message, 400, errorDetails);
  }
}

export class NotYetImplementedException extends UtomikError {
  /**
   * Thrown when code is hit that has not yet been implemented.
   * @param message Exception message
   * @param statusCode Exception status code (optional).
   */
  public constructor(message: string, statusCode?: number) {
    super('Something went wrong', statusCode);
  }
}

export class BugException extends UtomikError {
  /**
   * Thrown when code is hit that should not be and is determined as a bug.
   * @param message Exception message
   */
  public constructor(message: string) {
    super(message);
  }
}
