import React from 'react';

import { IUserMenu } from 'types/global';

import UserMenu from '../userMenu/userMenu';

import './index.scss';

const VerifyUserHeader: React.FC<IUserMenu> = ({ isDropdownMenu, userEmail }) => {
  return (
    <nav className="menu-items">
      <UserMenu isDropdownMenu={isDropdownMenu} userEmail={userEmail} />
    </nav>
  );
};
export default VerifyUserHeader;
