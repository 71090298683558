import clsx from 'clsx';
import { Field, useField } from 'formik';

import ErrorMessage from '../errorMessage/errorMessage';

import styles from './radioGroup.module.scss';

export type RadioOption = {
  label: string;
  value: string;
};

export type RadioGroupProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'type'
> & {
  className?: string;
  name: string;
  options: RadioOption[];
  children?: React.ReactNode;
};

const RadioGroup: React.FC<RadioGroupProps> = ({ className, options, children, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const hasError = meta.touched && meta.error;
  const labelClassName = clsx(styles['radio-group__label'], className && `${className}__label`);
  const radioClassName = clsx(styles['radio-group__radio'], className && `${className}__radio`);
  const errorClassName = clsx(styles['radio-group__error'], className && `${className}__error`);

  const handleChange = (value: string) => helpers.setValue(value);

  const fieldProps = {
    ...props,
    ...field,
    className: radioClassName,
  };

  return (
    <div className={clsx(styles['radio-group'], className)}>
      {options.map(({ label, value }) => (
        <label key={value} className={labelClassName}>
          <Field {...fieldProps} type="radio" value={value} onChange={() => handleChange(value)} />
          <div className="radio-group-children">
            {label}
            {children}
          </div>
        </label>
      ))}

      {hasError && <ErrorMessage className={errorClassName} name={field.name} />}
    </div>
  );
};

export default RadioGroup;
