import clsx from 'clsx';
import moment from 'moment';
import React from 'react';

import { ISubscriptionsResponseWithOrders, SubscriptionPlansTrialPeriod } from 'types/subscriptions';
import { User } from 'types/users';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';

import DeleteAccountHomePage from '../deleteAccount/deleteAccountHomePage';
import PurchaseSubscriptionButton from '../purchaseSubscriptionButton/purchaseSubscriptionButton';
import PromoCodeForm from '../setPromoCodeModal/promocodeForm/promoCodeForm';

import './jumbotron.scss';

interface IJumbotron {
  trialPeriodInfo: SubscriptionPlansTrialPeriod | null;
  hasActiveSubscriptions: boolean;
  hasCanceledSubscriptions: boolean;
  hadPastSubscription: boolean;
  userHasDeletionDate: boolean;
  redirectToSelectPlan: () => void;
  accountSubscription: ISubscriptionsResponseWithOrders | null;
  user: User;
  isFetchingReactivate: boolean;
  errorReactivate: string;
  reactivateSubscription: () => void;
  handleDownloadUtomik: () => void;
  handleDownloadGooglePlay: () => void;
}

const Jumbotron: React.FC<IJumbotron> = ({
  userHasDeletionDate,
  hasActiveSubscriptions,
  hasCanceledSubscriptions,
  hadPastSubscription,
  accountSubscription,
  user,
  isFetchingReactivate,
  errorReactivate,
  redirectToSelectPlan,
  reactivateSubscription,
  handleDownloadUtomik,
  handleDownloadGooglePlay,
}) => {
  const defaultSection = () => {
    return (
      <section className="jumbotron  jumbotron-style-home-start" id="jumbotron-home-logged-in">
        <div className="container">
          <h1 data-name="welcome-user-please-renew">Your {`  `} #1 gaming subscription</h1>
          <h2>Play as many games as you want</h2>

          <div className="btn-and-code-container">
            <PurchaseSubscriptionButton
              handleClick={redirectToSelectPlan}
              canStartTrial={true}
              renewLabel={''}
              trialLabel={`Start your trial`}
            />

            <PromoCodeForm
              label="Or redeem your coupon code:"
              placeholder="Fill in your code here"
              buttonSize="lg"
              buttonBlock={false}
            ></PromoCodeForm>
          </div>
        </div>
      </section>
    );
  };

  const deletedAccountSection = () => {
    return (
      <section className="jumbotron jumbotron-style-home-logged-in ">
        <div className="container">
          <DeleteAccountHomePage />
        </div>
      </section>
    );
  };
  const activePlanSection = () => {
    return (
      <section className="jumbotron jumbotron-style-home-logged-in">
        <div className="container">
          <h2>Welcome to Utomik, {`${user.first_name || user.username || user.email}`}!</h2>
          <h1>Start playing now!</h1>
          <div className="btn-container">
            <Button
              className="btn-downloader"
              primary
              lg
              title="Download the Utomik installer"
              onClick={handleDownloadUtomik}
            >
              <span className="fas fa-download fa-lg"></span> Install Utomik
            </Button>

            <Button
              className="btn-image btn-google-play"
              title="Download the Utomik app in the Google Play store"
              onClick={handleDownloadGooglePlay}
            ></Button>
          </div>
        </div>
      </section>
    );
  };

  const canceledPlanSection = () => {
    return (
      <section className="jumbotron jumbotron-style-home-logged-in ">
        <div className="container">
          <h2>Welcome to Utomik, {`${user.first_name || user.username || user.email}`}!</h2>
          {user.account?.role === 'Member' ? (
            <>
              <h1>Your family plan was canceled</h1>

              <p>This subscription is set to expire soon.</p>
              <p>Please contact the account owner for more information.</p>
            </>
          ) : (
            <>
              <h1>You have canceled your subscription</h1>
              {accountSubscription?.expire_due && (
                <div>
                  <p>
                    Your subscription is set to expire
                    <span> on {moment(accountSubscription.expire_due).format('LL')}</span>.
                  </p>
                  <p>
                    If you change your mind, you can reactivate instantly{' '}
                    <span>before {moment(accountSubscription?.expire_due).format('LL')}</span>.
                  </p>
                  <div className={clsx('btn-container', errorReactivate && 'btn-container-with-error')}>
                    <Button
                      lg
                      primary
                      onClick={reactivateSubscription}
                      disabled={isFetchingReactivate}
                      loading={isFetchingReactivate}
                      className="btn-reactivate"
                    >
                      <span className="fas fa-sync-alt"></span>
                      Reactivate
                    </Button>
                    {errorReactivate && (
                      <Alert danger className="alert-reactivate-error">
                        {errorReactivate}
                      </Alert>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    );
  };

  const pastSubscriptionSection = () => {
    return (
      <section className="jumbotron  jumbotron-style-home-start jumbotron-style-home-start-past-subscription">
        <div className="container">
          <h2>Welcome back, {`${user.first_name || user.username || user.email}`}!</h2>
          <h1 data-name="welcome-user-please-renew">Get back into the game</h1>

          <div className="btn-and-code-container">
            <PurchaseSubscriptionButton
              handleClick={redirectToSelectPlan}
              canStartTrial={false}
              renewLabel="Renew subscription"
              trialLabel=""
              renewIcon
            />

            <PromoCodeForm
              label="Or redeem your coupon code:"
              placeholder="Fill in your code here"
              buttonSize="lg"
              buttonBlock={false}
            ></PromoCodeForm>
          </div>
        </div>
      </section>
    );
  };
  const renderContent = () => {
    if (userHasDeletionDate) {
      return deletedAccountSection();
    } else if (hasActiveSubscriptions) {
      return activePlanSection();
    } else if (hasCanceledSubscriptions) {
      return canceledPlanSection();
    } else if (hadPastSubscription) {
      return pastSubscriptionSection();
    }
    return defaultSection();
  };
  return <>{renderContent()}</>;
};
export default Jumbotron;
