import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withAuth from 'app/hoc/withAuth';
import clsx from 'clsx';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { ReactNode, useContext, useEffect, useState } from 'react';

import { translateErrors } from 'utils/translateErrors';

import { PENDING_VERIFICATION_STATUSES } from 'app/global/constants';

import { UserControllerContext, RegisterControllerContext } from 'app/context/storesContext';

import Modal from 'app/components/modal/modal';
import Spinner from 'app/components/spinner/spinner';

import AlreadyVerifySection from './alreadyVerifySection';
import UpdateEmailForm from './updateEmailForm';
import VerifyForm from './verifyForm';

import './verifyPage.scss';

type ListItemProps = React.PropsWithChildren<{
  title: string;
  onClick: () => void;
  isFetchingResend?: boolean;
}>;

const ListItem: React.FC<ListItemProps> = ({ isFetchingResend, title, onClick, children }) => (
  <li>
    <FontAwesomeIcon icon="chevron-right" />
    <div className="appear-as-link" onClick={onClick}>
      {title}

      {isFetchingResend && <Spinner />}
      {children}
    </div>
  </li>
);

type ListProps = {
  actions: ListItemProps[];
};

const List: React.FC<ListProps> = ({ actions }) => (
  <ul className="verify-page__links">
    {actions.map((props) => (
      <ListItem key={props.title} {...props} />
    ))}
  </ul>
);

interface ResendMessageProps {
  isShowIcon: boolean;
  children: ReactNode;
}

const ResendMessage: React.FC<ResendMessageProps> = ({ isShowIcon, children }) => (
  <span className="verify-page__resend-message" data-testid="resend-message">
    {isShowIcon && <FontAwesomeIcon icon="check" />}

    <span>{children}</span>
  </span>
);

const VerifyPage: React.FC = observer(function VerifyPage() {
  const registerController = useContext(RegisterControllerContext);
  const userController = useContext(UserControllerContext);
  const [message, setMessage] = useState('');
  const [isHideResendButton, setIsHideResendButton] = useState(false);
  const [isShowUpdateEmailModal, setIsShowUpdateEmailModal] = useState(false);
  const [isHideError, setIsHideError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingResend, setIsFetchingResend] = useState(false);
  const [isFetchingVerifyCode, setIsFetchingVerifyCode] = useState(false);
  const openUpdateEmailModal = () => setIsShowUpdateEmailModal(true);
  const closeUpdateEmailModal = () => setIsShowUpdateEmailModal(false);

  const fetchUser = async () => {
    try {
      await userController.fetchUser();
      await userController.fetchAccountSubscriptions();
      await userController.getAccountSubscriptions();
      await userController.fetchLoggedInAccount();
    } catch (e) {
      console.error(e, 'VERIFY PAGE');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const resendVerificationCode = async () => {
    if (isFetchingResend || message) return;
    setIsHideError(true);
    setIsFetchingResend(true);
    try {
      await registerController.resendVerificationEmail();
      setMessage('Sent!');
      setTimeout(() => setMessage(''), 3000);
      setIsHideError(false);
    } catch (error: any) {
      setTimeout(() => setMessage(''), 3000);
      setIsHideError(false);

      if (error.message?.indexOf('Request was throttled') >= 0) {
        return setMessage(translateErrors(error, true));
      }
      setMessage('Too many requests.');
    } finally {
      setIsFetchingResend(false);
    }
  };

  const actions: ListItemProps[] = [
    !isHideResendButton
      ? {
          title: 'I need a new verification code',
          onClick: resendVerificationCode,
          children: message ? <ResendMessage isShowIcon={message === 'Sent!'}>{message}</ResendMessage> : null,
          isFetchingResend: isFetchingResend,
        }
      : null,
    {
      title: 'My email address is incorrect',
      onClick: openUpdateEmailModal,
    },
  ].filter((action) => action !== null) as ListItemProps[];

  if (isLoading) {
    return <Spinner />;
  }

  if (
    !isFetchingVerifyCode &&
    !PENDING_VERIFICATION_STATUSES.some((status) => status === userController.verificationStatus)
  ) {
    return <AlreadyVerifySection />;
  }
  return (
    <>
      <div className="verify-page container">
        <section className={clsx('verify-page__wrapper', 'page-section')}>
          <h1>Verify your email address</h1>

          <p>We have sent a verification code to {userController.email}.</p>
          <p>To complete your account, please fill in the code below.</p>

          <VerifyForm
            isHideMessage={isHideError}
            setIsFetchingVerifyCode={setIsFetchingVerifyCode}
            isFetchingVerifyCode={isFetchingVerifyCode}
            setIsHideResendButton={setIsHideResendButton}
          />

          <List actions={actions} />
        </section>
      </div>

      <Modal title="Update email address" show={isShowUpdateEmailModal} onHide={closeUpdateEmailModal}>
        <UpdateEmailForm onCancel={closeUpdateEmailModal} />
      </Modal>
    </>
  );
});

export default withAuth(VerifyPage);
