import CookieConsent from 'app/widgets/cookieConsent/cookieConsent';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useContext, useState } from 'react';

import { NavigationLink } from 'types/global';

import { UserControllerContext } from 'app/context/storesContext';

import CopyrightLink from './copyrightLink';

import './copyrights.scss';

const copyrightLinks: NavigationLink[] = [
  { to: 'https://www.utomik.com/sitemap/', label: 'Sitemap', title: 'Sitemap' },
  { to: 'https://static.utomik.com/termsofservice.pdf', label: 'Terms of service', title: 'Read the terms of service' },
  { to: 'https://static.utomik.com/privacypolicy.pdf', label: 'Privacy policy', title: 'Read the privacy policy' },
];

/**
 * Here is will be onClick handler for cookie settings modal
 * TODO: add cookie settings modal and logic when cookies will be implemented
 */

interface ICookieSettingsButton {
  handleClick: () => void;
}

const CookieSettingsButton: React.FC<ICookieSettingsButton> = ({ handleClick }) => (
  <li>
    <button title="Change cookie settings" onClick={handleClick}>
      <span>Cookie settings</span>
    </button>
  </li>
);

type CopyrightsProps = {
  className?: string;
  isAutoIndentation?: boolean;
};

const Copyrights: React.FC<CopyrightsProps> = observer(({ className, isAutoIndentation = false }) => {
  const userController = useContext(UserControllerContext);
  const { isLoggedInUser } = userController;

  const getCurrentYear = () => new Date().getFullYear();
  const [isShowIsCookieConsent, setIsShowCookieConsent] = useState(false);
  const toggleShowCookieConsent = () => {
    setIsShowCookieConsent((prev) => !prev);
  };
  return (
    <footer className={clsx('copyrights', isAutoIndentation && 'copyrights-auto-indentation', className)}>
      {isShowIsCookieConsent && <CookieConsent closeCookieWindow={toggleShowCookieConsent} />}
      <nav className="copyrights__container">
        <ul className="copyrights__links">
          {copyrightLinks.map(({ label, ...props }) => (
            <CopyrightLink key={props.to} {...props}>
              {label}
            </CopyrightLink>
          ))}

          {!isLoggedInUser && <CookieSettingsButton handleClick={toggleShowCookieConsent} />}
          <span className="copyrights__copyright copyrights__copyright-show">
            &copy; {getCurrentYear()} Utomik B.V.
          </span>
        </ul>

        <span className="copyrights__copyright copyrights__copyright-hide">&copy; {getCurrentYear()} Utomik B.V.</span>
      </nav>
    </footer>
  );
});

export default Copyrights;
