import { action, computed, makeObservable } from 'mobx';
import LoginService from 'services/loginService/loginServise';
import PageService from 'services/pageService/pageService';
import PreconditionService from 'services/preconditionService/preconditionService';
import ProfileService from 'services/profileService/profileService';
import UserService from 'services/userService/userService';

import { IAccountMineResponse } from 'types/account';
import { IIsUserNameAvailable, IPatchUserPassword, IPathUserRequest } from 'types/profile';
import { IDate, IisOfAge, User } from 'types/users';

import AuthorizationStore from 'dataStore/stores/authorizationStore/authorizationStore';
import LocalizationStore from 'dataStore/stores/localizationStore/localizationStore';

export default class UserController {
  public constructor(
    private readonly _authorizationStore: AuthorizationStore,
    private readonly _localizationStore: LocalizationStore,
    private readonly _profileService: ProfileService,
    private readonly _userService: UserService,
    private readonly _loginService: LoginService,
    private readonly _preconditionService: PreconditionService,
    private readonly _pageService: PageService,
  ) {
    makeObservable(this);
  }

  @computed
  public get user(): User {
    return this._userService.user;
  }

  @computed
  public get verificationStatus(): string {
    return this._userService.verificationStatus;
  }

  @computed
  public get email() {
    return this._userService.user?.email || '';
  }

  @computed get isInSupportedCloudCountry(): boolean {
    return this._userService.isInSupportedCloudCountry;
  }

  @computed get isCloudRegistrationAllowed(): boolean {
    return this._userService.isCloudRegistrationAllowed;
  }

  @computed get userCountry() {
    return this._userService.userCountry;
  }

  @computed get isApiHasCloudSupport(): boolean {
    return this._userService.isApiHasCloudSupport;
  }

  @computed
  public get userCountryId(): number | string | null {
    return this._userService.userCountryId;
  }

  @computed
  public get utomikIsLiveInUsersCountry() {
    return this._userService.utomikIsLiveInUsersCountry;
  }

  @computed
  public get isLoggedInUser() {
    return this._authorizationStore.isLoggedIn;
  }

  @computed
  public get allCountries() {
    return this._localizationStore.allCountries;
  }

  @computed
  public get countryNotSupported() {
    return this._localizationStore.countriesNotSupported;
  }

  @computed
  public get isUserCanManageDeletion() {
    return this._userService.isUserCanManageDeletion;
  }

  @computed
  public get userHasUsablePassword(): boolean {
    return this._userService.userHasUsablePassword;
  }

  @computed get userHasEnoughCredentials(): boolean {
    return !!this._userService.userHasEnoughCredentials;
  }

  @action getAccount() {
    return this._userService.account;
  }

  @action
  public async fetchUser() {
    await this._userService.fetchUser();
  }

  @action
  public getLoggedInAccount(): IAccountMineResponse | null {
    return this._userService.account;
  }

  @action
  public async fetchLoggedInAccount() {
    await this._userService.fetchLoggedInAccount();
  }

  @action async getAccountSubscriptions() {
    await this._userService.getAccountCanStartSubscription();
  }

  @action async fetchOrders() {
    await this._userService.fetchOrders();
  }

  @action async fetchAccountSubscriptions() {
    await this._userService.fetchAccountSubscriptions();
  }

  @action async setUserIsInSupportedCloudCountry(): Promise<void> {
    await this._profileService.setUserIsInSupportedCloudCountry();
  }

  @action
  public async profileInitPage() {
    await this._profileService.profileInitPage();
  }

  @action
  public async patchUser(patchData: IPathUserRequest): Promise<void> {
    await this._userService.patchUser(patchData);
  }

  @action
  public async patchUserCountry({ country }: { country: number }): Promise<void> {
    await this._userService.patchUserCountry({ country: country });
  }

  @action
  public async patchUserPassword(patchData: IPatchUserPassword): Promise<void> {
    await this._userService.patchUserPassword(patchData);
  }

  @action
  public isValidDate(date: IDate): boolean {
    return this._userService.isValidDate(date);
  }

  @action
  public isOfAge(userBirthDate: IisOfAge): boolean {
    return this._userService.isOfAge(userBirthDate);
  }

  public calculateCanChangeOwnBirthdate() {
    return this._userService.calculateCanChangeOwnBirthdate();
  }

  @action
  public async checkIsUserNameAvailable({
    email,
    username,
  }: {
    email: string;
    username: string;
  }): Promise<IIsUserNameAvailable | void> {
    return await this._userService.checkIsUserNameAvailable({ email, username });
  }

  public checkIsLoggedIn = async () => {
    return await this._loginService.checkIsLoggedIn();
  };

  public get preconditions() {
    return this._preconditionService.preconditions;
  }

  public checkIsFromRedeemPage = () => {
    return this._pageService.checkIsFromRedeemPage();
  };

  public async checkIsEmailAvailable(email: string) {
    return await this._userService.checkIsEmailAvailable(email);
  }
}
