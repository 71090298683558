import React from 'react';

export const IconUnChecked = () => {
  return (
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none">
      <path
        d="M8.92187 1.82384C9.24739 1.50646 9.24739 0.991028 8.92187 0.673645C8.59635 0.356262 8.0677 0.356262 7.74218 0.673645L4.99999 3.34982L2.2552 0.676184C1.92968 0.358801 1.40104 0.358801 1.07552 0.676184C0.749995 0.993567 0.749995 1.509 1.07552 1.82638L3.82031 4.50001L1.07812 7.17618C0.752599 7.49357 0.752599 8.009 1.07812 8.32638C1.40364 8.64376 1.93229 8.64376 2.25781 8.32638L4.99999 5.65021L7.74479 8.32384C8.07031 8.64122 8.59895 8.64122 8.92447 8.32384C9.25 8.00646 9.25 7.49103 8.92447 7.17365L6.17968 4.50001L8.92187 1.82384Z"
        fill="#332E59"
      />
    </svg>
  );
};
