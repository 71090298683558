import withErrorBoundary from 'app/hoc/withErrorBoundary';
import withNavigate from 'app/hoc/withNavigate';
import { Outlet, useLocation } from 'react-router-dom';

import Copyrights from 'app/components/copyrights/copyrights';
import Footer from 'app/components/footer/footer';
import Header from 'app/components/header/header';

import './app.scss';

const AppLayout = () => {
  const { pathname } = useLocation();

  const pathsWithoutFooter = ['/checkout', '/checkout/'];
  const isRenderFooter = !pathsWithoutFooter.includes(pathname);
  return (
    <div className="app-layout">
      <Header className="app-layout__header" />

      <main className="app-layout__main">
        <Outlet />
      </main>

      {isRenderFooter && <Footer className="app-layout__footer" />}
      <Copyrights isAutoIndentation={!isRenderFooter} />
    </div>
  );
};

export default withErrorBoundary(withNavigate(AppLayout));
