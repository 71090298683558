import * as yup from 'yup';

import { emailRegEx, passwordRegEx } from 'utils/regex';

export const validateRegistrationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Please enter a valid email address.')
    .matches(emailRegEx, 'Please enter a valid email address.'),
  password: yup
    .string()
    .required(
      'Passwords must contain at least 8 characters, have at least 1 letter (uppercase or lowercase a-z) and at least one number or special character',
    )
    .matches(
      passwordRegEx,
      'Passwords must contain at least 8 characters, have at least 1 letter (uppercase or lowercase a-z) and at least one number or special character',
    ),
});
