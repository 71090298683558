import PairingForm from 'app/widgets/pairingForm/pairingForm';
import SetPromoCodeModal from 'app/widgets/setPromoCodeModal/setPromoCodeModal';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { ActionForPost } from 'types/pairing';

import { Routes } from 'app/global/routes';

import { PairingControllerContext, UserServiceContext } from 'app/context/storesContext';

import Modal from 'app/components/modal/modal';

import './paring-page.scss';

interface IPairingPage {
  pairingCase: ActionForPost;
}

const PairingPage: React.FC<IPairingPage> = observer(({ pairingCase }) => {
  const navigate = useNavigate();
  const pairingController = useContext(PairingControllerContext);
  const userService = useContext(UserServiceContext);

  const fetchUser = async () => {
    try {
      await userService.fetchUser();
    } catch (e) {
      console.error(e, 'PAIRING PAGE fetchUser');
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (pairingCase === 'SIGN_UP') {
      pairingController.isCloudRegistrationAllowed();
    }
  }, [pairingCase]);

  const toggleModal = () => {
    return pairingController.setIsOpenPromoModal(!pairingController.isOpenPromoModal);
  };

  const skipAndContinue = () => {
    navigate(Routes.Register);
    toggleModal();
  };

  return (
    <div className="container pairing-page">
      <section className="page-section">
        <h1 className="pairing-title">Pair your TV</h1>
        <p className="pairing-subtitle">
          Please enter the pairing code from your TV to connect it to a Utomik account.
        </p>
        <PairingForm paringCase={pairingCase} />
      </section>
      <Modal show={pairingController.isOpenPromoModal} onHide={toggleModal} title="Use a promo code?">
        <SetPromoCodeModal skipAndContinue={skipAndContinue} />
      </Modal>
    </div>
  );
});
export default PairingPage;
