import withAuth from 'app/hoc/withAuth';
import DeleteAccount from 'app/widgets/deleteAccount/deleteAccount';
import NewsLetter from 'app/widgets/newsLetter/newsLetter';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';

import { NewsLetterControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Spinner from 'app/components/spinner/spinner';

import styles from './accountNewsLetter.module.scss';

const AccountNewsLetter = observer(() => {
  const newsletterController = useContext(NewsLetterControllerContext);
  const {
    isFetchingNewsLetter,
    initPage,
    step,
    isLoading,
    fetchError,
    patchUserError,
    user,
    toggleNewsletter,
    currentNewsLetter,
  } = newsletterController;
  const newsletterSignUp = currentNewsLetter();
  useEffect(() => {
    initPage();
  }, [user.deletion_date]);

  const isRenderStepNewsLetter =
    !isLoading && !fetchError && step !== 'user_pending_deletion' && step !== 'user_saved_successfully';
  const isRenderPendingDeletion = !isLoading && !fetchError && step === 'user_pending_deletion';
  const isRenderStepSavedSuccessfully = !isFetchingNewsLetter && step === 'user_saved_successfully';
  return (
    <div className="container">
      <section className="page-section">
        <h1 className={styles['newsLetter-title']}>
          <span className="fas fa-book-reader"></span> Your newsletter preferences
        </h1>
        {isLoading && <Spinner>Fetching account&hellip;</Spinner>}
        {fetchError && <Alert danger>{fetchError}</Alert>}
        {isRenderPendingDeletion && <DeleteAccount />}
        {isRenderStepNewsLetter && (
          <NewsLetter
            patchUserError={patchUserError}
            newsletterSignUp={!!newsletterSignUp}
            isFetchingNewsLetter={isFetchingNewsLetter}
            toggleNewsletter={toggleNewsletter}
          />
        )}
        {isRenderStepSavedSuccessfully && (
          <div className={styles['success-fetching']}>
            <i className={`${styles['success-fetching-icon']} fa fa-check `}></i>
            <div className={styles['success-fetching-description']}>
              <p>Newsletter preferences updated</p>
              <span>(you will be taken to the next screen in a few seconds)</span>
            </div>
          </div>
        )}
      </section>
    </div>
  );
});
export default withAuth(AccountNewsLetter);
