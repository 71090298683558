import { action, computed, makeObservable, observable } from 'mobx';
import FamilyMembersService from 'services/familyMembersService/familyMembersService';
import UserService from 'services/userService/userService';

import { Errors } from 'types/errors';
import {
  FamilyMembersCombined,
  IAccountSettingRequest,
  IFamilyMembers,
  IInviteMember,
  StateInviteMember,
} from 'types/familyMembers';
import { Messages } from 'types/messages';
import { IReauthModalContent, SendStates } from 'types/shared';
import { ISubscriptionsResponseWithOrders } from 'types/subscriptions';
import { IDate } from 'types/users';

import { activeUtomikSubscriptions } from 'utils/subscriptionFilters';
import { translateErrors } from 'utils/translateErrors';
import { parseUserBirthDate } from 'utils/user';
import { delay } from 'utils/utils';

export default class FamilyController {
  public constructor(
    private readonly _userService: UserService,
    private readonly _familyMembersService: FamilyMembersService,
  ) {
    makeObservable(this);
  }

  @observable
  private _subscription: ISubscriptionsResponseWithOrders | null = null;

  @action
  public setSubscription(subscription: ISubscriptionsResponseWithOrders | null) {
    this._subscription = subscription;
  }

  @computed
  public get subscription() {
    return this._subscription;
  }

  public get utomikIsLiveInUsersCountry() {
    return this._userService.utomikIsLiveInUsersCountry;
  }

  public get user() {
    return this._userService.user;
  }

  @observable
  private _familyMembers: FamilyMembersCombined[] = [];

  @action
  public setFamilyMembers(members: FamilyMembersCombined[]) {
    this._familyMembers = members;
  }

  @computed
  public get familyMembers() {
    return this._familyMembers;
  }

  @observable
  private _familyMemberInvitesUsed = 0;

  @action
  public setFamilyMemberInvitesUsed(familyMemberInvitesUsed: number) {
    this._familyMemberInvitesUsed = familyMemberInvitesUsed;
  }

  @computed get familyMemberInvitesUsed() {
    return this._familyMemberInvitesUsed;
  }

  @observable
  private _familyMemberInvitesRemaining = 0;

  @action
  public setFamilyMemberInvitesRemaining(familyMemberInvitesRemaining: number) {
    this._familyMemberInvitesRemaining = familyMemberInvitesRemaining;
  }

  @computed get familyMemberInvitesRemaining() {
    return this._familyMemberInvitesRemaining;
  }
  @observable
  private _maxMembersInFamilyPlan = 0;

  @action
  public setMaxMembersInFamilyPlan(maxMembersInFamilyPlan: number) {
    this._maxMembersInFamilyPlan = maxMembersInFamilyPlan;
  }

  @computed get maxMembersInFamilyPlan() {
    return this._maxMembersInFamilyPlan;
  }

  @observable
  private _forbiddenEmails: string[] = [];

  @action
  public setForbiddenEmails(emails: string[]) {
    this._forbiddenEmails = emails;
  }

  @computed
  public get forbiddenEmails() {
    return this._forbiddenEmails;
  }

  @observable
  private _isLoading = true;

  @action
  public setIsLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  @computed
  public get isLoading() {
    return this._isLoading;
  }

  @observable
  private _error = '';

  @action
  public setError(error: string) {
    this._error = error;
  }

  @computed
  public get error() {
    return this._error;
  }

  @observable
  private _familyWellError = '';

  @action
  public setFamilyWellError(familyWellError: string) {
    this._familyWellError = familyWellError;
  }

  @computed
  public get familyWellError() {
    return this._familyWellError;
  }

  @observable
  private _accountSettingsFormIsOpen = false;

  @action
  public setAccountSettingsFormIsOpen = (accountSettingsFormIsOpen: boolean) => {
    this._accountSettingsFormIsOpen = accountSettingsFormIsOpen;
  };

  @computed
  public get accountSettingsFormIsOpen() {
    return this._accountSettingsFormIsOpen;
  }

  @observable
  private _resendingInvitationState: SendStates | null = null;

  @action
  public setResendingInvitationState(resendingInvitationState: SendStates | null) {
    this._resendingInvitationState = resendingInvitationState;
  }

  @computed
  public get resendingInvitationState() {
    return this._resendingInvitationState;
  }

  @observable
  private _removingInvitationState: SendStates | null = null;

  @action
  public setRemovingInvitationState(removingInvitationState: SendStates | null) {
    this._removingInvitationState = removingInvitationState;
  }

  @computed
  public get removingInvitationState() {
    return this._removingInvitationState;
  }

  @observable
  private _activeMemberEmail: string[] = [];

  @action
  public setActiveMemberEmail(activeMemberEmail: string[]) {
    this._activeMemberEmail = activeMemberEmail;
  }

  @computed
  public get activeMemberEmail() {
    return this._activeMemberEmail;
  }

  @observable
  private _reauthModalIsOpen = false;

  @action
  public setIsOpenReauyhModal(isOpenReauth: boolean) {
    this._reauthModalIsOpen = isOpenReauth;
  }

  @computed
  public get reauthModalIsOpen() {
    return this._reauthModalIsOpen;
  }

  @observable
  private _reauthModaContent: IReauthModalContent | null = null;

  @action
  public setReauthModaContent(modalContent: IReauthModalContent | null) {
    this._reauthModaContent = modalContent;
  }

  @computed
  public get reauthModaContent() {
    return this._reauthModaContent;
  }

  @observable
  private _selectedMember: FamilyMembersCombined | null = null;

  @action
  public setSelectedMember(member: FamilyMembersCombined | null) {
    this._selectedMember = member;
  }

  @computed
  public get selectedMember() {
    return this._selectedMember;
  }

  @observable
  private _stateInvite: StateInviteMember = 'closed';

  @action
  public setStateInvite = (state: StateInviteMember) => {
    this._stateInvite = state;
  };

  @computed
  public get stateInvite() {
    return this._stateInvite;
  }

  @observable
  private _inviteMemberError = '';

  @action
  public setInviteMemberError(error: string) {
    this._inviteMemberError = error;
  }

  @computed
  public get inviteMemberError() {
    return this._inviteMemberError;
  }

  @observable
  private _currentAccountsEdit: Array<string | number> = [];

  @action
  public setCurrentAccountsEdit = (currentAccountsEdit: Array<string | number>) => {
    this._currentAccountsEdit = currentAccountsEdit;
  };

  @computed
  public get currentAccountsEdit() {
    return this._currentAccountsEdit;
  }

  @observable
  private _doRemoveAccountId: number | string | null = null;

  @action
  public setDoRemoveAccountId(removeAccountId: number | string | null) {
    this._doRemoveAccountId = removeAccountId;
  }

  @computed
  public get doRemoveAccountId() {
    return this._doRemoveAccountId;
  }

  public sortMembers(familyMembers: IFamilyMembers[]) {
    return familyMembers
      .sort(function (a, b) {
        const roleA = a.role.toUpperCase();
        const roleB = b.role.toUpperCase();
        if (roleA < roleB) {
          return -1;
        }
        if (roleA > roleB) {
          return 1;
        }
        return 0;
      })
      .reverse();
  }

  public initPage = async () => {
    try {
      const accountSubscriptions = await this._userService.getAccountSubscriptions();
      const familyMembers = await this._familyMembersService.getFamilyMembers();
      const pendingInvites = await this._familyMembersService.getPendingInvites();
      const accountOwner = await this._familyMembersService.getAccountOwner();

      if (this.user.account?.role === 'Member') {
        this.setIsLoading(false);
        throw Errors.MEMBER_FAMILY_MANAGE;
      }
      const activeSubscriptions = activeUtomikSubscriptions({
        subscriptions: accountSubscriptions,
        filterType: 'activeUtomikSubscriptions',
      });
      this.setSubscription(activeSubscriptions[0]);
      if (accountOwner?.cur_members && accountOwner?.cur_invites !== null) {
        this.setFamilyMemberInvitesUsed(accountOwner.cur_members + accountOwner.cur_invites);
      }
      if (accountOwner?.max_members) {
        this.setFamilyMemberInvitesRemaining(accountOwner.max_members - this.familyMemberInvitesUsed);
        this.setMaxMembersInFamilyPlan(accountOwner.max_members);
      }
      const sortedFamilyMembers = this.sortMembers(familyMembers);
      const combinedMembers: FamilyMembersCombined[] = [...new Set([...sortedFamilyMembers, ...pendingInvites])];
      this.setFamilyMembers(combinedMembers);
      const familyEmails = this.familyMembers.map((member) => member.email);
      const inviteEmails = pendingInvites.map((invite) => invite.sent_to);
      const forbiddenEmails = Array.from(new Set([...familyEmails, ...inviteEmails]));
      this.setForbiddenEmails(forbiddenEmails);
    } catch (e) {
      this.setError(translateErrors(e));
    } finally {
      this.setIsLoading(false);
    }
  };

  public isOfAge = (birthDate: string, minAge?: number) => {
    const memberBirthDate = parseUserBirthDate(birthDate || '');
    const newBirthDate = {
      year: memberBirthDate.year || '',
      month: memberBirthDate.month || '',
      day: memberBirthDate.day || '',
    };

    return this._userService.isOfAge({ birthdate: newBirthDate }, minAge);
  };

  public resendInvitation = async (familyMember: FamilyMembersCombined) => {
    this.setFamilyWellError('');
    this.setResendingInvitationState('sending');
    this.setActiveMemberEmail([...this.activeMemberEmail, familyMember.sent_to]);
    const oldId = familyMember.id;
    const invite = {
      email: familyMember.sent_to,
      ...(familyMember.birthdate && { birthdate: familyMember.birthdate }),
    };
    try {
      await this._familyMembersService.deleteMember(familyMember);
      await delay(1000);
      const newMember = await this._familyMembersService.sendInvitesToMembers(invite);
      const replacementMembers =
        newMember &&
        this.familyMembers.map((member) => {
          if (member.id === oldId) {
            member = newMember;
          }
          return member;
        });
      replacementMembers && this.setFamilyMembers(replacementMembers);
      this.setResendingInvitationState('sent');
    } catch (error: any) {
      this.setResendingInvitationState('not_sent');
      if (error !== undefined && error.message !== undefined && error.message.indexOf('Request was throttled') >= 0) {
        this.setFamilyWellError(translateErrors(error));
      } else {
        this.setFamilyWellError(Messages.INVITE_IS_USED);
      }
    }
  };

  public removeMember = async () => {
    try {
      this.setRemovingInvitationState('sending');
      const familyMember = this.selectedMember;
      familyMember && (await this._familyMembersService.deleteMember(familyMember));
      this.setRemovingInvitationState('sent');
      await delay(1000);
      await this.initPage();
      this.setDoRemoveAccountId(null);
      this.setRemovingInvitationState(null);
    } catch (e) {
      this.setRemovingInvitationState('not_sent');
      this.setFamilyWellError(translateErrors(e));
      throw translateErrors(e);
    } finally {
      this.setSelectedMember(null);
    }
  };

  public doRemoveAccount = async (familyMember: FamilyMembersCombined) => {
    this.setFamilyWellError('');
    this.setDoRemoveAccountId(familyMember.id);
    this.setSelectedMember(familyMember);
    if (familyMember.status === 'Pending') {
      await this.removeMember();
    } else {
      this.setReauthModaContent({
        title: 'Are you sure you want to remove this member from your family plan?',
        message: ['This action can not be undone. This member will no longer be able to play games on Utomik.'],
        buttonLabel: 'Yes, I am sure',
        buttonIsDanger: true,
        callback: async () => await this.removeMember(),
      });
      this.setIsOpenReauyhModal(true);
    }
  };
  public toggleModalReauth = () => {
    this.setIsOpenReauyhModal(!this.reauthModalIsOpen);
    this.setDoRemoveAccountId(null);
  };

  public isValidDate = (date: IDate) => {
    return this._userService.isValidDate(date);
  };

  public doInvite = async (inviteMember: IInviteMember) => {
    try {
      this.setStateInvite('saving');
      const inviteData = {
        email: inviteMember.email,
        ...(inviteMember.birthdate && { birthdate: inviteMember.birthdate }),
      };
      await this._familyMembersService.sendInvitesToMembers(inviteData);
      await delay(1000);
      this.setStateInvite('saved');
      this.initPage();
      setTimeout(() => {
        this.setStateInvite('closed');
      }, 4000);
    } catch (error: any) {
      this.setStateInvite('open');
      if (error !== undefined && error.message !== undefined && error.message.indexOf('Request was throttled') >= 0) {
        this.setInviteMemberError("You're inviting too many family members too quickly! Please try again later.");
      } else if (error !== undefined && error.message === Errors.EMAIL_TAKEN) {
        this.setInviteMemberError('Please enter a valid email address');
      } else {
        this.setInviteMemberError(translateErrors(error));
      }
    }
  };

  public doSaveRequestAccountSettings = async ({ suspend, birthdate, id }: IAccountSettingRequest): Promise<void> => {
    const currentMember = this.familyMembers.find((member) => member.id === id);
    if (birthdate && birthdate !== currentMember?.birthdate) {
      await this._familyMembersService.updateFamilyMemberBirthdate({ memberId: id, newBirthdate: birthdate });
    }
    if (currentMember?.suspended !== suspend) {
      if (suspend) {
        await this._familyMembersService.blockMember({ memberId: id });
      } else {
        await this._familyMembersService.unblockMember({ memberId: id });
      }
    }
    this.initPage();
    await delay(1000);
    this.setAccountSettingsFormIsOpen(false);
    this.setCurrentAccountsEdit([]);
  };

  public doSaveAccountSettings = async ({ suspend, birthdate, id }: IAccountSettingRequest) => {
    const currentMember = this.familyMembers.find((member) => member.id === id);
    if (suspend && currentMember?.suspended !== suspend) {
      this.setReauthModaContent({
        title: 'Are you sure you want to suspend this user?',
        message: [
          'This member will be unable to play games on Utomik until their account has been unsuspended. They will receive an email about this.',
        ],
        buttonLabel: 'Yes, I am sure',
        buttonIsDanger: false,
        callback: async () => await this.doSaveRequestAccountSettings({ suspend, birthdate, id }),
      });
      this.setIsOpenReauyhModal(true);
    } else {
      await this.doSaveRequestAccountSettings({
        suspend,
        birthdate,
        id,
      });
    }
  };

  public clearState = () => {
    this.setStateInvite('closed');
    this.setInviteMemberError('');
    this.setAccountSettingsFormIsOpen(false);
    this.setCurrentAccountsEdit([]);
    this.setFamilyMembers([]);
  };
}
