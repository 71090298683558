export const throttleRetryDuration = (throttleMessage: string): string => {
  let seconds: number;
  try {
    seconds = parseInt(throttleMessage.split(' ')[6], 10);
  } catch (err) {
    seconds = 60 * 60 * 24; // a day by default
  }

  if (seconds < 3300) {
    // less than 55 minutes
    // round minutes up to 5 minutes
    const minutes = Math.ceil(seconds / 300) * 5;
    return `${minutes} minutes`;
  } else if (seconds < 82800) {
    // less than 23 hours
    const hours = Math.round(seconds / 3600);
    if (hours === 1) {
      return 'an hour';
    }
    return `${hours} hours`;
  } else {
    const days = Math.round(seconds / 86400);
    if (days === 1) {
      return 'a day';
    }
    return `${days} days`;
  }
};
