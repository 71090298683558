import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import CookieService from 'services/cookieService/cookieService';
import LoginService from 'services/loginService/loginServise';

import { IAccountMineResponse } from 'types/account';
import { IBillingInfoResponse, IPaymentOptions, IUpdateIdealBank, IUpdateRecurlyToken } from 'types/billing';
import { IInvoices } from 'types/invoices';
import { ICountry } from 'types/localizations';
import { IOrdersResponse } from 'types/orders';
import { IIsUserNameAvailable, IPatchUserPassword, IPathUserRequest } from 'types/profile';
import { CheckIsEmailAvailablePostData, CheckIsEmailAvailableResponseData } from 'types/register';
import { ISubscriptionsResponse, ISubscriptionsResponseWithOrders, SubscriptionFilterType } from 'types/subscriptions';
import { IGetLoggedInUserImages } from 'types/upload';
import { IDate, IRequestDeletion, IisOfAge, User } from 'types/users';

import AuthorizationStore from 'dataStore/stores/authorizationStore/authorizationStore';
import UserStore from 'dataStore/stores/userStore/userStore';
import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';
import { IHttpResponse } from 'dataStore/transports/httpTransport/httpTransport';

import {
  activeUtomikSubscriptions,
  filterActiveSubscriptions,
  filterFamilySubscriptions,
  filterPersonalSubscriptions,
  toUsableAccountSubscription,
} from 'utils/subscriptionFilters';
import { parseUserBirthDate } from 'utils/user';

import { MIN_AGE_REQUIREMENT } from 'app/global/constants';

interface IResetPasswordResponse {
  email: string;
}

interface IChangePasswordResponse {
  newPassword1: string;
  newPassword2: string;
}

interface CheckIsUserAvailablePostData {
  email: string;
  username: string;
}

interface CheckIsUserNameAvailableResponseData {
  email_available: boolean;
  username_allowed: boolean;
  username_available: boolean;
  username_valid: boolean;
}

export default class UserService {
  public constructor(
    private readonly _httpTransport: AxiosTransport,
    private readonly _cookieService: CookieService,
    private readonly _authorizationStore: AuthorizationStore,
    private readonly _userStore: UserStore,
    private readonly _loginService: LoginService,
  ) {
    makeObservable(this);
  }

  @observable
  private _fetchUserPromise: Promise<void> | null = null;

  @action
  public setFetchUserPromise(fetchUserPromise: Promise<void> | null) {
    this._fetchUserPromise = fetchUserPromise;
  }

  @computed
  public get fetchUserPromise() {
    return this._fetchUserPromise;
  }
  @observable
  private _fetchLoggedInAccountPromise: Promise<void> | null = null;

  @action
  public setFetchLoggedInAccountPromise(fetchLoggedInAccountPromise: Promise<void> | null) {
    this._fetchLoggedInAccountPromise = fetchLoggedInAccountPromise;
  }

  @computed
  public get fetchLoggedInAccountPromise() {
    return this._fetchLoggedInAccountPromise;
  }

  @observable
  private _billingInfo: IBillingInfoResponse | null = null;

  @action
  public setBillingInfo(billingInfo: IBillingInfoResponse | null) {
    this._billingInfo = billingInfo;
  }

  @computed
  public get billingInfo(): IBillingInfoResponse | null {
    return this._billingInfo;
  }

  @action setUser(user: User): void {
    this._userStore.setUser(user);
  }

  @action resetUser() {
    this._userStore.resetUser();
  }

  @action setUserAvatar(avatar: string): void {
    this._userStore.setUserAvatar(avatar);
  }

  @computed
  public get user() {
    return this._userStore.user;
  }

  @computed
  public get verificationStatus(): string {
    return this._userStore.verificationStatus;
  }

  @action
  public async fetchUser(): Promise<void> {
    if (this.fetchUserPromise) return this.fetchUserPromise;
    const jwtToken = this._cookieService.getCookie('JWT') || this._authorizationStore.token;
    try {
      if (jwtToken) {
        const userPromise = this._httpTransport.get('/v2/users/me');

        this.setFetchUserPromise(userPromise as Promise<void>);

        const response = await userPromise;

        if (response?.data) {
          this.setUser(response.data as User);
        }
      }
    } finally {
      this.setFetchUserPromise(null);
    }
  }

  @action
  public async fetchResetPassword(resetData: IResetPasswordResponse): Promise<void> {
    await this._httpTransport.post<undefined, IResetPasswordResponse>('v1/accounts/reset_password', resetData);
  }

  @action
  public async fetchChangePassword(changeData: IChangePasswordResponse, querystring: string): Promise<void> {
    await this._httpTransport.post('v1/accounts/change_password?' + querystring, {
      new_password1: changeData.newPassword1,
      new_password2: changeData.newPassword2,
    });
  }

  private async fetchLoggedInUserImages(): Promise<IHttpResponse<IGetLoggedInUserImages[]> | undefined> {
    return this._httpTransport.get<IGetLoggedInUserImages[]>('/v1/users/me/images');
  }

  @action
  public async getLoggedInUserImages(): Promise<IGetLoggedInUserImages[]> {
    try {
      const response = await this.fetchLoggedInUserImages();
      return response?.data || [];
    } catch (error) {
      console.error('Error fetching user images:', error);
      return [];
    }
  }

  @action
  public async checkIsUserNameAvailable({
    email,
    username,
  }: {
    email: string;
    username: string;
  }): Promise<IIsUserNameAvailable | void> {
    const response = await this._httpTransport.post<CheckIsUserNameAvailableResponseData, CheckIsUserAvailablePostData>(
      '/v1/actions/do_check_user',
      { email, username },
    );
    if (response?.data) {
      const { email_available, username_allowed, username_available, username_valid } = response.data;
      return {
        emailAvailable: email_available,
        usernameAllowed: username_allowed,
        usernameAvailable: username_available,
        usernameValid: username_valid,
      };
    }
  }

  public getCanStartTrial() {
    try {
      const account = this.account;
      return account?.can_start_trial || false;
    } catch (e) {
      return false;
    }
  }

  @action
  public async patchUser(patchData: IPathUserRequest): Promise<void> {
    await this._httpTransport.patch<undefined, IPathUserRequest>('/v2/users/me', { ...patchData });
  }

  @action
  public async patchUserCountry({ country }: { country: number }): Promise<void> {
    await this._httpTransport.patch<undefined, { country: number }>('/v2/users/me', { country: country });
  }

  @action async patchUserPassword(patchData: IPatchUserPassword): Promise<void> {
    await this._httpTransport.patch<undefined, IPatchUserPassword>('/v2/users/me', { ...patchData });
  }

  @computed get getUserCountry() {
    return this._userStore.userCountry;
  }

  @computed get getUserCountryId(): string | number | null {
    return this._userStore.userCountryId;
  }

  @action setUserCountry(country: ICountry) {
    this._userStore.setUserCountry(country);
  }

  @action
  public setIsApiHasCloudSupport(isApiHasCloudSupport: boolean) {
    this._userStore.setIsApiHasCloudSupport(isApiHasCloudSupport);
  }

  @action
  public setIsInSupportedCloudCountry(isInSupportedCloudCountry: boolean) {
    this._userStore.setIsInSupportedCloudCountry(isInSupportedCloudCountry);
  }

  @action
  public setIsCloudRegistrationAllowed(isCloudRegistrationAllowed: boolean) {
    this._userStore.setIsCloudRegistrationAllowed(isCloudRegistrationAllowed);
  }

  @action
  public setUtomikIsLiveInUsersCountry(utomikIsLiveInUsersCountry: boolean) {
    this._userStore.setUtomikIsLiveInUsersCountry(utomikIsLiveInUsersCountry);
  }

  @action
  public setUserCountryId(userCountryId: number | string | null) {
    this._userStore.setUserCountryId(userCountryId);
  }

  public getNewsLetter() {
    return this.user.newsletter_signup;
  }

  @computed get isInSupportedCloudCountry(): boolean {
    return this._userStore.isInSupportedCloudCountry;
  }

  @computed get isCloudRegistrationAllowed(): boolean {
    return this._userStore.isCloudRegistrationAllowed;
  }

  @computed get userCountry() {
    return this._userStore.userCountry;
  }

  @computed get isApiHasCloudSupport(): boolean {
    return this._userStore.isApiHasCloudSupport;
  }

  @computed
  public get userCountryId(): number | string | null {
    return this._userStore.userCountryId;
  }

  @computed
  public get utomikIsLiveInUsersCountry() {
    return this._userStore.utomikIsLiveInUsersCountry;
  }

  @computed
  public get isUserCanManageDeletion() {
    return this._userStore.isUserCanManageDeletion;
  }

  @computed
  public get userHasUsablePassword(): boolean {
    return this.user.has_usable_password;
  }

  @computed get userHasEnoughCredentials(): boolean {
    return !!this.user.sso_providers.length;
  }

  @computed
  public get userSsoProviders(): string[] {
    return this.user.sso_providers.map((sso) => sso.provider);
  }

  @computed
  public get pairingStatus() {
    return this.user.pairing_status;
  }

  @observable
  private _accountSubscriptions: ISubscriptionsResponseWithOrders[] = [];

  @action
  public setAccountSubscription(accountSubscription: ISubscriptionsResponseWithOrders[]) {
    this._accountSubscriptions = accountSubscription;
  }

  @computed
  public get accountSubscriptions() {
    return this._accountSubscriptions;
  }

  @observable
  private _accountSubscriptionsDefault: ISubscriptionsResponse[] = [];

  @action
  public setAccountSubscriptionDefault(accountSubscriptionDefault: ISubscriptionsResponse[]) {
    this._accountSubscriptionsDefault = accountSubscriptionDefault;
  }

  @computed
  public get accountSubscriptionsDefault() {
    return this._accountSubscriptionsDefault;
  }

  @observable
  private _account: IAccountMineResponse | null = null;

  @action
  public setAccount(account: IAccountMineResponse | null) {
    this._account = account;
  }

  @computed
  public get account() {
    return this._account;
  }

  @observable
  private _orders: IOrdersResponse[] = [];

  @action
  public setOrders(orders: IOrdersResponse[]) {
    this._orders = orders;
  }

  @computed
  public get orders() {
    return this._orders;
  }

  @action
  public getUserAge(userBirthDate: IisOfAge) {
    if (userBirthDate && 'birthdate' in userBirthDate) {
      const birthDateMoment = moment(
        userBirthDate.birthdate.year + '-' + userBirthDate.birthdate.month + '-' + userBirthDate.birthdate.day,
        ['YYYY-MM-DD'],
        true,
      );
      return moment().diff(birthDateMoment, 'year');
    } else {
      return null;
    }
  }

  @action
  public hasReachedAge(userBirthDate: IisOfAge, minimumAge: number): boolean {
    const userAge = this.getUserAge(userBirthDate);
    if (userAge) {
      return userAge >= minimumAge;
    } else {
      return false;
    }
  }

  @action
  public isOfAge(userBirthDate: IisOfAge, minAge?: number): boolean {
    return this.hasReachedAge(userBirthDate, minAge ?? MIN_AGE_REQUIREMENT);
  }

  @action
  public isValidDate(date: IDate): boolean {
    try {
      const m = moment(date.year + '-' + date.month + '-' + date.day, ['YYYY-MM-DD'], true);
      return m.isValid();
    } catch (err) {
      return false;
    }
  }

  public async fetchAccountSubscriptions(): Promise<void> {
    const response = await this._httpTransport.get<ISubscriptionsResponse[]>('/v2/accounts/mine/subscriptions');
    if (!response?.data) {
      this.setAccountSubscriptionDefault([]);
      return;
    }
    this.setAccountSubscriptionDefault(response.data);
  }

  @action
  public async setUserCanManageDeletion(): Promise<void> {
    if (!this.user) {
      await this.fetchUser();
    }
    const subscriptions = this.accountSubscriptionsDefault;

    if (this.user.account?.role === 'Member') {
      this._userStore.setIsUserCanManageDeletion(true);
    } else if (!subscriptions.length) {
      this._userStore.setIsUserCanManageDeletion(true);
    } else {
      const usUserCanManage = subscriptions.filter((subscription) => subscription.status === 'ACTIVE');
      this._userStore.setIsUserCanManageDeletion(usUserCanManage.length === 0);
    }
  }

  @action
  public async fetchLoggedInAccount(): Promise<IAccountMineResponse | void> {
    if (this.fetchLoggedInAccountPromise) return this.fetchLoggedInAccountPromise;
    try {
      const fetchAccountPromise = this._httpTransport.get<IAccountMineResponse>('/v1/accounts/mine');
      this.setFetchLoggedInAccountPromise(fetchAccountPromise as Promise<void>);
      const result = await fetchAccountPromise;
      if (!result?.data) {
        this.setAccount(null);
        return;
      }
      this.setAccount(result.data);
    } catch (e) {
      console.error('getLoggedInAccount error');
      throw e;
    } finally {
      this.setFetchLoggedInAccountPromise(null);
    }
  }

  @action getLoggedInAccount(): IAccountMineResponse | null {
    return this.account;
  }

  @action
  public async fetchOrders(): Promise<void> {
    const result = await this._httpTransport.get<IOrdersResponse[]>('/v2/accounts/mine/orders');
    if (!result?.data) {
      this.setOrders([]);
      return;
    }
    const { data } = result;
    this.setOrders(data);
  }

  @action
  public async getOrders({ filter }: { filter?: string }): Promise<IOrdersResponse[]> {
    if (!filter) {
      return this.orders;
    }
    const result = await this._httpTransport.get<IOrdersResponse[]>(
      '/v2/accounts/mine/orders' + (filter !== undefined ? '?status=' + filter : ''),
    );
    if (!result?.data) return [];
    return result.data;
  }

  @action
  public async getOrder(id: number | string): Promise<IOrdersResponse | void> {
    const response = await this._httpTransport.get<IOrdersResponse>('/v2/accounts/mine/orders/' + `${id}`);
    if (response?.data) {
      return response.data;
    }
  }

  @action async getAccountCanStartSubscription(): Promise<boolean> {
    const subscriptions = await this.getAccountSubscriptions();
    const activeSubscriptions = filterActiveSubscriptions(subscriptions);
    return !activeSubscriptions.length;
  }

  @action async getUserHasPendingSubscriptionOrders(): Promise<boolean> {
    const orders = await this.getOrders({ filter: 'AP' });
    if (!orders.length) return false;
    const subscriptionPendingOrders = orders.filter((order) => {
      const hasPendingSubscription = order.order_shop_items.some((order_item) => {
        return order_item.shop_item && order_item.shop_item.url.includes('subscriptionplans');
      });
      return order.status === 'AP' && hasPendingSubscription;
    });
    return !!subscriptionPendingOrders.length;
  }

  @action private getFilteredSubscriptions(
    subscriptions: ISubscriptionsResponse[],
    orders: IOrdersResponse[],
    account: IAccountMineResponse,
  ): ISubscriptionsResponseWithOrders[] {
    const subscriptionsWithOrders: ISubscriptionsResponseWithOrders[] = subscriptions.map(
      (subscription): ISubscriptionsResponseWithOrders => {
        const matchingOrder =
          orders.find((order) => {
            return order.id === subscription.order.id;
          }) || subscription.order;
        let maxMembers = subscription.max_members;
        if (subscription.status === 'ACTIVE' || subscription.status === 'CANCELED') {
          maxMembers = account.max_members;
        }
        const modifiedSubscription: ISubscriptionsResponseWithOrders = {
          ...subscription,
          order: matchingOrder,
          max_members: maxMembers,
          _isCloudSubscription: false,
          _totalPricing: {
            currency: '',
            amount_in_cents: 0,
            includes_tax: false,
          },
        };

        return modifiedSubscription;
      },
    );

    const subscriptionWithFilter = subscriptionsWithOrders.map((subscription) => {
      return toUsableAccountSubscription(subscription);
    });

    return subscriptionWithFilter;
  }

  private async getUserHasSubscriptionMatchedWithOneOfManyFilterFunctionNames(
    subscriptionFilterNames: 'canceledResellerSubscriptions' | 'activeUtomikSubscriptions',
  ): Promise<ISubscriptionsResponseWithOrders[]> {
    const accountSubscriptions = this.accountSubscriptions.length
      ? this.accountSubscriptions
      : await this.getAccountSubscriptions();
    if (!accountSubscriptions.length) return [];
    const filteredAccountSubscriptions = activeUtomikSubscriptions({
      subscriptions: accountSubscriptions,
      filterType: subscriptionFilterNames,
    });
    return filteredAccountSubscriptions;
  }

  public async getUserHasSubscriptionByFilters(
    subscriptionFilters: 'canceledResellerSubscriptions' | 'activeUtomikSubscriptions',
  ) {
    const filteredSubscriptions = await this.getUserHasSubscriptionMatchedWithOneOfManyFilterFunctionNames(
      subscriptionFilters,
    );
    return filteredSubscriptions;
  }

  public isTheOwner() {
    return this._userStore.isTheOwner;
  }

  public async checkIsLoggedIn() {
    return await this._loginService.checkIsLoggedIn();
  }

  @action
  public async getAccountSubscriptions(): Promise<ISubscriptionsResponseWithOrders[]> {
    const subscriptions = this.accountSubscriptionsDefault;
    const isTheOwner = this.isTheOwner();
    const account = this.account;

    if (!subscriptions.length || !account) return [];
    if (isTheOwner && subscriptions.length && !this.orders.length) {
      await this.fetchOrders();
    }
    const orders = this.orders;
    const subscriptionWithFilter = this.getFilteredSubscriptions(subscriptions, orders, account);
    this.setAccountSubscription(subscriptionWithFilter);
    return subscriptionWithFilter;
  }

  @action async getBillingInfo(): Promise<IBillingInfoResponse | null> {
    if (await !this.checkIsLoggedIn()) {
      throw new Error('EXPIRED_JWT_TOKEN');
    }
    try {
      const response = await this._httpTransport.get<IBillingInfoResponse>('/v1/accounts/mine/billinginfo');
      if (response?.data && Object.keys(response.data).length) {
        this.setBillingInfo(response.data);
        return response.data;
      }
      this.setBillingInfo(null);
      return null;
    } catch (e) {
      throw new Error('COULDNT_FETCH_BILLINGINFO');
    }
  }

  @action
  public async getPaymentOptions(): Promise<IPaymentOptions[] | null> {
    const response = await this._httpTransport.get<IPaymentOptions[]>('/v1/accounts/mine/paymentoptions');
    if (response?.data) {
      return response.data;
    }
    return null;
  }

  public async updateBillingInformation(data: IUpdateRecurlyToken | IUpdateIdealBank) {
    let sendData = {};

    if ('userBankId' in data && data.paymentType === 'ideal') {
      sendData = {
        type: data.paymentType,
        g_recaptcha_response: data.g_recaptcha_response,
        user_bank_id: data.userBankId,
      };
    } else if ('extraData' in data) {
      if (!data.token) {
        throw new Error('BILLING_INFORMATION_INCOMPLETE');
      }
      sendData = {
        ...data.extraData,
        type: data.paymentType,
        token: data.token,
        ...(data.three_ds_token && { three_ds_token: data.three_ds_token }),
      };
    }

    await this._httpTransport.put('/v1/accounts/mine/billinginfo', sendData);
  }

  public async getInvoices(): Promise<IInvoices[]> {
    const response = await this._httpTransport.get<IInvoices[]>('/v2/accounts/mine/invoices');
    if (!response?.data) {
      return [];
    }
    return response.data;
  }

  public calculateCanChangeOwnBirthdate(): boolean {
    const userBirthDate = parseUserBirthDate(this.user.birthdate || '');
    const newBirthDate = {
      year: userBirthDate.year || '',
      month: userBirthDate.month || '',
      day: userBirthDate.day || '',
    };
    return this.user.birthdate === null || this.isOfAge({ birthdate: newBirthDate });
  }

  public canChangeOwnBirthdate() {
    return this.calculateCanChangeOwnBirthdate();
  }

  public async getUserHasSubscriptionMatchedWithAllFilterFunctionNames(
    filters: SubscriptionFilterType,
  ): Promise<boolean> {
    return await this.getUserHasSubscriptionMatchedWithAllFilterFunctions(filters);
  }

  public async getUserHasSubscriptionMatchedWithAllFilterFunctions(filters: SubscriptionFilterType): Promise<boolean> {
    type FilterFunction = (subscriptions: ISubscriptionsResponseWithOrders[]) => ISubscriptionsResponseWithOrders[];

    const accSubscriptions = await this.getAccountSubscriptions();
    const subscriptionFilterHandlers: Record<string, FilterFunction> = {
      filterActiveSubscriptions: filterActiveSubscriptions,
      filterFamilySubscriptions: filterFamilySubscriptions,
      filterPersonalSubscriptions: filterPersonalSubscriptions,
      activeUtomikSubscriptions: (accSubscriptions: ISubscriptionsResponseWithOrders[]) =>
        activeUtomikSubscriptions({ subscriptions: accSubscriptions, filterType: 'activeUtomikSubscriptions' }),
    };
    const filteredSubscriptions = filters.map((filterName) => {
      const strategy = subscriptionFilterHandlers[filterName];
      return strategy ? strategy(accSubscriptions) : [];
    });
    const hasMatchedWithAllFilters = filteredSubscriptions.every((filtered) => filtered.length > 0);

    return hasMatchedWithAllFilters;
  }

  public async revokeDeletion() {
    await this._httpTransport.post('/v1/users/me/do_revoke_deletion', {});
  }

  public async requestDeletion(): Promise<IRequestDeletion> {
    const response = await this._httpTransport.post<IRequestDeletion, undefined>(
      '/v1/users/me/do_request_deletion',
      undefined,
    );

    if (!response?.data) {
      return { deletion_date: null };
    }
    return response.data;
  }

  public async checkInviteToken({ inviteToken }: { inviteToken: string }): Promise<void> {
    await this._httpTransport.post('/v1/actions/do_validate_invitetoken', {
      invite_token: inviteToken,
      ...(this.user.id && { account_id: this.user.id }),
    });
  }

  public async useInviteToken({ token }: { token: string }) {
    await this._httpTransport.post('/v1/users/me/do_accept_memberinvite', { invite_token: token });
  }

  public async checkIsEmailAvailable(email: string) {
    const response = await this._httpTransport.post<CheckIsEmailAvailableResponseData, CheckIsEmailAvailablePostData>(
      '/v1/actions/do_check_user',
      { email },
    );

    return response?.data?.email_available as boolean;
  }

  public clearStore() {
    this.setAccount(null);
    this.setAccountSubscription([]);
    this.setOrders([]);
    this.setAccountSubscriptionDefault([]);
    this.setBillingInfo(null);
  }
}
