import React from 'react';

import { Errors } from 'types/errors';
import { FamilyMembersCombined, IAccountSettingRequest, IInviteMember, StateInviteMember } from 'types/familyMembers';
import { SendStates } from 'types/shared';
import { ISubscriptionsResponseWithOrders } from 'types/subscriptions';
import { IDate, User } from 'types/users';

import Alert from 'app/components/alert/alert';
import Spinner from 'app/components/spinner/spinner';

import FamilyMemberWell from '../familyMemberWell/familyMemberWell';
import InviteFamilyMember from '../inviteFamilyMember/inviteFamilyMember';

interface IFamilyDashboard {
  subscription: ISubscriptionsResponseWithOrders | null;
  user: User;
  error: string;
  isLoading: boolean;
  familyMembers: FamilyMembersCombined[];
  resendingInvitationState: SendStates | null;
  removingInvitationState: SendStates | null;
  familyWellError: string;
  familyMemberInvitesRemaining: number;
  maxMembers: number;
  forbiddenEmails: string[];
  activeMemberEmail: string[];
  stateInvite: StateInviteMember;
  inviteMemberError: string;
  accountSettingsFormIsOpen: boolean;
  currentAccountsEdit: Array<string | number>;
  doRemoveAccountId: number | string | null;
  setCurrentAccountsEdit: (currentAccounts: Array<string | number>) => void;
  setStateInvite: (state: StateInviteMember) => void;
  isOfAge: (birthDate: string, minAge?: number) => boolean;
  resendInvitation: (familyMember: FamilyMembersCombined) => void;
  doRemoveAccount: (familyMember: FamilyMembersCombined) => void;
  isValidDate: (date: IDate) => boolean;
  doInvite: (inviteMember: IInviteMember) => void;
  setAccountSettingsFormIsOpen: (isOpen: boolean) => void;
  doSaveAccountSettings: (data: IAccountSettingRequest) => Promise<void>;
}

const FamilyDashboard: React.FC<IFamilyDashboard> = ({
  isLoading,
  error,
  subscription,
  user,
  familyMembers,
  resendingInvitationState,
  removingInvitationState,
  familyWellError,
  familyMemberInvitesRemaining,
  maxMembers,
  forbiddenEmails,
  activeMemberEmail,
  stateInvite,
  inviteMemberError,
  accountSettingsFormIsOpen,
  currentAccountsEdit,
  doRemoveAccountId,
  setCurrentAccountsEdit,
  isOfAge,
  resendInvitation,
  doRemoveAccount,
  setStateInvite,
  isValidDate,
  doInvite,
  setAccountSettingsFormIsOpen,
  doSaveAccountSettings,
}) => {
  return (
    <div>
      {isLoading && <Spinner>Loading family details&hellip;</Spinner>}
      {!isLoading &&
        error &&
        (error === Errors.MEMBER_FAMILY_MANAGE ? (
          <Alert info icon="info-circle">
            {error}
          </Alert>
        ) : (
          <Alert danger>{error}</Alert>
        ))}
      {!isLoading && !(subscription?.type === 'SF') && !error && <Alert danger>You do not have a family plan.</Alert>}
      {subscription?.type === 'SF' && user.account?.role === 'Owner' && (
        <React.Fragment>
          <div>
            {!isLoading &&
              !error &&
              familyMembers.map((member) => (
                <React.Fragment key={member.id}>
                  <FamilyMemberWell
                    familyMember={member}
                    resendingInvitationState={resendingInvitationState}
                    removingInvitationState={removingInvitationState}
                    isOfAge={isOfAge}
                    familyWellError={familyWellError}
                    activeMemberEmail={activeMemberEmail}
                    accountSettingsFormIsOpen={accountSettingsFormIsOpen}
                    doRemoveAccountId={doRemoveAccountId}
                    resendInvitation={resendInvitation}
                    doRemoveAccount={doRemoveAccount}
                    setAccountSettingsFormIsOpen={setAccountSettingsFormIsOpen}
                    isValidDate={isValidDate}
                    doSaveAccountSettings={doSaveAccountSettings}
                    setCurrentAccountsEdit={setCurrentAccountsEdit}
                    currentAccountsEdit={currentAccountsEdit}
                  />
                </React.Fragment>
              ))}
          </div>
          {user && !user.deletion_date && (
            <div>
              <InviteFamilyMember
                invitesRemaining={familyMemberInvitesRemaining}
                maxMembers={maxMembers}
                forbiddenEmails={forbiddenEmails}
                state={stateInvite}
                inviteMemberError={inviteMemberError}
                setStateInvite={setStateInvite}
                isValidDate={isValidDate}
                isOfAge={isOfAge}
                doInvite={doInvite}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
export default FamilyDashboard;
