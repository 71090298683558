import { action, computed, makeObservable, observable } from 'mobx';
import InitPagesService from 'services/initPagesService/initPagesService';
import UserService from 'services/userService/userService';

import { translateErrors } from 'utils/translateErrors';

type Step = 'user_pending_deletion' | 'user_loaded' | 'user_load_error' | 'user_saved_successfully';

export default class NewsLetterController extends InitPagesService {
  public constructor(private readonly _userService: UserService) {
    super();
    makeObservable(this);
  }

  @observable
  private _isFetchingNewsLetter = false;

  @action
  public setIsFetchingNewsLetter(isFetching: boolean) {
    this._isFetchingNewsLetter = isFetching;
  }

  @computed
  public get isFetchingNewsLetter() {
    return this._isFetchingNewsLetter;
  }

  @observable
  private _showNewsLetter = false;

  @action
  public setShowNewsLetter(isShowNewsLetter: boolean) {
    this._showNewsLetter = isShowNewsLetter;
  }

  @computed
  public get showNewsLetter() {
    return this._showNewsLetter;
  }

  @observable
  private _step: Step = 'user_loaded';

  @action
  public setStep(step: Step) {
    this._step = step;
  }

  @computed
  public get step() {
    return this._step;
  }

  @observable
  private _patchUserError = '';

  @action
  public setPatchUserError(patchError: string) {
    this._patchUserError = patchError;
  }

  @computed
  public get patchUserError() {
    return this._patchUserError;
  }

  public initPage = async () => {
    this.setIsLoading(true);
    try {
      if (!this._userService.user.id) {
        await this._userService.fetchUser();
      }
      const user = this._userService.user;
      this.setStep(user.deletion_date ? 'user_pending_deletion' : 'user_loaded');
    } catch (e) {
      this.setStep('user_load_error');
      this.setFetchError(translateErrors('ACCOUNT_ERROR'));
    } finally {
      this.setIsLoading(false);
    }
  };

  public confirmAndCallback = async () => {
    try {
      const user = this._userService.user;
      this.setIsFetchingNewsLetter(true);
      await this._userService.patchUser({ newsletter_signup: true });
      this._userService.setUser({ ...user, newsletter_signup: true });
      this.setIsFetchingNewsLetter(false);
    } finally {
      this.setShowNewsLetter(false);
    }
  };
  public rejectAndCallback = async () => {
    try {
      const user = this._userService.user;
      this.setIsFetchingNewsLetter(true);
      await this._userService.patchUser({ newsletter_signup: false });
      this._userService.setUser({ ...user, newsletter_signup: false });
      this.setIsFetchingNewsLetter(false);
    } finally {
      this.setShowNewsLetter(false);
    }
  };

  public checkIsShowNewsLetter = () => {
    const newsLetter = this._userService.getNewsLetter();
    this.setShowNewsLetter(newsLetter === null);
  };

  public currentNewsLetter = () => {
    return this._userService.user.newsletter_signup;
  };

  public toggleNewsletter = async () => {
    const user = this._userService.user;
    if (user.id) {
      try {
        this.setIsFetchingNewsLetter(true);
        const id = user.id;
        const newsletter_signup = user.newsletter_signup;
        const newsLetterToUpdate = newsletter_signup ? false : true;
        await this._userService.patchUser({ id: id, newsletter_signup: newsLetterToUpdate });
        this._userService.setUser({ ...user, newsletter_signup: newsLetterToUpdate });
        this.setStep('user_saved_successfully');
        setTimeout(() => {
          this.setStep('user_loaded');
        }, 4000);
      } catch (e) {
        this.setPatchUserError('Something went wrong, please try again later.');
        setTimeout(() => {
          this.setPatchUserError('');
          this.setStep('user_loaded');
        }, 5000);
      } finally {
        this.setIsFetchingNewsLetter(false);
      }
    }
  };

  public get user() {
    return this._userService.user;
  }
  public closeNewsLetterModal = () => {
    this.setShowNewsLetter(false);
  };
}
