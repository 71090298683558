import { changePasswordSchema } from 'app/validationSchemes/changePasswordSchema';
import { FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useState } from 'react';

import { translateErrors } from 'utils/translateErrors';

import { PageControllerContext, UserServiceContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Input from 'app/components/input/input';

import styles from './changePasswordForm.module.scss';

type FormValues = {
  password: string;
  confirmPassword: string;
};

const initialValues: FormValues = {
  password: '',
  confirmPassword: '',
};
const ChangePasswordForm = observer(() => {
  const userService = useContext(UserServiceContext);
  const pageController = useContext(PageControllerContext);
  const [errorMessageRequest, setErrorMessageRequest] = useState('');
  const [isFetchingChangePassword, setIsFetchingChangePassword] = useState(false);
  const [isCompleteChangePassword, setIsCompleteChangePassword] = useState(false);

  const onSubmit = async (values: FormValues) => {
    setIsFetchingChangePassword(true);
    const dataToRequest = {
      newPassword1: values.password,
      newPassword2: values.confirmPassword,
    };
    const queryParams = pageController.getQueryParams();
    try {
      if (!queryParams?.token) {
        throw new Error('INVALID_TOKEN');
      }
      await userService.fetchChangePassword(dataToRequest, `token=${queryParams?.token}`);
      setIsCompleteChangePassword(true);
    } catch (error: any) {
      setErrorMessageRequest(translateErrors(error));
    } finally {
      setIsFetchingChangePassword(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: onSubmit,
    validateOnBlur: false,
  });

  const handlePasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value.length && !formik.touched['password'] && formik.setFieldTouched('password', true);

    formik.handleChange(e);
  }, []);

  const handleConfirmPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value.length && !formik.touched[`confirmPassword`] && formik.setFieldTouched('confirmPassword', true);

    formik.handleChange(e);
  }, []);

  const isDisabled = (errors: any, values: FormValues) =>
    !!(errors.confirmPassword || errors.password || !values.confirmPassword || !values.password);

  return (
    <section className="page-section">
      <h1 className={styles['change-form-title']}>New password</h1>
      {!isCompleteChangePassword && (
        <>
          <p className={styles['change-form-subtitle']}>Create a new password for your account.</p>
          <FormikProvider value={formik}>
            <div className={styles['form-change']}>
              <form onSubmit={formik.handleSubmit}>
                <Input
                  label="New password:"
                  name="password"
                  title="Please fill in this field."
                  onChange={(e) => {
                    handlePasswordChange(e);
                  }}
                  onBlur={() => {
                    return;
                  }}
                  type="password"
                  placeholder="Password"
                  showCheck={!formik.errors.password && !!formik.values.password && !isFetchingChangePassword}
                  isLoading={isFetchingChangePassword}
                  disabled={isFetchingChangePassword}
                />
                <Input
                  label="New password (again):"
                  name="confirmPassword"
                  title="Please fill in this field."
                  type="password"
                  onChange={(e) => {
                    handleConfirmPasswordChange(e);
                  }}
                  onBlur={() => {
                    return;
                  }}
                  placeholder="Confirm password"
                  showCheck={
                    !formik.errors.confirmPassword && !!formik.values.confirmPassword && !isFetchingChangePassword
                  }
                  isLoading={isFetchingChangePassword}
                  disabled={isFetchingChangePassword}
                />
                {!!errorMessageRequest && (
                  <Alert danger>
                    <span dangerouslySetInnerHTML={{ __html: errorMessageRequest }} />
                  </Alert>
                )}
                <Button
                  type="submit"
                  disabled={isDisabled(formik.errors, formik.values) || isFetchingChangePassword}
                  xl
                  loading={isFetchingChangePassword}
                  name="Change password"
                >
                  Change my password
                </Button>
              </form>
            </div>
          </FormikProvider>
        </>
      )}
      {isCompleteChangePassword && <p>Your password has been changed. You can now log in using your new password.</p>}
    </section>
  );
});
export default ChangePasswordForm;
