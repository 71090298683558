import { ReactNode } from 'react';

import { Routes } from 'app/global/routes';

interface IPageNavigationProps {
  handleLogout?: (e: React.MouseEvent<HTMLElement>) => void;
  accountMembers?: string;
}

interface IPageNavigation {
  title: string;
  subTitle: string;
  linkTitle?: string;
  icon: ReactNode;
  redirectTo: string;
  visible: string;
  action?: (e: React.MouseEvent<HTMLElement>) => void | undefined;
  id: string;
}

export const getPageNavigation = ({
  handleLogout = undefined,
  accountMembers,
}: IPageNavigationProps): IPageNavigation[] => [
  {
    title: 'Manage family members',
    subTitle: `Manage your family member accounts. ${accountMembers} used.`,
    linkTitle: 'Manage family members',
    icon: <span className="fas fa-users"></span>,
    redirectTo: Routes.Family,
    visible: 'canManageFamilyMembers',
    id: 'family-members',
  },
  {
    title: 'Manage subscription',
    subTitle: 'View and manage your subscription',
    linkTitle: 'Manage subscription',
    icon: <span className="fas fa-id-card"></span>,
    redirectTo: Routes.Subscription,
    visible: 'canManageSubscriptionAndBillingInfo',
    id: 'manage-subscription',
  },
  {
    title: 'Billing information',
    subTitle: 'Update your billing information',
    linkTitle: 'Billing information',
    icon: <span className="far fa-credit-card"></span>,
    redirectTo: Routes.Billing,
    visible: 'canManageSubscriptionAndBillingInfo',
    id: 'billing-information',
  },
  {
    title: 'Invoices',
    subTitle: 'View your payment history',
    linkTitle: 'View invoices',
    icon: <span className="far fa-calendar-alt"></span>,
    redirectTo: Routes.Invoices,
    visible: 'canManageSubscriptionAndBillingInfo',
    id: 'invoices',
  },
  {
    title: 'Edit profile',
    subTitle: `Make changes to your <span>master</span> account details`,
    linkTitle: 'Edit profile',
    icon: <span className="far fa-user-circle"></span>,
    redirectTo: Routes.Profile,
    visible: 'edit-profile',
    id: 'edit-profile',
  },
  {
    title: 'Manage newsletter',
    subTitle: `Change your newsletter subscription`,
    linkTitle: 'Manage newsletter',
    icon: <span className="fas fa-book-reader"></span>,
    redirectTo: Routes.Newsletter,
    visible: 'newsletter',
    id: 'newsletter',
  },
  {
    title: 'Support',
    subTitle: `Have a question? Contact our customer support`,
    icon: <span className="far fa-comments"></span>,
    redirectTo: 'https://support.utomik.com/hc/en-us/requests/new',
    visible: 'support',
    id: 'support',
  },
  {
    title: 'Upgrade to Family Plan',
    subTitle: `Add family members to your account`,
    linkTitle: 'Upgrade plan',
    icon: <span className="fas fa-exchange-alt"></span>,
    redirectTo: Routes.Subscription,
    visible: 'canSwitchFromUtomikPersonalToUtomikFamilyPlan',
    id: 'family-Plan',
  },
  {
    title: 'Leave Family Plan',
    subTitle: 'You are currently linked to a family plan. Do you want to leave it?',
    linkTitle: '',
    icon: <span className="fas fa-user-times"></span>,
    redirectTo: '',
    visible: 'canLeaveFamily',
    id: 'leave-family',
  },
  {
    title: 'Log out',
    subTitle: ``,
    linkTitle: 'Log out',
    icon: null,
    redirectTo: '',
    visible: 'logOut',
    action: handleLogout,
    id: 'logOut',
  },
];
