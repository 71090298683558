import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext } from 'react';

import { DeleteAccountControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';

import styles from './deleteAccount.module.scss';

const DeleteAccountHomePage = observer(() => {
  const deleteAccountController = useContext(DeleteAccountControllerContext);
  const { revokeDeletion, isFetching, errorRequest, user } = deleteAccountController;
  return (
    <div className={styles['delete-home-container']}>
      <h1 className={styles['delete-home-title']}>Your account is pending deletion</h1>
      <Alert warning isShowIcon={false}>
        Your account will be deleted within 2 working days after {moment(user.deletion_date).format('MMMM Do, YYYY')}.
      </Alert>
      {errorRequest && <Alert danger>{errorRequest}</Alert>}

      <Button primary lg onClick={revokeDeletion} loading={isFetching} disabled={isFetching}>
        Revoke request
      </Button>
    </div>
  );
});
export default DeleteAccountHomePage;
