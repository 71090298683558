import clsx, { ClassValue } from 'clsx';
import { cloneElement } from 'react';
import ModalComponent, { ModalProps as ModalComponentProps, RenderModalBackdropProps } from 'react-overlays/Modal';
import { Transition, TransitionStatus } from 'react-transition-group';

import styles from './modal.module.scss';

export type ModalProps = ModalComponentProps & {
  className?: ClassValue | ClassValue[];
  title?: string;
  closeOnBackdropClick?: boolean;
  fullWidth?: boolean;
  onHide: () => void;
};

const FADE_DURATION = 300;

const fadeStyles: Record<TransitionStatus, string | undefined> = {
  entering: styles['fade--entering'],
  entered: styles['fade--entered'],
  exiting: styles['fade--exiting'],
  exited: styles['fade--exited'],
  unmounted: undefined,
};

const Fade: React.FC<{ children: React.ReactElement<{ className: string }> }> = ({ children, ...props }) => (
  <Transition {...props} timeout={FADE_DURATION}>
    {(status, innerProps) =>
      cloneElement(children, {
        ...innerProps,
        className: clsx(styles.fade, fadeStyles[status], children.props.className),
      })
    }
  </Transition>
);

const backdropFadeStyles: Record<TransitionStatus, string | undefined> = {
  entering: styles['backdrop-fade--entering'],
  entered: styles['backdrop-fade--entered'],
  exiting: styles['backdrop-fade--exiting'],
  exited: styles['backdrop-fade--exited'],
  unmounted: styles['backdrop-fade--unmounted'],
};

const BackdropFade: React.FC<{ children: React.ReactElement<{ className: string }> }> = ({ children, ...props }) => (
  <Transition {...props} timeout={FADE_DURATION}>
    {(status, innerProps) =>
      cloneElement(children, {
        ...innerProps,
        className: clsx(styles['backdrop-fade'], backdropFadeStyles[status], children.props.className),
      })
    }
  </Transition>
);

const Modal: React.FC<ModalProps> = ({
  className,
  title,
  children,
  onHide,
  closeOnBackdropClick = true,
  fullWidth = false,
  ...props
}) => {
  const renderBackdrop = (backdropProps: RenderModalBackdropProps) => (
    <div
      role="presentation"
      className={clsx(styles.modal__backdrop, className && `${className}__backdrop`)}
      {...backdropProps}
    />
  );

  return (
    <ModalComponent
      containerClassName={styles.modal__container}
      className={clsx(styles.modal, fullWidth && styles['modal-fullWidth'], className)}
      renderBackdrop={renderBackdrop}
      onBackdropClick={closeOnBackdropClick ? onHide : undefined}
      aria-labelledby="modal-label"
      onEscapeKeyDown={onHide || props.onEscapeKeyDown}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      transition={Fade}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      backdropTransition={BackdropFade}
      {...props}
    >
      <div className={styles.modal__content}>
        <div className={styles.modal__header}>
          <button
            className={styles['modal__close-button']}
            role="button"
            data-testid="close-button-modal"
            onClick={onHide}
          >
            <span>×</span>
          </button>

          {title && (
            <h4 className={styles.modal__title} id="modal-label">
              {title}
            </h4>
          )}
        </div>

        <div className={styles.modal__body}>{children}</div>
      </div>
    </ModalComponent>
  );
};

export default Modal;
